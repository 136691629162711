import React, { FC } from 'react';

import { UserAvatar, Badge } from 'components';
import { DefaultAvatar, LeftCloseArrow } from 'static';
import { ChatRoles, CommunityPermission, GroupPermission } from 'types';

import { StyledBlock, StyledName } from './styled';
import { HighlightText } from 'pages/PersonalChat/ChatUserList';
import { Layout } from 'pages/PersonalChat/Chat/styled';

interface ProfileNameProps {
  avatar: string;
  name: string;
  communityPermission?: CommunityPermission;
  groupPermission?: GroupPermission;
  zoom?: number;
  size?: 'sm' | 'md';
  onClick?: () => void;
  searchFilter?: string;
  icon?: boolean;
  chatAdmin?: boolean;
}

const ProfileName: FC<ProfileNameProps> = ({
  avatar = DefaultAvatar,
  name,
  communityPermission,
  groupPermission,
  zoom = 1,
  size = 'md',
  onClick = () => {},
  searchFilter,
  icon,
  chatAdmin,
}) => (
  <StyledBlock className="profile-name-wrapper" onClick={onClick}>
    <div>
      <UserAvatar avatar={avatar} alt={name} size={size} zoom={zoom} />
      <StyledName size={size} zoom={zoom}>
        <HighlightText searchText={searchFilter} text={name} />
      </StyledName>
    </div>
    <div>
      {communityPermission && (
        <Badge
          userRole={communityPermission.role}
          styleConfig={{
            shape: 'square',
          }}
          tooltipContent={communityPermission.role}
          isShowTooltip
          isShowBadge={
            communityPermission.role === 'owner' ||
            communityPermission.role === 'admin'
          }
          permissionType="Community"
          zoom={zoom}
        />
      )}
      {groupPermission && (
        <Badge
          userRole={groupPermission.role}
          styleConfig={{
            shape: 'square',
            backgroundColor: '#8298ab',
            left:
              communityPermission?.role === 'owner' ||
              communityPermission?.role === 'admin'
                ? 0
                : 5,
          }}
          tooltipContent={groupPermission.role}
          isShowTooltip
          isShowBadge={
            groupPermission.role === 'admin' ||
            groupPermission.role === 'group_lead'
          }
          permissionType="Group"
          zoom={zoom}
        />
      )}
      {chatAdmin && (
        <Badge
          userRole={ChatRoles.ADMIN}
          styleConfig={{
            shape: 'square',
            backgroundColor: 'rgb(255, 255, 255)',
            border: '1px solid #8298ab',
            left:
              communityPermission?.role === 'owner' ||
              communityPermission?.role === 'admin' ||
              groupPermission?.role === 'admin' ||
              groupPermission?.role === 'group_lead'
                ? 0
                : 5,
          }}
          tooltipContent={ChatRoles.ADMIN}
          isShowTooltip
          textColor="#8298ab"
          isShowBadge
          permissionType="Chat"
          zoom={zoom}
        />
      )}
    </div>
    {icon && (
      <Layout.Header.Close style={{ marginLeft: 'auto' }}>
        <LeftCloseArrow width={20} height={20} />
      </Layout.Header.Close>
    )}
  </StyledBlock>
);

export default ProfileName;
