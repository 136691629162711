import styled from 'styled-components';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

export const SharedStyledPickerWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > .react-datepicker-wrapper:hover + img {
    filter: brightness(0) saturate(100%) invert(73%) sepia(79%) saturate(2725%)
      hue-rotate(128deg) brightness(95%) contrast(87%);
  }

  & > .react-datepicker__tab-loop + img {
    filter: brightness(0) saturate(100%) invert(73%) sepia(79%) saturate(2725%)
      hue-rotate(128deg) brightness(95%) contrast(87%);
  }

  & > .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker-popper {
    z-index: 100;
  }
`;

export const SharedStyledDatePicker = styled(DatePicker)`
  height: 39px;
  border: 1px solid
    ${({ error, theme }) =>
      error ? theme.color.bittersweet : theme.color.botticelli};
  border-radius: 4px;
  outline: none;
  padding-left: 14px;
  font-weight: 600;
  font-size: 13px;
  line-height: 32px;
  color: ${({ theme }) => theme.color.pickledBluewood};
  cursor: pointer;

  &:focus {
    border-color: ${({ theme }) => theme.color.brightTurquoise};
    color: ${({ theme }) => theme.color.brightTurquoise};
  }
`;

export const SharedStyledDateIcon = styled.img`
  position: absolute;
  right: 10px;
  bottom: 10px;
`;
