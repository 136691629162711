import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import {
  Sidebar,
  SidebarHeader,
  SidebarContent,
  ColumnInfoBlock,
} from 'components';

import { closeModal } from 'store/actionCreators/modal';

import {
  ListWrapper,
  Wrapper,
  ImageWrapper,
  SkeletonListWrapper,
} from './styled';
import { Title } from 'pages/UserProfile/Content/styled';
import ParticipantsTab from 'sidebars/EventDetails/Participants';
import { useSelector } from 'react-redux';
import {
  getCurrentCommunity,
  getCurrentCommunityLoading,
} from 'store/selectors/currentCommunity';
import { Chat, ChatUser } from 'store/reducers/chat';
import { getData } from 'store/selectors/modal';
import ImageBox from 'components/Inputs/ImageInput/ImageBox';

const AboutChatGroup = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const community = useSelector(getCurrentCommunity);
  const currentCommunityLoading = useSelector(getCurrentCommunityLoading);
  const { chat, members } = useSelector(getData) as {
    chat: Chat;
    members: ChatUser[];
    communityId: string;
    createdAt: string;
  };

  const handleClose = () => {
    dispatch(closeModal());
  };

  const uniqueUsers = useMemo(() => {
    if (!community?.groups) return [];

    const userMap = new Map();

    community.groups.forEach((group) => {
      group.users.forEach((user) => {
        if (!userMap.has(user.id)) {
          userMap.set(user.id, user);
        } else {
          const existingUser = userMap.get(user.id);
          if (existingUser.groupPermission.role === 'member') {
            userMap.set(user.id, user);
          }
        }
      });
    });

    return Array.from(userMap.values());
  }, [community?.groups]);

  const groupUsers = useMemo(() => {
    if (!community?.users || !members?.length) return [];

    const memberIds = new Set(members.map((m) => m.userId));

    return community.users.reduce((acc, user) => {
      if (memberIds.has(user.profile.userId)) {
        acc.push({
          ...user.profile,
          user,
          groupPermission:
            uniqueUsers.find((u) => u.id === user.id)?.groupPermission || null,
          communityPermission: user.communityPermission,
        });
      }
      return acc;
    }, []);
  }, [community?.users, members, uniqueUsers]);

  return (
    <Sidebar>
      <SidebarHeader
        label={t('chat.details.title')}
        onCloseClick={handleClose}
      />
      <SidebarContent fullHeight>
        <Wrapper>
          <ImageWrapper>
            <ImageBox src={chat.logo} image={chat.logo} />
          </ImageWrapper>
          <Title>
            <p>{t('chat.details.secondTitle')}</p>
          </Title>
          <ColumnInfoBlock
            label={t('chat.details.name')}
            text={chat.chatName}
            name={chat.chatName}
            preventEdit
            style={{
              fontSize: '13px',
              fontFamily: 'Open Sans,sans-serif',
              color: '#304659',
            }}
          />
          <ColumnInfoBlock
            label={t('common.description')}
            text={chat.description}
            name={chat.description}
            preventEdit
            isDescription
            linkifyStyle={{
              fontSize: '13px',
              fontFamily: 'Open Sans,sans-serif',
              color: '#304659',
            }}
          />
          {/* <ColumnInfoBlock
            label={'Created At'}
            text={createdAt}
            name={createdAt}
            preventEdit
          /> */}
        </Wrapper>
        <Wrapper>
          <Title>
            <p>{t('common.member')}</p>
          </Title>
        </Wrapper>
        <ListWrapper>
          {currentCommunityLoading ? (
            <SkeletonListWrapper>
              {Array.from({ length: 3 }).map((_, index) => (
                <div key={index} className="skeleton skeleton-list" />
              ))}
            </SkeletonListWrapper>
          ) : (
            <ParticipantsTab
              users={groupUsers}
              height="auto"
              admin={chat.admin}
            />
          )}
        </ListWrapper>
      </SidebarContent>
    </Sidebar>
  );
};

export default AboutChatGroup;
