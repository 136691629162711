import { ChatResponse, Conversation } from 'store/reducers/chat';
import { axiosInstance as axios } from './index';
import { default as axiosInstance } from 'axios';
import { validImageSize } from 'pages/PersonalChat/Chat';
import { doUpdateUpdateCount } from 'store/actionCreators/chat';

export const getUnSeenChatSeenCount = async (
  userId: string,
  communityId?: string,
) => {
  const params = {};

  if (communityId) params['communityId'] = communityId;
  if (userId) params['userId'] = userId;

  const { data } = await axios.get('/getUnreadCountTotal', {
    params,
  });
  return data;
};

export const getChatUsers = async ({
  userId,
  communityId,
  unseen,
  search,
}: {
  userId: string;
  communityId?: string;
  unseen: boolean;
  search?: string;
}): Promise<ChatResponse> => {
  const params: Record<string, any> = {};

  if (unseen) params['unseen'] = unseen;
  if (communityId) params['communityId'] = communityId;
  if (search) params['search'] = search;

  const { data } = await axios.get<ChatResponse>(
    `/userId/${userId}/getUserChatList`,
    {
      params,
    },
  );
  return data;
};
export type MediaDetailData = {
  filename: string;
  mimetype: string;
  size: number;
  ext: string;
};
export interface GetPresignedUrl {
  userId: string;
  chatId: string;
  mediaDetail?: File[];
  dispatch: any;
  success?: (urls: string[]) => void;
}

export type SendMessagePayload = {
  userId: string;
  isGroup?: boolean;
  chatId: string;
  content: string;
  type: 'text' | 'image' | 'file';
  communityId?: string;
  receiverId?: string;
  mediaUrls?: string[];
  onSuccess: () => void;
};

export const getPresignedUrl = async (payload: GetPresignedUrl) => {
  const { userId, mediaDetail, chatId } = payload;
  const formData = new FormData();
  if (mediaDetail && mediaDetail.length > 0) {
    const detail = mediaDetail.map((file) => ({
      ext: file.type.split('/')?.[1],
      filename: file.name,
      mimetype: file.type,
      size: file.size / 1000000000,
    }));
    formData.append('mediaDetail', JSON.stringify(detail));
    formData.append('chatId', chatId);
  }
  const endpoint = `/userId/${userId}/getPresignedUrl`;
  try {
    const response = await axios.post(endpoint, formData);
    const presignedUrls = response.data?.presignedUrls;
    const mediaUrls = response.data?.mediaUrls;
    let uploadedCount = 0;
    const totalFiles = presignedUrls.length;
    if (Array.isArray(presignedUrls) && presignedUrls.length > 0) {
      const uploadPromises = presignedUrls.map(async (url, index) => {
        const mediaFile = mediaDetail[index];
        if (url && mediaFile) {
          try {
            console.log('mediaFile', mediaFile);

            await axiosInstance.put(url, mediaFile);
            uploadedCount++;
            const remainingFiles = totalFiles - uploadedCount;

            payload.dispatch(
              doUpdateUpdateCount.success({
                uploadedCount,
                remainingFiles,
                totalFiles,
              }),
            );

            return {
              uploadedCount,
              remainingFiles,
            };
          } catch (uploadError) {
            console.error(`Error uploading file ${index + 1}:`, uploadError);
            return Promise.reject(uploadError);
          }
        } else {
          const error = new Error(
            `Missing URL or media file for index ${index}`,
          );
          console.error(error.message);
          return Promise.reject(error);
        }
      });
      await Promise.all(uploadPromises);
      await payload.success(mediaUrls);
      return { presignedUrls, mediaUrls, success: true };
    }
    throw new Error('No presigned URLs or media URLs found.');
  } catch (error) {
    console.error('Error fetching presigned URLs:', error);
    throw error;
  }
};

export const sendMessage = async (payload: SendMessagePayload) => {
  const { userId, isGroup, ...messageData } = payload;
  const formData = new FormData();
  formData.append('content', messageData.content || '');
  formData.append('type', payload.type);
  if (payload.chatId) {
    formData.append('chatId', payload.chatId);
  }
  if (payload.receiverId) {
    formData.append('receiverId', payload.receiverId);
  }
  if (payload.communityId) {
    formData.append('communityId', payload.communityId);
  }

  if (payload.mediaUrls.length > 0) {
    formData.append('mediaUrls', JSON.stringify(payload.mediaUrls));
  }

  const endpoint = isGroup
    ? `/userId/${userId}/sendMessageToGroup`
    : `/userId/${userId}/sendMessages`;

  const response = await axios.post(endpoint, formData);
  return response.data;
};

export const getChatSearchUsers = async ({ userId, search }) => {
  const params = {};

  params['search'] = search || '';

  const { data } = await axios.get(`/userId/${userId}/chatSearch`, {
    params,
  });
  return data;
};

export const getConversation = async (
  userId: string,
  receiverId: string,
  chatId: string,
  limit?: number,
  page?: number,
) => {
  const url = `/userId/${userId}/getChats/`;
  const params: { [key: string]: string } = {};

  if (chatId) {
    params.chatId = chatId;
  }

  if (receiverId) {
    params.receiverId = receiverId;
  }

  if (limit) {
    params.limit = limit.toString();
  }
  if (page) {
    params.page = page.toString();
  }

  const { data } = await axios.get(url, { params });
  return data;
};

export type CreateChatGroupPayload = {
  chatName: string;
  description: string;
  logo: string;
  members: string[];
  admin: string[];
};

export type CreateChatGroupResponse = {
  success: boolean;
  message: string;
  chat: Conversation;
};

export const createChatGroup = async (
  userId: string,
  payload: CreateChatGroupPayload,
  communityId: string,
): Promise<CreateChatGroupResponse> => {
  const formData = new FormData();

  formData.append('chatName', payload.chatName);
  formData.append('description', payload.description);

  if (typeof payload.logo === 'object' && payload.logo !== null) {
    formData.append('logo', payload.logo);
  } else if (payload.logo === null) {
    formData.append('logo', '');
  }
  formData.append('members', JSON.stringify(payload.members));
  if (payload.admin) formData.append('admins', JSON.stringify(payload.admin));

  const { data } = await axios.post(
    `communityId/${communityId}/userId/${userId}/createGroupChat`,
    formData,
  );
  return data;
};

export const deleteChatGroup = async (userId: string, chatId: string) => {
  const { data } = await axios.delete(
    `userId/${userId}/deleteGroupChat/chatId/${chatId}`,
  );
  console.log('data=>>>>>', data);
  return data;
};

export type UpdateChatGroupPayload = {
  chatName: string;
  description: string;
  logo: string | File | null;
  members: string[];
  admin: string[];
};

export type UpdateChatGroupResponse = {
  success: boolean;
  message: string;
};

export const updateChatGroup = async (
  userId: string,
  chatId: string,
  payload: UpdateChatGroupPayload,
  communityId: string,
): Promise<UpdateChatGroupResponse> => {
  console.log('payload', payload);

  const formData = new FormData();

  // Only append defined fields
  if (payload.chatName) formData.append('chatName', payload.chatName);
  if (payload.description) formData.append('description', payload.description);
  if (payload.members)
    formData.append('members', JSON.stringify(payload.members));
  if (payload.admin) formData.append('admins', JSON.stringify(payload.admin));

  // Handle logo field
  if (typeof payload.logo === 'object' && payload.logo !== null) {
    formData.append('logo', payload.logo);
  } else if (payload.logo === null) {
    formData.append('logo', '');
  }
  const { data } = await axios.put(
    `communityId/${communityId}/userId/${userId}/updateGroupChat/chatId/${chatId}`,
    formData,
  );
  return data;
};
