import React, { useState, useEffect, useCallback, useMemo } from 'react';
import cn from 'classnames';
import { reduce, assoc, filter, includes } from 'lodash/fp';

import { ProfileName, CheckBox } from 'components';
import { StyledListItem, StyledNameWrapper, StyledGroupItem } from './styled';
import { Down } from 'static';

const useGrid = (
  data,
  selectedItems,
  selectItem,
  selectGroup,
  getProfileName,
  searchFilter,
  members,
  chatAdmin = [],
) => {
  const [groups, setGroups] = useState({});

  useEffect(() => {
    setGroups(
      reduce(
        (acc, item) => {
          if (item.type === 'group') {
            acc[item.id] = {
              ...item,
              isShow: true,
            };
          }
          return acc;
        },
        {},
        data,
      ),
    );
  }, [data]);

  const filteredData = useMemo(
    () =>
      filter((item) => {
        if (item.type !== 'group') {
          return (
            groups[item.group]?.isShow &&
            (searchFilter
              ? includes(searchFilter.toLowerCase(), item.label?.toLowerCase())
              : true)
          );
        }
        return true;
      }, data),
    [data, groups, searchFilter],
  );

  const rowRenderer = useCallback(
    ({ index, key, style }) => {
      if (filteredData[index].type === 'group') {
        const groupUsersIds = data
          .filter((item) => item.group === filteredData[index].id)
          .map((item) => item.id);

        return (
          <div key={key} style={style}>
            <StyledGroupItem className="grid-group">
              <CheckBox
                isSelected={
                  groupUsersIds.length &&
                  groupUsersIds.every((id) => selectedItems[id])
                }
                onChange={(e) => selectGroup(e.target.name)}
                name={filteredData[index].id}
              />
              <span
                data-clickable="true"
                data-type="group"
                data-id={filteredData[index].id}
                className={cn(
                  'grid-group-toogle-container',
                  'grid-clickable-item',
                  {
                    'grid-group-toogle-container-close':
                      !groups[filteredData[index].id]?.isShow,
                  },
                )}
              >
                <Down />
              </span>
              {filteredData[index].label}
            </StyledGroupItem>
          </div>
        );
      }

      return (
        <StyledListItem key={key} style={style}>
          <CheckBox
            isSelected={selectedItems[filteredData[index].id]}
            onChange={(e) => selectItem(e.target.name)}
            name={filteredData[index].id}
          />
          <StyledNameWrapper>
            <ProfileName
              searchFilter={searchFilter}
              name={getProfileName(filteredData[index])}
              avatar={filteredData[index].icon}
              groupPermission={
                members.find((m) => m.id === filteredData[index].id)
                  ?.groupPermission
              }
              communityPermission={
                members.find((m) => m.id === filteredData[index].id)
                  ?.communityPermission
              }
              chatAdmin={chatAdmin.includes(filteredData[index].id)}
            />
          </StyledNameWrapper>
        </StyledListItem>
      );
    },
    [filteredData, selectedItems, groups, data],
  );

  const toogleGroup = useCallback(
    (id) => {
      if (groups[id]) {
        setGroups(assoc([id, 'isShow'], !groups[id].isShow, groups));
      }
    },
    [groups, setGroups],
  );

  const onClick = useCallback(
    (event) => {
      const elem = event.target.closest('[data-clickable="true"]');
      if (elem) {
        const { dataset } = elem;
        if (dataset.type === 'group') {
          toogleGroup(dataset.id);
        }
      }
    },
    [toogleGroup],
  );

  return {
    rowRenderer,
    onClick,
    filteredData,
  };
};

export default useGrid;
