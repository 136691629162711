import React from 'react';
import { useDispatch } from 'react-redux';

import { getProfileName } from 'utils/common';
import {
  ProfileName,
  DragIcon,
  EditButton,
  DeleteButton,
  IconText,
  IconDocument,
  IconTextWithSelect,
} from 'components';
import { CellsTypes } from 'constants/index';
import { VIEW_MEMBER } from 'constants/sidebars';
import { openModal } from 'store/actionCreators/modal';
import SpotCell from '../SpotCell';

import { StyledRow, StyledCell, StyledCellLink } from './styled';

const TableRow = ({ dataItem, config, withBadge, search, icon, admin }) => {
  const dispatch = useDispatch();

  const getCellProps = (type) => {
    switch (type) {
      case CellsTypes.EDIT:
      case CellsTypes.DELETE:
        return {
          center: true,
        };
      default:
        return null;
    }
  };

  const renderCellContent = (type, key) => {
    switch (type) {
      case CellsTypes.DRAGNDROP:
        return <DragIcon />;
      case CellsTypes.AVATAR_NAME:
        return (
          <ProfileName
            searchFilter={search}
            name={getProfileName(dataItem)}
            avatar={dataItem.smallLogo || dataItem.logo}
            communityPermission={
              withBadge ? dataItem.communityPermission : null
            }
            icon={icon}
            groupPermission={withBadge ? dataItem.groupPermission : null}
            chatAdmin={admin.includes(dataItem.user.id)}
            onClick={() =>
              dispatch(
                openModal({
                  type: VIEW_MEMBER,
                  placement: 'left',
                  data: dataItem.user,
                }),
              )
            }
          />
        );
      case CellsTypes.TASK:
        return <IconText text={dataItem.title} icon={dataItem.icon} />;
      case CellsTypes.TASK_WITH_SELECT:
        return (
          <IconTextWithSelect text={dataItem.title} icon={dataItem.icon} />
        );
      case CellsTypes.DOCUMENT:
        return (
          <StyledCellLink
            href={dataItem.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconDocument name={dataItem.name} />
          </StyledCellLink>
        );
      case CellsTypes.TEXT:
        return dataItem[key];
      case CellsTypes.ARRAY:
        return dataItem[key] ? dataItem[key].join(', ') : '-';
      case CellsTypes.TEXT_WITH_SPOT:
        return <SpotCell color={dataItem.color} text={dataItem[key]} />;
      case CellsTypes.EDIT:
        return <EditButton onClick={() => {}} />;
      case CellsTypes.DELETE:
        return <DeleteButton />;
      default:
        return dataItem[key];
    }
  };

  return (
    <StyledRow opacity={config.opacity}>
      {config.headers.map((cell) => (
        <StyledCell key={cell.id} {...getCellProps(cell.type)}>
          {renderCellContent(cell.type, cell.key)}
        </StyledCell>
      ))}
    </StyledRow>
  );
};

export default TableRow;
