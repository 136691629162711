import React, { FC, useMemo } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { openModal } from 'store/actionCreators/modal';
import { MenuListWrapper, Badge, Tooltip } from 'components';
import { VIEW_MEMBER } from 'constants/sidebars';
import { UserIcon } from 'static';
import { getProfileName } from 'utils';
import { useAlphabetSortUsers } from 'utils/common';
import { COMMUNITY_ROUTES, ROUTES } from 'configs';
import {
  ChatUser,
  CommunityRoles,
  CommunityUser,
  ModalTypes,
  GroupRoles,
  CommunityGroup,
} from 'types';

import {
  ChatIconWrapper,
  CountBadge,
  FlexBox,
  IconSendMessage,
  MessageCount,
  NameAndBadgeWrapper,
  StyledMenuList,
  StyledMenuListItem,
  StyledName,
} from './styled';
import { StyledShadow } from '../styled';
import { modalSelector } from 'store/selectors/modal';
// import { getClient } from 'store/selectors/chat';
import { getMyId } from 'store/selectors/auth';
import { useToasts } from 'react-toast-notifications';

interface CommunityMenuMembersListProps {
  list: ChatUser[];
  listRef: React.MutableRefObject<any>;
  listMaxHeight: number;
  userRole: CommunityRoles;
  users: CommunityUser[];
  groupList?: CommunityGroup[];
  to?: string;
  showArrow?: boolean;
  handleOnArrowClick?: () => void;
}

const CommunityMenuMembersList: FC<CommunityMenuMembersListProps> = ({
  list = [],
  listRef,
  listMaxHeight,
  userRole,
  users,
  groupList,
  to,
  showArrow,
  handleOnArrowClick,
}) => {
  // const [filteredChannels, setFilteredChannels] = useState([]);
  const authId = useSelector(getMyId);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const client = useSelector(getClient);
  const { communityId } = useParams();
  const { data: modalData, isOpen: isModalOpen } = useSelector(modalSelector);
  const sortedUsers = useAlphabetSortUsers(users);
  const { removeAllToasts } = useToasts();
  // const fetchChannels = async () => {
  //   try {
  //     // Fetch channels with the specified filters
  //     const filters = { members: { $in: [client.userID] } };
  //     // Fetch channels with the specified filters
  //     const channels = await client.queryChannels(filters, {}, { limit: 50 });
  //     const filteredChannels = channels.filter((channel) =>
  //       channel?.id?.startsWith('!members'),
  //     );
  //     setFilteredChannels(filteredChannels);
  //   } catch (error) {
  //     console.error('Error fetching channels:', error);
  //   }
  // };

  // useEffect(() => {
  //   if (client) fetchChannels();
  // }, [client, dispatch]);
  const tooltipProps = {
    tooltipStyle: {
      fontSize: 12,
      maxWidth: 200,
      whiteSpace: 'pre-wrap',
      overflow: 'hidden',
      lineBreak: 'normal',
    },
    opacity: '0.9',
    text: t('common.sendMessage'),
  };

  const formattedUsers = useMemo(() => {
    return sortedUsers.map((user) => {
      const isGroupAdmin = groupList.some((group) => {
        return group?.users?.some(
          (groupUser) =>
            groupUser.groupPermission.role === GroupRoles.ADMIN &&
            groupUser.id === user.id,
        );
      });
      const isGroupLeader = groupList.some((group) => {
        return group?.users?.some(
          (groupUser) =>
            groupUser.groupPermission.role === GroupRoles.GROUP_LEAD &&
            groupUser.id === user.id,
        );
      });
      return {
        user,
        isGroupAdmin,
        isGroupLeader,
      };
    });
  }, [groupList, sortedUsers]);

  const shadowShown =
    listRef.current?.getBoundingClientRect().height > listMaxHeight;

  const handleAddMemberPopupOpen = () => {
    navigate(
      `${ROUTES.COMMUNITY}/${communityId}/${COMMUNITY_ROUTES.SETTINGS}#members`,
    );

    setTimeout(() => {
      dispatch(
        openModal({
          type: ModalTypes.ADD_MEMBER,
        }),
      );
    }, 1500);
  };

  const handleRedirectToChat = (e, userId) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(
      `${ROUTES.COMMUNITY}/${communityId}/${COMMUNITY_ROUTES.CHAT}?peerId=${userId}`,
    );
    removeAllToasts();
  };

  const openViewMemberSidebar = (e, user: CommunityUser) => {
    if (user.unreadCount > 0) {
      handleRedirectToChat(e, user?.profile?.userId);
    } else {
      dispatch(
        openModal({
          type: VIEW_MEMBER,
          placement: 'right',
          data: user,
        }),
      );
    }
  };

  return (
    <MenuListWrapper
      arrowTooltipText={t('common.toolTipText.memberArrowIcon')}
      to={to}
      toolTipText={t('common.toolTipText.memberPlusIcon')}
      title={t('community.members')}
      icon={<UserIcon />}
      userRole={userRole}
      isAddButtonContained={true}
      onClick={handleAddMemberPopupOpen}
      showArrow={showArrow}
      handleOnArrowClick={handleOnArrowClick}
    >
      <>
        <PerfectScrollbar
          style={{
            width: '100%',
            maxHeight: listMaxHeight || 'calc((100svh - 520px) / 2)',
            paddingBottom: shadowShown ? 20 : 0,
          }}
          className={'menu-list-scrollbar'}
          options={{
            wheelSpeed: 0.1,
            wheelPropagation: false,
            minScrollbarLength: 4,
          }}
        >
          <StyledMenuList ref={listRef}>
            {sortedUsers?.map((user, index) => (
              <StyledMenuListItem
                key={user.id}
                // isOnline={
                //   list.find((item) => item.userId === user.profile.userId)
                //     ?.online
                // }
                isActive={
                  isModalOpen && modalData?.['profile']?.['userId'] === user.id
                }
                hasGreenDot={
                  user?.unreadCount > 0 &&
                  user.profile.userId !== authId &&
                  true
                }
                onClick={(e) => openViewMemberSidebar(e, user)}
              >
                <NameAndBadgeWrapper>
                  <StyledName>{getProfileName(user.profile)}</StyledName>
                  {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
                  {/* @ts-ignore*/}
                  <Badge
                    isShowBadge={
                      sortedUsers[index].communityPermission.role === 'owner' ||
                      sortedUsers[index].communityPermission.role === 'admin'
                    }
                    isShowTooltip
                    userRole={sortedUsers[index].communityPermission.role}
                    tooltipContent={sortedUsers[index].communityPermission.role}
                    styleConfig={{
                      shape: 'square',
                      left: 5,
                    }}
                    isStretch={false}
                    permissionType="Community"
                  />
                  <Badge
                    isShowBadge={
                      formattedUsers[index].isGroupAdmin ||
                      formattedUsers[index].isGroupLeader
                    }
                    isShowTooltip
                    userRole={
                      (formattedUsers[index].isGroupAdmin &&
                        GroupRoles.ADMIN) ||
                      (formattedUsers[index].isGroupLeader &&
                        GroupRoles.GROUP_LEAD)
                    }
                    tooltipContent={
                      (formattedUsers[index].isGroupAdmin &&
                        GroupRoles.ADMIN) ||
                      (formattedUsers[index].isGroupLeader &&
                        GroupRoles.GROUP_LEAD)
                    }
                    styleConfig={{
                      shape: 'square',
                      backgroundColor: '#8298ab',
                      left:
                        sortedUsers[index].communityPermission.role ===
                          'owner' ||
                        sortedUsers[index].communityPermission.role === 'admin'
                          ? 0
                          : 5,
                    }}
                    isStretch={false}
                    permissionType="Group"
                  />
                </NameAndBadgeWrapper>
                <FlexBox>
                  {user?.unreadCount > 0 && user.profile.userId !== authId && (
                    <MessageCount
                      onClick={(e: MouseEvent) =>
                        handleRedirectToChat(e, user.profile.userId)
                      }
                    >
                      <CountBadge>{user?.unreadCount}</CountBadge>
                    </MessageCount>
                  )}
                  {user.profile.userId !== authId && (
                    <Tooltip
                      {...tooltipProps}
                      position="right-center"
                      isVisible
                    >
                      <ChatIconWrapper
                        onClick={(e: MouseEvent) =>
                          handleRedirectToChat(e, user.profile.userId)
                        }
                      >
                        <IconSendMessage />
                      </ChatIconWrapper>
                    </Tooltip>
                  )}
                </FlexBox>
              </StyledMenuListItem>
            ))}
          </StyledMenuList>
        </PerfectScrollbar>
        {shadowShown ? <StyledShadow bottom="-10px" /> : null}
      </>
    </MenuListWrapper>
  );
};

export default CommunityMenuMembersList;
