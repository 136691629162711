import React, { useEffect } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Auth } from 'aws-amplify';
import { useLocalStorage } from 'react-use';
import { Page404, Page500 } from 'pages';
import { ROUTES } from 'configs/routes';
import { getAuthAuthenticated, getAuthLoading } from 'store/selectors/auth';
import { doCheckIsAuthorized } from 'store/actionCreators/auth';
import { setHeaders } from 'services';
import { Loader } from 'components';
import AppRouter from './AppRouter';
import { default as AuthRouter } from 'pages/Auth';
import { logInGoogle, logInFacebook } from 'utils/auth';
import { getProfileName } from '../utils';
import { FullUser } from '../types';
const Router = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(getAuthAuthenticated);
  const isLoading = useSelector(getAuthLoading);
  const [prevAuth, setPrevAuth] = useLocalStorage<{ [key: string]: string }>(
    'socialSignIns',
    {},
  );

  const onAuthSuccess = (user: FullUser, session) => {
    if (user?.profile && session?.idToken?.payload?.identities) {
      const profileName = getProfileName(user.profile);
      const newIdentities: { [key: string]: string } = {};

      session.idToken.payload.identities.forEach((i) => {
        if (i.providerName === 'Google') {
          newIdentities.google = profileName;
        } else if (i.providerName === 'Facebook') {
          newIdentities.facebook = profileName;
        } else if (i.providerName === 'SignInWithApple') {
          newIdentities.apple = profileName;
        }
      });

      setPrevAuth({ ...prevAuth, ...newIdentities });
    }
  };

  useEffect(() => {
    const search = new URLSearchParams(window.location.search);
    const error = search.get('error_description');

    if (
      error &&
      /already.found.an.entry.for.username.google/gi.test(error.toString())
    ) {
      logInGoogle();
    } else if (
      error &&
      /already.found.an.entry.for.username.facebook/gi.test(error.toString())
    ) {
      logInFacebook();
    } else if (
      !window.location.pathname.includes('change-email') &&
      !window.location.pathname.includes('verify')
    ) {
      dispatch(doCheckIsAuthorized({ onSuccess: onAuthSuccess }));
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(refreshSession, 1000 * 60 * 20);

    return () => clearInterval(interval);
  }, []);

  const refreshSession = async () => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const cognitoSession = await Auth.currentSession();

      cognitoUser.refreshSession(
        cognitoSession['refreshToken'],
        (err, session) => {
          if (session) {
            const { idToken } = session;

            setHeaders(idToken.jwtToken);
          }
        },
      );
    } catch (e) {
      console.log('Unable to refresh Token', e);
    }
  };

  return !isLoading ? (
    <BrowserRouter>
      <Routes>
        <Route path={ROUTES.PAGE404} element={<Page404 />} />
        <Route path={ROUTES.PAGE500} element={<Page500 />} />
        {isAuthenticated &&
        !window.location.pathname.includes('change-email') &&
        !window.location.pathname.includes('verify') ? (
          <Route path="*" element={<AppRouter />} />
        ) : (
          <Route path="*" element={<AuthRouter />} />
        )}
      </Routes>
    </BrowserRouter>
  ) : (
    <Loader />
  );
};

export default Router;
