import React, { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getDate, getDateForEngLocation } from 'utils/dates';
import {
  ContentWrapper,
  Divider,
  Loader,
  PopupButtonMessage,
  PopupEventWrapper,
} from 'components';
import { ROUTES, PROFILE_ROUTES } from 'configs';
import {
  selectCurrentUserSubscription,
  selectIsSubscriptionLoading,
} from 'store/selectors/subscriptions';
import { getAuthUser } from 'store/selectors/auth';
import { getCommunities } from 'store/selectors/communities';
import {
  startTrial,
  activateSubscription,
  cancelSubscription,
} from 'store/actionCreators/subscriptions';
import { SubscriptionFrequency, SubscriptionStatus } from 'types';

import {
  Layout,
  Heading,
  Details,
  SubscriptionsActionButton,
  AdditionalInfo,
} from './styled';
import { StyledFloatingLoader } from '../UserProfileSettings/styled';

const UserProfileSubscription = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const subscription = useSelector(selectCurrentUserSubscription);
  const profile = useSelector(getAuthUser);
  const communities = useSelector(getCommunities);
  const isLoading = useSelector(selectIsSubscriptionLoading);

  useEffect(() => {
    if (window) {
      window.scrollTo(0, 0);
    }
  }, []);

  const groupsCount = useMemo(() => {
    if (communities.length > 0 && profile) {
      const filteredCommunities = communities.filter(
        (item) => profile?.userId === item?.communityOwner?.userId,
      );

      return filteredCommunities
        .map((item) => Number(item.groupsCount) || 0)
        .reduce((acc, cur) => acc + cur, 0);
    }

    return 0;
  }, [communities, profile]);

  const planName = useMemo(() => {
    if (subscription?.id) {
      if (
        subscription.status === SubscriptionStatus.CANCELLED &&
        subscription.subscriptionId !== SubscriptionStatus.MIGRATED
      ) {
        return t('subscriptions.cancelledTitle');
      } else if (subscription.planId === SubscriptionStatus.TRIAL) {
        return t('subscriptions.trial');
      } else if (subscription.subscriptionPlan) {
        return subscription.subscriptionPlan.productName;
      } else {
        return subscription.planId;
      }
    }

    return t('subscriptions.free');
  }, [subscription]);

  const nextPaymentLabel = useMemo(() => {
    if (
      (subscription?.status === SubscriptionStatus.CANCELLED &&
        subscription?.subscriptionId !== SubscriptionStatus.MIGRATED) ||
      subscription?.planId === SubscriptionStatus.TRIAL
    ) {
      return t('profileSubscription.details.validUntil');
    }

    return t('profileSubscription.details.nextPayment');
  }, [subscription]);

  const translatedPaymentCycle = useMemo(() => {
    if (subscription?.frequency === SubscriptionFrequency.MONTH) {
      return t('profileSubscription.details.paymentCycleMonth');
    } else if (subscription?.frequency === SubscriptionFrequency.YEAR) {
      return t('profileSubscription.details.paymentCycleYear');
    } else {
      return '';
    }
  }, [subscription]);

  const subscriptionEndDate = useMemo(() => {
    if (!subscription?.endDate) {
      return '-';
    }

    return i18n.language === 'en'
      ? getDateForEngLocation(subscription.endDate)
      : getDate(subscription.endDate);
  }, [subscription, i18n.language]);

  const additionalInfoText = useMemo(() => {
    if (subscription?.id) {
      if (
        subscription.status === SubscriptionStatus.CANCELLED &&
        subscription.subscriptionId !== SubscriptionStatus.MIGRATED
      ) {
        return t('profileSubscription.details.infoCancelled');
      } else if (subscription.planId === SubscriptionStatus.TRIAL) {
        return t('profileSubscription.details.infoTrialNew', {
          expireDate: subscriptionEndDate,
        });
      }

      return '';
    }

    return t('subscriptions.requireSubscription');
  }, [subscription]);

  const maxBucketSize = subscription?.maxStorage || 0;
  const maxCommunities = subscription?.maxCommunities || 0;
  const maxGroups = subscription?.maxGroups || 0;
  const maxUsers = subscription?.maxMembers || 0;

  const trialButtonComponent = () => (
    <SubscriptionsActionButton>
      {t('subscriptions.startTrial')}
    </SubscriptionsActionButton>
  );

  const handleTrialStart = () => {
    dispatch(startTrial());
  };

  const headerButton = useMemo(() => {
    if (
      subscription?.status === SubscriptionStatus.CANCELLED &&
      subscription?.subscriptionId !== SubscriptionStatus.MIGRATED
    ) {
      return (
        <PopupEventWrapper
          popupMessageText={t('subscriptions.renewSubscriptionText')}
          popupButtons={[
            {
              popupButtonText: t('subscriptions.activate'),
              handleConfirm: () => {
                dispatch(activateSubscription(subscription?.subscriptionId));
              },
            },
          ]}
          darkBackground={true}
        >
          <SubscriptionsActionButton>
            {t('profileSubscription.buttonRestart')}
          </SubscriptionsActionButton>
        </PopupEventWrapper>
      );
    }

    if (subscription?.id && subscription?.planId !== SubscriptionStatus.TRIAL) {
      return (
        <PopupEventWrapper
          popupMessageText={t('subscriptions.cancelConfirmation')}
          popupButtons={[
            {
              popupButtonText: t('subscriptions.unsubscribe'),
              handleConfirm: () => {
                dispatch(cancelSubscription(subscription?.subscriptionId));
              },
            },
          ]}
          darkBackground={true}
        >
          <SubscriptionsActionButton>
            {t('profileSubscription.buttonCancel')}
          </SubscriptionsActionButton>
        </PopupEventWrapper>
      );
    }

    if (!subscription?.id && !profile.trialStarted) {
      return (
        <PopupButtonMessage
          popupHeaderText={t('subscriptions.startTrialHeader')}
          popupMessageText={t('subscriptions.startTrialText')}
          popupButtonText={t('subscriptions.startTrialPopupButton')}
          ButtonComponent={trialButtonComponent}
          onConfirm={handleTrialStart}
        />
      );
    }

    return null;
  }, [subscription]);

  const innerComponents = useMemo(() => {
    if (!subscription) {
      return <Loader />;
    }

    return (
      <Layout.Inner>
        {isLoading && (
          <StyledFloatingLoader>
            <Loader size="60px" type={null} />
          </StyledFloatingLoader>
        )}
        <Layout.Details>
          <Layout.Title>
            {t('profileSubscription.titleMembership')}
            {headerButton}
          </Layout.Title>
          <Details>
            <Details.Item>
              <Details.Item.Label>
                {t('profileSubscription.details.currentPlan')}
              </Details.Item.Label>
              <Details.Item.Value>
                <span>{planName}</span>
                <Link
                  to={`${ROUTES.PROFILE}/${PROFILE_ROUTES.SUBSCRIPTION}/${PROFILE_ROUTES.PLANS}`}
                >
                  {t('profileSubscription.details.linkChangePlan')}
                </Link>
              </Details.Item.Value>
              <Details.Item.Label fullWidth={true}>
                {additionalInfoText ? (
                  <AdditionalInfo>{additionalInfoText}</AdditionalInfo>
                ) : null}
              </Details.Item.Label>
            </Details.Item>
            <Divider customMargin="0px 0px 15px 0px" />
            <Details.Item>
              <Details.Item.Label>
                {t('profileSubscription.details.communitiesInUse')}
              </Details.Item.Label>
              <Details.Item.Value>
                <span>
                  <strong>{subscription.info.communities}</strong> /{' '}
                  {maxCommunities}
                </span>

                <Link to={`${ROUTES.PROFILE}/${PROFILE_ROUTES.COMMUNITIES}`}>
                  {t('profileSubscription.details.linkShowCommunities')}
                </Link>
              </Details.Item.Value>
            </Details.Item>
            <Details.Item>
              <Details.Item.Label>
                {t('profileSubscription.details.groupsInUse')}
              </Details.Item.Label>
              <Details.Item.Value>
                <span>
                  <strong>{groupsCount}</strong> / {maxGroups}
                </span>
              </Details.Item.Value>
            </Details.Item>
            <Details.Item>
              <Details.Item.Label>
                {t('profileSubscription.details.membersInUse')}
              </Details.Item.Label>
              <Details.Item.Value>
                <span>
                  <strong>{subscription.info.users}</strong> / {maxUsers}
                </span>
              </Details.Item.Value>
            </Details.Item>
            <Details.Item>
              <Details.Item.Label>
                {t('profileSubscription.details.storageInUse')}
              </Details.Item.Label>
              <Details.Item.Value>
                <span>
                  <strong>{subscription.info.bucketSize.toFixed(2)} GB</strong>/{' '}
                  {maxBucketSize} GB
                </span>
              </Details.Item.Value>
            </Details.Item>
          </Details>
        </Layout.Details>
        <Divider customMargin="0px 20%" />
        <Layout.Details>
          <Layout.Title>{t('profileSubscription.titlePayment')}</Layout.Title>
          <Details>
            <Details.Item>
              <Details.Item.Label>
                {t('profileSubscription.details.paymentCycle')}
              </Details.Item.Label>
              <Details.Item.Value>
                {translatedPaymentCycle || '-'}
              </Details.Item.Value>
            </Details.Item>
            <Details.Item>
              <Details.Item.Label>{nextPaymentLabel}</Details.Item.Label>
              <Details.Item.Value>
                {subscriptionEndDate}
                <a
                  href="https://paypal.com/invoice/manage"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('profileSubscription.details.linkHistory')}
                </a>
              </Details.Item.Value>
            </Details.Item>
            <Details.Item>
              <Details.Item.Label>
                {t('profileSubscription.details.paymentMethod')}
              </Details.Item.Label>
              <Details.Item.Value>
                <span>{t('subscriptions.paymentMethods')}</span>
              </Details.Item.Value>
            </Details.Item>
          </Details>
        </Layout.Details>
        <Divider customMargin="0px 20%" />
      </Layout.Inner>
    );
  }, [subscription, isLoading]);

  return (
    <ContentWrapper minHeight="100svh">
      <Layout>
        <Layout.Header>
          <Heading>{t('profileSubscription.heading')}</Heading>
        </Layout.Header>
        {innerComponents}
      </Layout>
    </ContentWrapper>
  );
};

export default UserProfileSubscription;
