import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { setLocale } from 'yup';
import { useTranslation } from 'react-i18next';

export const FIELDS = {
  GROUP_NAME: 'name',
};

export const useGeneralGroupSettingsSchema = () => {
  const { t } = useTranslation();

  setLocale({
    mixed: { required: t('errors.required') },
    string: {
      min: ({ min }) => t('errors.min', { value: min }),
      max: ({ max }) => t('errors.max', { value: max }),
    },
  });

  return yupResolver(
    yup.object().shape({
      [FIELDS.GROUP_NAME]: yup.string().required().min(3).max(50).trim(),
    }),
  );
};

export const useGeneralGroupSettingsForm = (defaultValues) =>
  useForm({
    resolver: useGeneralGroupSettingsSchema(),
    defaultValues,
  });
