import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash/fp';
import useDimensions from 'react-use-dimensions';
import { Tabs, Loader, ZoomInput, OverallScheduleStatistics } from 'components';
import ScheduleTab from './Schedule';
import MembersTab from './Members';
import DocumentationTab from './Documentation';
import { Community, CommunityOverallScheduler, SchedulerFilter } from 'types';
import { doGetCommunityTotal } from 'store/actionCreators/currentCommunity';
import { StyledContentWrapper, StyledHeader, StyledGroupTitle } from './styled';
import { useParams } from 'react-router-dom';
import { useDebounce } from 'hooks';
import { useDispatch } from 'react-redux';
import ImportCsv from 'components/ImportCsv';

interface OverallSchedulerPageProps {
  filters: SchedulerFilter;
  community: Community;
  overallScheduler: CommunityOverallScheduler;
  isMenuOpen: boolean;
  isLoading: boolean;
  zoom: number;
  initialFiltersState: SchedulerFilter;
  setFilters: (value: SchedulerFilter) => void;
  setZoom: (value: number) => void;
}

const OverallSchedulerPage: FC<OverallSchedulerPageProps> = ({
  community,
  filters,
  overallScheduler,
  isMenuOpen,
  isLoading,
  zoom,
  initialFiltersState,
  setZoom,
  setFilters,
}) => {
  const [tabHeight, setTabHeight] = useState<number>(0);
  const [headHeight, setHeadHeight] = useState<number>(71);
  const { t } = useTranslation();
  const { communityId } = useParams();
  const dispatch = useDispatch();
  const [filterValue, setFilterValue] = useState<string>('');
  const [isFilterResetting, setIsFilterResetting] = useState<boolean>(false);
  const debouncedValue = useDebounce(filterValue, 500);
  const [tabsRef, { height: tabsHeight }, tabsHeightRef] = useDimensions();
  const [refHeader, { height: headerHeight }] = useDimensions();

  useEffect(() => {
    setTabHeight(tabsHeight);
    setHeadHeight(headerHeight);
  }, [headerHeight, tabsHeight]);

  useEffect(() => {
    setTimeout(() => {
      setTabHeight(tabsHeightRef?.offsetHeight);
    }, 500);
  }, [isMenuOpen, tabsHeightRef?.offsetHeight, tabsHeight, headerHeight]);

  const applyFilters = () => {
    dispatch(
      doGetCommunityTotal({
        communityId,
        from: filters.from,
        to: filters.to,
      }),
    );
  };

  useEffect(() => {
    if (isFilterResetting) {
      setIsFilterResetting(false);
      applyFilters();
    }
  }, [isFilterResetting]);

  const resetFilters = () => {
    setFilters(initialFiltersState);
    setIsFilterResetting(true);
  };

  return (
    <StyledContentWrapper isMenuOpen={isMenuOpen} controlOverflow={'auto'}>
      {isLoading || isEmpty(overallScheduler) ? (
        <Loader />
      ) : (
        <>
          <StyledHeader ref={refHeader} isMenuOpen={isMenuOpen}>
            <StyledGroupTitle>{t('menu.groupScheduler')}</StyledGroupTitle>
            <ZoomInput value={zoom} onChange={setZoom} color="black" />
          </StyledHeader>
          <Tabs
            filterValue={filterValue}
            tabsRef={tabsRef}
            noApply={false}
            filters={filters}
            setFilters={setFilters}
            setFilterValue={setFilterValue}
            applyFilters={applyFilters}
            resetFilters={resetFilters}
            initialFiltersState={initialFiltersState}
          >
            <div
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              label={t('group.tabs.schedule')}
              style={{ display: 'flex' }}
              withSearch
              withFilter
              index={0}
            >
              <ScheduleTab
                filterValue={debouncedValue}
                filters={filters}
                usersFilter={debouncedValue}
                community={community}
                overallScheduler={overallScheduler}
                zoom={zoom}
                headerHeight={tabHeight + headHeight}
              />
            </div>
            <div
              /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
              /* @ts-ignore */
              label={t('group.tabs.import')}
              style={{ display: 'flex' }}
              index={1}
              // withSearch={false}
              // withFilter={false}
            >
              <ImportCsv
                headerHeight={tabHeight + headHeight}
                communityDetails={community}
              />
            </div>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <div label={t('group.tabs.members')} withSearch index={2}>
              <MembersTab
                t={t}
                communityId={communityId}
                headerHeight={tabHeight + headHeight}
                filterValue={debouncedValue}
                overallScheduler={overallScheduler}
              />
            </div>
            <div
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              label={t('group.tabs.documentation')}
              index={3}
              withSearch
              withFilter
              withDocumentSearch
            >
              <DocumentationTab />
            </div>
            <div
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              label={t('group.tabs.statistic')}
              index={4}
            >
              <OverallScheduleStatistics zoom={zoom} />
            </div>
          </Tabs>
        </>
      )}
    </StyledContentWrapper>
  );
};

export default OverallSchedulerPage;
