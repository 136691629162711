import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { getCurrentUserInCommunity } from 'store/selectors/currentCommunity';

export const useDocumentation = (
  t,
  communityId,
  uploadDocuments,
  deleteDocument,
) => {
  const { handleSubmit, ...form } = useForm();
  const { addToast } = useToasts();

  const onSuccessUpload = () => {
    addToast(t('documentation.uploadSuccess'), {
      appearance: 'success',
      autoDismiss: true,
    });
  };

  const onSuccessDelete = () => {
    addToast(t('documentation.deleteSuccess'), {
      appearance: 'success',
      autoDismiss: true,
    });
  };

  const onSubmit = handleSubmit((data) => {
    uploadDocuments({
      communityId,
      data,
      onSuccess: onSuccessUpload,
    });
  });

  const onDelete = (e, documentId) => {
    e.preventDefault();
    deleteDocument({ communityId, documentId, onSuccess: onSuccessDelete });
  };

  return {
    onSubmit,
    onDelete,
    ...form,
  };
};

export const useGetDocument = (documents, currentCommunity) => {
  const currentUser = useSelector(getCurrentUserInCommunity);
  const userRole = currentUser?.communityPermission.role;
  if (userRole === 'owner' || userRole === 'admin') {
    return documents;
  }
  const filtersDoc = documents.filter(
    (doc) =>
      doc.groupId === null ||
      currentCommunity.groups.find((group) => group.id === doc.groupId),
  );
  console.log('filtersDoc', filtersDoc);

  return filtersDoc;
};
