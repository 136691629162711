import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Divider, NotificationsButton } from 'components';
import { getNameFromEmail } from 'utils';
import { ROUTES, PROFILE_ROUTES } from 'configs';
import Lottie from 'react-lottie';
import newIcon from 'static/animations/new.json';

import {
  PersonIcon,
  CreditCardIcon,
  MenuSettingsIcon,
  CommunitiesIcon,
  Calendar,
  CalendarConnectionIcon,
  ChatIcon,
} from 'static';
import {
  getAuthUser,
  getMessageSeenCountLoading,
  getMessageUnSeenCount,
  getNotificationsUnreadCount,
} from 'store/selectors/auth';
import { getUnrespondedEventsCount } from 'store/selectors/events';
import {
  doGetTotalChatCount,
  doGetUnRespondedEventsCount,
} from 'store/actionCreators/profile';

import { Layout, List, UnrespondedEventsCircle } from './styled';
import { getProfileNameCapital } from 'utils/common';
import { StyledRightIcon } from 'components/Tabs/Tab/styled';
export const animationOptions = {
  animationData: newIcon,
  autoplay: true,
  loop: true,
};
const ProfileMenu = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const profile = useSelector(getAuthUser);
  const notificationsUnreadCount = useSelector(getNotificationsUnreadCount);
  const unrespondedEventsCount = useSelector(getUnrespondedEventsCount);
  const messageUnSeenCount = useSelector(getMessageUnSeenCount);
  const isActive = (to: string) => location.pathname === to;

  useEffect(() => {
    dispatch(doGetUnRespondedEventsCount());
    dispatch(doGetTotalChatCount({ userId: profile.userId }));
  }, []);

  const listMenu = [
    {
      name: t('profile.myProfile'),
      to: ROUTES.PROFILE,
      icon: <PersonIcon />,
    },
    {
      name: t('profile.subscription'),
      to: `${ROUTES.PROFILE}/${PROFILE_ROUTES.SUBSCRIPTION}`,
      icon: <CreditCardIcon />,
    },
    {
      name: t('profile.settings'),
      to: `${ROUTES.PROFILE}/${PROFILE_ROUTES.SETTINGS}`,
      icon: <MenuSettingsIcon />,
    },
    {
      name: t('calendarConnection.pageTitle'),
      to: `${ROUTES.PROFILE}/${PROFILE_ROUTES.CALENDAR_CONNECTION}`,
      icon: <CalendarConnectionIcon />,
      iconStyles: {
        width: '20px',
        height: '20px',
        marginTop: '4px',
        marginLeft: '-4px',
      },
    },
    {
      name: t('profile.myCommunities'),
      to: `${ROUTES.PROFILE}/${PROFILE_ROUTES.COMMUNITIES}`,
      icon: <CommunitiesIcon />,
    },
    {
      name: t('profile.myAppointments'),
      to: `${ROUTES.PROFILE}/${ROUTES.EVENTS}`,
      icon: <Calendar />,
    },
    {
      name: t('profile.myChat'),
      to: `${ROUTES.PROFILE}/${PROFILE_ROUTES.CHAT}`,
      icon: <ChatIcon />,
    },
  ];

  if (!profile) {
    return null;
  }

  const getIsActive = (item: { to: string; name: string }) => {
    return (
      isActive(item.to) ||
      (location.pathname.includes('plans') &&
        item.name === t('profile.subscription')) ||
      (location.pathname.includes('personal-chat') &&
        item.name.toLocaleLowerCase() === 'chat')
    );
  };

  return (
    <Layout>
      <Layout.Header>
        <Layout.Header.Name>
          {getProfileNameCapital({
            firstName: profile.firstName,
            lastName: profile.lastName,
          }) || getNameFromEmail(profile.email)}
        </Layout.Header.Name>

        <Layout.Notifications
          to={`${ROUTES.PROFILE}/${PROFILE_ROUTES.NOTIFICATIONS}`}
        >
          <NotificationsButton
            unreadNotificationsCount={notificationsUnreadCount}
          />
        </Layout.Notifications>
      </Layout.Header>
      <Divider customMargin="0px" />
      <List>
        {listMenu.map((item) => (
          <List.Item
            key={item.to}
            to={item.to}
            end
            isActive={getIsActive(item)}
          >
            <List.Item.Icon iconStyles={item.iconStyles || {}}>
              {item.icon}
            </List.Item.Icon>
            <List.Item.Link>{item.name}</List.Item.Link>
            {item.to.includes(ROUTES.EVENTS) && unrespondedEventsCount ? (
              <UnrespondedEventsCircle>
                <span>
                  {unrespondedEventsCount > 99 ? '99+' : unrespondedEventsCount}
                </span>
              </UnrespondedEventsCircle>
            ) : null}
            {item.to.includes(PROFILE_ROUTES.CHAT) && messageUnSeenCount ? (
              <UnrespondedEventsCircle>
                <span>
                  {messageUnSeenCount > 99 ? '99+' : messageUnSeenCount}
                </span>
              </UnrespondedEventsCircle>
            ) : null}
            {item.to.includes(PROFILE_ROUTES.CHAT) &&
            messageUnSeenCount <= 0 ? (
              <StyledRightIcon style={{ position: 'absolute', right: '10px' }}>
                <Lottie options={animationOptions} width={31} height={31} />
              </StyledRightIcon>
            ) : null}
          </List.Item>
        ))}
      </List>
    </Layout>
  );
};

export default ProfileMenu;
