import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'lodash/fp';
import { useSearchParams } from 'react-router-dom';

import { Loader } from 'components';
import { useDocumentation, useGetDocument } from './hooks';
import {
  getCurrentCommunity,
  getCurrentUserInCommunity,
} from 'store/selectors/currentCommunity';
import {
  getDocuments,
  getIsDocumentUploading,
} from 'store/selectors/documents';
import {
  doUploadDocument,
  doDeleteDocument,
} from 'store/actionCreators/currentCommunity/documents';

import DocumentationPage from './Documentation';

const DocumentationContainer = ({
  t,
  community,
  documents,
  currentUser,
  deleteDocument,
  uploadDocument,
  isUploading,
}) => {
  const [searchParams] = useSearchParams();

  const querySearch = searchParams.get('querySearch') || '';
  const sortBy = searchParams.get('sort') || 'name-asc';

  const { onDelete, onSubmit, register, setValue } = useDocumentation(
    t,
    community.id,
    uploadDocument,
    deleteDocument,
  );

  const filtersDoc = useGetDocument(documents, community);
  const filteredDocuments = useMemo(() => {
    if (!filtersDoc) return [];

    let filtered = [...filtersDoc];

    // Apply search filter
    if (querySearch) {
      filtered = filtered.filter((doc) =>
        doc.name.toLowerCase().includes(querySearch.toLowerCase()),
      );
    }

    // Apply sorting
    switch (sortBy) {
      case 'name-asc':
        return filtered.sort((a, b) => a.name.localeCompare(b.name));
      case 'name-desc':
        return filtered.sort((a, b) => b.name.localeCompare(a.name));
      case 'date-asc':
        return filtered.sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
        );
      case 'date-desc':
        return filtered.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
        );
      default:
        return filtered;
    }
  }, [documents, querySearch, sortBy]);

  const props = {
    t,
    documents: filteredDocuments,
    isUploading,
    community,
    currentUser,
    onSubmit,
    register,
    setValue,
    onDelete,
  };

  return community && documents ? <DocumentationPage {...props} /> : <Loader />;
};

const mapStateToProps = (state) => ({
  community: getCurrentCommunity(state),
  currentUser: getCurrentUserInCommunity(state),
  documents: getDocuments(state),
  isUploading: getIsDocumentUploading(state),
});

const mapDispatchToProps = {
  deleteDocument: doDeleteDocument,
  uploadDocument: doUploadDocument,
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
)(DocumentationContainer);
