import React, { useCallback, useEffect, useState } from 'react';
import { Route, Navigate, Routes, Outlet, useParams } from 'react-router-dom';
import { useLocalStorage, useMedia } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';

import {
  Modal,
  PageContainer,
  MainMenu,
  NotificationsListener,
  AppWidget,
} from 'components';
import ProfileRouter from './ProfileRouter';
import CommunityRouterWrapper from './CommunityRouterWrapper';
import CommunityLandingWrapper from './CommunityLandingWrapper';
import { getCommunities } from 'store/selectors/communities';
import { getAuthUser } from 'store/selectors/auth';
import { doRequestCommunities } from 'store/actionCreators/communities';
import { breakpoints } from 'theme';
import { getChatNotification, getChatUser } from 'store/selectors/chat';
import { useToasts } from 'react-toast-notifications';
import { COMMUNITY_ROUTES, PROFILE_ROUTES, ROUTES } from 'configs';
import { Chat, ChatMessage } from 'store/reducers/chat';
import { IN_APP_NOTIFICATION_TYPE } from 'types';
import { doAddNotification } from 'store/actionCreators/profile';

const PageContainerWrapper = () => {
  const isWide = useMedia(breakpoints.md);
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const chatUser = useSelector(getChatUser);
  const { communityId } = useParams();
  const notificationData = useSelector(getChatNotification);
  const communities = useSelector(getCommunities);
  const userProfile = useSelector(getAuthUser);
  const [appWidgetVisible, setAppWidgetVisible] = useState<boolean>(false);
  const [appWidgetStatus, setAppWidgetStatus] = useLocalStorage<boolean>(
    'appWidgetShown',
    false,
  );

  useEffect(() => {
    if (!communities?.length && userProfile?.id) {
      dispatch(doRequestCommunities());
    }
  }, [userProfile]);

  useEffect(() => {
    if (!isWide && !appWidgetStatus) {
      setAppWidgetStatus(true);
      setTimeout(() => setAppWidgetVisible(true), 2000);
    }
  }, [appWidgetStatus]);

  const sendNotificationSticky = useCallback((notification) => {
    addToast(notification, {
      appearance: 'success',
      autoDismiss: false,
    });
  }, []);

  const createAndDispatchNotification = async (
    messages: ChatMessage,
    chatId: string,
    userData: Chat,
  ) => {
    try {
      console.log('communityId', communityId);

      const link = communityId
        ? `${ROUTES.COMMUNITY}/${communityId}/${COMMUNITY_ROUTES.CHAT}?talkId=${chatId}`
        : `/profile/${PROFILE_ROUTES.CHAT}?talkId=${chatId}`;

      const notification = {
        id: messages.senderId,
        userId: userProfile.userId,
        message: `💬  •  ${
          userData.isGroupChat
            ? userData.chatName
            : `${userData.peerUsers.firstName} ${userData.peerUsers.lastName}`
        } \n <span>${messages.content.replaceAll('\n', ' ')}</span>`,
        image: userData.isGroupChat ? userData.logo : userData.peerUsers.logo,
        link,
        type: IN_APP_NOTIFICATION_TYPE.MESSAGE,
        isRead: false,
      };
      // Dispatch the notification action
      await dispatch(doAddNotification(notification));
      // Send the notification
      sendNotificationSticky(notification);
    } catch (error) {
      console.error('Failed to create and dispatch notification:', error);
      // Optional: Add fallback action here, e.g., log to a monitoring service
    }
  };

  useEffect(() => {
    if (notificationData) {
      const userData = chatUser.find(
        (li) => li?.chatId === notificationData.chatId,
      );
      createAndDispatchNotification(
        notificationData.message,
        notificationData.chatId,
        userData,
      );
    }
  }, [notificationData]);

  return (
    <PageContainer>
      <Modal />
      {isWide && <MainMenu />}
      <NotificationsListener />
      <Outlet />
      {appWidgetVisible ? <AppWidget /> : null}
    </PageContainer>
  );
};

const AppRouter = () => {
  return (
    <Routes>
      <Route path="*" element={<PageContainerWrapper />}>
        <Route path="profile/*" element={<ProfileRouter />} />
        <Route
          path="communities/:communityId/landing"
          element={<CommunityLandingWrapper />}
        />
        <Route
          path="community/:communityId/*"
          element={<CommunityRouterWrapper />}
        />
        <Route path="*" element={<Navigate to="profile" replace />} />
      </Route>
    </Routes>
  );
};

export default AppRouter;
