import { EyeIcon } from 'static';
import styled from 'styled-components';
import { breakpoints } from 'theme';

export const MainContainer = styled.div`
  display: block;
  position: relative;
  overflow: hidden;
  border-radius: ${({ reverse }) =>
    reverse ? '0px 6px 6px 6px' : '6px 0px 6px 6px'};
  margin: 22px 0px;
  background-color: ${({ theme, reverse }) =>
    !reverse ? theme.color.blackSqueeze : '#F1F9F9'};
  width: fit-content;
  max-width: ${({ isOpen }) => (isOpen ? '78%' : '60%')};
  padding: ${({ reverse }) =>
    reverse ? '22px 88px 22px 18px' : '22px 18px 22px 18px'};
  @media ${breakpoints.downLgPlus} {
    margin: 16px 0px;
  }
  @media ${breakpoints.downLg} {
    max-width: 80%;
  }
  @media ${breakpoints.downSmPlus} {
    max-width: 90%;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  /* justify-content: center; */
  gap: 20px;
  flex-direction: ${({ reverse }) => (reverse ? 'row' : 'row-reverse')};
  align-items: ${({ aline }) => (aline ? 'start' : 'center')};
  @media ${breakpoints.downLgPlus} {
    gap: 12px;
  }
`;

export const UerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 6px;
`;

UerWrapper.Text = styled.p`
  font-size: 13px;
  line-height: 16px;
  color: ${({ theme }) => theme.color.pickledBluewood};
`;

export const MessagePieceWrapper = styled.div`
  position: absolute;
  top: ${({ top }) => (top ? '-2px' : '-4px')};
  right: ${({ reverse }) => (reverse ? 'auto' : '-8px')};
  left: ${({ reverse }) => (reverse ? '-8px' : 'auto')};
  transform: ${({ reverse }) =>
    reverse ? 'rotateY(180deg)' : 'rotateY(0deg)'};
  path {
    fill: ${({ theme, reverse }) =>
      !reverse ? theme.color.blackSqueeze : '#F1F9F9'};
  }
`;

export const AvatarContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  margin-bottom: ${({ extraHeight }) => (extraHeight ? '4px' : '0px')};
  cursor: pointer;
  & > img,
  & > span {
    object-fit: cover;
    border-radius: 99999px;
    width: 46px;
    height: 46px;
    aspect-ratio: 1;
    outline: calc(46px / 2) solid #fff1;
    outline-offset: calc(46px / -2);
    cursor: pointer;
    transition: 0.3s;
    :hover {
      outline: 2px solid #33aba7;
      outline-offset: 2px;
    }
  }
`;

export const LogoText = styled.span`
  display: flex;
  width: ${({ size }) => size || '40px'};
  height: ${({ size }) => size || '40px'};
  justify-content: center;
  align-items: center;
  font-weight: bold;
  background-color: white;
  font-size: ${({ fontSize }) => fontSize || '16px'};
  line-height: ${({ lineHeight }) => lineHeight || '16px'};
  color: ${({ theme }) => theme.color.black};
  border-radius: 99999px;
  text-transform: uppercase;
  border: 2px solid ${({ theme }) => theme.color.brightTurquoise};
`;

export const MessageContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
  gap: 8px;
  overflow: visible;
  padding: ${({ reverse, extraPadding }) =>
    reverse
      ? extraPadding
        ? '0px 80px 0px 4px'
        : '0px 10px 0px 4px'
      : extraPadding
      ? '0px 80px 0px 0px'
      : '0px 10px 0px 0px'};
  color: ${({ theme }) => theme.color.pickledBluewood};
  @media ${breakpoints.downLg} {
    padding: ${({ reverse, extraPadding }) =>
      reverse
        ? extraPadding
          ? '0px 40px 0px 4px'
          : '0px 10px 0px 4px'
        : extraPadding
        ? '0px 40px 0px 0px'
        : '0px 10px 0px 0px'};
  }
`;

export const SenderTitle = styled.p`
  font-size: 12.8px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
  width: 100%;
  white-space: nowrap;
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const MessageContent = styled.p`
  font-size: 14px;
  font-weight: 400;
  overflow-wrap: anywhere;
  line-height: 17px;
  text-align: left;
  width: 100%;
  line-break: normal;
  white-space: pre-wrap;
  a {
    text-decoration: none;
    color: ${({ theme }) => theme.color.brightTurquoise} !important;
    :hover {
      text-decoration: underline;
    }
  }
`;

export const MessageTime = styled.div`
  display: flex;
  //TODO update color secondary
  color: ${({ reverse }) => (reverse ? '#8298AB' : '#8298AB')};
  white-space: nowrap;
  position: absolute;
  right: 17px;
  bottom: 6px;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  p {
    font-weight: 400;
    font-size: 10px;
    line-height: 1;
    line-height: 10px;
  }
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
  }
`;
export const EyesIcon = styled(EyeIcon)`
  width: 14px;
  height: 10px;
  path {
    fill: ${({ color }) => color || '#41A2EF'};
  }
`;
export const SameAuthorMessageContent = styled.div`
  display: block;
  position: relative;
  align-items: center;
  font-size: 14px;
  max-width: ${({ isOpen }) => (isOpen ? '78%' : '60%')};
  color: ${({ theme }) => theme.color.pickledBluewood};
  border-radius: 6px;
  margin: 22px 0px;
  background-color: ${({ theme, reverse }) =>
    !reverse ? theme.color.blackSqueeze : '#F1F9F9'};
  width: fit-content;
  padding: 22px 18px 22px 18px;
  min-width: 120px;
  @media ${breakpoints.downLgPlus} {
    margin: 16px 0px;
  }
  @media ${breakpoints.downLg} {
    max-width: 80%;
  }
  @media ${breakpoints.downSmPlus} {
    max-width: 90%;
  }
`;
export const ImageContent = styled.img`
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 320px;
  border-radius: 6px;
  z-index: 40;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`;
export const FileContainer = styled.a`
  padding: 16px;
  width: 100%;
  max-width: 320px;
  min-width: 172px;
  height: 100%;
  max-width: 100%;
  border-radius: 6px;
  text-decoration: none;
  color: ${({ theme }) => theme.color.pickledBluewood};
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  background-color: ${({ theme, fromModal }) =>
    fromModal ? theme.color.aquaSqueeze : theme.color.white};
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  @media ${breakpoints.downLgPlus} {
    min-width: 162px;
  }
  @media ${breakpoints.downSmPlus} {
    min-width: 152px;
  }
`;

export const FileName = styled.p`
  white-space: pre-wrap;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  padding: 4px 0px;
  word-break: break-word;
  overflow: hidden;
`;

export const FileType = styled.p`
  display: block;
  margin-left: auto;
  padding: 2px 8px;
  white-space: pre-wrap;
  text-align: right;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 12px;
  border-radius: 9999px;
  background-color: ${({ theme }) => theme.color.blackSqueeze};
`;
export const SkeletonWrapper = styled.div`
  padding: 8px;
  display: grid;
  width: 100%;
  grid-template-columns: 0.5fr 0.5fr;
  grid-template-rows: auto auto;
  gap: 8px;
  align-items: center;
`;

export const IconWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  gap: 4px;
  border-radius: 9999px;
  background-color: ${({ theme }) => theme.color.blackSqueeze};
  opacity: 0;
  transition: opacity 0.3s ease;
  @media ${breakpoints.downMd} {
    opacity: 1 !important;
  }
  :hover {
    background-color: ${({ theme }) => theme.color.brightTurquoise};
    svg {
      stroke: ${({ theme }) => theme.color.pickledBluewood};
    }
    p {
      color: ${({ theme }) => theme.color.pickledBluewood};
    }
  }
  z-index: 50;
  p {
    color: ${({ theme }) => theme.color.baliHai};
    font-size: 12px;
    font-weight: 400;
    @media ${breakpoints.downMd} {
      display: none;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  position: relative;
  min-width: ${({ minWidth = '0px' }) => minWidth};
  &:hover ${IconWrapper} {
    opacity: 1;
  }
`;

export const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  border-radius: 6px;
  align-items: center;
  cursor: pointer;
  p {
    color: ${({ theme }) => theme.color.brightTurquoise};
    display: flex;
    font-size: 16px;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    z-index: 51;
    border-radius: 99999px;
    background-color: ${({ theme }) => theme.color.white};
    transition: all 0.3s ease;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
`;
