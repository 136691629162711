import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { isEmpty } from 'lodash/fp';
import PerfectScrollbar from 'react-perfect-scrollbar';

import {
  // Badge,
  DropdownContent,
  DropdownItem,
  DropdownItemImage,
  DropdownItemName,
  Layout,
  LoaderWrapper,
  PlaceholderContainer,
  PlusBtn,
  SelectionOption,
  SearchWrapper,
  UserInfo,
  UserList,
  StyledInputSearch,
  Selected,
  FlexBox,
  CommunityWrapper,
} from './styled';
import { Divider, Loader, Tooltip } from 'components';
import { type noop } from 'react-virtualized/dist/es/Masonry';
import { GreenPlusIcon, IconSearch, LeftCloseArrow } from 'static';
import { COMMUNITY_ROUTES, PROFILE_ROUTES, ROUTES } from 'configs';
import { useNavigate } from 'react-router-dom';
import {
  getAvailableActiveCommunities,
  getCommunitiesLoading,
} from 'store/selectors/communities';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getChatUserLoading } from 'store/selectors/chat';
import { LogoText } from '../Chat/Message/styled';
import { getProfileNameCapital } from 'utils/common';
import { getDate, getDateForEngLocation } from 'utils/dates';
import { useOutsideClick } from 'hooks';
import { getIsMenuOpen } from 'store/selectors/currentCommunity';
import { SkeletonListWrapper } from 'sidebars/AboutGroup/styled';
import { openModal } from 'store/actionCreators/modal';
import { ModalTypes } from 'types';
import { Chat } from 'store/reducers/chat';

interface ChatUserListProps {
  openUserList: boolean;
  toggleSidebar: noop;
  handleSelectUserType: (type: 'all' | 'unseen') => void;
  userListType: 'all' | 'unseen';
  search: string;
  onChange?: (arg?: React.ChangeEvent<HTMLInputElement>) => void;
  searchLoading: boolean;
  talkId: string;
  peerId: string;
  openCreateChatPopUp: () => void;
  communityId?: string;
  isOpen: boolean;
  chatUser: Chat[] | null;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  debouncedSearch?: string;
}
interface SelectOptionProps {
  options: { label: string; type: string }[];
  userListType: string;
  handleSelectUserType: (type: string) => void;
}

const SelectOption: React.FC<SelectOptionProps> = React.memo(
  ({ options, userListType, handleSelectUserType }) => (
    <>
      {options.map((option) => (
        <PlusBtn
          option
          style={{ fontSize: '14px', padding: '0.3125rem 0.725rem' }}
          key={option.type}
          isOpen={userListType !== option.type}
          onClick={() => handleSelectUserType(option.type)}
        >
          {option.label}
        </PlusBtn>
      ))}
    </>
  ),
);
SelectOption.displayName = 'SelectOption';

interface HighlightTextProps {
  text: string;
  searchText: string;
}

export const HighlightText = React.memo<HighlightTextProps>(
  ({ text, searchText }) => {
    if (!searchText) return <>{text}</>;
    const escapeRegex = (string) =>
      string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

    const regex = new RegExp(`(${escapeRegex(searchText)})`, 'gi');
    const parts = text.split(regex);
    return (
      <>
        {parts.map((part: string) =>
          part.toLowerCase() === searchText.toLowerCase() ? (
            <Selected key={crypto.randomUUID()}>{part}</Selected>
          ) : (
            part
          ),
        )}
      </>
    );
  },
);

HighlightText.displayName = 'HighlightText';

const ChatUserList: FC<ChatUserListProps> = ({
  openUserList,
  toggleSidebar,
  handleSelectUserType,
  userListType,
  search,
  onChange,
  searchLoading,
  openCreateChatPopUp,
  peerId,
  talkId,
  communityId,
  isOpen,
  setIsOpen,
  chatUser,
  debouncedSearch,
}) => {
  const dispatch = useDispatch();
  const isOpenSidebar = useSelector(getIsMenuOpen);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const chatUserLoading = useSelector(getChatUserLoading);
  const [isShowSearch, setIsShowSearch] = useState(false);
  const communities = useSelector(getAvailableActiveCommunities);
  const communitiesLoading = useSelector(getCommunitiesLoading);
  const searchRef = useRef();
  const inputSearchRef = useRef<HTMLInputElement>(null);
  const communityListRef = useRef();
  useOutsideClick(searchRef, () => setIsShowSearch(false));
  useOutsideClick(communityListRef, () => setIsOpen(false));
  const [users, setUsers] = useState<Chat[] | null>(chatUser);
  useEffect(() => {
    if (userListType === 'unseen' && chatUser && chatUser.length > 0) {
      setUsers((u) => u.filter((i) => i.chatUnreadCount > 0) || null);
    } else {
      setUsers(chatUser);
    }
  }, [userListType, chatUser]);

  const filteredUsers = useMemo(() => {
    if (debouncedSearch.length > 0) {
      const lowercasedSearch = debouncedSearch.toLocaleLowerCase();
      return chatUser.filter((user) => {
        const { firstName = '', lastName = '' } = user?.peerUsers || {};
        const chatName = user?.chatName || '';
        return (
          (firstName + ' ' + lastName)
            .toLocaleLowerCase()
            .includes(lowercasedSearch) ||
          chatName.toLocaleLowerCase().includes(lowercasedSearch)
        );
      });
    }
    return chatUser;
  }, [debouncedSearch, chatUser]);

  useEffect(() => {
    setUsers(filteredUsers);
  }, [filteredUsers]);

  const tooltipProps = (text: string) => ({
    tooltipStyle: {
      fontSize: 12,
      maxWidth: 200,
      whiteSpace: 'pre-wrap',
      lineBreak: 'normal',
    },
    opacity: '0.9',
    text,
  });

  const toggleShowSearch = useCallback(() => {
    setIsShowSearch((prev) => !prev);
  }, []);
  const options = [
    { label: t('common.all'), type: 'all' },
    { label: t('common.unseen'), type: 'unseen' },
  ];
  useEffect(() => {
    if (isShowSearch && inputSearchRef.current) {
      inputSearchRef.current.value = '';
      inputSearchRef.current.focus();
    }
  }, [isShowSearch]);

  const toggleDropdown = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen((prevVal) => !prevVal);
  }, []);

  const handleRedirect = useCallback(
    (id: string) => {
      navigate(`${ROUTES.COMMUNITY}/${id}/${COMMUNITY_ROUTES.CHAT}`);
      setTimeout(() => {
        dispatch(
          openModal({
            type: ModalTypes.CHAT_POPUP,
          }),
        );
      }, 1500);
    },
    [navigate],
  );

  return (
    <Layout isOpen={openUserList}>
      <Layout.Header isOpen={isOpenSidebar}>
        <Layout.Header.Name>{t('menu.chats')}</Layout.Header.Name>
        <Layout.Header.Icon ref={communityListRef}>
          <Tooltip
            {...tooltipProps(t('chat.plusTooltip'))}
            offset={8}
            position="bottom-center"
            isVisible
          >
            <PlusBtn onClick={toggleDropdown} isOpen={isOpen}>
              <div
                style={{
                  width: '22px',
                  height: '22px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <GreenPlusIcon width={14} height={14} />
              </div>
              <p>{t('common.new')}</p>
            </PlusBtn>
          </Tooltip>
          <SearchWrapper isShowSearch={isShowSearch} ref={searchRef}>
            <Tooltip
              {...tooltipProps(t('chat.searchHover'))}
              position="bottom-center"
              isVisible
            >
              <IconSearch width={22} height={22} onClick={toggleShowSearch} />
            </Tooltip>
            <StyledInputSearch
              disabled={false}
              placeholder={t('common.search')}
              value={search}
              onChange={onChange}
              isShowSearch={isShowSearch}
              ref={inputSearchRef}
            />
          </SearchWrapper>

          <DropdownContent isOpen={isOpen}>
            {communitiesLoading ? (
              <LoaderWrapper>
                <Loader type="button" size="40px" />
              </LoaderWrapper>
            ) : communities.length > 0 ? (
              <PerfectScrollbar
                options={{
                  swipeEasing: true,
                  wheelSpeed: 1,
                  wheelPropagation: false,
                  minScrollbarLength: 4,
                }}
              >
                {communities.map((option, index) => (
                  <DropdownItem
                    key={option.id}
                    onClick={() => handleRedirect(option.id)}
                  >
                    <FlexBox>
                      <DropdownItemImage>
                        {option.logo ? (
                          <img src={option.logo} alt={option.name} />
                        ) : (
                          <DropdownItemImage.LogoText>
                            {option.name.charAt(0)}
                          </DropdownItemImage.LogoText>
                        )}
                      </DropdownItemImage>
                      <DropdownItemName>{option.name}</DropdownItemName>
                    </FlexBox>
                    <FlexBox>
                      <Layout.Header.Close onClick={toggleSidebar} rotate>
                        <LeftCloseArrow width={22} height={22} />
                      </Layout.Header.Close>
                    </FlexBox>
                  </DropdownItem>
                ))}
              </PerfectScrollbar>
            ) : (
              <LoaderWrapper>
                <p>You dont have any active community</p>
              </LoaderWrapper>
            )}
          </DropdownContent>
        </Layout.Header.Icon>
        <Layout.Header.Close onClick={toggleSidebar}>
          <LeftCloseArrow width={22} height={22} />
        </Layout.Header.Close>
      </Layout.Header>
      <Divider customMargin="0px" />
      <SelectionOption>
        <FlexBox gap={'6px'}>
          <SelectOption
            options={options}
            handleSelectUserType={handleSelectUserType}
            userListType={userListType}
          />
        </FlexBox>
      </SelectionOption>
      <UserList>
        {searchLoading ? (
          <>
            <PlaceholderContainer>
              Looking For A Past Conversation...
            </PlaceholderContainer>
          </>
        ) : (
          <>
            {chatUserLoading ? (
              <SkeletonListWrapper>
                {Array.from({ length: 5 }).map((_, index) => (
                  <div key={index} className="skeleton skeleton-list" />
                ))}
              </SkeletonListWrapper>
            ) : !isEmpty(users) ? (
              <PerfectScrollbar
                options={{
                  swipeEasing: true,
                  wheelSpeed: 1,
                  wheelPropagation: false,
                  minScrollbarLength: 4,
                }}
              >
                {users.map((item) => {
                  const date =
                    i18n.language === 'en'
                      ? getDateForEngLocation(item.lastMessageAt)
                      : getDate(item.lastMessageAt);

                  return (
                    <UserList.Item
                      key={item?.chatId || item?.id || item?.peerUsers?.userId}
                      to={
                        communityId
                          ? `${ROUTES.COMMUNITY}/${communityId}/${
                              COMMUNITY_ROUTES.CHAT
                            }?talkId=${item?.chatId || item?.id}`
                          : `${ROUTES.PROFILE}/${PROFILE_ROUTES.CHAT}?talkId=${
                              item?.chatId || item?.id
                            }`
                      }
                      end
                      isActive={
                        talkId === item?.chatId ||
                        talkId === item?.id ||
                        peerId === item?.peerUsers?.userId
                      }
                      isUnseen={item?.chatUnreadCount > 0}
                    >
                      <UserList.Item.Icon>
                        {item?.logo || item?.peerUsers?.logo ? (
                          <img
                            src={
                              item.isGroupChat
                                ? item?.logo
                                : item?.peerUsers?.logo
                            }
                          />
                        ) : (
                          <LogoText>
                            {item.isGroupChat
                              ? item?.chatName?.charAt(0)
                              : item?.peerUsers
                              ? item?.peerUsers?.firstName.charAt(0) +
                                item?.peerUsers?.lastName.charAt(0)
                              : 'U'}
                          </LogoText>
                        )}
                        <>
                          {item.communityId.slice(0, 3).map((id, i) => {
                            const community = communities.find(
                              (c) => c.id === id,
                            );
                            if (!community) return null;
                            const communityImg = community.logo;
                            const communityName = community.name.charAt(0);

                            return (
                              <>
                                <Tooltip
                                  {...tooltipProps(community.name)}
                                  offset={8}
                                  position="top-center"
                                  isVisible
                                >
                                  <CommunityWrapper
                                    key={i}
                                    style={{
                                      left: `${16 + i * 6}px`,
                                      zIndex: 3 - i,
                                    }}
                                  >
                                    {communityImg ? (
                                      <img src={communityImg} />
                                    ) : (
                                      <LogoText
                                        size={'22px'}
                                        fontSize={'10px'}
                                        lineHeight={'11px'}
                                        style={{
                                          fontWeight: '600',
                                          border: '1.5px solid white',
                                          backgroundColor: 'rgb(229,235,231)',
                                        }}
                                      >
                                        {communityName}
                                      </LogoText>
                                    )}
                                  </CommunityWrapper>
                                </Tooltip>
                              </>
                            );
                          })}
                        </>
                      </UserList.Item.Icon>
                      <UserInfo>
                        <UserInfo.FirstRow>
                          <UserInfo.FirstRow.name className="select">
                            <HighlightText
                              text={
                                item.isGroupChat
                                  ? item.chatName
                                  : item?.peerUsers
                                  ? getProfileNameCapital({
                                      firstName: item?.peerUsers?.firstName,
                                      lastName: item?.peerUsers?.lastName,
                                    })
                                  : 'Unknown User'
                              }
                              searchText={search}
                            />
                          </UserInfo.FirstRow.name>
                          {item.chatUnreadCount > 0 && (
                            <UserInfo.FirstRow.count>
                              {item.chatUnreadCount}
                            </UserInfo.FirstRow.count>
                          )}
                        </UserInfo.FirstRow>
                        {!isEmpty(item.lastMessage) && (
                          <UserInfo.SecondRow>
                            <UserInfo.SecondRow.LastMessage>
                              {(() => {
                                const lastMessage = item.lastMessage;
                                if (!lastMessage) return null;

                                if (lastMessage.content)
                                  return lastMessage.content;

                                const mediaUrls = lastMessage.mediaUrl;
                                if (!mediaUrls?.length) return null;

                                const lastMediaUrl =
                                  mediaUrls[mediaUrls.length - 1];
                                const underscoreIndex =
                                  lastMediaUrl.indexOf('_');
                                return underscoreIndex >= 0
                                  ? lastMediaUrl.substring(underscoreIndex + 1)
                                  : lastMediaUrl;
                              })()}
                            </UserInfo.SecondRow.LastMessage>
                            <UserInfo.SecondRow.Time
                              bold={item.chatUnreadCount > 0}
                            >
                              {date}
                            </UserInfo.SecondRow.Time>
                          </UserInfo.SecondRow>
                        )}
                      </UserInfo>
                    </UserList.Item>
                  );
                })}
              </PerfectScrollbar>
            ) : isEmpty(chatUser) ? (
              <PlaceholderContainer>
                {t('chat.emptyStatus.noChatUser')}
              </PlaceholderContainer>
            ) : null}
          </>
        )}
      </UserList>
    </Layout>
  );
};

export default ChatUserList;
