import { format, parseISO, differenceInSeconds } from 'date-fns';
import { inRange } from 'lodash/fp';
import { TFunction } from 'i18next';

export const getDate = (dateString?: string): string =>
  dateString ? format(parseISO(dateString), 'dd.MM.yyyy') : null;

export const getDateForEngLocation = (dateString?: string): string =>
  dateString ? format(parseISO(dateString), 'PP') : null;

export const getDateAndTime = (dateString?: string): string =>
  dateString ? format(parseISO(dateString), 'dd.MM.yyyy hh:mm:ss') : null;

export const getTime = (dateString?: string): string =>
  dateString ? format(parseISO(dateString), 'hh:mm') : null;

export const getTimeWithA = (
  dateString?: string,
  type: 12 | 24 = 12,
): string | null => {
  if (!dateString) return null;

  const date = parseISO(dateString);

  const formatString = type === 12 ? 'hh:mm a' : 'HH:mm';

  return format(date, formatString);
};

export const getUTCDateString = (date?: Date): string =>
  date
    ? `${date.getUTCDate().toString().padStart(2, '0')}.${(
        date.getUTCMonth() + 1
      )
        .toString()
        .padStart(2, '0')}.${date.getUTCFullYear()}`
    : null;

export const getDateString = (date?: Date): string =>
  date
    ? `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1)
        .toString()
        .padStart(2, '0')}.${date.getFullYear()}`
    : null;

export const getDayName = (day: number, t: TFunction) => {
  const days = [
    t('common.sunday'),
    t('common.monday'),
    t('common.tuesday'),
    t('common.wednesday'),
    t('common.thursday'),
    t('common.friday'),
    t('common.saturday'),
  ];
  return days[day];
};

export const getMonthName = (month: number, t: TFunction) => {
  const months = [
    t('common.january'),
    t('common.february'),
    t('common.march'),
    t('common.april'),
    t('common.may'),
    t('common.june'),
    t('common.july'),
    t('common.august'),
    t('common.september'),
    t('common.october'),
    t('common.november'),
    t('common.december'),
  ];
  return months[month];
};
export const lastUpdateTime = (time: string, format: 12 | 24) => {
  const today = getDate(new Date().toISOString());
  return today === getDate(time) ? getTimeWithA(time, format) : getDate(time);
};

export const getDateStringWithDay = (date?: Date, t?: TFunction) => {
  return date
    ? `${getDayName(date.getDay(), t)}, ${date
        .getDate()
        .toString()
        .padStart(2, '0')} ${getMonthName(date.getMonth(), t)
        .toString()
        .padStart(2, '0')} ${date.getFullYear()}`
    : null;
};

export const getUTCDateAddMonth = (date?: Date): string =>
  date
    ? `${date.getUTCDate().toString().padStart(2, '0')}.${(date.getUTCMonth() +
        2 >
      12
        ? 1
        : date.getUTCMonth() + 2
      )
        .toString()
        .padStart(2, '0')}.${date.getUTCFullYear()}`
    : null;

const formatTimeShow = (hours24: number, minutes: number): string => {
  let hours = hours24 % 12;

  if (hours === 0) {
    hours = 12;
  }

  return `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}${hours24 < 12 ? ' AM' : ' PM'}`;
};

export const getTimeInterval = (
  dateFrom: Date,
  dateTo: Date,
  format: 12 | 24,
): string => {
  if (dateFrom && dateTo) {
    if (format === 12) {
      return `${formatTimeShow(
        dateFrom.getHours(),
        dateFrom.getMinutes(),
      )} - ${formatTimeShow(dateTo.getHours(), dateTo.getMinutes())}`;
    } else
      return `${dateFrom.getHours().toString().padStart(2, '0')}:${dateFrom
        .getMinutes()
        .toString()
        .padStart(2, '0')} - ${dateTo
        .getHours()
        .toString()
        .padStart(2, '0')}:${dateTo.getMinutes().toString().padStart(2, '0')}`;
  }

  return null;
};

export const getUTCDateFromDate = (date: Date): Date =>
  new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));

export const addDays = (date: Date, days: number): Date => {
  const result = new Date(date);

  result.setDate(result.getDate() + days);

  return result;
};

export const addMonths = (date: Date, months: number): Date => {
  const result = new Date(date);

  result.setMonth(date.getMonth() + months);

  return result;
};

export const subtractDays = (date: Date, days: number): Date => {
  const result = new Date(date);

  result.setDate(result.getDate() - days);

  return result;
};

export const getNotificationDate = (
  t: TFunction,
  createdAt: string,
  name?: string,
  chat?: boolean,
  type?: 12 | 24,
  isEng?: boolean,
): string => {
  const secondsDifference = Math.max(
    0,
    differenceInSeconds(new Date(), new Date(createdAt)),
  );

  const minutesDifference = Math.floor(secondsDifference / 60);
  const hoursDifference = Math.floor(secondsDifference / 3600);

  switch (true) {
    case inRange(0, 60, secondsDifference):
      return chat
        ? t('chat.read.now', { name })
        : t('profileSettings.notifications.now');
    case inRange(60, 120, secondsDifference):
      return chat
        ? t('chat.read.minute', { name })
        : t('profileSettings.notifications.minute');
    case inRange(1, 60, minutesDifference):
      return chat
        ? t('chat.read.minutes', { name, value: minutesDifference })
        : t('profileSettings.notifications.minutes', {
            value: minutesDifference,
          });
    case inRange(1, 24, hoursDifference):
      return chat
        ? t('chat.read.hours', { name, value: hoursDifference })
        : t('profileSettings.notifications.hours', {
            value: hoursDifference,
          });
    case hoursDifference > 24:
      return chat
        ? t('chat.read.dateTime', {
            name,
            value: isEng
              ? getDateForEngLocation(createdAt)
              : getDate(createdAt),
            time: getTimeWithA(createdAt, type),
          })
        : getDateAndTime(createdAt);
    default:
      return getDate(createdAt);
  }
};
