import React from 'react';
import { map, isEmpty, get } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ContentWrapper, ProgressBar, DocumentItem, Loader } from 'components';
import {
  getCurrentCommunity,
  getIsMenuOpen,
} from 'store/selectors/currentCommunity';
import { getDocumentationOverview } from 'store/selectors/documents';
import { selectCurrentUserSubscription } from 'store/selectors/subscriptions';

import {
  Header,
  MemoryStatusContainer,
  MemoryStatusMessage,
  HeaderContainer,
  DocumentsList,
} from './styled';

const DocumentationPage = () => {
  const { t } = useTranslation();
  const community = useSelector(getCurrentCommunity);
  const overview = useSelector(getDocumentationOverview);
  const currentUserSubscription = useSelector(selectCurrentUserSubscription);
  const isMenuOpen = useSelector(getIsMenuOpen);

  if (!(community || overview)) {
    return <Loader />;
  }

  return (
    <ContentWrapper isMenuOpen={isMenuOpen}>
      <HeaderContainer>
        <Header isMenuOpen={isMenuOpen}>{t('documentation.title')}</Header>
        <MemoryStatusContainer>
          <MemoryStatusMessage>
            {t('documentation.overview', {
              value: overview?.size?.toFixed(4),
              capacity: get(['maxStorage'], currentUserSubscription),
            })}
          </MemoryStatusMessage>
          <ProgressBar percentage={Math.ceil((overview?.size / 15) * 100)} />
        </MemoryStatusContainer>
      </HeaderContainer>
      <DocumentsList>
        <DocumentItem
          link="general"
          documentName={t('documentation.generalDocumentation')}
        />
        {!isEmpty(community.groups) &&
          map(
            (group) => (
              <DocumentItem
                key={group.id}
                link={group.id}
                documentName={group.name}
              />
            ),
            community.groups,
          )}
      </DocumentsList>
    </ContentWrapper>
  );
};

export default DocumentationPage;
