import styled from 'styled-components';

import { breakpoints } from 'theme';

export const StyledContentWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100svh - 212px);
  height: 100%;
  padding-bottom: 16vh;
  position: relative;
`;

export const StyledContainer = styled.div`
  margin: 0 16px 0 16px;
`;

export const StyledForm = styled.form`
  overflow-x: hidden;
  height: calc(100svh - ${({ submitVisible }) => (submitVisible ? 51 : 0)}px);
`;

export const TaskWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 28px;
  padding: 0px 16px;
  & > div {
    display: flex;
    flex-direction: column;
    border: 1px solid ${({ theme }) => theme.color.baliHai};
    border-radius: 6px;
    width: 100%;
    padding: 10px;
  }
`;

export const StyledFooter = styled.div`
  width: 100%;
  padding: 0px 16px;
  display: flex;
  flex-direction: column;
  padding-top: 16px;

  @media ${breakpoints.downMd} {
    padding-bottom: 100px;
  }
`;

export const StyledResponseQuestionText = styled.p`
  text-align: center;
  font-size: 12px;
  line-height: 32px;
  color: ${({ theme }) => theme.color.baliHai};
`;

export const StyledResponsesBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.5;
    pointer-events: none;
  `}
`;

export const CommentWrapper = styled.div`
  position: sticky;
  bottom: 0px;
  border-top: 1px solid #e5e9e9;
  background-color: white;
  z-index: 123654;
  padding: 0px 17px;
  label {
    background: ${({ theme }) => theme.color.white};
    width: 100%;
    height: 32px;
    margin-left: 0px;
    justify-content: center;
    margin: 0px;
  }
  textArea {
    padding: 8px 8px;
  }
  p {
    margin: 0px !important;
  }
`;

export const StyledResponseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  cursor: pointer;
  background: none;
  color: ${({ theme }) => theme.color.baliHai};
  width: 100%;
  height: 39px;
  position: relative;

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.5;
    pointer-events: none;
  `}
`;

export const StyledResponseRadio = styled.input`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.01;
  z-index: 100;
  cursor: pointer;
  transition: all 0.6s ease;

  &:active + label {
    transform: scale(0.95);
  }
  &:checked + label {
    background-color: ${({ color }) => color};
    color: ${({ theme }) => theme.color.black};
    border: 3px solid ${({ theme }) => theme.color.pickledBluewood};
    svg {
      path {
        fill: ${({ theme }) => theme.color.black};
      }
    }
  }
`;

export const StyledResponseLabel = styled.label`
  display: block;
  border: 1px solid ${({ theme }) => theme.color.botticelli};
  border-radius: 99999999px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  z-index: 90;
  color: ${({ color }) => color};
  font-size: 13px;
  line-height: 32px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    margin-bottom: 5px;
    margin-left: 3px;
    path {
      fill: ${({ color }) => color};
    }
  }
`;

export const StyledPopupWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.darkGrey};
  left: 0;
  top: 0;
`;

export const StyledPopup = styled.div`
  width: 334px;
  position: relative;
  background: ${({ theme }) => theme.color.white};
  padding: 30px 0 20px 0;
  border-radius: 8px;
  box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.02), 0 -1px 6px 0 rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  align-items: center;

  & > button:first-of-type {
    position: absolute;
    right: 13px;
    top: 13px;
  }
`;

export const StyledPopupHeader = styled.h1`
  color: ${({ theme }) => theme.color.pickledBluewood};
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  padding: 0 30px 20px 30px;
`;

export const StyledRedioWrapper = styled.div`
  margin-bottom: 10px;
`;
