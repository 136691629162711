/* eslint-disable quotes */
export const translation = {
  en: {
    appWidget: 'Install Gumb App on your device',
    featurePopups: {
      appointmentSchedule: {
        innerHtml:
          "<h3>New feature: Download your appointment schedule as an Excel or CSV file. </h3><p>We have completely redesigned the statistics page, and you can export your entire schedule now. The new report called 'Appointment schedule' gives you an overview of facts and figures of your events.</p><p>We are already working on another report called 'Member figures' which will be related to user performance. </p><p>The diary report is now available in the web app for all users.</p>",
        buttonOk: 'LATER',
        buttonRedirect: 'SEE NOW',
      },
      preferecePopup: {
        title: '2 New Features',
        first: {
          title: 'Set All to "Going":',
          subtitle:
            'Set the default response for new or existing events – for all participants at once!',
          link: '',
        },
        second: {
          title: 'Default Event Settings:',
          subtitle: 'Define how new events should look.',
          link: 'Instructions',
        },
      },
      appleCalendar: {
        title: 'CONNECT YOUR APPLE (iCal) CALENDAR&nbsp;🍏',
        message:
          'We did it! After a lot of hard work, Gumb now enables direct calendar connection in the WEB version for seamless synchronization. Stay tuned for the iOS/Smartphone release&nbsp;coming&nbsp;soon!',
        extraMessage: 'Open the step-by-step guide',
        redirectText: 'here',
        okButton: 'Connect Now',
        cancelButton: 'Not Now',
      },
      calendar: {
        innerhtml:
          "<h3>We did it!</h3> <p> <b>GOOGLE CALENDAR connections</b> are now available! </p> <br/> <p> Events from Gumb - Each community individually selectable, can now be automatically added to your calendar.  </p> <div class='images-container'> <img src='https://gumb-cdn-prod.s3.eu-central-1.amazonaws.com/featurePopups/calendar-1.png' alt='calendar-1' /> <img src='https://gumb-cdn-prod.s3.eu-central-1.amazonaws.com/featurePopups/calendar-2.png' alt='calendar-2' /></div> <p> This tutorial explains everything you need to know: <br/><a target='_blank' rel='noopener noreferrer' href='https://gumb.app/en/support/connect-gumb-with-google-calender/'>→&nbsp; Click here for full instructions </a> </p> <p>Quick guide:</p> <ol> <li>Connect and important: check the box!</li> <li>Select community</li> <li>Wait a few minutes and reload the page.</li> </ol> <p> Connect your Google Calendar to save time and make the most of each day. </p>",
        buttonOk: 'NOTHING FOR ME',
        buttonRedirect: 'CONNECT NOW',
      },
      notifications: {
        title: 'How should members be notified?',
        firstRow: 'We added: Mobile Push Messages & Notification Settings.',
        secondRow:
          'Customize now the notification settings that admins manage, for the entire community:',
        thirdRow: 'All you need to do is:',
        listFirstRow: 'Go to Community Settings → Notifications.',
        listSecondRow:
          'Enable/disable the options in each category → to customize everything perfectly for you. (→ Read tutorial <0>here</0>)',
        lastRow:
          'Tell now your members to enable push or email settings in their own Gumb profile.',
        buttonLater: 'Later',
        buttonSettings: 'Go to settings now',
      },
      pushNotifications: {
        title: '📢 Next-Level Communication: Push Notifications',
        message:
          'Admins and group leaders can now create push notifications to send you important information directly to your smartphone.\n\nWhat you can do with push notifications:\n\n• <b>Share important information instantly</b> (e.g., schedule changes, emergencies)\n• <b>Reach individuals or entire teams</b> (personalized communication)\n• <b>Save messages as templates to save time</b> (efficient communication)',
        extraMessage: 'Stay up-to-date and never miss any news again!',
        cancelButton: 'View later',
        okButton: 'Try now',
        newMessageOne:
          'Admins and group leaders can now communicate directly with individuals or entire teams to send you important information directly to your smartphone.\n\n',
        redirectLink: 'Create custom push notifications',
        newMessageTwo:
          ', select recipients, and even save your messages as templates for later use.',
      },
      importEvent: {
        title: 'Upload/Import Events ',
        subText:
          "No more manual entry! Easily import your events with a CSV or Excel file. Whether it's 30 or 300 events - it's quick and can even be undone if there are mistakes.",
        cancelButton: 'View later',
        okButton: 'Try now',
        secondText: 'Find step-by-step instructions',
      },
      mail: {
        title: 'New function: MAIL MODE',
        firstRow:
          'The integrated e-mail function simplifies and speeds up team communication.  Urgently need to send an e-mail to everyone? But only to members who answered "Yes" at the appointment? → Now possible with just a few clicks!',
        secondRow:
          'The mail mode is now available in the web app for admins and group leaders. It will be further expanded in the coming time.',
        thirdRow: 'Read more: <0>https://gumb.app/support/mailmodus/</0>',
        buttonLater: 'LATER',
        buttonMail: 'VIEW NOW',
      },
      eventPdf: {
        title: 'New feature: Download event as PDF',
        firstRow: 'Save time now and print the event details with one click!',
        secondRow:
          'Shortly before upcoming events, or even for past ones, all event details including all response statistics can be exported to PDF.',
        thirdRow: 'Requirement scheduler: You have admin rights',
        fourthRow: 'Requirement group planner: You are the group leader',
        fifthRow: 'The feature is now available in the web app.',
      },
    },
    menu: {
      homePage: 'Home',
      subscriptions: 'Subscriptions',
      groupScheduler: 'Scheduler',
      documentation: 'FILES',
      chats: 'Chat',
    },
    badges: {},
    dayOfWeek: {
      day1: 'MON',
      day2: 'TUE',
      day3: 'WED',
      day4: 'THU',
      day5: 'FRI',
      day6: 'SAT',
      day0: 'SUN',
    },
    email: {
      sender: 'Sender',
      recipients: 'Recipients',
      otherRecipients: 'Other recipients (separate with comma)',
      subject: 'Subject',
      message: 'Message',
      attachCommunityDocs: 'Attach community documents',
      plusTooltip: 'Write new email',
      emailSentSuccessfuly:
        'Email was sent successfully. Please note: This was a "No Reply Email" - An email to which the recipient cannot reply.',
      sendCopy: 'Send a copy to me',
      maxAttachmentsSize:
        'If you add multiple attachments, their combined size must not exceed 15 MB.',
      changeEmail: {
        title: 'Confirmation Email Sent',
        message:
          "We've emailed you with a confirmation link for your new email address. Please check your inbox and click on the link to confirm your changes. If you don't receive the email within a few minutes, please check your spam folder. If you have any further questions or encounter any issues, don't hesitate to reach out to us.",
      },
    },
    pushNotification: {
      header: 'Push Notification',
      filterByRecipient: 'Select Recipients',
      filterByResponse: 'Select Recipient by Response',
      pushMessage: 'Create Push',
      saveAsTemplate: 'Save Template',
      sendCopy: 'Send me a copy',
      allowMember: 'Allow member to member',
      pasteUrl: '🌐 Event or external link?',
      plusTooltip: 'Create new push notification',
      toasterText:
        '🆕 New Feature: You can now add an external link, like any website 🌐 (URL), to push notifications!\nDirect recipients straight to the website.',
      templates: 'Templates',
      title: 'Title',
      lastUsed: 'Last Used',
      noTemplates: 'Saved templates appear here',
      template: 'Template',
      sendConfirmHeader: 'Review Before Sending',
      totalRecipients: 'RECIPIENTS',
      redirectLink: 'TO LINK',
      successTitle: 'Push Sent!',
      successMessage:
        'Your message was sent to <b>{{totalRecipients}}</b> recipients.',
      selectEventFirst: 'Please select an event first',
      templateLimitTitle: 'Template Limit Reached',
      templateLimitInfo:
        "Save up to 8 of your messages as templates to reuse them later. We've already added 3 templates for inspiration – feel free to edit or delete them.",
      templateLimitMessage:
        'You have reached the maximum number of templates. Please delete one to create a new one.',
      urlInfo:
        'You can add links to any website (except Gumb) here. This could be a link to a product page, a news article, or anything else you find relevant. Simply paste the full URL (including https://).',
      urlInfoOne:
        'You can add any external website (URL) here to direct your recipients straight to it. If you choose an event above, the app will insert the event link, and clicking the push notification will open the event directly in Gumb.',
      urlInfoTwo:
        'Coming Soon: Add any external link (except Gumb) to your pushes! Link to websites, news, and more. Stay&nbsp;tuned!',
      urlTooltip:
        'You can create a push notification with or without linking to an event. Want to share your event link? Simply select an event above to automatically copy it here.',
    },
    common: {
      onlyAdmin: 'Only admins can use this feature.',
      download: 'Download',
      attachFile: 'Attach File',
      new: 'New',
      unseen: 'Unseen',
      more: 'More',
      less: 'Less',
      sunday: 'Sunday',
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
      january: 'January',
      february: 'February',
      march: 'March',
      april: 'April',
      may: 'May',
      june: 'June',
      july: 'July',
      august: 'August',
      september: 'September',
      october: 'October',
      november: 'November',
      december: 'December',
      overallScheduler: 'Overall Scheduler',
      selectEvent: 'Select event (optional)',
      displayEventsFrom: 'Display events from',
      modalExit: 'Are you sure you want to exit without saving your data?',
      all: 'All',
      tooltipEdit: 'Edit',
      tooltipDelete: 'Remove',
      overview: 'Overview',
      cancel: 'Cancel',
      removeLogo: 'Are you sure you want to delete this?',
      noTasks: 'No tasks found',
      noUsers: 'No users found',
      role: 'Roles',
      admin: 'Admins',
      member: 'Members',
      group_lead: 'Group Leaders',
      email: 'Email',
      groups: 'Groups',
      logoutHeader: 'Logout?',
      logoutMessage: "You'll be directed back to the login screen.",
      deleteHeader: 'Are you sure you want to delete user?',
      logout: 'Logout',
      delete: 'Delete',
      tablePlaceholder: 'No members to show',
      contentPlaceholder: 'No content to show',
      create: 'Create',
      copy: 'Copy',
      visible: 'Visible',
      saveChanges: 'Save changes',
      newField: 'New field',
      fieldName: 'Field name',
      addField: 'Add field',
      notifications: 'Notifications',
      participants: 'Participants',
      tasks: 'Tasks',
      documentation: 'Documentation',
      description: 'Description',
      facebook: 'Facebook',
      twitter: 'Twitter',
      linkedin: 'LinkedIn',
      instagram: 'Instagram',
      youtube: 'Youtube',
      startDate: 'Start date',
      startTime: 'Start time',
      startDateTime: 'Start date/time',
      endDateTime: 'End date/time',
      endDate: 'End date',
      endTime: 'End time',
      icon: 'Icon',
      sendMessage: 'Send message',
      aboutMe: 'About Me',
      selectAll: 'Select all',
      approve: 'Approve',
      reject: 'Reject',
      backToGumb: 'Back to Gumb',
      edit: 'Edit',
      addComment: 'Add comment',
      answer: 'Save',
      ok: 'Ok',
      withoutGroup: 'Without group',
      copyLink: 'COPY LINK',
      copied: 'Copied',
      sendInviteLink: 'Send invite link',
      apply: 'Apply',
      from: 'From',
      to: 'To',
      enable: 'Enable',
      search: 'Search',
      color: 'Color',
      columns: 'Show columns',
      hiddenColumns: 'Hidden columns',
      noResultsFound: 'No results found',
      sorryNoResults:
        "We're sorry you couldn't find what you were looking for. Please try another way.",
      tutorial: 'Tutorial',
      next: 'Next',
      back: 'Back',
      step: 'Step',
      createSuccess: {
        title: 'Created',
        message: 'Your option has been successfully created.',
      },
      editSuccess: {
        title: 'Edited',
        message: 'Your changes have been saved.',
      },
      deleteSuccess: {
        title: 'Deleted',
        message: 'The option has been successfully removed.',
      },
      toolTipText: {
        faq: 'Help: Guides, Contact, More.',
        communityPlusIcon: 'Create New Community',
        logoGumb:
          'Mystery in the making: Discover later what this button conceals!',
        memberArrowIcon: 'Go to Member Overview',
        memberPlusIcon: 'Add New User',
        eventType: 'Create Event Type',
        responses: 'Create Answer Option',
        task: 'Create Task',
        schedulerPlusIcon: 'Create Event or Group',
      },
      shareEventTitle: 'Share event link via:',
      copyEventLink: 'Or copy the link:',
    },
    auth: {
      signInWith: 'Sign in with',
      continueAs: 'Continue as',
      create: 'Create Free Account',
      signUp: 'Sign up',
      signIn: 'Sign in',
      welcome: 'Welcome!',
      createAccount: 'Create free',
      forgotPassword: 'Forgot password?',
      resetPassword: 'Reset password',
      resetPasswordTooltip:
        "If you reset the password, the member gets an email with instructions on how to set a new password. However, Gumb can't reset for Facebook/Google/Apple sign-ups; they should use the respective icon.",
      resetPasswordModalTitle: 'Password Reset Initiated',
      resetPasswordModalText:
        "The member will soon receive a reset link to the email listed below. For Facebook/Google/Apple, it's not possible, but the member can still use the respective icon to log in.",
      resetPasswordErrorModalTitle: 'Password Reset Not Possible',
      resetPasswordErrorModalText:
        'Note! This member seems to have registered via Social Media (e.g., Facebook, Google, Apple). Therefore, Gumb cannot reset the password for this account. However, the user can still log in using the respective Social Media icon. Please inform the member accordingly.',
      terms: {
        '0': 'I agree to the ',
        '1': 'Terms',
        '2': ' and ',
        '3': 'Privacy Policy',
      },
      home: 'Home',
      confirmed: 'Email successfully confirmed',
      verifying: 'verifying email...',
      recovery: 'Password recovery',
      send: 'Send',
      codeSended:
        'Verification link has been sent to your email. Please check and confirm. Pay attention to spam folder.',
      signUpSuccess:
        'Registered successfully. Please check email to confirm registration',
      inviteSent:
        'Registered successfully. Please check email to confirm registration',
      inviteSentTitle: 'Invitation and Access Data Successfully Sent',
      inviteSentText:
        "Great! You've successfully preregistered the member and sent the invitation with a temporary password. The member can now log in and change their password. If necessary, reload this page to immediately see the member. You and your admins can now determine group allocations and assign roles. Using the pencil icon on the far right, you can also complete the member's personal details. Keep it up!",
      reset: 'Reset',
      yourEmail: '{{value}} is already your email address',
      existEmail: '{{value}} already in use',
      labels: {
        email: 'Email',
        currentEmail: 'Current email',
        newEmail: 'New email',
        password: 'Password',
        oldPassword: 'Old password',
        newPassword: 'New password',
        confirmPassword: 'Confirm password',
      },
      accountExists:
        'We already have an account with this email address at Gumb. If this email belongs to you, click forgot password and check your inbox and spam folder.',
      codeExpired:
        'This was an expired link. After you enter your email here, we will email you with a new link to complete your account verification.',
      userNotConfirmed:
        'You still need to verify your account. We have sent you an email with a confirmation link. If you did not receive an email, please check the spam folder in your mailbox. ',
      invalidCredentials: 'Incorrect username or password',
      userNotFound:
        "The email address is already connected via Google/Apple/Facebook or hasn't been registered. You can't change a social media password with Gumb. Log in via the social media icon instead.",
    },
    profile: {
      editSuccess: 'Changes have been successfully updated',
      settings: 'Settings',
      editProfile: 'Edit profile',
      selectImage: 'Select image',
      myProfile: 'My profile',
      profileEditBtn: 'ADD YOUR INFO: ADDRESS, SOCIALS & MORE',
      basicInformation: 'Basic Information',
      contactInformation: 'Contact Information',
      addressInformation: 'Address Information',
      myCommunities: 'My communities',
      myAppointments: 'My Appointments',
      myChat: 'MY CHATS',
      subscription: 'Subscription',
      userProfile: 'User profile',
      tellUsAboutYourself: 'Tell us about yourself',
      firstName: 'First name',
      lastName: 'Last name',
      profileName: 'Profile name',
      person: 'Person',
      role: 'User Role',
      email: 'Email',
      dateOfBirth: 'Date of birth',
      privatePhoneNumber: 'Phone Number Private',
      officePhoneNumber: 'Phone Number Office',
      mobilePhoneNumber: 'Phone Number Mobile',
      street: 'Street',
      region: 'Region',
      postalCode: 'Postal code',
      city: 'City',
      country: 'Country',
      about: 'About',
      linkChangeEmail: '(Change email)',
    },
    profileCommunities: {
      heading: 'My Communities',
      titleOwner: 'Community owner',
      titleMember: 'Member',
      popupLeave: {
        title: 'Are you sure you want to leave this community?',
        button: 'Leave',
      },
      leaveCommunity: {
        title: 'Are you sure about leaving this community?',
        message:
          'When you leave this community, your user account still exists, and you can still join other communities.',
        okButton: 'Yes, Leave',
        cancelButton: 'Cancel',
      },
      table: {
        labelCommunity: 'Community',
        labelOwner: 'Owner',
        labelGroups: 'Groups',
        labelMembers: 'Members',
      },
    },
    profileSubscription: {
      heading: 'Subscription Details',
      titleMembership: 'Membership',
      titlePayment: 'Payment',
      buttonCancel: 'Cancel membership',
      buttonRestart: 'Restart Membership',
      details: {
        linkChangePlan: 'Change plan',
        infoCancelled:
          'Your subscription has been cancelled and will not be renewed. You can continue to use the app until the next billing date. You can renew your subscription anytime.',
        infoTrial:
          'Your trial month is currently active. After the expiration, you will of course not be charged.  If you would like to continue using Gumb, select a suitable subscription under “Change plan”.',
        infoTrialNew:
          "Your trial period is currently running. After it expires on {{expireDate}}, you won't be charged. If you'd like to continue using Gumb, select a suitable subscription under 'Change plan.'",
        linkShowCommunities: 'Show my communities',
        linkHistory: 'Billing History',
        linkChangePayment: 'Change payment method',
        currentPlan: 'Current Plan:',
        communitiesInUse: 'Communities in use:',
        groupsInUse: 'Groups in use:',
        membersInUse: 'Members in use:',
        storageInUse: 'Storage in use:',
        paymentCycle: 'Payment cycle:',
        paymentCycleMonth: 'Monthly',
        paymentCycleYear: 'Yearly',
        nextPayment: 'Next payment:',
        validUntil: 'Still valid until:',
        paymentMethod: 'Payment method:',
      },
    },
    profileSettings: {
      title: 'Profile settings',
      notification: 'Notification',
      notificationsPlaceholder: "You don't have any notifications",
      changePassword: 'Change password',
      changeEmail: 'Email',
      saveNewEmail: 'Save new email',
      language: 'Language',
      timeFormat: 'Time format',
      permissions: 'Permissions',
      allowedToEdit: 'Admins are allowed to edit my profile',
      other: 'Other',
      pushNotification: 'Push notification',
      receiveByEmail: 'Receive notifications by email',
      receivePush: 'Receive mobile push notifications',
      receiveInApp: 'Receive in-app notifications',
      terms: 'Terms and Conditions',
      protectionRegulation: 'Privacy Policy',
      deleteAccount: 'Delete account',
      logout: 'Logout',
      deleteProfileHeader: 'Are you sure you want to delete your account?',
      notifications: {
        now: 'Now',
        hours: '{{value}}h ago',
        minutes: '{{value}} mins ago',
        minute: '1 min ago',
      },
    },
    calendarConnection: {
      googleCalendar: 'Google Calendar',
      pageTitle: 'Calendar connection',
      connectTitle: 'Connect your calendar\nto automatically sync your events',
      connectDescription:
        'Gumb can automatically create events that appear on your calendar. Keep track of things with this sync.',
      appleCalendar: 'Apple Calendar',
      appleConnectionSteps: {
        headerOne: 'Integrate Gumb events into your Apple Calendar with a URL',
        headerOneSplit: 'in 4 steps; quick demo',
        here: 'here',
        headerTwo: 'Or detailed instructions',
        stepOne:
          '1. In your Apple Calendar, under "Calendar", choose "File" > "New Calendar Subscription".',
        stepTwo:
          '2. Enter the Community’s iCal Link copied from below, then click "Subscribe".',
        stepThree:
          '3. Enter a name for the calendar and pick a color to help you identify it on your calendar.',
        stepFour:
          '4. Adjust preferences like location (iCloud or Mac) or the auto-refresh, then click "OK".',
      },
      appleConnectionPopup: {
        title: 'How to connect?',
        stepOne: 'Copy the community link',
        stepTwo:
          'In your Apple Calendar, under "Calendar", choose "File" > "New Calendar Subscription".',
        stepThree:
          'Enter the Community’s iCal Link copied, then click "Subscribe".',
        stepFour:
          'Name the calendar, choose a color, set preferences like location (iCloud or Mac) or auto-refresh, and click "OK".',
      },
      appleDisconnectPopup: {
        title: 'Confirm Disconnection',
        message:
          'Clicking "Yes, disconnect now" will remove all Gumb events from your calendar(s). Please manually delete the calendar(s) afterwards, as it/they will otherwise remain visible.\n\n<b>To fully unsubscribe:</b>\n\n• <b>On macOS</b>: Open Calendar, right-click the calendar > "Unsubscribe".\n• <b>On iOS</b>: Go to "Calendars", tap the "Info" button next to the calendar > "Delete Calendar".',
        okButton: 'Yes, disconnect now',
      },
      appleUncheckBoxPopup: {
        title: 'Confirm Disconnection',
        message:
          'Clicking "Yes, disconnect now" will remove all Gumb events from your calendar. Please manually delete the calendar afterwards, as it will otherwise remain visible.\n\n<b>To fully unsubscribe:</b>\n\n• <b>On macOS</b>: Open Calendar, right-click the calendar > "Unsubscribe".\n• <b>On iOS</b>: Go to "Calendars", tap the "Info" button next to the calendar > "Delete Calendar".',
        okButton: 'Yes, disconnect now',
      },
      connectWithApple: 'Connect Apple (iCal)',
      cantSeeICal: "Can't see the iCal link? Just tick the checkbox.",
      syncTip:
        'Only the events from the selected community will be synchronized with your calendar.',
      connect: 'Connect',
      connected: 'Connected',
      disconnect: 'Disconnect',
      disconnectSuccess: 'Disconnected successfully',
      selectCommunities: 'Communities selection',
      selectCommunitiesDescription:
        'Only the events from the selected community will be synchronized with your calendar.',
      authSuccessTitle: 'Successfully connected',
      authSuccessMessage:
        'Your communities will now automatically synchronize. Wait a moment and then refresh the Google Calendar page to complete the initial synchronization.',
      authSuccessTitleTwo: 'Select your community now',
      authSuccessMessageTwo: 'Tap the checkmark and "Save Changes" to confirm.',
      syncSuccess:
        'Successfully updated!\nFrom now on, everything will be synchronized automatically. You can change the selection of communities at any time.',
      syncSuccessTitle: 'Almost Done!',
      syncSuccessMessage:
        "We're linking your community to your Google Calendar. It'll just take 2-3 minutes. Refresh your Google Calendar page to see updates.",
      disconnectConfirmTitle: 'Are you sure?',
      disconnectConfirmDescription:
        'All the events will be removed from your calendar if you disconnect.',
      disconnectConfirm: {
        title: 'Disconnect Now?',
        message:
          "Disconnecting will remove all events from your Google Calendar. Don't worry, you can reconnect Gumb for a new synchronization later at any time.",
        cancelButton: 'Cancel',
        okButton: 'Yes, Disconnect',
      },
      noCommunities:
        'There are no communities to select.\nWait until an admin adds you.',
      checkboxWarning: 'ATTENTION: The check mark must be set!',
    },
    community: {
      plusIconSubText: 'Plan an event or set up recurring events.',
      selectAllCommunityAdmins: 'All admins',
      selectAllGroupAdmins: 'All group leaders',
      createSuccess: 'Community was successfully created',
      createFailure: 'Failed to create community',
      deleteSuccess: 'Community was successfully deleted',
      leaveSuccess: 'You have successfully left the community',
      descriptionTitle: 'About community',
      descriptionPlaceholder: 'Here you can describe your community',
      online: 'Online',
      updateCommunity: 'Update Community',
      members: 'Members',
      createCommunity: 'Create community',
      communityName: 'Community name',
      category: 'Category',
      aboutCommunity: 'About community',
      selectLogo: 'Select logo',
      selectLogoTip: 'jpg, jpeg, png (5MB)',
      chooseCover: 'Choose cover',
      chooseCoverTip: 'jpg, jpeg, png (20MB)',
      website: 'Website',
      partnership: 'Partnership',
      deleteCommunity: 'Delete community',
      deleteHeader: 'Are you sure you want to remove this user from group?',
      addMembers: 'Add members',
      invitationLink: 'Invitation link',
      communityRole: 'Community role',
      joinCommunity: 'Join community',
      namePlaceholder: "{{value}}'s team",
      requestSent: 'Membership request successfully sent',
      requestSentButton: 'Membership request sent',
      limitExceededHeader:
        'You have reached the limit for communities. You need to upgrade your subscription plan to create more.',
      limitExceededMessage:
        'Please contact the community owner. Your community owner(s) can check the subscription plan limits and upgrade your community subscription plan.',
      limitExceededMessageOwner:
        'Please visit subscriptions page to check your subscription plan limits or upgrade your subscription',
      updateFailure: 'Community update failure',
      buttonAddEvent: 'Add Event',
      userBlockedTitle: 'Blocked',
      userBlockedText:
        'Seems like someone blocked you on this community. Admins can block or deblock members.',
      createOverLimitTitle: 'Communities limit reached',
      createOverLimitText:
        'Start your free trial now or choose a subscription under “Change plan” to organize your own community.',
      notInvitedTitle: 'No permission',
      notInvitedText:
        'You may not have permission to access this event. Admins and group leaders can add you to the event.',
      noRequestsTitle: 'No requests to review',
      noRequestsText:
        'When people submit requests for your community, they will be displayed here for you to review.',
      invitationCodeExpired:
        'The invitation link has expired. You can now send a join request, which admins will decide whether to accept or reject.',
      communityNotFound: 'Community not found',
      communityPopup: {
        title: 'Create Event or Group',
        subTitleOne:
          'Plan events in the central SCHEDULER. Use groups to divide members into teams.',
        createEvent: {
          infoText:
            "Schedule events for all or specific groups/members in the SCHEDULER. Visible in scheduler, 'My Events', and groups.",
          btntitle: 'Create Event',
          linkText: 'Tutorial',
        },
        createGroup: {
          infoText:
            'Groups have their own planners, with events visible only to group members. Manage member roles effectively',
          btntitle: 'Create Group',
          linkText: 'here',
        },
        chatTitle: 'Chat Options',
        chatSubTitle: 'Start a 1:1 chat or create a chat room for groups.',
        oneToOne: {
          infoText:
            "Click the chat icon next to a member's name or their profile picture to send a message.",
          btntitle: 'Go to Member List',
          linkText: 'here',
        },
        createChatGroup: {
          infoText:
            'Create a private chat room for selected members, teams, or groups. Only admins can create these rooms.',
          btntitle: 'Create Chat Room',
          linkText: 'here',
        },
      },
    },
    communitySettings: {
      admin: 'Admin',
      member: 'Member',
      blocked: 'Blocked user',
      owner: 'Owner',
      group_lead: 'Group Leader',
      group_admin: 'Group Admin',
      chat_admin: 'Chat Admin',
      community_admin: 'Community Admin',
      editRoleSuccess: 'Role was successfully changed',
      deleteUserSuccess: 'User was successfully deleted from community',
      removeUser: {
        title: 'Are you sure about removing this user?',
        message:
          'When you remove this user, they will be removed from your community but will retain their user account and can still participate in other communities.',
        cancelButton: 'Cancel',
        okButton: 'Yes, Remove',
      },
      removeUserSuccess: {
        title: 'User Removed',
        message: 'The user has been successfully removed from your community.',
      },
      editSuccess: 'Changes have been successfully updated',
      title: 'Community settings',
      backToSubscriptionsDetails: 'Back to subscription details',
      tabs: {
        general: 'General preferences',
        eventTypes: 'Event Types',
        subscriptions: 'Subscriptions',
        responses: 'Response Options',
        roles: 'Members',
        chats: 'Chats',
        requests: 'Requests',
        other: 'Other',
        tasks: 'Tasks',
      },
      addMember: 'Add Member',
      exportMembers: 'Export',
      exportMembersTooltip: 'Export the member list to a CSV file',
      deleteCommunityHeader: 'Are you sure you want to delete this?',
      defaultEventList: [
        'Meeting',
        'Training',
        'Game',
        'Rehearsal',
        'Event',
        'Board meeting',
      ],
      defaultResponsesTypes: [
        "Yes, I'm coming",
        "No, I'm not coming",
        'May be',
        'Neutral',
      ],
      defaultTasks: ["Who's driving?", 'Who brings the drinks?'],
      selectUsers: {
        title: 'Select Users',
        message:
          'Please mark users with a checkbox to approve or decline membership requests.',
      },
      memberExists: 'Member already exists',
      mailSent:
        'Invitation is sent successfully. Now the user just presses "Join now" in the invitation and as soon as she/he logs in with the temporary password, he/she is registered and directly added to your community as a member.',
      userNotFound:
        'No Gumb profile was found. To create new profile and send invitation to this email, please provide additional information',
      disableAddMemberTooltip:
        'The number of members has already reached the limit. To resolve this, the community owner can consider a subscription upgrade to add more members.',
      notifyByFields: 'Send notification, if the following fields get updated',
      notifyByReplies: 'Send notification, if invitee has responded with',
      remindBefore: 'Remind before',
      addReminder: '+ Add reminder (2 max.)',
      notificationsTypes: [
        'Event created',
        'Event updated',
        'Event deleted',
        'Event reminder',
      ],
      notificationsClasses: ['In-App', 'Email', 'Push'],
      notificationPopupHeader:
        'Would you like to send notifications to invited users?',
      notificationPopupMessageLabel:
        'Add an optional message for invited users',
      notificationPopupNotify: 'Send',
      notificationPopupDoNotNotify: "Don't send",
      notificationPopupBack: 'Back to editing',
    },
    communityInvitation: {
      subjectFirst: 'You have been invited to join the community',
      subjectSecond: '',
      body: 'Please follow the link below to join the community',
      invitationSuccess:
        'Successfully approved. User has been added to the member list. Now check the role and grouping in the community settings member list.',
      invitationFailure:
        'There was an error, you may have run out of subscriptions',
      membersInvitation: {
        title: 'Invite members',
        description:
          'Add new users to your community so they become members and have permission to respond to your events. See the <0>"Add a new user"</0> tutorial.',
        showMore: 'Show more',
        showLess: 'Show less',
        codeInvitation: {
          title: 'The fastest and easiest way',
          button: 'Copy invitation link',
          updateLink: 'Renew link',
          generateLink: 'Generate link now',
          howItWorks: 'How it works',
          goodToKnow: 'Good to know',
          howItWorksText:
            'Members click on "Create free account" using the link selected above. When Gumb sends an email, members only need to confirm their email address to gain direct access.',
          goodToKnowText:
            'This is super-quick, but please note that once the account verification is complete, people with this link will be able to access your events immediately. Hence, you can renew the above link whenever you like.',
        },
        linkInvitation: {
          title:
            'Better safe than sorry: send membership request with community link',
          button: 'Copy community link',
          howItWorks: 'How it works',
          adminFlow: 'Admins accept or decline',
          howItWorksText:
            '<0>Admins copy and share this community link.</0><0>Members, with a Gumb account, click "join community".</0><0>Members, without account, first register on Gumb, and then click the link again to send you the request.</0>',
          adminFlowText:
            'On the "Requests" tab, admins must approve or deny a member\'s request to join your community. When there are open requests, admins will see a notification on the gear.',
        },
        addMember: {
          title: '2 more options: Efficient or Personal',
          button: 'ADD USER',
          selfSignUp: 'Members register themselves',
          preRegister: 'Pre-register Person',
          selfSignUpText:
            'Tell everyone to simply register themselves on Gumb (for free) and send you the registered email. If you enter it here, the member will automatically join your community and see all the events.',
          preRegisterText:
            'Register new members directly through their email address. This action automatically sends them an "Email Invitation" with a temporary password. Using this, they can immediately log in and change the password as they wish later on.',
        },
      },
    },
    group: {
      deleteEventSuccess: 'Event was successfully deleted',
      createSuccess: 'Group was successfully created',
      deleteSuccess: 'Group was successfully deleted',
      addMembersSuccess: 'Members successfully added. Check your members list.',
      addMembersSuccessWithMessage: {
        title: 'Member added!',
        message:
          'Your member list has been successfully updated. Manage roles and group assignments. You can also add or change user details.',
      },
      deleteUserSuccess: 'Successfully deleted user from group',
      addParticipants: 'Add Members',
      createGroup: 'Create Group',
      deleteGroup: 'Delete Group',
      groupName: 'Group Name',
      changeGroupNameSuccess: 'Group Name Successfully Changed.',
      groups: 'Groups',
      inviteMembers: 'Invite members',
      tabs: {
        schedule: 'Schedule',
        members: 'Members',
        chat: 'Chat',
        documentation: 'Documentation',
        statistic: 'Export & Statistics',
        import: 'Import',
      },
      limitExceededHeader:
        'You have reached the limit for groups. You need to upgrade your subscription plan to create more.',
      limitExceededMessage:
        'Please contact the Community owner. Your community owner(s) can check the subscription plan limits and upgrade your community subscription plan.',
      limitExceededMessageOwner:
        'Please visit subscriptions page to check your subscription plan limits or upgrade your subscription',
      groupRole: 'Group role',
      addGroup: 'Add Group',
    },
    chat: {
      noRead: 'Not yet read',
      lastAdmin:
        'A chat room needs at least one admin or group leader. Make sure at least one person always has this role.',
      read: {
        now: '{{name}} read just now',
        hours: '{{name}} read {{value}}h ago',
        minutes: '{{name}} read {{value}} mins ago',
        minute: '{{name}} read 1 min ago',
        dateTime: '{{name}} read on {{value}} at {{time}}',
      },
      update: {
        btn: 'SAVE',
        success: 'Member list has been updated',
        successUpdate: 'Chat group updated successfully',
      },
      modal: {
        btn: 'Manage',
        about: 'About',
        editRoom: 'Edit Chat Room',
        delete: 'Delete Chat Room',
        editMember: 'Edit Members',
      },
      details: {
        title: 'Chat Room Details',
        secondTitle: 'INFORMATION',
        name: 'Chat group name',
      },
      delete: { success: 'Chat room has been deleted' },
      create: {
        title: 'Create Chat Room',
        name: 'Chat Room Name',
        description: 'Group Description (optional)',
        btnNext: 'NEXT',
        success: 'Chat room created',
      },
      new: 'New',
      chat: 'Chat',
      plusTooltip: 'Create a new chat or group chat',
      op1: 'Files & Videos',
      op2: 'Photos',
      files: 'Files',
      inputPlaceHolder: 'Type a message',
      searchHover: 'Search or start a new chat',
      emptyStatus: {
        noChatSelected: {
          firstLine: 'Select a conversation to view the chat,',
          secondLine: 'or click here to start a',
          LinkText: 'NEW CHAT',
        },
        noChatUser:
          'No chats yet? Start a 1:1 chat or wait for your admin to add you.',
        noUnseenUser: "Don't have any unseen chat",
        sendFirstMessage: 'Write your first message to {{recipient}}.',
      },
      infoPopup: {
        title: 'Limit Exceeded',
        message: 'You can upload a maximum of 20 files at a time.',
      },
    },
    statistics: {
      tableType: 'Choose a report',
      eventReplies: 'Responses & Tasks',
      eventTypes: 'Event type',
      appointmentSchedule: 'Appointment schedule',
      memberFigures: 'Presence check',
      charts: {
        eventTypesByMonth: 'Number of events by type',
        eventReplies: 'Reactions to response options',
        memberPresence: 'Number of reactions per response',
      },
      loadingStatistics: 'Loading...',
      memberFiguresExportSuccess: {
        title: 'Report is being created!',
        message:
          "We're currently creating your custom attendance report. Due to the large amount of data, this may take a few minutes. For security reasons, the report will then be sent to your email address. Just check your inbox. Thank you for your patience!",
      },
    },
    filters: {
      clickToSelect: 'To filter, click here',
      showFilters: 'Show filters',
      dateRange: 'Select date range',
      filterBy: 'Filter By',
      dateRanges: {
        yearToDate: 'Year-to-date',
        lastDays: 'Last {{number}} days',
        lastYear: 'Last year',
        yesterday: 'Yesterday',
        today: 'Today',
        tomorrow: 'Tomorrow',
        nextDays: 'Next {{number}} days',
        thisWeek: 'This week',
        thisMonth: 'This month',
        thisYear: 'This year',
        allTime: 'All time',
        specificDateRange: 'Specific date range',
      },
      downloadXlsxOrCsv: 'Download here: Excel or CSV',
    },
    bulkUploadEvents: {
      toastText:
        '🆕  The new appointment import feature is now available for each group!  🎉',
      moduleTitle: 'Import',
      step1: 'Step 1',
      step2: 'Step 2',
      step3: 'Step 3',
      step4: 'Step 4',
      stepOne: {
        stepTitle: 'Prepare Data Import',
        stepDescription: 'Download Excel Template',
        componentTitle: 'Download Excel Template',
        componentDescription:
          'Fill in the template with your event data, or use your own Excel file.',
        p1: 'Fill in the template with your event data.',
        p2: 'Column headers must remain in English.',
        p3: 'Examples in each column show you the correct format.',
        p4: 'New event types are added automatically.',
        p5: '<b>Important</b>: If you use your own file, the following column titles must be copied exactly as shown in English (case sensitive):',
        p6: 'Examples in each column of the template show you the correct format.',
        p7: 'New event types are added automatically',
        eventTypes: 'GO TO EVENT TYPES',
        template: 'Download Template',
        nextStepButton: 'Continue',
        copy: 'Copy All',
      },
      stepTwo: {
        stepTitle: 'Upload File',
        stepDescription: 'Upload CSV File',
        componentTitle: 'UPLOAD CSV FILE',
        componentDescription:
          'Save the Excel file as <b><u>CSV</u></b> and upload it here.',
        p1: "We'll check the format and show you a preview.",
        uploadButton: 'Choose File',
        maximumFileSize: 'Maximum file size: 1MB',
      },
      stepThree: {
        stepTitle: 'Confirm Data',
        stepDescription: 'Preview of your first event.',
        componentTitle: 'Confirm Data',
        componentDescription:
          'Review the sample and adjust the activated members.\nThe selected members apply to all events.',
        nextStepButton: 'CONFIRM AND UPLOAD',
      },
      stepFour: {
        stepTitle: 'Successfully Uploaded!',
        stepDescription:
          'Uploading events was a success. See them in the scheduler.',
        componentTitle: 'Successfully Uploaded!',
        componentDescription: '<b>{{count}}</b> events have been imported.',
        secondTitle: 'Good to Know',
        secondSubTitle: 'You can undo the import or upload a new list.',
        goToScheduler: 'Go to Scheduler',
        undoImport: 'Undo Import',
        uploadNewList: 'Upload New List',
      },
      errors: {
        eventNameErrorTitle: 'Event Name Error',
        eventNameErrorMessage:
          'Event name missing on row <b><u>{{row}}</u></b>.',
        eventTypeErrorTitle: 'Event Type Error',
        eventTypeErrorMessage:
          'Event type missing on row <b><u>{{row}}</u></b>.',
        timeErrorTitle: 'Time Error',
        timeErrorMessage:
          'Start or end time missing on row <b><u>{{row}}</u></b>.',
        dateErrorTitle: 'Date Error',
        dateErrorMessage: 'Date missing on row <b><u>{{row}}</u></b>.',
        locationErrorTitle: 'Location Error',
        locationErrorMessage:
          'Event location missing on row <b><u>{{row}}</u></b>.',
        invalidDateOrTimeTitle: 'Invalid Date or Time',
        invalidDateOrTimeMessage:
          'Please re-check date and time format on row <b><u>{{row}}</u></b> (e.g., use HH:MM for time).',
      },
      deletePopupTitle: 'Delete Event List?',
      deletePopupMessage:
        'This action will permanently delete your last uploaded event list. Are you sure?',
      deleteSuccessTitle: 'Deletion Successful!',
      deleteSuccessMessage:
        'Your last uploaded event list was deleted successfully.',
      successMessage:
        'Validation Successful. Proceed to the next step to see a preview.',
      restartProcessTitle: 'Upload New Event List',
      restartProcessMessage:
        'You can upload a new event list at any time. Please note that previously uploaded events will still be visible in the scheduler. If you want to replace the old list, delete it first or undo the last import.',
      restartProcessOkButton: 'UPLOAD NEW LIST',
      exportDataSample: {
        event_name: 'Zurich Street Parade',
        event_type_name: 'Performance',
        event_location: 'Bahnhofstrasse 5, 8001 Zurich',
        event_description: 'Open-air festival with international bands and DJs',
      },
    },
    event: {
      event: 'Event',
      createdAt: 'Creation date',
      editEvent: 'Edit Event',
      createEvent: 'Create event',
      updateEvent: 'Update event',
      eventName: 'Event name',
      eventType: 'Event type',
      eventTypeDeleted: 'Deleted event type',
      eventDetails: 'Event details',
      eventOrganizer: 'Event organizer',
      eventOrganizerShort: 'Organizer',
      location: 'Location',
      eventTypeEdit: 'Event type edit',
      eventTypeName: 'Event type name',
      eventTypeCreate: 'Create event type',
      responseRequired: 'Response required',
      responseName: 'Response name',
      responsesEdit: 'Response edit',
      responsesCreate: 'Create response',
      repeatAnEvent: 'Repeat an event',
      repeatWithInterval: 'Repeat with interval',
      allDay: 'All day',
      registrationDeadline: 'Registration deadline',
      commentRequired: 'Comment required',
      editTask: 'Edit task',
      createTask: 'Create task',
      eventTaskName: 'Task name',
      icon: 'Icon',
      isMemberComing: 'Your response?',
      recurringEvent: 'Recurring event',
      allEvents: 'All events',
      thisEventOnly: 'This event only',
      createEventSuccess: 'Event was successfully created',
      updateEventSuccess: 'Event was successfully updated',
      createEventReplySuccess: 'Response successfully saved',
      updateEventReplySuccess: 'Response successfully updated',
      addGuests: 'Additional guests?',
      eventOperations: {
        eventOnly: 'This Event Only',
        eventAndFollowUp: 'This Event and Follow-up',
        all: 'All Events',
        deleteText: 'Deleting Recurring Events',
        editText: 'Editing Recurring Events',
        editReplyText: 'Your answer applies to',
      },
      dfRes: 'Set Default Response',
      responseToast:
        'Careful! If you delete the default response without saving a new one, all previous reactions and comments will be deleted.',
      setPreferences: 'Set Preferences',
      setEventDefaults: 'Set Event Defaults',
      preferencesText:
        'Define default settings for new events of this type, including the default response for members',
      responseSubTitle: 'Request Comment (optional)',
      requestComment:
        'Enable this option to prompt the user for a comment when this response is selected (e.g., for "No, I can\'t make it" to inquire about the reason)',
      registeredUsers: 'Responses',
      guests: 'Guests',
      nomination: 'Nomination',
      nominated: "You're nominated",
      havePenalty: 'You received a penalty',
      penalty: 'Penalty',
      invitees: 'Invitees',
      awaiting: 'Awaiting',
      minutes: 'Minutes',
      hours: 'Hours',
      days: 'Days',
      weeks: 'Weeks',
      weekDay: 'Weekday',
      duration: 'Duration',
      months: 'Months',
      years: 'Years',
      viewAppointment: 'View Event',
      myResponse: 'My Response',
      noEvents:
        "Quiet here? Maybe you've answered all appointments, aren't activated, or none are scheduled. Admins and team leaders are in charge.",
      shareEvent:
        'Share event — click on this icon to copy this event and send it anywhere you can paste texts (email, WhatsApp, etc.)',
      shareEventOption: 'Share event link',
      exportPDFOption: 'Export to PDF',
      deadlineExpired:
        'The registration deadline of this event seems expired, or the event is already passed. ',
      totalInvitees: 'Total invitees',
      responsesReceived: 'Responses received',
      additionalGuests: 'Additional guests',
      noPermissionToGiveReply: "You don't have permission to change this",
      responsesPercentage: 'Responses percentage',
      acceptedTasks: 'Accepted tasks',
      acceptedTasksByUser: 'Tasks by user',
      givenComments: 'Given comments',
      givenCommentsByUser: 'Given comments by user',
      optionalMessage: 'Click here to see the news.',
    },
    task: {
      createTask: 'Create task',
      taskName: 'Task name',
      noTasks: 'No tasks',
    },
    groupSettings: {
      title: 'Group settings',
      tabs: {
        general: 'General preferences',
        eventTypes: 'Event types',
        responses: 'Responses',
        membersManagement: 'Member administration',
        other: 'Other',
      },
      deleteGroupHeader: 'Are you sure you want to delete this?',
    },
    documentation: {
      title: 'Documentation',
      generalDocumentation: 'General documentation',
      uploadedFiles: 'Uploaded files',
      chooseFile: 'Choose file',
      tipMessage: 'Maximum upload file size: 50 MB',
      overview: '{{value}} GB of {{capacity}} GB used',
      uploadSuccess: 'Document was successfully uploaded',
      deleteSuccess: 'Document was successfully deleted',
      attachFiles: 'Attach files',
      limitExceededHeader:
        'You have reached the limit of available storage space for documentation.',
      sort: {
        label: 'Sort by',
        nameAsc: 'Name (A-Z)',
        nameDesc: 'Name (Z-A)',
        dateOldest: 'Date (Oldest first)',
        dateNewest: 'Date (Newest first)',
      },
    },
    subscriptions: {
      title: 'Subscriptions',
      subscription: 'Subscription',
      yearly: 'Yearly',
      monthly: 'Monthly',
      adBlocker: {
        header: 'Disable Ad-Blocker for Local Prices',
        body: "Disable your ad blocker to see correct prices. Steps vary by browser and blocker: Usually, click the ad blocker icon near the URL bar and select 'Disable'. Reload the page. For other steps, check your blocker’s or browser’s help. Thank you.",
      },
      freeTitle: '2 months free trial',
      freeDescription: 'Start your free 2 months trial now',
      freeText1: 'Open up to 2 communities',
      freeText2: 'Full access to all features',
      freeText3: 'No credit card required – sign up and start immediately',
      freeText4: 'No automatic renewal',
      freeText5: 'No ads',
      subscribe: 'SUBSCRIBE',
      seeDetails: 'SEE DETAILS',
      buildCustom: 'Custom',
      month: 'month',
      year: 'year',
      community: 'Community Hub',
      communities: 'Community Hubs',
      scheduler: '1 Central Event Scheduler',
      schedulers: 'Each with 1 Central Event Scheduler',
      group: 'Team/Department Group Planners',
      capacity: 'Member Capacity',
      chatEnabled: 'Chat included',
      chatExcluded: 'Chat Functionality Not Included',
      maxStorage: 'GB storage',
      classic: 'Classic',
      classicPlus: 'Classic+',
      popular: 'POPULAR',
      textInnerTop:
        'Choose the Gumb subscription that fits to your community. With this plan you can divide your team (or your community) into up to {{groups}} groups and manage up to {{members}} members. You may upgrade or cancel the subscription whenever you want. The free trial month is activated automatically upon your successful registration in Gumb application without having to provide your payment details. After the free trial ends, you can select one of the subscription plans to keep using Gumb, submitting your preferred payment method.',
      textInnerBottom:
        'When you subscribe, you sign a contract for an unlimited period of time. The benefit: Your subscription will be renewed automatically after the expiration of the validity period of one year/month (we will remind you by e-mail). You can pay the subscription by credit card (MasterCard and Visa) or PayPal.',
      chatsExcluded: 'Chats excluded',
      current: 'CURRENT',
      pay: 'PAY',
      usingBlocker: 'Please disable ad blocker and reload the page',
      failedToSubscribe: 'Failed to subscribe',
      usedGroups: 'Used groups',
      usedMembers: 'Used members',
      usedCommunity: 'Used communities',
      usedStorage: 'Used storage',
      orderSummary: 'Order summary',
      tax: 'TAX',
      total: 'Total',
      periodEnds: 'Paid period ends',
      cancelSubscription: 'CANCEL MY SUBSCRIPTION',
      paypalLink: 'BILLING HISTORY',
      paymentApproved: 'Payment approved',
      paymentError: 'Payment error',
      limitExceededHeader: 'You have exceeded the max number of members',
      limitExceededMessage:
        'Please contact the community owner. Your community owner(s) can check the subscription plan limits and upgrade your community subscription plan.',
      limitExceededMessageOwner:
        'Please visit subscriptions page to check your subscription plan limits or upgrade your subscription',
      inactiveSubscriptionHeader: 'Payment approval pending',
      inactiveSubscriptionMessage:
        "Sorry, you can't continue this action. You may have to first complete the payment process.",
      subscriptionNotPaid:
        'Your subscription has expired or the subscription is not paid.',
      contactAdmin: 'Please, contact the Admin.',
      free: 'Free account',
      requireSubscription:
        'Gumb is free for members of active communities. Create your own community and start your free trial now or change to a suitable plan under "Change Plan".',
      requireSubscriptionMember:
        'No valid subscription was detected. You don\'t have to do anything here yourself. The community owner on the other hand can check in his profile under "Subscription" if the license for this community is activated or extended.',
      cancelConfirmation: 'Are you sure you want to cancel your subscription?',
      unsubscribe: 'Cancel',
      cancelled:
        'Cancellation confirmed. A confirmation of cancellation has just been sent to your email. You will continue to have access to all features until the paid settlement date.',
      renewSubscriptionText:
        'Thank you!\n\nYour previous subscription will be renewed, if you activate it now',
      activate: 'Activate now',
      activated:
        'You have successfully activated your subscription. It is our pleasure that you are now organizing and planning with Gumb. An email has just been sent to confirm your membership. You can access all the features immediately.',
      approved:
        'The subscription is being prepared. One moment please ... This can take up to 45 seconds. Until then, do not reload the page and do not close the browser window. ',
      trial: 'Free trial',
      trialActivated:
        'Your trial period has started. You and your admins can now begin planning, inviting members, and managing settings.',
      cancelledTitle: 'Cancelled',
      startTrial: 'START YOUR FREE TRIAL',
      startTrialHeader: 'Looooong Testing!',
      startTrialText:
        'To allow your community to thoroughly test all features in real operation, we offer a full 2 months for testing! No payment information required and no hidden costs – simply test all features with all members worry-free.',
      subscriptionExpired:
        "This community currently doesn't have an active subscription.To add new users, the subscription needs to be renewed.For questions: support@gumb.app",
      startTrialPopupButton: 'Test for Free',
      paymentMethods: 'Credit card or Paypal',
      subscriptionsPopup: {
        title: 'Your subscription has expired',
        subtitle:
          'Your subscription has expired, but your community owner, <b>{{ownerName}}</b> can easily change that. <brPure />Every community has an owner who is responsible for the subscription. 👑',
        firstBox: {
          title:
            '<b>{{ownerName}}</b> <brPure />Would you like to renew your subscription and bring your team planning back to life?',
          btnText: 'Renew',
        },
        secondBox: {
          title:
            '<b>For members</b> <brPure />Would you like to take over the subscription and become the community owner yourself? (Start your own subscription, contact us afterwards, and we will transfer ownership to you.)',
          btnText: 'Become Owner',
        },
      },
    },
    terms: {
      title: 'Gumb Terms & Conditions',
    },
    errors: {
      cantSendMessageToYourself: 'You cannot send a message to yourself.',
      downloadFail: 'Download error, try again.',
      required: 'Field is required',
      email: 'Please enter a valid email address',
      invalidEmail:
        'Oops! Please enter a valid email. Check for typos and try again.',
      integer: 'Must be an integer',
      confirmPassword: "Passwords don't match. Please try again",
      NotAuthorizedException: 'Incorrect username or password.',
      invalidCode: 'That code was invalid. Please try again.',
      errorToast: 'Error: {{message}}',
      invalidRequest: 'Invalid request',
      passwordChanged: 'Password changed',
      password:
        'Password should contain at least 8 characters, letters\n in upper and lower cases and digits',
      termsError: 'Please accept terms and conditions',
      min: 'Minimum {{value}} characters',
      max: 'Maximum {{value}} characters',
      startDate: "Date can't be earlier than {{value}}",
      endDate: 'Date can not be earlier than start date',
      fileSize: 'File size is too large',
      fileSizeCustom: 'File too large: Maximum size is {{size}} MB.',
      customFileSize:
        'File too large: Max {{size}} GB. Remove it and try again.',
      unsupportedFormat: 'Unsupported file format',
      postalCode: 'Minimum 4 digits',
      sorry: 'Sorry',
      text404: 'Looks like the page you are looking for is not available :(',
      url: 'Must be valid url',
      text500: 'Something went wrong :(',
      onlyForGroupAndAdmin:
        'This feature is only available to admins and group leaders',
      onlyForAdmin: 'Only admins can use this feature',
    },
    eventTypes: {
      createSuccess: 'Event type was successfully created',
      editSuccess: 'Changes have been successfully updated',
      deleteSuccess: 'Event type was successfully deleted',
    },
    eventTasks: {
      createSuccess: 'Event task was successfully created',
      editSuccess: 'Changes have been successfully updated',
      deleteSuccess: 'Event task was successfully deleted',
    },
    eventResponses: {
      createSuccess: 'Event response was successfully created',
      editSuccess: 'Changes have been successfully updated',
      deleteSuccess: 'Event response was successfully deleted',
    },
    tips: {
      eventType:
        'The community admin can add new event types on the community settings page',
    },
    frequencies: {
      doNotRepeat: 'Does not repeat',
      daily: 'Daily',
      weekly: 'Weekly',
      monthly: 'Monthly',
      yearly: 'Annually',
      everyWorkingDay: 'Every weekday (Monday to Friday)',
      other: 'Custom…',
    },
  },
  de: {
    appWidget: 'Gumb App auf Ihrem Gerät installieren',
    featurePopups: {
      appointmentSchedule: {
        innerHtml:
          '<h3>Neue Funktion: Terminkalender als Excel- oder CSV-Datei herunterladen! </h3><p>Jetzt kannst du eure gesamte Terminliste exportieren und wir haben die Statistik-Seite komplett überarbeitet! Der neue Bericht namens ‚Terminkalender‘ gibt dir einen Überblick über Zahlen und Fakten eurer Veranstaltungen. </p><p>Wir arbeiten bereits an einem weiteren Bericht namens ‚Mitgliederzahlen‘, der sich auf die Nutzerperformanz beziehen wird. </p><p>Der Terminkalender-Bericht steht ab sofort in der Web-App für alle Nutzer zur Verfügung.</p>',
        buttonOk: 'SPÄTER',
        buttonRedirect: 'JETZT ANSEHEN',
      },
      appleCalendar: {
        title: 'VERBINDE DEINEN APPLE (iCal) KALENDER&nbsp;🍏',
        message:
          'Wir haben es geschafft! Nach harter Arbeit ermöglicht Gumb nun die direkte Kalenderverbindung in der WEB-Version für eine nahtlose Synchronisation.\nDas iOS/Smartphone-Release folgt&nbsp;auch&nbsp;bald.',
        extraMessage: 'die Schritt-für-Schritt-Anleitung öffnen.',
        redirectText: 'Hier',
        okButton: 'Jetzt verbinden',
        cancelButton: 'Jetzt nicht',
      },
      preferecePopup: {
        title: '2 neue Funktionen',
        first: {
          title: 'Alle gleichzeitig auf Grün:',
          subtitle:
            'Lege die Standardantwort für neue oder bestehende Termine fest – für alle Teilnehmer auf einmal!',
          link: '',
        },
        second: {
          title: 'Termin-Standardwerte:',
          subtitle:
            'Definiere bei den Ereignistypen, wie neue Termine standardmäßig aussehen sollen.',
          link: 'Anleitung',
        },
      },
      calendar: {
        innerhtml:
          "<h3>Wir haben es geschafft!</h3> <p> Die <b>GOOGLE KALENDER-Anbindung</b> ist da! </p> <br/> <p> Termine aus Gumb – Jede Gemeinschaft individuell wählbar, können nun automatisch in deinen Kalender übernommen werden. </p> <div class='images-container'> <img src='https://gumb-cdn-prod.s3.eu-central-1.amazonaws.com/featurePopups/calendar-1.png' alt='calendar-1' /> <img src='https://gumb-cdn-prod.s3.eu-central-1.amazonaws.com/featurePopups/calendar-2.png' alt='calendar-2' /></div> <p> Alles, was du tun musst, wird dir in dieser Anleitung erklärt: <br/><a target='_blank' rel='noopener noreferrer' href='https://gumb.app/de/support/gumb-mit-google-kalender-verbinden/'>→&nbsp; Vollständige Anleitung lesen </a> </p> <p>Kurzanleitung:</p> <ol> <li>Verbinden und wichtig: Häkchen setzen!</li> <li>Gemeinschaft auswählen</li> <li>Ein paar Minuten warten und Seite neu laden</li> </ol> <p> Verbinde deinen Google-Kalender, um Zeit zu sparen und das Beste aus jedem Tag zu machen. </p>",
        buttonOk: 'NICHTS FÜR MICH',
        buttonRedirect: 'JETZT VERBINDEN',
      },
      notifications: {
        title: 'Wie sollen die Mitglieder benachrichtigt werden?',
        firstRow:
          'Hinzugefügt haben wir: Mobile-Push-Nachrichten  & Benachrichtigungseinstellungen.',
        secondRow:
          'Die Benachrichtigungseinstellungen, die Admins verwalten, für die gesamte Community jetzt passgenau einstellen:',
        thirdRow: 'Alles, was du dafür tun musst, ist:',
        listFirstRow:
          'Gehe zu den Community-Einstellungen → Benachrichtigungen.',
        listSecondRow:
          'Aktiviere/Deaktiviere die Optionen in jeder Kategorie, → um alles für euch perfekt anzupassen. (→ Anleitung <0>hier</0> lesen)',
        lastRow:
          'Teile euren Mitgliedern mit, jetzt in ihrem eigenen Gumb-Profil die Einstellungen für Push oder E-Mail zu aktivieren.',
        buttonLater: 'Später',
        buttonSettings: 'Jetzt zu den Einstellungen gehen',
      },
      pushNotifications: {
        title: '📢 Next-Level-Kommunikation: Push-Nachrichten',
        message:
          'Admins und Gruppenleiter können jetzt Push-Nachrichten erstellen, um euch wichtige Infos direkt auf euer Smartphone zu senden.\n\nDas könnt ihr mit Push-Nachrichten:\n\n• <b>Wichtige Infos sofort teilen</b> (z.B. Terminänderungen, Notfälle)\n• <b>Einzelne Personen oder ganze Teams erreichen</b> (personalisierte Kommunikation)\n• <b>Nachrichten als Vorlagen speichern, um Zeit zu sparen</b> (effiziente Kommunikation)',
        extraMessage:
          'Bleibt immer auf dem Laufenden und verpasst keine Neuigkeiten mehr!',
        cancelButton: 'Später ansehen',
        okButton: 'Jetzt testen',
        newMessageOne:
          'Admins und Gruppenleiter können jetzt direkt mit einzelnen Personen oder ganzen Teams kommunizieren, um euch wichtige Infos direkt auf euer Smartphone zu&nbsp;senden.\n\n',
        redirectLink: 'Erstelle individuelle Push-Nachrichten',
        newMessageTwo:
          ', wähle die Empfänger aus und speichere deine Nachrichten sogar als Vorlagen für die spätere&nbsp;Verwendung.',
      },
      importEvent: {
        title: 'Termine hochladen/importieren ',
        subText:
          'Vorbei sind die Zeiten des manuellen Eintippens! Importiert eure Termine jetzt ganz einfach per CSV- oder Excel-Datei. Egal ob 30 oder 300 Termine – es geht schnell und kann bei Fehlern sogar rückgängig gemacht werden.',
        cancelButton: 'Später ansehen',
        okButton: 'Jetzt testen',
        secondText: ' findest du eine Schritt-für-Schritt-Anleitung.',
      },
      mail: {
        title: 'Neue Funktion: MAILMODUS',
        firstRow:
          'Die integrierte E-Mail Funktion vereinfacht und beschleunigt die Kommunikation im Team.  Dringend ein E-Mail an alle versenden? Aber nur an Mitglieder, die beim Termin mit "Ja" geantwortet haben? → Jetzt mit wenigen Klicks möglich!',
        secondRow:
          'Der Mailmodus steht ab sofort in der Web-App für Admins und Gruppenleiter:innen zur Verfügung. Er wird in der kommenden Zeit weiter ausgebaut.',
        thirdRow: 'Mehr dazu: <0>https://gumb.app/support/mailmodus/</0>',
        buttonLater: 'Später',
        buttonMail: 'Jetzt ansehen',
      },
      eventPdf: {
        title: 'Neue Funktion: Termin als PDF herunterladen',
        firstRow:
          'Jetzt Zeit sparen und die Termindetails per Klick ausdrucken!',
        secondRow:
          'Kurz vor anstehenden Veranstaltungen, oder auch für vergangene, können alle Termindetails inklusive aller Antwortstatistiken auf PDF exportiert werden.',
        thirdRow: 'Voraussetzung Terminplaner: Du hast Adminrechte',
        fourthRow: 'Voraussetzung Gruppenplaner: Du bist Gruppenleiter',
        fifthRow: 'Die Funktion steht ab sofort in der Web-App zur Verfügung.',
      },
    },
    menu: {
      homePage: 'STARTSEITE',
      subscriptions: 'Abos',
      groupScheduler: 'TERMINPLANER',
      documentation: 'DATEIEN',
      chats: 'Chat',
    },
    dayOfWeek: {
      day1: 'MO',
      day2: 'DI',
      day3: 'MI',
      day4: 'DO',
      day5: 'FR',
      day6: 'SA',
      day0: 'SO',
    },
    email: {
      sender: 'Absender',
      recipients: 'Empfänger',
      otherRecipients: 'Weitere Empfänger (mit Komma trennen)',
      subject: 'Betreff',
      message: 'Nachricht',
      attachCommunityDocs: 'Gemeinschaftsdokumente anhängen',
      plusTooltip: 'Neue E-Mail schreiben',
      emailSentSuccessfuly:
        'E-Mail wurde erfolgreich gesendet. Bitte beachten: Dies war ein "No Reply E-Mail" – Eine E-Mail, auf die der Empfänger nicht antworten kann.',
      sendCopy: 'Sende eine Kopie an mich',
      maxAttachmentsSize:
        'Wenn Du mehrere Anhänge hinzufügst, dürfen diese zusammengenommen maximal 15 MB groß sein.',
      changeEmail: {
        title: 'E-Mail mit Link versendet',
        message:
          'Wir haben dir eine E-Mail mit einem Bestätigungslink für deine neue E-Mail-Adresse gesendet. Bitte überprüfe dein Postfach und klicke auf den Link, um deine Änderungen zu bestätigen. Wenn du die E-Mail nicht innerhalb weniger Minuten erhältst, überprüfe bitte deinen Spam-Ordner. Bei weiteren Fragen oder Problemen stehen wir dir gerne zur Verfügung.',
      },
    },
    pushNotification: {
      header: 'Push-Nachricht',
      filterByRecipient: 'Empfänger auswählen',
      filterByResponse: 'Empfänger nach Antwort auswählen',
      pushMessage: 'Push erstellen',
      saveAsTemplate: 'Als Vorlage speichern',
      sendCopy: 'Mir auch senden',
      pasteUrl: '🌐 Ereignis- oder externer Link?',
      plusTooltip: 'Neue Push-Nachricht erstellen',
      toasterText:
        '🆕 Neue Funktion: Jetzt kannst du einen externen Link, also eine beliebige Webseite 🌐 (URL), in Push-Nachrichten einfügen!\n Leite Empfänger direkt zur Webseite weiter.',
      templates: 'Vorlagen',
      title: 'Titel',
      lastUsed: 'Zuletzt verwendet',
      noTemplates: 'Gespeicherte Vorlagen werden hier angezeigt',
      template: 'Vorlage',
      sendConfirmHeader: 'Vor dem Senden überprüfen',
      totalRecipients: 'EMPFÄNGER',
      redirectLink: 'ZUM LINK',
      successTitle: 'Push versendet!',
      successMessage:
        'Deine Nachricht wurde an <b>{{totalRecipients}}</b> Empfänger gesendet.',
      selectEventFirst: 'Wähle zuerst ein Ereignis aus',
      templateLimitTitle: 'Vorlagen-Limit erreicht',
      templateLimitInfo:
        'Speichere bis zu 8 deiner Nachrichten als Vorlage, um sie später wiederzuverwenden. 3 Ideenvorlagen haben wir dir schon hinzugefügt – bearbeite oder lösche sie nach Belieben.',
      templateLimitMessage:
        'Du hast die maximale Anzahl an Vorlagen erreicht. Bitte lösche eine Vorlage, um eine neue zu speichern.',
      urlInfo:
        'Du kannst hier Links zu jeder Website hinzufügen (außer Gumb). Dies kann ein Link zu einer Produktseite, einem Nachrichtenartikel oder etwas anderem für dich Relevantes sein. Füge einfach die vollständige URL (inkl. https://) ein.',
      urlInfoOne:
        'Du kannst hier jede beliebige externe Webseite (URL) einfügen, damit deine Empfänger direkt dorthin geleitet werden. Wählst du oben ein Ereignis, fügt die App den Termin-Link ein, und bei Klick auf die Push-Nachricht öffnet sich das Ereignis direkt in Gumb.',
      urlInfoTwo:
        'Funktion noch in Entwicklung: Füge beliebige externe Links (außer Gumb) zu deinen Push-Nachrichten hinzu! Verlinke auf Websites, Nachrichten&nbsp;und&nbsp;mehr.',
      urlTooltip:
        'Du kannst eine Push-Nachricht mit oder ohne Verlinkung zu einem Event erstellen. Möchtest du deinen Event-Link teilen? Wähle einfach ein Event oben aus, um ihn automatisch hier zu kopieren.',
    },
    common: {
      onlyAdmin: 'Nur Admins können diese Funktion nutzen.',
      download: 'Herunterladen',
      attachFile: 'Datei anhängen',
      new: 'Neu',
      unseen: 'Ungesehen',
      more: 'Mehr',
      less: 'Weniger',
      sunday: 'Sonntag',
      monday: 'Montag',
      tuesday: 'Dienstag',
      wednesday: 'Mittwoch',
      thursday: 'Donnerstag',
      friday: 'Freitag',
      saturday: 'Samstag',
      january: 'Januar',
      february: 'Februar',
      march: 'März',
      april: 'April',
      may: 'Mai',
      june: 'Juni',
      july: 'Juli',
      august: 'August',
      september: 'September',
      october: 'Oktober',
      november: 'November',
      december: 'Dezember',
      overallScheduler: 'Gesamtplaner',
      selectEvent: 'Ereignis auswählen (optional)',
      displayEventsFrom: 'Ereignisse anzeigen aus',
      modalExit:
        'Möchtest du den Vorgang wirklich beenden, ohne deine Daten zu speichern?',
      all: 'Alle',
      tooltipEdit: 'Bearbeiten',
      tooltipDelete: 'Entfernen',
      overview: 'Übersicht',
      cancel: 'Abbrechen',
      removeLogo: 'Bist du sicher, dass du das löschen willst?',
      noTasks: 'Keine Aufgaben gefunden',
      noUsers: 'Keine Nutzer gefunden',
      role: 'Rollen',
      admin: 'Admins',
      member: 'Mitglieder',
      group_lead: 'Gruppenleiter',
      email: 'E-Mail',
      groups: 'Gruppen',
      logoutHeader: 'Ausloggen?',
      logoutMessage: 'Du wirst zum Anmeldebildschirm zurückgeleitet.',
      deleteHeader: 'Sicher, Mitglied soll gelöscht werden?',
      logout: 'Ausloggen',
      delete: 'Löschen',
      tablePlaceholder: 'Keine Mitglieder zum Anzeigen',
      contentPlaceholder: 'Kein Inhalt zum Anzeigen',
      create: 'Erstellen',
      copy: 'Kopieren',
      visible: 'Sichtbar',
      saveChanges: 'Änderungen speichern',
      newField: 'Neues Feld',
      fieldName: 'Feld-Name',
      addField: 'Feld hinzufügen',
      notifications: 'Benachrichtigungen',
      participants: 'Teilnehmer',
      tasks: 'Aufgaben',
      documentation: 'Dokumentation',
      description: 'Beschreibung',
      facebook: 'Facebook',
      twitter: 'Twitter',
      linkedin: 'LinkedIn',
      instagram: 'Instagram',
      youtube: 'Youtube',
      startDate: 'Startdatum',
      startTime: 'Startzeit',
      startDateTime: 'Startdatum/Startzeit',
      endDateTime: 'Enddatum/Endzeit',
      endDate: 'Enddatum',
      endTime: 'Endzeit',
      icon: 'Symbol',
      sendMessage: 'Nachricht senden',
      aboutMe: 'Über mich',
      selectAll: 'Alle auswählen',
      approve: 'Genehmigen',
      reject: 'Ablehnen',
      backToGumb: 'Zurück zu Gumb',
      edit: 'Bearbeiten',
      addComment: 'Kommentar hinzufügen',
      answer: 'Speichern',
      ok: 'Ok',
      withoutGroup: 'Ohne Gruppe',
      copyLink: 'Link kopieren',
      copied: 'Kopiert',
      sendInviteLink: 'Einladungslink senden',
      apply: 'Anwenden',
      from: 'Von',
      to: 'Bis',
      enable: 'Aktiviert',
      search: 'Suchen',
      color: 'Farbe',
      columns: 'Eingeblendete Spalten',
      hiddenColumns: 'Ausgeblendete Spalten',
      noResultsFound: 'Keine Ergebnisse gefunden',
      sorryNoResults:
        'Es tut uns leid, dass wir nicht finden konnten, was du suchst. Bitte probiere eine andere Methode.',
      tutorial: 'Anleitung',
      next: 'Weiter',
      back: 'Zurück',
      step: 'Schritt',
      createSuccess: {
        title: 'Erstellt',
        message: 'Deine Option wurde erfolgreich erstellt.',
      },
      editSuccess: {
        title: 'Aktualisiert',
        message: 'Deine Änderungen wurden gespeichert.',
      },
      deleteSuccess: {
        title: 'Gelöscht',
        message: 'Die Option wurde erfolgreich entfernt.',
      },
      toolTipText: {
        faq: 'Hilfe: Anleitungen, Kontakt, mehr.',
        communityPlusIcon: 'Neue Gemeinschaft erstellen',
        logoGumb:
          'Geheimnis in Planung: Entdeckt später, was dieser Button verbirgt!',
        memberPlusIcon: 'Neuen Benutzer hinzufügen',
        memberArrowIcon: 'Zur Mitgliederübersicht',
        eventType: 'Erstelle Eventtyp',
        responses: 'Erstelle Antwortmöglichkeit',
        task: 'Erstelle Aufgabe',
        schedulerPlusIcon: 'Ereignis oder Gruppe erstellen',
      },
      shareEventTitle: 'Teile den Eventlink über:',
      copyEventLink: 'Oder kopiere den Link:',
    },
    auth: {
      signInWith: 'Anmelden mit',
      continueAs: 'Weiter als',
      create: 'Kostenloses Konto erstellen',
      signUp: 'Registrieren',
      signIn: 'Einloggen',
      welcome: 'Herzlich Willkommen!',
      createAccount: 'Kostenloses Konto erstellen',
      forgotPassword: 'Passwort vergessen?',
      resetPassword: 'Passwort zurücksetzen',
      resetPasswordTooltip:
        'Wenn du das Passwort zurücksetzt, bekommt das Mitglied eine E-Mail mit Anweisungen, wie ein neues Passwort festgelegt wird. Für Konten, die über Facebook/Google/Apple erstellt wurden, kann Gumb das Passwort nicht zurücksetzen. Das Mitglied kann sich jedoch weiterhin über das jeweilige Social-Media-Icon anmelden.',
      resetPasswordModalTitle: 'Passwort-Rücksetzung eingeleitet',
      resetPasswordModalText:
        'Das Mitglied erhält in Kürze einen Link zur Passwort-Neueinrichtung an die registrierte E-Mail-Adresse. Für Facebook/Google/Apple geht das nicht, aber das Mitglied kann stattdessen weiterhin das entsprechende Icon zum Anmelden nutzen.',
      resetPasswordErrorModalTitle: 'Passwort-Rücksetzung nicht möglich',
      resetPasswordErrorModalText:
        'Hinweis! Dieses Mitglied hat sich offenbar über Social Media (z.B. Facebook, Google, Apple) registriert. Daher kann Gumb das Passwort für dieses Konto nicht zurücksetzen. Der Nutzer kann sich jedoch weiterhin über das jeweilige Social-Media-Icon anmelden. Bitte informiere das Mitglied entsprechend.',
      terms: {
        '0': 'Ich bin einverstanden mit: ',
        '1': 'Bedingungen',
        '2': ' und  ',
        '3': 'Datenschutzerklärung',
      },
      home: 'Startseite',
      confirmed: 'E-Mail erfolgreich bestätigt',
      verifying: 'E-Mail bestätigen...',
      recovery: 'Passwort-Wiederherstellung',
      send: 'Senden',
      codeSended:
        'Verifizierungslink wurde an deine E-Mail gesendet. Bitte überprüfe und bestätige ihn. Achte auch auf deinen Spam-Ordner.',
      signUpSuccess:
        'Registrierung erfolgreich. Bitte prüfe jetzt dein E-Mail-Postfach, um die Registrierung zu bestätigen.',
      inviteSentTitle: 'Einladung erfolgreich versandt',
      inviteSentText:
        'Top! Du hast das Mitglied erfolgreich erfasst und die Einladung mit temporärem wurde versendet (Spamordner ggf. prüfen). Das Mitglied kann sich jetzt einloggen und das Passwort anpassen. Lade diese Seite neu, um die Mitgliederliste zu aktualisieren. Du und deine Admins können jetzt die Gruppe und Rolle zuweisen. Mit dem Stift-Symbol rechts ergänzt du die Personalien. Weiter so.',
      reset: 'Zurücksetzen',
      yourEmail: '{{value}} ist bereits deine E-Mail-Adresse',
      existEmail: '{{value}} ist bereits vorhanden',
      labels: {
        email: 'E-Mail',
        currentEmail: 'Aktuelle E-Mail',
        newEmail: 'Neue E-mail',
        password: 'Passwort',
        oldPassword: 'Altes Passwort',
        newPassword: 'Neues Passwort',
        confirmPassword: 'Passwort bestätigen',
      },
      accountExists:
        'Diese E-Mail-Adresse wird bereits für ein Gumb-Konto verwendet. Wenn diese E-Mail dir gehört, klicke auf Passwort vergessen und prüfe deinen Posteingang und Spamordner.',
      codeExpired:
        'Dieser Link ist abgelaufen. Gib hier deine E-Mail ein, dann senden wir dir einen neuen Link zur Kontoverifizierung.',
      userNotConfirmed:
        'Du musst dein Konto noch verifizieren. Dazu haben wir dir eine E-Mail mit einem Bestätigungslink geschickt. Wenn du keine E-Mail bekommen hast, schaue bitte auch in deinem Spam-Ordner nach.',
      invalidCredentials: 'Falscher Benutzername oder falsches Passwort',
      userNotFound:
        'Diese E-Mail ist anscheinend mit Google/Apple/Facebook verknüpft oder nicht registriert. Ändere das Passwort nicht via Gumb, sondern logge dich über das passende Social-Media-Symbol ein.',
    },
    profile: {
      editSuccess: 'Änderungen wurden erfolgreich aktualisiert',
      settings: 'Einstellungen',
      editProfile: 'Profil bearbeiten',
      selectImage: 'Bild auswählen',
      myProfile: 'Mein Profil',
      profileEditBtn:
        'Füge deine Daten hinzu: Adresse, Soziale Netzwerke & mehr',
      basicInformation: 'Grundinformationen',
      contactInformation: 'Kontaktdaten',
      addressInformation: 'Adressinformationen',
      myCommunities: 'Meine Gemeinschaften',
      myChat: 'MEINE CHATS',
      myAppointments: 'Meine Termine',
      subscription: 'Abo',
      userProfile: 'Benutzerprofil',
      tellUsAboutYourself: 'Ehrzähl etwas von dir',
      firstName: 'Vorname',
      lastName: 'Nachname',
      profileName: 'Benutzername',
      person: 'Person',
      role: 'Rolle',
      email: 'E-Mail',
      dateOfBirth: 'Geburtsdatum',
      privatePhoneNumber: 'Telefonnummer (Privat)',
      officePhoneNumber: 'Telefonnummer (Arbeit)',
      mobilePhoneNumber: 'Telefonnummer (Mobil)',
      street: 'Straße',
      region: 'Region',
      postalCode: 'Postleitzahl',
      city: 'Stadt',
      country: 'Land',
      about: 'Über mich',
      linkChangeEmail: '(E-mail ändern)',
    },
    profileCommunities: {
      popupLeave: {
        title: 'Bist du sicher, dass du diese Gemeinschaft verlassen möchtest?',
        button: 'Verlassen',
      },
      leaveCommunity: {
        title: 'Bist du sicher, diese Community zu verlassen?',
        message:
          'Wenn du diese Community verlässt, bleibt dein Benutzerkonto erhalten, und du kannst anderen Communities beitreten.',
        okButton: 'Ja, Verlassen',
        cancelButton: 'Abbrechen',
      },
    },
    profileSubscription: {
      heading: 'Abodetails',
      titleMembership: 'Mitgliedschaft',
      titlePayment: 'Zahlung',
      buttonCancel: 'Mitgliedschaft kündigen',
      buttonRestart: 'Mitgliedschaft neu starten',
      details: {
        linkChangePlan: 'Abo ändern',
        infoCancelled:
          'Dein Abo wurde gekündigt und wird nicht mehr verlängert. Du kannst die App bis zum nächsten Rechnungsdatum weiter verwenden. Du kannst jederzeit das Abo wieder erneuern.',
        infoTrial:
          'Dein Testmonat läuft gerade. Nach Ablauf wird dir selbstverständlich nichts berechnet. Wenn du Gumb weiterhin nutzen möchtest, wähle unter „Abo ändern“ ein passendes Abo.',
        infoTrialNew:
          'Dein Testzeitraum läuft gerade. Nach Ablauf am {{expireDate}} wird dir selbstverständlich nichts berechnet. Wenn du Gumb weiterhin nutzen möchtest, wähle unter „Abo ändern“ ein passendes Abo.',
        linkShowCommunities: 'Meine Gemeinschaften anzeigen',
        linkHistory: 'Abrechnungsverlauf',
        linkChangePayment: 'Ändern der Zahlungsmethode',
        currentPlan: 'Aktueller Plan:',
        communitiesInUse: 'Genutzte Gemeinschaften:',
        groupsInUse: 'Genutzte Gruppen:',
        membersInUse: 'Genutzte Mitglieder:',
        storageInUse: 'Genutzter Speicherplatz:',
        paymentCycle: 'Zahlungszyklus:',
        paymentCycleMonth: 'Monatlich',
        paymentCycleYear: 'Jährlich',
        nextPayment: 'Nächste Zahlung:',
        validUntil: 'Noch gültig bis:',
        paymentMethod: 'Zahlungsmethode:',
      },
    },
    profileSettings: {
      title: 'Profil Einstellungen',
      notification: 'Benachrichtigungen',
      notificationsPlaceholder: 'Du hast keine Benachrichtigungen',
      changePassword: 'Passwort ändern',
      changeEmail: 'E-Mail',
      saveNewEmail: 'Neue E-Mail speichern',
      language: 'Sprache',
      timeFormat: 'Zeitformat',
      permissions: 'Berechtigungen',
      allowedToEdit: 'Admins dürfen mein Profil bearbeiten',
      other: 'Andere',
      pushNotification: 'Push-Benachrichtigungen',
      receiveByEmail: 'Benachrichtigungen per E-Mail erhalten',
      receivePush: 'Mobile Push-Benachrichtigungen empfangen',
      receiveInApp: 'In-App-Benachrichtigungen empfangen',
      terms: 'Allgemeine Geschäftsbedingungen (AGB)',
      protectionRegulation: 'Datenschutzerklärung',
      deleteAccount: 'Konto löschen',
      logout: 'Ausloggen',
      deleteProfileHeader: 'Bist du sicher, dass du das Konto löschen willst?',
      notifications: {
        now: 'Jetzt',
        hours: 'Vor {{value}} std',
        minutes: 'Vor {{value}} min',
        minute: 'Vor 1 Minute',
      },
    },
    calendarConnection: {
      googleCalendar: 'Google Kalender',
      pageTitle: 'Kalenderverbindung',
      connectTitle:
        'Verbinde deinen Kalender\num deine Termine automatisch zu synchronisieren',
      connectDescription:
        'Gumb kann automatisch Termine erstellen, die in deinem Kalender erscheinen. Mit dieser Synchronisierung behältst du den Überblick.',
      appleCalendar: 'Apple Kalender',
      appleConnectionSteps: {
        headerOne:
          'Integriere Gumb-Ereignisse in deinen Apple Kalender mit einer URL',
        headerOneSplit: 'in 4 Schritten; schnelle Demo',
        here: 'hier',
        headerTwo: 'Oder detaillierte Anleitung',
        stepOne:
          '1. Wähle in deinem Apple-Kalender unter „Kalender“ die Option „Ablage“ > „Neues Kalenderabonnement“.',
        stepTwo:
          '2. Gib den iCal-Link der Community ein, den du unten kopiert hast, und klicke dann auf „Abonnieren“.',
        stepThree:
          '3. Gib einen Namen für den Kalender ein und wähle eine Farbe, um ihn in deinem Kalender leicht identifizieren zu können.',
        stepFour:
          '4. Passe Einstellungen wie den Speicherort (iCloud oder Mac) oder das automatische Aktualisieren an, dann klicke auf „OK“.',
      },
      appleConnectionPopup: {
        title: 'Wie verbinden?',
        stepOne: 'Den Community-Link kopieren',
        stepTwo:
          'Im Apple-Kalender unter „Kalender“ auf „Ablage“ > „Neues Kalenderabonnement“ gehen.',
        stepThree:
          'Den kopierten iCal-Link der Community eingeben und auf „Abonnieren“ klicken.',
        stepFour:
          'Kalender benennen, Farbe wählen, Einstellungen wie Speicherort (iCloud oder Mac) oder Automatik-Aktualisierung festlegen und „OK“ drücken.',
      },
      appleDisconnectPopup: {
        title: 'Trennung Bestätigen',
        message:
          'Wenn du auf „Ja, jetzt trennen“ klickst, werden alle Gumb-Events aus deinem/n Kalender/n entfernt. Bitte lösche den/die Kalender anschließend manuell, da er/sie sonst sichtbar bleibt/bleiben.\n\n<b>Um dich vollständig abzumelden:</b>\n\n• <b>Auf macOS</b>: Kalender öffnen, mit Rechtsklick auf den Kalender > „Abo kündigen“.\n• <b>Auf iOS</b>: Gehe zu „Kalender“, tippe auf das „Info“-Symbol neben dem Kalender > „Kalender löschen“.',
        okButton: 'Ja, jetzt trennen',
      },
      appleUncheckBoxPopup: {
        title: 'Trennung Bestätigen',
        message:
          'Wenn du auf „Ja, jetzt trennen“ klickst, werden alle Gumb-Events aus deinem Kalender entfernt. Bitte lösche den Kalender anschließend manuell, da er sonst sichtbar bleibt.\n\n<b>Um dich vollständig abzumelden:</b>\n\n• <b>Auf macOS</b>: Kalender öffnen, mit Rechtsklick auf den Kalender > „Abo kündigen“.\n• <b>Auf iOS</b>: Gehe zu „Kalender“, tippe auf das „Info“-Symbol neben dem Kalender > „Kalender löschen“.',
        okButton: 'Ja, jetzt trennen',
      },
      connectWithApple: 'Apple (iCal) verbinden',
      cantSeeICal:
        'Siehst du den iCal-Link nicht? Markiere einfach das Kästchen.',
      syncTip:
        'Nur die Termine der ausgewählten Gemeinschaft werden mit deinem Kalender synchronisiert.',
      connect: 'Verbinden',
      connected: 'Verbunden',
      disconnect: 'Trennen',
      disconnectSuccess: 'Verbindung erfolgreich getrennt',
      selectCommunities: 'Auswahl der Gemeinschaften',
      selectCommunitiesDescription:
        'Nur die Termine der ausgewählten Gemeinschaft werden mit deinem Kalender synchronisiert.',
      authSuccessTitle: 'Erfolgreich autorisiert',
      authSuccessMessage:
        'Deine Gemeinschaften werden jetzt automatisch synchronisiert. Warte einen kurzen Moment und aktualisiere danach die Google Kalender-Seite neu, um die Erstsynchronisation abzuschließen.',
      authSuccessTitleTwo: 'Wähle jetzt deine Community',
      authSuccessMessageTwo:
        'Tippe auf das Häkchen und dann auf "Änderungen speichern" zum Bestätigen.',
      syncSuccess:
        'Erfolgreich aktualisiert!\nVon nun an wird alles automatisch synchronisiert. Du kannst die Auswahl der Gemeinschaften jederzeit ändern.',
      syncSuccessTitle: 'Gleich fertig!',
      syncSuccessMessage:
        'Wir verbinden deine Community mit deinem Google Kalender. Das dauert nur 2-3 Minuten. Aktualisiere deine Google Kalender-Seite, um die Updates zu sehen.',
      disconnectConfirmTitle: 'Bist du sicher?',
      disconnectConfirmDescription:
        'Alle Termine werden aus deinem Kalender entfernt, wenn du die Verbindung trennst.',
      disconnectConfirm: {
        title: 'Jetzt trennen?',
        message:
          'Durch die Trennung werden alle Termine aus deinem Google Kalender gelöscht. Keine Sorge, du kannst jedoch Gumb für eine neue Synchronisierung später jederzeit erneut verbinden.',
        cancelButton: 'Abbrechen',
        okButton: 'Ja, Trennen',
      },
      noCommunities:
        'Es gibt keine Gemeinschaften zum Auswählen.\nWarte, bis ein Administrator dich hinzufügt.',
      checkboxWarning: 'ACHTUNG: Das Häkchen muss gesetzt sein!',
    },
    community: {
      plusIconSubText: 'Plane ein Ereignis oder erstelle Terminserien.',
      selectAllCommunityAdmins: 'Alle Admins',
      selectAllGroupAdmins: 'Alle Gruppenleiter:innen',
      createSuccess: 'Gemeinschaft wurde erfolgreich erstellt',
      createFailure: 'Fehler beim Erstellen der Gescheinschaft',
      deleteSuccess: 'Gemeinschaft wurde erfolgreich gelöscht',
      leaveSuccess: 'Du hast die Gemeinschaft erfolgreich verlassen',
      descriptionTitle: 'Über die Gemeinschaft',
      descriptionPlaceholder: 'Hier kannst du deine Gemeinschaft beschreiben',
      online: 'Online',
      updateCommunity: 'Communityaktualisieren',
      members: 'Mitglieder',
      createCommunity: 'Gemeinschaft erstellen',
      communityName: 'Name der Gemeinschaft',
      category: 'Kategorie',
      aboutCommunity: 'Über die Gemeinschaft',
      selectLogo: 'Logo auswählen',
      selectLogoTip: 'jpg, jpeg, png (5MB)',
      chooseCover: 'Cover auswählen',
      chooseCoverTip: 'jpg, jpeg, png (20MB)',
      website: 'Webseite',
      partnership: 'Partnerschaft',
      deleteCommunity: 'Gemeinschaft löschen',
      deleteHeader:
        'Sicher, dass du diesen Benutzer aus der Gruppe entfernen möchtest?',
      addMembers: 'Mitglieder hinzufügen',
      invitationLink: 'Einladungslink',
      communityRole: 'Rolle',
      joinCommunity: 'Gemeinschaft beitreten',
      namePlaceholder: "{{value}}'s team",
      requestSent: 'Beitrittsanfrage erfolgreich gesendet',
      requestSentButton: 'Beitrittsanfrage gesendet',
      limitExceededHeader:
        'Du hast das Limit für Gemeinschaften erreicht. Wechsle deinen Abo-Plan unter Abo in deinem Profil, um mehr zu erstellen.',
      limitExceededMessage:
        'Bitte wende dich an den Gemeinschaftsbesitzer. Diese Person kann die Grenzen eures Abos überprüfen und es bei Bedarf aktualisieren.',
      limitExceededMessageOwner:
        'Bitte gehe zu den Abo-Details in deinem Profil, um die Limits zu prüfen oder dein/euer Abo zu aktualisieren.',
      updateFailure: 'Fehler beim Aktualisieren',
      buttonAddEvent: 'Ereignis hinzufügen',
      userBlockedTitle: 'Blockiert',
      userBlockedText:
        'Es scheint, als hätte dich jemand in dieser Community blockiert. Admins können Mitglieder sperren oder entsperren.',
      createOverLimitTitle: 'Maximale Anzahl an Gemeinschaften erreicht',
      createOverLimitText:
        'Starte jetzt deinen Gratistest oder wähle unter "Abo ändern" ein Abo, um deine eigene Gemeinschaft zu organisieren.',
      notInvitedTitle: 'Keine Berechtigung',
      notInvitedText:
        'Es scheint, dass du keinen Zugriff auf diesen Termin hast. Admins oder Gruppenleiter können dich hinzufügen.',
      noRequestsTitle: 'Es gibt keine Anfragen zur Überprüfung.',
      noRequestsText:
        'Wenn Personen Anfragen für eure Gemeinschaft einreichen, werden sie dir hier zur Überprüfung angezeigt.',
      invitationCodeExpired:
        'Das ist ein abgelaufener Einladungslink. Du kannst aber jetzt eine Beitrittsanfrage senden, die Admins annehmen oder ablehnen können.',
      communityNotFound: 'Gemeinschaft nicht gefunden',
      communityPopup: {
        title: 'Ereignis oder Gruppe erstellen',
        subTitleOne:
          'Plane Ereignisse im zentralen TERMINPLANER. Nutze Gruppen, um Mitglieder in Teams aufzuteilen.',
        chatTitle: 'Chat-Optionen',
        chatSubTitle:
          'Starte einen 1:1-Chat oder erstelle einen Chatraum für Gruppen.',
        oneToOne: {
          infoText:
            'Klicke auf das Chat-Symbol neben dem Namen eines Mitglieds oder auf sein Profilbild, um eine Nachricht zu senden.',
          btntitle: 'Zur Mitgliederübersicht',
          linkText: 'Tutorial',
        },
        createChatGroup: {
          infoText:
            'Erstelle einen privaten Chatraum für ausgewählte Mitglieder, Teams oder Gruppen. Nur Admins können diese Räume einrichten.',
          btntitle: 'Chatraum erstellen',
          inkText: 'hier',
        },
        createEvent: {
          infoText:
            "Plane Ereignisse für alle oder spezifische Gruppen/Mitglieder im TERMINPLANER. Sichtbar im Terminplaner, unter 'Meine Termine' und in Gruppen.",
          btntitle: 'Ereignis erstellen',
          linkText: 'Tutorial',
        },
        createGroup: {
          infoText:
            'Gruppen haben ihre eigenen Planer, mit Ereignissen, die nur für Gruppenmitglieder sichtbar sind. Verwalte Mitgliederrollen effektiv',
          btntitle: 'Gruppe erstellen',
          linkText: 'hier',
        },
      },
    },
    communitySettings: {
      admin: 'Admin',
      member: 'Mitglied',
      blocked: 'Geblockter Nutzer',
      owner: 'Besitzer:in',
      group_lead: 'Gruppenleiter:in',
      group_admin: 'Gruppenadministrator:in',
      chat_admin: 'Chat-Admin',
      community_admin: 'Community-Administrator:in',
      editRoleSuccess: 'Rolle wurde erfolgreich geändert',
      deleteUserSuccess: 'Benutzer:in wurde erfolgreich aus Community gelöscht',
      removeUser: {
        title: 'Bist du sicher, diesen Benutzer zu entfernen?',
        message:
          'Wenn du diesen Benutzer entfernst, wird er aus deiner Community entfernt, behält jedoch sein Benutzerkonto und kann weiterhin in anderen Communities aktiv sein.',
        cancelButton: 'Abbrechen',
        okButton: 'Ja, entfernen',
      },
      removeUserSuccess: {
        title: 'Benutzer entfernt',
        message:
          'Der Benutzer wurde erfolgreich aus deiner Community entfernt.',
      },
      editSuccess: 'Änderungen wurden erfolgreich aktualisiert',
      title: 'Gemeinschafts-Einstellungen',
      backToSubscriptionsDetails: 'Zurück zu den Abonnementdetails',
      tabs: {
        general: 'Voreinstellungen',
        eventTypes: 'Ereignistypen',
        subscriptions: 'Abonnements',
        responses: 'Antwortmöglichkeiten',
        roles: 'Mitglieder',
        requests: 'Anfragen',
        other: 'Sonstige',
        tasks: 'Aufgaben',
      },
      addMember: 'Benutzer:in hinzufügen',
      exportMembers: 'Export',
      exportMembersTooltip: 'Mitgliederliste exportieren in eine CSV-Datei',
      deleteCommunityHeader: 'Bist du sicher, dass du das löschen willst?',
      defaultEventList: [
        'Treffen',
        'Training',
        'Spiel',
        'Probe',
        'Veranstaltung',
        'Vorstandssitzung',
      ],
      defaultResponsesTypes: [
        'Ja, ich komme',
        'Nein, ich komme nicht',
        'Vielleicht',
        'Ich weiß es nicht',
      ],
      defaultTasks: ['Wer fährt?', 'Wer bringt die Getränke?'],
      selectUsers: {
        title: 'Nutzer auswählen',
        message:
          'Bitte markiere die Nutzer mit einem Häkchen, um Beitrittsanfragen zu genehmigen oder abzulehnen.',
      },
      memberExists: 'Mitglied existiert bereits',
      mailSent:
        'Die Einladung wurde erfolgreich gesendet und ist 24 Stunden gültig. Admins können diese Einladung mehrmals versenden bzw. den Vorgang wiederholen. Der Nutzer verifiziert sein Konto, indem er auf "Jetzt beitreten" klickt und sobald er sich mit dem temporären Passwort einloggt, ist er/sie registriert und direkt in deiner Community als Mitglied angelegt.',
      userNotFound:
        'Kein Gumb-Profil gefunden. Gib nun Vorname und Nachname an, um ein neues Profil anzulegen und eine Einladung an diese E-Mail zu schicken.',
      disableAddMemberTooltip:
        'Die Anzahl der Mitglieder hat bereits ihr Limit erreicht. Um dies zu lösen, kann der Community-Besitzer ein Abo-Upgrade durchführen, um weitere Mitglieder hinzuzufügen.',
      notifyByFields:
        'Benachrichtigung versenden, wenn die folgenden Felder aktualisiert werden',
      notifyByReplies:
        'Benachrichtigung versenden, wenn Eingeladene geantwortet haben mit',
      remindBefore: 'Vorher erinnern',
      addReminder: '+ Erinnerung hinzufügen (max. 2)',
      notificationsTypes: [
        'Neuer Termin',
        'Termin wurde aktualisiert',
        'Termin wurde gelöscht',
        'Terminerinnerung',
      ],
      notificationsClasses: ['In-App', 'E-mail', 'Push'],
      notificationPopupHeader:
        'Möchtest du Benachrichtigungen an eingeladene Nutzer:innen senden?',
      notificationPopupMessageLabel:
        'Optionale Nachricht für eingeladene hinzufügen',
      notificationPopupNotify: 'Senden',
      notificationPopupDoNotNotify: 'Nicht senden',
      notificationPopupBack: 'Zurück zum Bearbeiten',
    },
    communityInvitation: {
      subjectFirst: 'Sie wurden in die Community',
      subjectSecond: 'eingeladen',
      body: 'Bitte folgen Sie dem Link unten, um der Community beizutreten',
      invitationSuccess:
        'Erfolgreich genehmigt. Benutzer:in wurde in die Mitgliederliste aufgenommen. Prüfe jetzt die Rolle und Gruppeneinteilung in der Gemeinschafts-Einstellungen-Mitgliederliste.',
      invitationFailure:
        'Es gab einen Fehler. Vermutlich ist dein/euer Aboplanlimit erreicht.',
      membersInvitation: {
        title: 'Mitglieder einladen',
        description:
          'Füge neue Nutzer zu deiner Gemeinschaft hinzu, damit sie Mitglieder werden und die Erlaubnis haben, auf eure Events zu reagieren. Siehe Anleitung <0>"Neuen Benutzer hinzufügen"</0>.',
        showMore: 'Mehr anzeigen',
        showLess: 'Weniger anzeigen',
        codeInvitation: {
          title: 'Der schnellste und einfachste Weg',
          button: 'Einladungslink kopieren',
          updateLink: 'Link erneuern',
          generateLink: 'Jetzt Link generieren',
          howItWorks: 'So funktioniert’s',
          goodToKnow: 'Gut zu wissen',
          howItWorksText:
            'Mitglieder klicken auf "Kostenloses Konto erstellen" mit dem oben ausgewählten Link. Wenn Gumb eine E-Mail sendet, müssen die Mitglieder nur ihre E-Mail-Adresse bestätigen, um direkten Zugang zu erhalten.',
          goodToKnowText:
            'Das geht superschnell, aber beachte bitte, dass Personen mit diesem Link sofort auf eure Termine zugreifen können, sobald die Kontoverifizierung abgeschlossen ist. Deshalb kannst du den oben genannten Link jederzeit erneuern.',
        },
        linkInvitation: {
          title:
            'Sicher ist sicher: Beitrittsanfrage senden mit Gemeinschafts-Link',
          button: 'Gemeinschafts-Link kopieren',
          howItWorks: 'So funktioniert’s',
          adminFlow: 'Admins akzeptieren oder lehnen ab',
          howItWorksText:
            '<0>Admins kopieren und teilen diesen Gemeinschafts-Link.</0><0>Mitglieder, mit einem Gumb-Konto, klicken "Gemeinschaft beitreten".</0><0>Mitglieder, ohne Konto, registrieren sich zuerst auf Gumb, und klicken anschließend den Link nochmals, um euch die Anfrage zu senden.</0>',
          adminFlowText:
            'Im Reiter "Anfragen" müssen Admins die Anfrage eines Mitglieds zum Beitritt zu eurer Gemeinschaft genehmigen oder ablehnen. Wenn es offene Anfragen gibt, sehen die Admins eine Benachrichtigung auf dem Zahnrad.',
        },
        addMember: {
          title: '2 weitere Optionen: Effizient oder Persönlich',
          button: 'BENUTZER:IN HINZUFÜGEN',
          selfSignUp: 'Mitglieder registrieren sich selbst',
          preRegister: 'Person vorerfassen',
          selfSignUpText:
            'Jeder soll sich ganz einfach selbst bei Gumb registrieren (kostenlos) und dir die registrierte E-Mail schicken. Wenn du sie hier eingibst, wird das Mitglied automatisch deiner Gemeinschaft beitreten und alle Termine sehen.',
          preRegisterText:
            'Erfasse neue Mitglieder direkt über ihre E-Mail-Adresse. Diese Aktion schickt ihnen automatisch eine "E-Mail-Einladung" mit einem temporären Passwort. Mit diesem können sie sich sofort einloggen und später das Passwort nach Belieben ändern.',
        },
      },
    },
    group: {
      deleteEventSuccess: 'Ereignis wurde erfolgreich gelöscht',
      createSuccess: 'Gruppe wurde erfolgreich erstellt',
      deleteSuccess: 'Gruppe wurde erfolgreich gelöscht',
      addMembersSuccess:
        'Mitglied erfolgreich hinzugefügt. Prüfe deine Mitgliederliste.',
      addMembersSuccessWithMessage: {
        title: 'Mitglied hinzugefügt!',
        message:
          'Eure Mitgliederliste wurde erfolgreich aktualisiert. Verwalte jetzt Rolle und Gruppenzuweisung. Du kannst auch die Personalien des Nutzers ergänzen oder ändern.',
      },
      deleteUserSuccess: 'Der Nutzer wurde erfolgreich aus Gruppe entfernt',
      addParticipants: 'Mitglieder hinzufügen',
      createGroup: 'Gruppe erstellen',
      deleteGroup: 'Gruppe löschen',
      groupName: 'Gruppename',
      changeGroupNameSuccess: 'Gruppenname erfolgreich geändert.',
      groups: 'Gruppen',
      inviteMembers: 'Mitglieder einladen',
      tabs: {
        schedule: 'Terminplan',
        members: 'Mitglieder',
        chat: 'Unterhaltung',
        documentation: 'Dokumentation',
        statistic: 'Export & Statistiken',
        import: 'Import',
      },
      limitExceededHeader:
        'Du hast das Limit für Gruppen erreicht. Du musst Deinen Abo-Plan upgraden, um weitere zu erstellen.',
      limitExceededMessage:
        'Bitte wende dich an den Gemeinschaftsbesitzer. Diese Person kann euren Aboplan überprüfen und aktualisieren.',
      limitExceededMessageOwner:
        'Überprüfe und aktualisiere dein Aboplanlimit in deinem Profil auf der Abo-Seite. Der Gemeinschaftsbesitzer muss dies prüfen.',
      groupRole: 'Gruppen Rolle',
      addGroup: 'Gruppe hinzufügen',
    },
    chat: {
      read: {
        now: '{{name}} gelesen gerade eben',
        hours: '{{name}} gelesen vor {{value}}h',
        minutes: '{{name}} gelesen vor {{value}} Min.',
        minute: '{{name}} gelesen vor 1 Min.',
        day: '{{name}} gelesen am {{value}}',
        dateTime: '{{name}} gelesen am {{value}} um {{time}}',
      },
      noRead: 'Noch nicht gelesen',
      lastAdmin:
        'Ein Chatraum braucht mindestens einen Admin oder Gruppenleiter. Stelle sicher, dass immer mindestens eine Person diese Rolle hat.',
      update: {
        successUpdate: 'Chatraum erfolgreich aktualisiert',
        success: 'Mitgliederliste wurde aktualisiert',
        btn: 'SPEICHERN',
      },
      modal: {
        btn: 'Verwalten',
        about: 'Info',
        editRoom: 'Chatraum bearbeiten',
        delete: 'Chatraum löschen',
        editMember: 'Mitglieder bearbeiten',
      },
      details: {
        title: 'Chatraum Details',
        secondTitle: 'INFORMATIONEN',
        name: 'Chatraumname',
      },
      delete: { success: 'Chatraum wurde gelöscht' },
      create: {
        title: 'Chatraum erstellen',
        name: 'Chatraumname',
        description: 'Gruppenbeschreibung (optional)',
        success: 'Chatraum wurde erstellt',
        btnNext: 'WEITER',
      },
      new: 'Neuer',
      chat: 'Chat',
      inputPlaceHolder: 'Nachricht eingeben',
      op1: 'Dateien & Videos',
      op2: 'Fotos',
      files: 'Dateien',
      searchHover: 'Suchen oder neuen Chat beginnen',
      plusTooltip: 'Neuen Chat oder Gruppenchat erstellen',
      emptyStatus: {
        noChatSelected: {
          firstLine: 'Wähle eine Unterhaltung aus, um den Chat anzuzeigen,',
          secondLine: 'oder starte hier einen',
          LinkText: 'NEUEN CHAT',
        },
        noChatUser:
          'Noch keine Chats? Starte einen 1:1-Chat oder warte, bis dein Admin dich hinzufügt.',
        noUnseenUser: "Don't have any unseen chat",
        sendFirstMessage: 'Schreib deine erste Nachricht an {{recipient}}.',
      },
      infoPopup: {
        title: 'Limit überschritten',
        message: 'Du kannst maximal 20 Dateien auf einmal hochladen.',
      },
    },
    statistics: {
      tableType: 'Wähle einen Bericht',
      eventReplies: 'Antworten & Aufgaben',
      eventTypes: 'Ereignisart',
      appointmentSchedule: 'Terminkalender',
      memberFigures: 'Anwesenheitskontrolle',
      charts: {
        eventTypesByMonth: 'Anzahl der Termine nach Typ',
        eventReplies: 'Reaktionen auf Antwortmöglichkeiten',
        memberPresence: 'Anzahl der Reaktionen pro Antwort',
      },
      loadingStatistics: 'Wird geladen...',
      memberFiguresExportSuccess: {
        title: 'Bericht wird erstellt!',
        message:
          'Wir erstellen gerade Deinen individuellen Anwesenheitsbericht. Aufgrund der großen Datenmenge kann dies einige Minuten dauern. Anschließend wird der Bericht aus Sicherheitsgründen an Deine E-Mail-Adresse gesendet. Schau einfach in Deinen Posteingang. Vielen Dank für Deine Geduld!',
      },
    },
    filters: {
      clickToSelect: 'Klicke hier, um zu filtern',
      showFilters: 'Filter anzeigen',
      dateRange: 'Datumsbereich auswählen',
      filterBy: 'Filtern nach',
      dateRanges: {
        yearToDate: 'Bisheriges Jahr',
        lastDays: 'Letzte {{number}} Tage',
        lastYear: 'Letztes Jahr',
        yesterday: 'Gestern',
        today: 'Heute',
        tomorrow: 'Morgen',
        nextDays: 'Nächste {{number}} Tage',
        thisWeek: 'Diese Woche',
        thisMonth: 'In diesem Monat',
        thisYear: 'In diesem Jahr',
        allTime: 'Alle Zeiten',
        specificDateRange: 'Spezifischer Datumsbereich',
      },
      downloadXlsxOrCsv: 'Hier herunterladen: Excel oder CSV',
    },
    bulkUploadEvents: {
      toastText:
        '🆕  Die neue Terminimport-Funktion ist jetzt für jede Gruppe verfügbar!  🎉',
      moduleTitle: 'Import',
      step1: 'Schritt 1',
      step2: 'Schritt 2',
      step3: 'Schritt 3',
      step4: 'Schritt 4',
      stepOne: {
        stepTitle: 'Datenimport vorbereiten',
        stepDescription: 'Excel-Vorlage herunterladen',
        componentTitle: 'Excel-Vorlage herunterladen',
        componentDescription:
          'Füll die Vorlage mit deinen Ereignisdaten aus oder verwende deine eigene Excel-Datei.',
        p1: 'Fülle die Vorlage mit deinen Ereignisdaten aus.',
        p2: 'Spaltentitel müssen in Englisch bleiben.',
        p3: 'Beispiele in jeder Spalte zeigen dir das richtige Format.',
        p4: 'Neue Ereignistypen werden automatisch hinzugefügt.',
        p5: '<b>Wichtig</b>: Wenn du deine eigene Datei verwendest, müssen die folgenden Spaltentitel exakt so in Englisch (Groß-/Kleinschreibung beachten) kopiert und übernommen werden:',
        p6: 'Beispiele in jeder Spalte der Vorlage zeigen dir das richtige Format.',
        p7: 'Neue Ereignistypen werden automatisch hinzugefügt.',
        eventTypes: 'ZU DEN EREIGNISTYPEN',
        template: 'VORLAGE HERUNTERLADEN',
        nextStepButton: 'WEITER',
        copy: 'Jetzt alle kopieren',
      },
      stepTwo: {
        stepTitle: 'DATEI HOCHLADEN',
        stepDescription: 'CSV DATEI HOCHLADEN',
        componentTitle: 'CSV DATEI HOCHLADEN',
        componentDescription:
          'Speichere die Excel-Datei als <b><u>CSV</u></b> und lade sie hier hoch.',
        p1: 'Wir prüfen das Format und zeigen dir eine Vorschau.',
        uploadButton: 'Datei auswählen',
        maximumFileSize: 'Maximale Dateigröße: 1MB',
      },
      stepThree: {
        stepTitle: 'Daten bestätigen',
        stepDescription: 'Vorschau deines ersten Ereignisses.',
        componentTitle: 'Daten bestätigen',
        componentDescription:
          'Überprüfe das Beispiel und passe die aktivierten Mitglieder an.\nDie ausgewählten Mitglieder gelten für alle Ereignisse.',
        nextStepButton: 'BESTÄTIGEN & HOCHLADEN',
      },
      stepFour: {
        stepTitle: 'Erfolgreich hochgeladen!',
        stepDescription:
          'Das Hochladen der Ereignisse war erfolgreich. Du findest sie im Terminplaner.',
        componentTitle: 'Erfolgreich hochgeladen!',
        componentDescription: '<b>{{count}}</b> Termine wurden importiert.',
        secondTitle: 'Gut zu wissen',
        secondSubTitle:
          'Du kannst den Import rückgängig machen oder eine neue Liste hochladen.',
        goToScheduler: 'ZUM TERMINPLANER',
        undoImport: 'IMPORT RÜCKGÄNGIG MACHEN',
        uploadNewList: 'NEUE LISTE HOCHLADEN',
      },
      errors: {
        eventNameErrorTitle: 'Ereignisname fehlt',
        eventNameErrorMessage:
          'Fehlender Ereignisname in Zeile <b><u>{{row}}</u></b>.',
        eventTypeErrorTitle: 'Ereignistyp fehlt',
        eventTypeErrorMessage:
          'Fehlender Ereignistyp in Zeile <b><u>{{row}}</u></b>.',
        timeErrorTitle: 'Zeitangabe fehlt',
        timeErrorMessage:
          'Start- oder Endzeit fehlt in Zeile <b><u>{{row}}</u></b>.',
        dateErrorTitle: 'Datum fehlt',
        dateErrorMessage: 'Fehlendes Datum in Zeile <b><u>{{row}}</u></b>.',
        locationErrorTitle: 'Ort fehlt',
        locationErrorMessage:
          'Fehlender Veranstaltungsort in Zeile <b><u>{{row}}</u></b>.',
        invalidDateOrTimeTitle: 'Ungültiges Datum oder ungültige Uhrzeit',
        invalidDateOrTimeMessage:
          'Bitte überprüfe das Datums- und Zeitformat in Zeile <b><u>{{row}}</u></b> (z. B. HH:MM für die Uhrzeit).',
      },
      deletePopupTitle: 'Terminliste löschen?',
      deletePopupMessage:
        'Diese Aktion löscht deine zuletzt hochgeladene Terminliste unwiderruflich. Bist du sicher?',
      deleteSuccessTitle: 'Erfolgreich gelöscht!',
      deleteSuccessMessage:
        'Deine zuletzt hochgeladene Terminliste wurde erfolgreich gelöscht.',
      successMessage:
        'Überprüfung erfolgreich. Gehe zum nächsten Schritt, um eine Vorschau zu sehen.',
      restartProcessTitle: 'Neue Terminliste hochladen',
      restartProcessMessage:
        'Du kannst jederzeit eine neue Terminliste hochladen. Beachte bitte, dass bereits hochgeladene Termine weiterhin im Terminplaner sichtbar bleiben. Wenn du die alte Liste ersetzen möchtest, lösche sie vorher oder mache den letzten Import rückgängig.',
      restartProcessOkButton: 'NEUE LISTE HOCHLADEN',
      exportDataSample: {
        event_name: 'Zürich Street Parade',
        event_type_name: 'Auftritt',
        event_location: 'Bahnhofstrasse 5, 8001 Zürich',
        event_description:
          'Open-Air-Festival mit internationalen Bands und DJs',
      },
    },
    event: {
      event: 'Ereignis',
      createdAt: 'Erstellungsdatum',
      editEvent: 'Ereignis bearbeiten',
      createEvent: 'Ereignis erstellen',
      updateEvent: 'Ereignis aktualisieren',
      eventName: 'Ereignisname',
      eventType: 'Ereignistyp',
      eventDetails: 'Ereignisdetails',
      eventTypeDeleted: 'Gelöschte Ereignistypen',
      eventOrganizer: 'Organisator:in',
      eventOrganizerShort: 'Organisator:in',
      location: 'Ort',
      eventTypeEdit: 'Ereignistyp bearbeiten',
      eventTypeName: 'Name des Ereignistyps',
      eventTypeCreate: 'Veranstaltungstyp erstellen',
      responseRequired: 'Antwort erforderlich',
      responseName: 'Name der Antwort',
      responsesEdit: 'Antwort bearbeiten',
      responsesCreate: 'Antwort erstellen',
      repeatAnEvent: 'Ereignis wiederholen',
      repeatWithInterval: 'Wiederholung mit Intervall',
      allDay: 'Ganztags',
      registrationDeadline: 'Anmeldefrist',
      commentRequired: 'Kommentar erforderlich',
      editTask: 'Aufgabe bearbeiten',
      createTask: 'Aufgabe erstellen',
      eventTaskName: 'Name der Aufgabe',
      icon: 'Symbol',
      isMemberComing: 'Deine Antwort?',
      recurringEvent: 'Wiederkehrendes Ereignis',
      allEvents: 'Alle Ereignisse',
      thisEventOnly: 'Nur dieses Ereignis',
      createEventSuccess: 'Ereignis wurde erfolgreich erstellt',
      updateEventSuccess: 'Ereignis wurde erfolgreich aktualisiert',
      createEventReplySuccess: 'Antwort erfolgreich gespeichert',
      updateEventReplySuccess: 'Antwort erfolgreich aktualisiert',
      addGuests: 'Zusätzliche Gäste?',
      eventOperations: {
        eventOnly: 'Diesen Termin',
        eventAndFollowUp: 'Diesen Termin und alle folgenden',
        all: 'Alle Termine',
        deleteText: 'Wiederkehrenden Termin löschen',
        editText: 'Wiederkehrenden Termin bearbeiten',
        editReplyText: 'Deine Antwort gilt für',
      },
      dfRes: 'Standardantwort festlegen',
      responseToast:
        'Achtung! Wenn du die Standard-Antwort löschst, ohne eine neue zu speichern, werden alle bisherigen Reaktionen und Kommentare gelöscht.',
      setPreferences: 'Voreinstellungen festlegen',
      setEventDefaults: 'Standardwerte festlegen',
      preferencesText:
        'Definiere Voreinstellungen für neue Termine dieses Typs, inkl. Standardantwort für Mitglieder',
      responseSubTitle: 'Kommentar anfordern (optional)',
      requestComment:
        'Aktiviere diese Option, um bei Auswahl dieser Antwort ein Kommentarfeld für den Nutzer anzuzeigen (z. B. bei "Nein, ohne mich", um den Grund zu erfragen)',
      registeredUsers: 'Eingeladene Personen',
      guests: 'Gäste',
      nomination: 'Aufgebot',
      nominated: 'Du bist nominiert',
      havePenalty: 'Du hast einen Penalty bekommen',
      penalty: 'Penalty',
      invitees: 'Eingeladene Personen',
      awaiting: 'Ausstehend',
      minutes: 'Minuten',
      hours: 'Stunden',
      days: 'Tage',
      weeks: 'Wochen',
      weekDay: 'Wochentag',
      duration: 'Dauer',
      months: 'Monate',
      years: 'Jahre',
      viewAppointment: 'Event anzeigen',
      myResponse: 'Meine Antwort',
      noEvents:
        'Ruhephase? Vielleicht hast du alle Termine beantwortet, bist nicht freigeschaltet oder es sind keine geplant. Admins und Teamleiter sind dafür zuständig.',
      shareEvent:
        'Termin teilen — Klick auf dieses Zeichen, um diesen Termin zu kopieren und überall dorthin zu senden, wo du Texte einfügen kannst (E-Mail, WhatsApp, etc.)',
      shareEventOption: 'Terminlink teilen',
      exportPDFOption: 'Als PDF exportieren',
      deadlineExpired:
        'Die Anmeldefrist für diesen Termin scheint abgelaufen zu sein, oder das Ereignis ist bereits vorbei.',
      totalInvitees: 'Eingeladene insgesamt',
      responsesReceived: 'Erhaltene Antworten',
      additionalGuests: 'Zusätzliche Gäste',
      noPermissionToGiveReply: 'Du hast nicht die Rechte, dies zu ändern',
      responsesPercentage: 'Prozentsatz der Antworten',
      acceptedTasks: 'Angenommene Aufgaben',
      acceptedTasksByUser: 'Aufgaben nach Benutzer',
      givenComments: 'Gegebene Kommentare',
      givenCommentsByUser: 'Erteilte Kommentare durch Benutzer',
      optionalMessage: 'Klick hier, um die Nachrichten zu sehen.',
    },
    task: {
      createTask: 'Aufgabe erstellen',
      taskName: 'Name der Aufgabe',
      noTasks: 'Keine Aufgaben',
    },
    groupSettings: {
      title: 'Gruppen-Einstellungen',
      tabs: {
        general: 'Voreinstellungen',
        eventTypes: 'Ereignistypen',
        responses: 'Antworten',
        membersManagement: 'Mitglieder-Verwaltung',
        other: 'Andere',
      },
      deleteGroupHeader: 'Bist du sicher, dass du das löschen willst?',
    },
    documentation: {
      title: 'Dokumentation',
      generalDocumentation: 'Allgemeine Dokumentation',
      uploadedFiles: 'Hochgeladene Dateien',
      chooseFile: 'Datei auswählen',
      tipMessage: 'Maximale Upload-Dateigröße: 50 MB',
      overview: '{{value}} GB von {{capacity}} GB benutzt',
      uploadSuccess: 'Dokument wurde erfolgreich hochgeladen',
      deleteSuccess: 'Dokument wurde erfolgreich gelöscht',
      attachFiles: 'Dateien anhängen',
      limitExceededHeader:
        'Du hast das Speicherlimit für die Dokumentation erreicht.',
      sort: {
        label: 'Sortieren nach',
        nameAsc: 'Name (A-Z)',
        nameDesc: 'Name (Z-A)',
        dateOldest: 'Datum (Älteste zuerst)',
        dateNewest: 'Datum (Neueste zuerst)',
      },
    },
    subscriptions: {
      title: 'Abonnements',
      subscription: 'Abonnement',
      yearly: 'Jährlich',
      adBlocker: {
        header: 'Ad-Blocker deaktivieren für lokale Preise',
        body: 'Für korrekte lokale Preise deaktiviere bitte deinen Ad-Blocker: Klicke auf das Ad-Block-Symbol neben der URL-Leiste und wähle ‚Deaktivieren‘. Dann Seite neu laden. Danke.',
      },
      monthly: 'Monatlich',
      freeTitle: '2 Monate kostenlos testen',
      freeDescription: 'Starte jetzt deinen kostenlosen Probemonat',
      freeText1: 'Bis zu 2 Gemeinschaften eröffnen',
      freeText2: 'Vollzugriff auf alle Funktionen',
      freeText3: 'Keine Kreditkarte benötigt – einfach anmelden',
      freeText4: 'Keine automatische Verlängerung',
      freeText5: 'Keine Werbung',
      subscribe: 'ABONNIEREN',
      seeDetails: 'DETAILS ANSEHEN',
      buildCustom: 'Custom',
      month: 'Monat',
      year: 'Jahr',
      community: 'Gemeinschaft',
      communities: 'Gemeinschaften',
      maxStorage: 'GB Speicher',
      scheduler: '1 zentraler Terminplaner',
      schedulers: 'Jede mit einem zentralen Terminplaner',
      classic: 'Classic',
      group: 'Team-/Abteilungs-Gruppenplaner',
      capacity: 'Mitglieder',
      classicPlus: 'Classic+',
      popular: 'BELIEBT',
      textInnerTop:
        'Wähle das Gumb-Abo, das zu eurer Gemeinschaft passt. Mit diesem Abo kannst du dein Team in bis zu {{groups}} Gruppen einteilen und bis zu {{members}} Mitglieder verwalten. Du kannst das Abo jederzeit aktualisieren oder kündigen.',
      textInnerBottom:
        'Beim Kauf eines Abos schließt du einen unbefristeten Vertrag ab. Der Vorteil: Dein Abo wird nach Ablauf der Gültigkeitsdauer von einem Jahr/Monat automatisch verlängert (wir erinnern dich per E-Mail). Du kannst das Abo mit Kreditkarte (MasterCard und Visa) oder PayPal bezahlen.',
      chatsExcluded: 'Ohne Chat-Funktion',
      current: 'AKTUELL',
      pay: 'BEZAHLEN',
      usingBlocker: 'Bitte deaktiviere den Werbeblocker und lade die Seite neu',
      failedToSubscribe: 'Abonnement fehlgeschlagen',
      usedGroups: 'Verwendete Gruppen',
      usedMembers: 'Verwendete Mitglieder',
      usedCommunity: 'Verwendete Gemeinschaften',
      usedStorage: 'Genutzter Speicherplatz',
      orderSummary: 'Zusammenfassung des Auftrags',
      tax: 'STEUERN',
      total: 'Gesamt',
      periodEnds: 'Bezahlte Periode endet',
      cancelSubscription: 'ABO KÜNDIGEN',
      paypalLink: 'ZAHLUNGSÜBERSICHT',
      chatEnabled: 'Mit Chat',
      chatExcluded: 'Kein Chat',
      paymentApproved: 'Zahlung genehmigt',
      paymentError: 'Zahlungsfehler',
      limitExceededHeader:
        'Sie haben die maximale Anzahl von Mitgliedern überschritten',
      limitExceededMessage:
        'Bitte kontaktiere den Admin der Gemeinschaft. Dein Admin kann die Aboplanbegrenzungen prüfen und euren Gemeindeaboplan aktualisieren.',
      limitExceededMessageOwner:
        'Bitte besuche die Seite für Abos, um die Grenzen Deines Aboplans zu überprüfen oder Dein Abo zu aktualisieren.',
      inactiveSubscriptionHeader: 'Zahlungsgenehmigung ausstehend',
      inactiveSubscriptionMessage:
        'Entschuldigung, Du kannst diese Aktion nicht fortsetzen. Du musst zuerst den Zahlungsvorgang abschließen.',
      subscriptionNotPaid:
        'Dein Abo ist abgelaufen oder das Abo ist nicht bezahlt.',
      contactAdmin: 'Bitte kontaktiere den Admin.',
      free: 'Gratiskonto',
      requireSubscription:
        'Gumb ist kostenlos für Mitglieder aktiver Communities. Erstelle deine eigene Community und starte jetzt deine Testphase oder ändere dein Abo unter "Abo ändern".',
      requireSubscriptionMember:
        'Es wurde kein gültiges Abo gefunden. Keine Sorge, du musst nichts tun. Der Gemeinschafts-Besitzer sollte jedoch prüfen, ob die Lizenz für diese Gemeinschaft in seinem Abo-Bereich aktiviert oder erneuert wird.',
      cancelConfirmation:
        'Bist du sicher, dass du dein Abonnement kündigen willst?',
      unsubscribe: 'Kündigen',
      cancelled:
        'Kündigung bestätigt. Eine Bestätigung der Kündigung wurde soeben an deine E-Mail versendet. Bis zum bezahlten Abrechnungstag haben alle weiterhin Zugriff auf alle Funktionen.',
      renewSubscriptionText:
        'Vielen Dank!\n\nDein bisheriges Abonnement wird verlängert, wenn du es jetzt aktivierst',
      activate: 'Jetzt aktivieren',
      activated:
        'Dein Abo wurde erfolgreich aktiviert. Wir freuen uns sehr, dass du ab sofort mit Gumb organisierst und planst. Eine Bestätigung der Mitgliedschaft wurde soeben an deine E-Mail versendet. Dein Abo ist ab sofort gültig, und du kannst auf alle Funktionen zugreifen.',
      approved:
        'Wir bereiten dein Abo vor. Bitte warte einen Moment (bis zu 45 Sekunden). Bitte aktualisiere oder schließe das Browserfenster nicht währenddessen.',
      trial: 'Kostenlose Probezeit',
      cancelledTitle: 'Gekündigt',
      trialActivated:
        'Deine Testzeit hat gestartet. Du und deine Admins können jetzt mit der Planung beginnen, Mitglieder einladen und die Einstellungen verwalten.',
      startTrial: 'STARTE DEINEN GRATISTEST',
      startTrialHeader: 'Laaaaaaange testen!',
      startTrialText:
        'Damit deine Gemeinschaft alle Funktionen ausgiebig im echten Betrieb testen kann, bieten wir volle 2 Monate zum Testen! Keine Zahlungsdaten erforderlich und keine versteckten Kosten – einfach sorgenlos alle Funktionen mit allen Mitgliedern testen.',
      subscriptionExpired:
        'Diese Community hat derzeit kein aktives Abo. Um neue Nutzer hinzuzufügen, muss das Abo erneuert werden. Bei Fragen: support@gumb.app',
      startTrialPopupButton: 'Kostenlos testen',
      paymentMethods: 'Kreditkarte oder Paypal',
      subscriptionsPopup: {
        title: 'Euer Abo ist abgelaufen',
        subtitle:
          'Euer Abo ist abgelaufen, aber euer Community-Besitzer, <b>{{ownerName}}</b> kann das ganz einfach ändern. <brPure />Jede Community hat einen Besitzer, der für das Abo verantwortlich ist. 👑',
        firstBox: {
          title:
            '<b>{{ownerName}}</b> <brPure /> Möchtest du euer Abo erneuern und die Teamplanung wieder zum Leben erwecken?',
          btnText: 'Erneuern',
        },
        secondBox: {
          title:
            '<b>Für Mitglieder</b> <brPure />Möchtest du das Abo übernehmen und selbst zum Community-Besitzer werden? (Starte ein eigenes Abo, kontaktiere uns im Anschluss, und wir stellen die Besitzerrechte für dich um.)',
          btnText: 'Besitzer werden',
        },
      },
    },
    terms: {
      title: 'Allgemeine Geschäftsbedingungen',
    },
    errors: {
      cantSendMessageToYourself: 'Du kannst dir selbst keine Nachricht senden.',
      downloadFail: 'Download-Fehler, bitte versuche es erneut.',
      required: 'Dieses Feld ist erforderlich',
      email: 'Bitte gib eine gültige E-Mail ein',
      invalidEmail:
        'Ups! Bitte gib eine gültige E-Mail-Adresse ein. Überprüfe auf Tippfehler und versuche es erneut.',
      integer: 'Muss eine ganze Zahl sein',
      confirmPassword:
        'Passwörter stimmen nicht überein. Bitte versuche es erneut',
      NotAuthorizedException: 'Falscher Benutzername oder Passwort.',
      invalidCode: 'Dieser Code ist ungültig. Bitte versuche es erneut.',
      errorToast: 'Error: {{message}}',
      invalidRequest: 'Ungültige Anfrage',
      passwordChanged: 'Passwort geändert',
      password:
        'Das Passwort sollte mindestens 8 Zeichen lang sein, Buchstaben in Groß- und Kleinschreibung, Ziffern und Sonderzeichen enthalten',
      termsError: 'Bitte akzeptieren Sie die Bedingungen',
      min: 'Mindestens {{value}} Zeichen',
      max: 'Maximal {{value}} Zeichen',
      startDate: 'Das Datum darf nicht vor dem {{value}} liegen',
      endDate: 'Das Datum kann nicht vor dem Startdatum liegen',
      fileSize: 'Die Datei ist zu groß',
      fileSizeCustom: 'Datei zu groß: Maximale Größe ist {{size}} MB.',
      customFileSize:
        'Datei zu groß: Max {{size}} GB. Entferne sie und versuche es erneut.',
      unsupportedFormat: 'Dieses Dateiformat wird nicht unterstützt',
      postalCode: 'Mindestens 4 Ziffern',
      sorry: 'Entschuldigung',
      text404: 'Sieht aus, als ob die gesuchte Seite nicht verfügbar ist :(',
      url: 'Muss eine gültige URL sein.',
      text500: 'Da ist etwas schief gelaufen :(',
      onlyForGroupAndAdmin:
        'Diese Funktion kann nur durch Admins und Gruppenleiter genutzt werden',
      onlyForAdmin: 'Nur Admins können diese Funktion nutzen.',
    },
    eventTypes: {
      createSuccess: 'Ereignistyp wurde erfolgreich erstellt',
      editSuccess: 'Änderungen wurden erfolgreich aktualisiert',
      deleteSuccess: 'Ereignistyp wurde erfolgreich gelöscht',
    },
    eventTasks: {
      createSuccess: 'Aufgabe wurde erfolgreich erstellt',
      editSuccess: 'Änderungen wurden erfolgreich aktualisiert',
      deleteSuccess: 'Aufgabe wurde erfolgreich gelöscht',
    },
    eventResponses: {
      createSuccess: 'Antwort wurde erfolgreich gespeichert',
      editSuccess: 'Änderungen wurden erfolgreich aktualisiert',
      deleteSuccess: 'Antwort wurde erfolgreich gelöscht',
    },
    tips: {
      eventType:
        'Der Admin kann auf der Seite mit den Gemeinschaftseinstellungen neue Ereignistypen hinzufügen',
    },
    frequencies: {
      doNotRepeat: 'Einmalig',
      daily: 'Täglich',
      weekly: 'Wöchentlich',
      monthly: 'Monatlich',
      yearly: 'Jährlich',
      everyWorkingDay: 'Jeden Montag bis Freitag',
      other: 'Benutzerdefiniert…',
    },
  },
  es: {
    appWidget: 'Instala Gumb App en tu dispositivo',
    featurePopups: {
      appointmentSchedule: {
        innerHtml:
          "<h3>New feature: Download your appointment schedule as an Excel or CSV file. </h3><p>We have completely redesigned the statistics page, and you can export your entire schedule now. The new report called 'Appointment schedule' gives you an overview of facts and figures of your events.</p><p>We are already working on another report called 'Member figures' which will be related to user performance. </p><p>The diary report is now available in the web app for all users.</p>",
        buttonOk: 'LATER',
        buttonRedirect: 'SEE NOW',
      },
      preferecePopup: {
        title: '2 New Features',
        first: {
          title: 'Set All to "Going":',
          subtitle:
            'Set the default response for new or existing events – for all participants at once!',
          link: '',
        },
        second: {
          title: 'Default Event Settings:',
          subtitle: 'Define how new events should look.',
          link: 'Instructions',
        },
      },
      appleCalendar: {
        title: 'CONECTA TU CALENDARIO (iCal)&nbsp;🍏',
        message:
          '¡Lo logramos! Después de mucho trabajo, Gumb ahora habilita la conexión directa del calendario en la versión WEB para una sincronización perfecta. ¡Estén atentos para el lanzamiento de la versión para iOS/Smartphone&nbsp;muy&nbsp;pronto!',
        extraMessage: 'Abre la guía paso a paso',
        redirectText: 'aquí',
        okButton: 'Conectar ahora',
        cancelButton: 'No ahora',
      },
      calendar: {
        innerhtml:
          "<h3>Lo hemos conseguido.</h3> <p> ¡La conexión de GOOGLE CALENDAR está aquí! </p> <br/> <p> Eventos de Gumb - Cada comunidad seleccionable individualmente, ahora puede ser transferida automáticamente a su calendario. </p> <div class='images-container'> <img src='https://gumb-cdn-prod.s3.eu-central-1.amazonaws.com/featurePopups/calendar-1.png' alt='calendar-1' /> <img src='https://gumb-cdn-prod.s3.eu-central-1.amazonaws.com/featurePopups/calendar-2.png' alt='calendar-2' /></div> <p> En esta guía se explica todo lo que hay que hacer: <br/><a target='_blank' rel='noopener noreferrer' href='https://gumb.app/en/support/connect-gumb-with-google-calender/'>→&nbsp; Leer las instrucciones completas </a> </p> <p>Guía rápida:</p> <ol> <li>Conectar e importante: ¡marcar la casilla!</li> <li>Seleccionar la comunidad</li> <li>Espere unos minutos y recargue la página</li> </ol> <p> Conecta tu Google Calendar para ahorrar tiempo y aprovechar al máximo cada día. </p>",
        buttonOk: 'NADA PARA MÍ',
        buttonRedirect: 'CONECTAR AHORA',
      },
      notifications: {
        title: '¿Cómo se debe notificar a los miembros?',
        firstRow:
          'Hemos añadido: Configuración de mensajes y notificaciones push para móviles.',
        secondRow:
          'La configuración de las notificaciones que gestionan los administradores puede ahora personalizarse para toda la comunidad:',
        thirdRow: 'Todo lo que tienes que hacer es:',
        listFirstRow: 'Vaya a Configuración de la comunidad → Notificaciones.',
        listSecondRow:
          'Activa/desactiva las opciones de cada categoría → para personalizar todo a la perfección para ti. (→ Lea las instrucciones <0>aquí</0>)',
        lastRow:
          'Dígales a sus miembros que habiliten la configuración de push o correo electrónico en su propio perfil de Gumb ahora. ',
        buttonLater: 'Más tarde',
        buttonSettings: 'Ir a la configuración ahora',
      },
      pushNotifications: {
        title: '📢 Comunicación de siguiente nivel: Notificaciones Push',
        message:
          'Los administradores y líderes de grupo ahora pueden crear notificaciones push para enviarte información importante directamente a tu smartphone.\n\nQué puedes hacer con las notificaciones push:\n\n• <b>Compartir información importante al instante</b> (por ejemplo, cambios de horario, emergencias)\n• <b>Llegar a personas individuales o equipos completos</b> (comunicación personalizada)\n• <b>Guardar mensajes como plantillas para ahorrar tiempo</b> (comunicación eficiente)',
        extraMessage:
          '¡Mantente siempre informado y no te pierdas ninguna novedad!',
        cancelButton: 'Ver más tarde',
        okButton: 'Probar ahora',
        newMessageOne:
          'Los administradores y los líderes de grupo ahora pueden comunicarse directamente con personas individuales o equipos completos para enviarle información importante directamente a su teléfono inteligente.\n\n',
        redirectLink: 'Cree notificaciones push personalizadas',
        newMessageTwo:
          ', seleccione destinatarios e incluso guarde sus mensajes como plantillas para usarlos más tarde.',
      },
      importEvent: {
        title: 'Upload/Import Events ',
        subText:
          "No more manual entry! Easily import your events with a CSV or Excel file. Whether it's 30 or 300 events - it's quick and can even be undone if there are mistakes.",
        cancelButton: 'View later',
        okButton: 'Try now',
        secondText: 'Find step-by-step instructions',
      },
      mail: {
        title: 'Nueva función: MODO CORREO',
        firstRow:
          'La función de correo electrónico integrada simplifica y agiliza la comunicación del equipo.  ¿Necesita enviar urgentemente un correo electrónico a todo el mundo? ¿Pero sólo a los miembros que han respondido "Sí" a la cita? → ¡Ahora es posible con solo unos pocos clics!',
        secondRow:
          'El modo de correo está ahora disponible en la aplicación web para los administradores y líderes de grupo. Se ampliará aún más en el próximo periodo.',
        thirdRow: 'Más información: <0>https://gumb.app/support/mailmodus/</0>',
        buttonLater: 'VER MÁS TARDE',
        buttonMail: 'AHORA',
      },
      eventPdf: {
        title: 'Nueva función: Descargar la cita como PDF',
        firstRow:
          'Ahorre tiempo ahora e imprima los detalles del evento con un solo clic.',
        secondRow:
          'Poco antes de los próximos eventos, o incluso para eventos pasados, se pueden exportar a PDF todos los detalles del evento, incluyendo todas las estadísticas de respuesta.',
        thirdRow:
          'Requisito del planificador de citas: tiene derechos de administrador',
        fourthRow:
          'Requisito de la planificación del grupo: Usted es un líder de grupo',
        fifthRow: 'La función ya está disponible en la aplicación web.',
      },
    },
    menu: {
      homePage: 'INICIO',
      subscriptions: 'Suscripciónes',
      groupScheduler: 'CALENDARIO',
      documentation: 'ARCHIVOS',
      chats: 'Chat',
    },
    dayOfWeek: {
      day1: 'L',
      day2: 'M',
      day3: 'X',
      day4: 'J',
      day5: 'V',
      day6: 'S',
      day0: 'D',
    },
    email: {
      sender: 'Remitente',
      recipients: 'Destinatarios',
      otherRecipients: 'Otros destinatarios (separar con coma)',
      subject: 'Asunto',
      message: 'Mensaje',
      attachCommunityDocs: 'Adjuntar documentos comunitarios',
      plusTooltip: 'Escribir nuevo correo electrónico',
      emailSentSuccessfuly:
        'El correo electrónico se ha enviado con éxito. Tenga en cuenta: Se trata de un "correo sin respuesta" - Un correo electrónico al que el destinatario no puede responder.',
      sendCopy: 'Envíenme una copia',
      maxAttachmentsSize:
        'Si añade varios archivos adjuntos, no deben superar los 15 MB en total.',
      changeEmail: {
        title: 'Email de confirmación enviado',
        message:
          'Acabamos de enviarte un correo electrónico de confirmación con un enlace para tu nueva dirección de correo electrónico. Por favor, abre tu bandeja de entrada y haz clic de inmediato en el enlace para confirmar tus cambios. Si no recibes el correo electrónico en pocos minutos, revisa tu carpeta de correo no deseado. Si tienes alguna pregunta o problema, nuestro servicio de atención al cliente está aquí para ayudarte.',
      },
    },
    pushNotification: {
      header: 'Notificaciones Push',
      filterByRecipient: 'Seleccionar destinatarios',
      filterByResponse: 'Seleccionar destinatarios por respuesta',
      pushMessage: 'Crear push',
      saveAsTemplate: 'Guardar como plantilla',
      sendCopy: 'Enviarme una copia',
      pasteUrl: '🌐 Enlace a evento o externo?',
      plusTooltip: 'Crear nueva notificación push',
      toasterText:
        '🆕 Nueva función: ¡Ahora puedes agregar un enlace externo, como cualquier sitio web 🌐 (URL), a las notificaciones push!\nDirige a los destinatarios directamente al sitio web.',
      templates: 'Plantillas',
      title: 'Título',
      lastUsed: 'ZÚltimo uso',
      noTemplates: 'Las plantillas guardadas aparecen aquí',
      template: 'Plantilla',
      sendConfirmHeader: 'Revisar antes de enviar',
      totalRecipients: 'DESTINATARIOS',
      redirectLink: 'AL ENLACE',
      successTitle: '¡Notificación enviada!',
      successMessage:
        'Tu mensaje ha sido enviado a <b>{{totalRecipients}}</b> destinatarios!',
      selectEventFirst: 'Por favor, selecciona un evento primero',
      templateLimitTitle: 'Límite de plantillas alcanzado',
      templateLimitInfo:
        'Guarda hasta 8 de tus mensajes como plantillas para reutilizarlos más tarde. Ya hemos añadido 3 plantillas para inspirarte: siéntete libre de editarlas o borrarlas.',
      templateLimitMessage:
        'Has alcanzado el número máximo de plantillas. Por favor, elimina una para crear una nueva.',
      urlInfo:
        'Puedes añadir enlaces a cualquier sitio web (excepto Gumb) aquí. Puede ser un enlace a una página de producto, un artículo de noticias o cualquier otra cosa que consideres relevante. Simplemente pega la URL completa (incluyendo https://).',
      urlInfoOne:
        'Puedes añadir cualquier enlace externo (URL) aquí para dirigir a tus destinatarios directamente. Si seleccionas un evento arriba, la app insertará el enlace del evento y al hacer clic en la notificación push, se abrirá directamente en Gumb.',
      urlInfoTwo:
        'Próximamente: ¡Agrega cualquier enlace externo (excepto Gumb) a tus notificaciones push! Enlaza a sitios web, noticias y más. ¡Mantente&nbsp;atento!',
      urlTooltip:
        'Puedes crear una notificación push con o sin enlace a un evento. ¿Quieres compartir el enlace de tu evento? Simplemente selecciona un evento arriba para copiarlo automáticamente aquí.',
    },
    common: {
      onlyAdmin: 'Solo los administradores pueden usar esta función.',
      download: 'Descargar',
      attachFile: 'Adjuntar archivo',
      new: 'Nuevo',
      unseen: 'No vistos',
      more: 'Más',
      less: 'Menos',
      sunday: 'Domingo',
      monday: 'Lunes',
      tuesday: 'Martes',
      wednesday: 'Miércoles',
      thursday: 'Jueves',
      friday: 'Viernes',
      saturday: 'Sábado',
      january: 'Enero',
      february: 'Febrero',
      march: 'Marzo',
      april: 'Abril',
      may: 'Mayo',
      june: 'Junio',
      july: 'Julio',
      august: 'Agosto',
      september: 'Septiembre',
      october: 'Octubre',
      november: 'Noviembre',
      december: 'Diciembre',
      overallScheduler: 'Programador general',
      selectEvent: 'Seleccionar evento (opcional)',
      displayEventsFrom: 'Mostrar eventos de',
      modalExit: 'Ertu viss um að þú viljir hætta án þess að vista gögnin þín?',
      all: 'Todo',
      tooltipEdit: 'Editar miembro',
      tooltipDelete: 'Quitar miembro',
      overview: 'Información general',
      cancel: 'Cancelar',
      removeLogo: '¿Está seguro que quiere borrar esto?',
      noTasks: 'No se han encontrado tareas',
      noUsers: 'No se han encontrado usuarios',
      role: 'Roles',
      admin: 'Administradores',
      member: 'Miembros',
      group_lead: 'Líderes de grupo',
      email: 'Email',
      groups: 'Grupos',
      logoutHeader: '¿Cerrar sesión?',
      logoutMessage: 'Serás redirigido a la pantalla de inicio de sesión.',
      deleteHeader: '¿Está seguro de que quiere eliminar el usuario?',
      logout: 'Cerrar sesión',
      delete: 'Borrar',
      tablePlaceholder: 'No se han encontrado miembros',
      contentPlaceholder: 'No se ha encontrado el contenido',
      create: 'Crear',
      copy: 'Copiar',
      visible: 'Visible',
      saveChanges: 'Guardar cambios',
      newField: 'Campo nuevo',
      fieldName: 'Nombre de campo',
      addField: 'Añadir campo',
      notifications: 'Notificaciones',
      participants: 'Participantes',
      tasks: 'Tareas',
      documentation: 'Documentación',
      description: 'Descripción',
      facebook: 'Facebook',
      twitter: 'Twitter',
      linkedin: 'LinkedIn',
      instagram: 'Instagram',
      youtube: 'Youtube',
      startDate: 'Fecha de inicio',
      startTime: 'Hora de inicio',
      startDateTime: 'Fecha/hora de inicio',
      endDateTime: 'Fecha/hora de finalización',
      endDate: 'Fecha de terminación',
      endTime: 'Hora de terminación',
      icon: 'Ícono',
      sendMessage: 'Enviar un mensaje',
      aboutMe: 'Sobre Mí',
      selectAll: 'Seleccionar todo',
      approve: 'Aprobar',
      reject: 'Rechazar',
      backToGumb: 'Volver a Gumb',
      edit: 'Editar',
      addComment: 'Añadir un comentario',
      answer: 'Guardar',
      ok: 'Ok',
      withoutGroup: 'Sin grupo',
      copyLink: 'Copiar link',
      copied: 'Copiado',
      sendInviteLink: 'Enviar enlace de invitación',
      apply: 'Aplique',
      from: 'De',
      to: 'A',
      enable: 'Activar',
      search: 'Buscar en',
      color: 'Color',
      columns: 'Columnas difuminadas',
      hiddenColumns: 'Columnas ocultas',
      noResultsFound: 'No se han encontrado resultados',
      sorryNoResults:
        'Lamentamos que no haya encontrado lo que buscaba. Por favor, inténtelo de otra manera.',
      tutorial: 'Guía',
      next: 'Siguiente',
      back: 'Atrás',
      step: 'Paso',
      createSuccess: {
        title: 'Creada',
        message: 'Tu opción ha sido creada exitosamente.',
      },
      editSuccess: {
        title: 'Editada',
        message: 'Tus cambios han sido guardados.',
      },
      deleteSuccess: {
        title: 'Eliminada',
        message: 'La opción ha sido eliminada exitosamente.',
      },
      toolTipText: {
        faq: 'Ayuda: Guías, Contacto, Más.',
        communityPlusIcon: 'Crear nueva comunidad',
        logoGumb:
          'Misterio en desarrollo: ¡Descubran más tarde lo que este botón esconde!',
        memberArrowIcon: 'Ir a la vista de miembros',
        memberPlusIcon: 'Añadir nuevo usuario',
        eventType: 'Crear tipo de evento',
        responses: 'Crear opción de respuesta',
        task: 'Crear tarea',
        schedulerPlusIcon: 'Crear evento o grupo',
      },
      shareEventTitle: 'Comparte el enlace del evento a través de:',
      copyEventLink: 'O copia el enlace:',
    },
    auth: {
      signInWith: 'Inicie sesión con',
      continueAs: 'Continuar como',
      create: 'Crear una Cuenta Gratis',
      signUp: 'Registrarse',
      signIn: 'Iniciar una Sesión',
      welcome: '¡Bienvenido!',
      createAccount: 'Crear una cuenta gratis',
      forgotPassword: '¿Olvidó su contraseña?',
      resetPassword: 'Restablecer la contraseña',
      resetPasswordTooltip:
        'Si restableces la contraseña, el miembro recibe un correo electrónico con instrucciones sobre cómo establecer una nueva contraseña. Sin embargo, Gumb no puede restablecer para registros con Facebook/Google/Apple; deben usar el ícono correspondiente.',
      resetPasswordModalTitle: '¡Reinicio de contraseña iniciado',
      resetPasswordModalText:
        'El miembro recibirá pronto un enlace de reinicio al correo indicado abajo. Para Facebook/Google/Apple no es posible, pero el miembro aún puede usar el ícono correspondiente para iniciar sesión.',
      resetPasswordErrorModalTitle:
        '¡Restablecimiento de contraseña no posible',
      resetPasswordErrorModalText:
        '¡Atención! Este miembro parece haberse registrado a través de redes sociales (por ejemplo, Facebook, Google, Apple). Por lo tanto, Gumb no puede restablecer la contraseña de esta cuenta. Sin embargo, el usuario todavía puede iniciar sesión usando el ícono de la red social correspondiente. Por favor, informa al miembro de manera adecuada.',
      terms: {
        '0': 'Estoy de acuerdo con ',
        '1': 'las Condiciones',
        '2': ' y ',
        '3': 'Política de Privacidad',
      },
      home: 'Pagina Principal',
      confirmed: 'Email confirmado exitosamente',
      verifying: 'verificación del email...',
      recovery: 'Recuperación de contraseña',
      send: 'Enviar',
      codeSended:
        'Se ha enviado el enlace de verificación a tu correo electrónico. Por favor, verifica y confirma. Presta atención a la carpeta de spam.',
      signUpSuccess:
        'Registrado exitosamente. Por favor compruebe su correo electrónico para confirmar la registración',
      inviteSentTitle: 'Invitación y datos de acceso enviados con éxito',
      inviteSentText:
        '¡Genial! Has pre-registrado con éxito al miembro y enviado la invitación con una contraseña temporal. El miembro ahora puede iniciar sesión y cambiar su contraseña. Si es necesario, recarga esta página para ver al miembro de inmediato. Tú y tus administradores ahora pueden determinar las asignaciones de grupo y asignar roles. Usando el ícono de lápiz en el extremo derecho, también puedes completar los detalles personales del miembro. ¡Sigue así!',
      reset: 'Restablecer',
      yourEmail: '{{value}} ya es su dirección de email',
      existEmail: '{{value}} ya existe',
      labels: {
        email: 'Email',
        currentEmail: 'Correo electrónico actual',
        newEmail: 'Nuevo email',
        password: 'Contraseña',
        oldPassword: 'Contraseña anterior',
        newPassword: 'Contraseña nueva',
        confirmPassword: 'Confirmar contraseña',
      },
      accountExists:
        'Esta dirección de correo electrónico ya está en uso para una cuenta de Gumb. Si este correo electrónico es el suyo, haga clic en Olvidé mi contraseña y compruebe su bandeja de entrada y su carpeta de correo no deseado.',
      codeExpired:
        'Este era un enlace caducado. Después de introducir su correo electrónico aquí, le enviaremos un nuevo enlace para completar la verificación de su cuenta.',
      userNotConfirmed:
        'Todavía tiene que verificar su cuenta. Le hemos enviado un correo electrónico con un enlace de confirmación. Si no ha recibido un correo electrónico, compruebe la carpeta de correo no deseado de su buzón. ',
      invalidCredentials: 'Nombre de usuario o contraseña incorrectos',
      userNotFound:
        'Al parecer, este correo electrónico ya está conectado a través de Google/Apple/Facebook o no está registrado. No se puede cambiar la contraseña de una red social a través de Gumb. Intenta conectarte a través del icono de las redes sociales.',
    },
    profile: {
      editSuccess: 'Los cambios se han actualizado con éxito',
      settings: 'Configuraciones',
      editProfile: 'Editar perfíl',
      selectImage: 'Seleccionar imagen',
      myProfile: 'Mí perfíl',
      profileEditBtn: 'Agrega tu información: dirección, redes sociales y más',
      basicInformation: 'Información básica',
      contactInformation: 'Información de contacto',
      addressInformation: 'Información de dirección',
      myCommunities: 'Mis comunidades',
      myChat: 'MIS CHATS',
      myAppointments: 'Mis citas',
      subscription: 'Suscripción',
      userProfile: 'Perfíl de usuario',
      tellUsAboutYourself: 'Cuéntanos sobre usted',
      firstName: 'Nombre',
      lastName: 'Apellido',
      profileName: 'Nombre de perfíl',
      person: 'Persona',
      role: 'Role de usuario',
      email: 'Email',
      dateOfBirth: 'Fecha de Nacimiento',
      privatePhoneNumber: 'Número de Teléfono Privado',
      officePhoneNumber: 'Número de Teléfono de la Oficina',
      mobilePhoneNumber: 'Número de Teléfono Móvil',
      street: 'Calle',
      region: 'Región',
      postalCode: 'Código Postal',
      city: 'Ciudad',
      country: 'País',
      about: 'About',
      linkChangeEmail: '(Cambiar E-Mail)',
    },
    profileCommunities: {
      popupLeave: {
        title: '¿Estás seguro de que quieres dejar esta comunidad?',
        button: 'Salir',
      },
      leaveCommunity: {
        title: '¿Estás seguro de abandonar esta comunidad?',
        message:
          'Cuando abandonas esta comunidad, tu cuenta de usuario aún existe y puedes unirte a otras comunidades.',
        okButton: 'Sí, Abandonar',
        cancelButton: 'Cancelar',
      },
    },
    profileSubscription: {
      heading: 'Detalles de la suscripción',
      titleMembership: 'Membresía',
      titlePayment: 'Pago',
      buttonCancel: 'Cancelar membresía',
      buttonRestart: 'Reiniciar la afiliación',
      details: {
        linkChangePlan: 'Plan de cambio',
        infoCancelled:
          'Su suscripción ha sido cancelada y no será renovada. Puede seguir utilizando la aplicación hasta la siguiente fecha de facturación. Puedes renovar tu suscripción en cualquier momento.',
        infoTrial:
          'Su mes de prueba está actualmente activo. Después de la expiración, por supuesto, no se le cobrará.  Si desea seguir utilizando Gumb, seleccione una suscripción adecuada en "Plan de cambio".',
        infoTrialNew:
          "Tu período de prueba está en curso actualmente. Después de que expire el {{expireDate}}, no se te cobrará. Si deseas seguir utilizando Gumb, selecciona una suscripción adecuada en 'Plan de cambio'.",
        linkShowCommunities: 'Mostrar a mis comunidades',
        linkHistory: 'Historial de facturación',
        linkChangePayment: 'Cambiar el método de pago',
        currentPlan: 'Plan actual:',
        communitiesInUse: 'Comunidades en uso:',
        groupsInUse: 'Grupos en uso:',
        membersInUse: 'Miembros en uso:',
        storageInUse: 'Almacenamiento en uso:',
        paymentCycle: 'Ciclo de pago:',
        paymentCycleMonth: 'Mensualmente',
        paymentCycleYear: 'Anualmente',
        nextPayment: 'Próximo pago:',
        validUntil: 'Sigue siendo válido hasta:',
        paymentMethod: 'Método de pago:',
      },
    },
    profileSettings: {
      title: 'Configuraciones del perfíl',
      notification: 'Notificación',
      notificationsPlaceholder: 'No se han encontrado notificaciones',
      changePassword: 'Cambiar contraseña',
      changeEmail: 'Email',
      saveNewEmail: 'Guardar el nuevo email',
      language: 'Lengua',
      timeFormat: 'Formato de tiempo',
      permissions: 'Autorizaciones',
      allowedToEdit: 'Los administradores pueden editar mi perfil',
      other: 'Otro',
      pushNotification: 'Notificación Push',
      receiveByEmail: 'Recibir notificaciones por correo electrónico',
      receivePush: 'Recibir notificaciones push en el móvil',
      receiveInApp: 'Recibir notificaciones in-app',
      terms: 'Condiciones generales',
      protectionRegulation: 'Política de privacidad',
      deleteAccount: 'Borrar la cuenta',
      logout: 'Cerrar la sesión',
      deleteProfileHeader: '¿Está seguro que quiere borrar su cuenta?',
      notifications: {
        now: 'Ahora',
        hours: '{hace {value}}h',
        minutes: '{hace {value}} mins',
        minute: 'hace 1 min',
      },
    },
    calendarConnection: {
      googleCalendar: 'Google Calendar',
      pageTitle: 'Conexión con el calendario',
      connectTitle:
        'Conecta tu calendario\npara sincronizar automáticamente tus eventos',
      connectDescription:
        'Gumb puede crear automáticamente eventos que aparezcan en su calendario. No pierdas de vista nada con esta sincronización.',
      appleCalendar: 'Calendario de Apple',
      appleConnectionSteps: {
        headerOne:
          'Integra eventos de Gumb en tu calendario de Apple con una URL',
        headerOneSplit: 'en 4 pasos; demostración rápida',
        here: 'aquí',
        headerTwo: 'O instrucciones detalladas',
        stepOne:
          '1. En tu Calendario de Apple, bajo "Calendario", elige "Archivo" > "Nueva suscripción a calendario".',
        stepTwo:
          '2. Ingresa el enlace iCal de la Comunidad copiado de abajo, luego haz clic en "Suscribirse".',
        stepThree:
          '3. Ingresa un nombre para el calendario y elige un color para ayudarte a identificarlo en tu calendario.',
        stepFour:
          '4. Ajusta preferencias como la ubicación (iCloud o Mac) o la actualización automática, luego haz clic en "Aceptar".',
      },
      appleConnectionPopup: {
        title: '¿Cómo conectar?',
        stepOne: 'Copiar el enlace de la comunidad',
        stepTwo:
          'En tu Calendario de Apple, bajo "Calendario", elige "Archivo" > "Nueva suscripción a calendario".',
        stepThree:
          'Ingresa el enlace iCal de la Comunidad copiado, luego haz clic en "Suscribirse".',
        stepFour:
          'Nombra el calendario, selecciona un color, ajusta preferencias como ubicación (iCloud o Mac) o actualización automática y haz clic en "Aceptar".',
      },
      appleDisconnectPopup: {
        title: 'Confirmar Desconexión',
        message:
          'Al pulsar "Sí, desconectar ahora", se eliminarán todos los eventos de Gumb de tu(s) calendario(s). Por favor, elimina manualmente el/los calendario(s) después, ya que de lo contrario seguirá/seguirán siendo visible(s).\n\n<b>Para darte de baja completamente:</b>\n\n• <b>En macOS</b>: Abre Calendario, haz clic derecho en el calendario > "Darse de baja".\n• <b>En iOS</b>: Ve a "Calendarios", toca el botón "Info" al lado del calendario > "Eliminar Calendario".',
        okButton: 'Sí, desconectar ahora',
      },
      appleUncheckBoxPopup: {
        title: 'Confirmar Desconexión',
        message:
          'Al hacer clic en "Sí, desconectar ahora", se eliminarán todos los eventos de Gumb de tu calendario. Por favor, elimina manualmente el calendario después, ya que de lo contrario seguirá visible.\n\n<b>Para darte de baja completamente:</b>\n\n• <b>En macOS</b>: Abre Calendario, haz clic derecho en el calendario > "Darse de baja".\n• <b>En iOS</b>: Ve a "Calendarios", toca el botón "Info" al lado del calendario > "Eliminar Calendario".',
        okButton: 'Sí, desconectar ahora',
      },
      connectWithApple: 'Conectar con Apple (iCal)',
      cantSeeICal: '¿No ves el enlace iCal? Solo marca la casilla.',
      syncTip:
        'Sólo los eventos de la comunidad seleccionada se sincronizarán con su calendario.',
      connect: 'Conectar',
      connected: 'Asociados',
      disconnect: 'Desconectar',
      disconnectSuccess: 'Desconectado con éxito',
      selectCommunities: 'Selección de comunidades',
      selectCommunitiesDescription:
        'Sólo los eventos de la comunidad seleccionada se sincronizarán con su calendario.',
      authSuccessTitle: 'Conexión exitosa',
      authSuccessMessage:
        'Tus comunidades se sincronizarán automáticamente. Espera un momento y luego actualiza la página de Google Calendar para completar la sincronización inicial.',
      authSuccessTitleTwo: '¡Selecciona tu comunidad ahora',
      authSuccessMessageTwo:
        'Toca la marca de verificación y pulsa "Guardar cambios" para confirmar.',
      syncSuccess:
        '¡Actualizado con éxito!\nA partir de ahora, todo se sincronizará automáticamente. Puedes cambiar la selección de comunidades en cualquier momento.',
      syncSuccessTitle: '¡Casi listo!',
      syncSuccessMessage:
        'Estamos sincronizando tu comunidad con tu Google Calendar. Solo tomará 2-3 minutos. Actualiza tu página de Google Calendar para ver las actualizaciones.',
      disconnectConfirmTitle: '¿Estás seguro?',
      disconnectConfirmDescription:
        'Si te desconectas, todos los eventos serán eliminados de tu calendario.',
      disconnectConfirm: {
        title: '¿Desconectar ahora?',
        message:
          'La desconexión eliminará todos los eventos de tu Google Calendar. No te preocupes, puedes volver a conectar Gumb para una nueva sincronización en cualquier momento.',
        cancelButton: 'Cancelar',
        okButton: 'Sí, Desconectar',
      },
      noCommunities:
        'No hay comunidades para seleccionar.\nEspera a que un administrador te añada.',
      checkboxWarning: 'ATENCIÓN: ¡La garrapata debe estar ajustada!',
    },
    community: {
      plusIconSubText: 'Planea un evento o establece series de eventos.',
      selectAllCommunityAdmins: 'Todos los administradores',
      selectAllGroupAdmins: 'Todos los jefes de grupo',
      createSuccess: 'La comunidad fué creada exitosamente',
      createFailure: 'No se pudo crear la comunidad',
      deleteSuccess: 'La comunidad fué borrada exitosamente',
      leaveSuccess: 'Has abandonado con éxito la comunidad',
      descriptionTitle: 'Sobre la comunidad',
      descriptionPlaceholder: 'Aquí se puede describir su comunidad',
      online: 'En linea',
      updateCommunity: 'Actualizar comunidad',
      members: 'Miembros',
      createCommunity: 'Crear la comunidad',
      communityName: 'Nombre de la comunidad',
      category: 'Categoría',
      aboutCommunity: 'Sobre la comunidad',
      selectLogo: 'Seleccionar logo',
      selectLogoTip: 'jpg, jpeg, png (5MB)',
      chooseCover: 'Seleccionar cover',
      chooseCoverTip: 'jpg, jpeg, png (20MB)',
      website: 'Sitio web',
      partnership: 'Colaboración',
      deleteCommunity: 'Borrar la comunidad',
      deleteHeader:
        '¿Estás seguro de que quieres eliminar a este usuario del grupo?',
      addMembers: 'Añadir miembros',
      invitationLink: 'Enlace de invitación',
      communityRole: 'Role de la comunidad',
      joinCommunity: 'Unirse a la comunidad',
      namePlaceholder: '{Equipo de {value}}',
      requestSent: 'Solicitud de afiliación enviada con éxito',
      requestSentButton: 'Solicitud de afiliación enviada',
      limitExceededHeader:
        'Ha alcanzado el límite de comunidades. Debe actualizar su plan de suscripción para crear más.',
      limitExceededMessage:
        'Por favor contacte el propietario de la comunidad. Los propietario(s) de su comunidad pueden comprobar los límites del plan de suscripción y actualizarlo.',
      limitExceededMessageOwner:
        'Por favor visite la página del plan de suscripción para comprobar los límites del plan de suscripción o actualizarlo.',
      updateFailure: 'No se pudo actualizar la comunidad',
      buttonAddEvent: 'Añadir evento',
      userBlockedTitle: 'Bloqueado',
      userBlockedText:
        'Parece que alguien te ha bloqueado en esta comunidad. Los administradores pueden bloquear o desbancar a los miembros.',
      createOverLimitTitle: 'Ha alcanzado el límite de comunidades',
      createOverLimitText:
        'Inicia tu prueba gratuita ahora o elige una suscripción en "Plan de cambio" para organizar tu propia comunidad.',
      notInvitedTitle: 'Sin permiso',
      notInvitedText:
        'Es posible que no tengas permiso para acceder a este evento. Los administradores y jefes de grupo pueden añadirte al evento.',
      noRequestsTitle: 'No hay solicitudes de revisión',
      noRequestsText:
        'Cuando la gente envíe solicitudes para su comunidad, se mostrarán aquí para que usted las revise.',
      invitationCodeExpired:
        'Este es un enlace de invitación caducado. Pero ahora puedes enviar una solicitud de adhesión, que los administradores pueden aceptar o rechazar.',
      communityNotFound: 'Comunidad no encontrada',
      communityPopup: {
        title: 'Crear Evento o Grupo',
        subTitleOne:
          'Planifica eventos en el PROGRAMADOR central. Usa grupos para dividir a los miembros en equipos.',
        createEvent: {
          infoText:
            "Programa eventos para todos o grupos/miembros específicos en el PROGRAMADOR. Visible en el programador, en 'Mis Eventos' y en los grupos.",
          btntitle: 'Crear Evento',
          linkText: 'Tutorial',
        },
        createGroup: {
          infoText:
            'Los grupos tienen sus propios planificadores, con eventos visibles solo para los miembros del grupo. Gestiona los roles de los miembros eficazmente',
          btntitle: 'Crear Grupo',
          linkText: 'aquí',
        },
        chatTitle: 'Opciones de chat',
        chatSubTitle: 'Inicia un chat 1:1 o crea una sala de chat para grupos.',
        oneToOne: {
          infoText:
            'Haz clic en el ícono de chat junto al nombre de un miembro o en su foto de perfil para enviarle un mensaje.',
          btntitle: 'Ver lista de miembros',
          linkText: 'Tutorial',
        },
        createChatGroup: {
          infoText:
            'Crea una sala de chat privada para miembros, equipos o grupos seleccionados. Solo los administradores pueden crear estas salas.',
          btntitle: 'Crear sala de chat',
          linkText: 'aquí',
        },
      },
    },
    communitySettings: {
      admin: 'Administrador',
      member: 'Miembro',
      blocked: 'Usuario bloqueado',
      owner: 'Propietario',
      group_lead: 'Lider de grupo',
      group_admin: 'Administrador de grupo',
      chat_admin: 'Admin de chat',
      community_admin: 'Administrador de la comunidad',
      editRoleSuccess: 'Role fué cambiado exitosamente changed',
      deleteUserSuccess:
        'El usuario ha sido eliminado con éxito de la comunidad',
      removeUser: {
        title: '¿Estás seguro de eliminar a este usuario?',
        message:
          'Cuando elimines a este usuario, será eliminado de tu comunidad, pero mantendrá su cuenta de usuario y podrá seguir participando en otras comunidades.',
        cancelButton: 'Cancelar',
        okButton: 'Sí, Eliminar',
      },
      removeUserSuccess: {
        title: 'Usuario eliminado',
        message: 'El usuario ha sido eliminado con éxito de tu comunidad.',
      },
      editSuccess: 'Los cambios se han actualizado con éxito',
      title: 'Configuraciones de la comunidad',
      backToSubscriptionsDetails: 'Volver a los detalles de la suscripción',
      tabs: {
        general: 'Preferencias generales',
        eventTypes: 'Tipos de evento',
        subscriptions: 'Suscripciones',
        responses: 'Opciones de respuesta',
        roles: 'Miembros',
        requests: 'Solicitudes',
        other: 'Otro',
        tasks: 'Tareas',
      },
      addMember: 'Añadir miembro',
      exportMembers: 'Exportar',
      exportMembersTooltip: 'Exportar la lista de miembros a un archivo CSV',
      deleteCommunityHeader: '¿Está seguro que quiere borrar esto?',
      defaultEventList: [
        'Reunión',
        'Entrenamiento',
        'Juego',
        'Ensayo',
        'Evento',
        'Reunión del consejo de administración',
      ],
      defaultResponsesTypes: [
        'Sí, voy a ir',
        'No, no voy',
        'Puede ser',
        'Neutral',
      ],
      defaultTasks: ['¿Quién conduce?', '¿Quién trae las bebidas?'],
      selectUsers: {
        title: 'Seleccionar Usuarios',
        message:
          'Marca a los usuarios con una casilla para aprobar o rechazar las solicitudes de membresía.',
      },
      memberExists: 'El miembro ya existe',
      mailSent: 'La invitación se envió correctamente',
      userNotFound:
        'No se encontró ningún perfil de Gumb. Para crear un nuevo perfil y enviar una invitación a este correo electrónico, proporcione información adicional',
      disableAddMemberTooltip:
        'El número de miembros ya ha alcanzado el límite. Para resolverlo, el propietario de la comunidad puede verificar una actualización de la suscripción para añadir más miembros.',
      notifyByFields:
        'Enviar una notificación cuando se actualicen los siguientes campos',
      notifyByReplies:
        'Enviar una notificación cuando los invitados hayan respondido con',
      remindBefore: 'Recordar antes',
      addReminder: '+ Añadir recordatorio (2 máx.)',
      notificationsTypes: [
        'Evento creado',
        'Evento actualizado',
        'Evento eliminado',
        'Recordatorio de evento',
      ],
      notificationsClasses: ['In-App', 'E-mail', 'Push'],
      notificationPopupHeader:
        '¿Desea enviar notificaciones a los usuarios invitados?',
      notificationPopupMessageLabel:
        'Añadir un mensaje opcional para los usuarios invitados',
      notificationPopupNotify: 'Enviar',
      notificationPopupDoNotNotify: 'No enviar',
      notificationPopupBack: 'Volver a la edición',
    },
    communityInvitation: {
      subjectFirst: 'Usted ha sido invitado a unirse a la comunidad',
      subjectSecond: '',
      body: 'Por favor, siga el siguiente enlace para unirse a la comunidad',
      invitationSuccess: 'El usuario es invitado a la comunidad',
      invitationFailure:
        'Se ha producido un error, puede que se haya agotado la suscripción',
      membersInvitation: {
        title: 'Invitar a los miembros',
        description:
          'Añade nuevos usuarios a tu comunidad para que se conviertan en miembros y tengan permiso para responder a tus eventos. Consulte la guía <0>"Añadir un nuevo usuario"</0>.',
        showMore: 'Mostrar más',
        showLess: 'Mostrar menos',
        codeInvitation: {
          title: 'La forma más rápida y sencilla',
          button: 'Copiar enlace de invitación',
          updateLink: 'Generar enlace ahora',
          generateLink: 'Generate link now',
          howItWorks: 'Cómo funciona',
          goodToKnow: 'Es bueno saberlo',
          howItWorksText:
            'Los miembros hacen clic en "Crear cuenta gratuita" utilizando el enlace seleccionado anteriormente. Cuando Gumb envía un correo electrónico, los miembros sólo tienen que confirmar su dirección de correo electrónico para obtener acceso directo.',
          goodToKnowText:
            'Esto es súper rápido, pero tenga en cuenta que una vez que la verificación de la cuenta esté completa, las personas con este enlace podrán acceder a sus eventos inmediatamente. Por lo tanto, puedes renovar el enlace anterior cuando quieras.',
        },
        linkInvitation: {
          title:
            'Más vale prevenir que curar: envíe la solicitud de afiliación con el enlace comunitario',
          button: 'Copiar el enlace de la comunidad',
          howItWorks: 'Cómo funciona',
          adminFlow: 'Los administradores aceptan o rechazan',
          howItWorksText:
            '<0>Los administradores copian y comparten este enlace de la comunidad.</0><0>Los miembros, con una cuenta de Gumb, hacen clic en "unirse a la comunidad".</0><0>Los miembros, sin cuenta, primero se registran en Gumb, y luego vuelven a hacer clic en el enlace para enviarle la solicitud.</0>',
          adminFlowText:
            'En la pestaña "Solicitudes", los administradores deben aprobar o denegar la solicitud de un miembro para unirse a su comunidad. Cuando haya solicitudes abiertas, los administradores verán una notificación en el equipo.',
        },
        addMember: {
          title: '2 opciones más: Eficiente o personal',
          button: 'AÑADIR USUARIO',
          selfSignUp: 'Los miembros se registran ellos mismos',
          preRegister: 'Pre-registrar Persona',
          selfSignUpText:
            'Dígale a todo el mundo que simplemente se registre en Gumb (de forma gratuita) y le envíe su correo electrónico registrado. A continuación, puede introducirlo aquí y el miembro acabará inmediatamente en su cuenta y verá automáticamente todas sus citas.',
          preRegisterText:
            'Registra nuevos miembros directamente a través de su dirección de correo electrónico. Esta acción les envía automáticamente una "Invitación por Correo Electrónico" con una contraseña temporal. Con ella, pueden iniciar sesión de inmediato y cambiar la contraseña a su gusto más tarde.',
        },
      },
    },
    group: {
      deleteEventSuccess: 'El evento se ha eliminado con éxito',
      createSuccess: 'Grupo fue creado exitosamente',
      deleteSuccess: 'Grupo fue borrado exitosamente',
      addMembersSuccess: 'Participantes fueron añadidos exitosamente',
      addMembersSuccessWithMessage: {
        title: 'Miembro añadido!',
        message:
          'Tu lista de miembros ha sido actualizada exitosamente. Gestiona roles y asignaciones a grupos. También puedes añadir o cambiar los detalles del usuario.',
      },
      deleteUserSuccess: 'Se ha eliminado con éxito el usuario del grupo',
      addParticipants: 'Añadir miembros',
      createGroup: 'Crear grupo',
      deleteGroup: 'Borrar grupo',
      groupName: 'Nombre de grupo',
      changeGroupNameSuccess: 'Nombre del grupo cambiado con éxito.',
      groups: 'Grupos',
      inviteMembers: 'Invitar miembros',
      tabs: {
        schedule: 'Planificar',
        members: 'Miembros',
        chat: 'Chat',
        documentation: 'Documentación',
        statistic: 'Exportación y estadísticas',
        import: 'Importación',
      },
      limitExceededHeader:
        'Ha alcanzado el límite de comunidades. Debe actualizar su plan de suscripción para crear más.',
      limitExceededMessage:
        'Por favor contacte el propietario de la comunidad. Los propietario(s) de su comunidad pueden comprobar los límites del plan de suscripción y actualizarlo.',
      limitExceededMessageOwner:
        'Por favor visite la página del plan de suscripción para comprobar los límites del plan de suscripción o actualizarlo.',
      groupRole: 'Role de grupo',
      addGroup: 'Añadir grupo',
    },
    chat: {
      read: {
        now: '{{name}} leído justo ahora',
        hours: '{{name}} leído hace {{value}}h',
        minutes: '{{name}} leído hace {{value}} min',
        minute: '{{name}} leído hace 1 min',
        day: '{{name}} leído el {{value}}',
        dateTime: '{{name}} leído el {{value}} a las {{time}}',
      },
      noRead: 'Aún no leído',
      lastAdmin:
        'Una sala de chat necesita al menos un administrador o un líder de grupo. Asegúrate de que al menos una persona tenga siempre este rol.',
      update: {
        success: 'La lista de miembros ha sido actualizada',
        successUpdate: 'El grupo de chat se ha actualizado correctamente',
        btn: 'GUARDAR',
      },
      modal: {
        btn: 'Gestionar',
        about: 'Info',
        editRoom: 'Editar sala de chat',
        delete: 'Eliminar sala de chat',
        editMember: 'Editar miembros',
      },
      details: {
        title: 'Detalles de sala',
        secondTitle: 'INFORMACIÓN',
        name: 'Nombre de la sala de chat',
      },
      delete: { success: 'La sala de chat ha sido eliminada' },
      create: {
        title: 'Crear sala de chat',
        name: 'Nombre de la sala de chat',
        description: 'Descripción del grupo (opcional)',
        success: 'Sala de chat creada',
        btnNext: 'SIGUIENTE',
      },
      new: 'Nuevo',
      chat: 'chat',
      inputPlaceHolder: 'Escribe un mensaje',
      searchHover: 'Buscar o iniciar un nuevo chat',
      op1: 'Archivos & Videos',
      op2: 'Fotos',
      files: 'Archivos',
      plusTooltip: 'Crear un nuevo chat o un chat grupal',
      emptyStatus: {
        noChatSelected: {
          firstLine: 'Selecciona una conversación para ver el chat,',
          secondLine: 'o haz clic aquí para iniciar un',
          LinkText: 'NUEVO CHAT',
        },
        noChatUser:
          '¿Aún no hay chats? Inicia un chat 1:1 o espera a que tu admin te añada.',
        noUnseenUser: "Don't have any unseen chat",
        sendFirstMessage: 'Escribe tu primer mensaje a {{recipient}}.',
      },
      infoPopup: {
        title: 'Límite excedido',
        message: 'Puedes subir un máximo de 20 archivos a la vez.',
      },
    },
    statistics: {
      tableType: 'Elegir un informe',
      eventReplies: 'Respuestas y tareas',
      eventTypes: 'Tipo de evento',
      appointmentSchedule: 'Calendario de citas',
      memberFigures: 'Control de asistencia',
      charts: {
        eventTypesByMonth: 'Número de citas por tipo',
        eventReplies: 'Respuestas a las opciones de respuesta',
        memberPresence: 'Número de reacciones por respuesta',
      },
      loadingStatistics: 'Cargando...',
      memberFiguresExportSuccess: {
        title: '¡El informe se está creando!',
        message:
          'Actualmente estamos creando tu informe de asistencia personalizado. Debido a la gran cantidad de datos, esto puede tardar unos minutos. Por razones de seguridad, el informe se enviará a tu dirección de correo electrónico. Solo revisa tu bandeja de entrada. ¡Gracias por tu paciencia!',
      },
    },
    filters: {
      clickToSelect: 'Haga clic aquí para filtrar',
      showFilters: 'Mostrar filtros',
      dateRange: 'Seleccione el intervalo de fechas',
      filterBy: 'Filtrar por',
      dateRanges: {
        yearToDate: 'Año transcurrido hasta la fecha',
        lastDays: 'Últimos {{number}} días',
        lastYear: 'Último año',
        yesterday: 'Ayer',
        today: 'Hoy',
        tomorrow: 'Mañana',
        nextDays: 'Próximos {{number}} días',
        thisWeek: 'Esta semana',
        thisMonth: 'Este mes',
        thisYear: 'Este año',
        allTime: 'Todo el tiempo',
        specificDateRange: 'Intervalo de fechas específico',
      },
      downloadXlsxOrCsv: 'Descárguelo aquí: Excel o CSV',
    },
    bulkUploadEvents: {
      toastText:
        '🆕  ¡La nueva función de importación de citas ahora está disponible para cada grupo!  🎉',
      moduleTitle: 'Importación',
      step1: 'Paso 1',
      step2: 'Paso 2',
      step3: 'Paso 3',
      step4: 'Paso 4',
      stepOne: {
        stepTitle: 'Preparar importación de datos',
        stepDescription: 'Descargar plantilla de Excel',
        componentTitle: 'Descargar plantilla de Excel',
        componentDescription:
          'Complete la plantilla con los datos de su evento o utilice su propio archivo de Excel.',
        p1: 'Complete la plantilla con los datos de su evento.',
        p2: 'Los encabezados de columna deben permanecer en inglés.',
        p3: 'Los ejemplos en cada columna le muestran el formato correcto.',
        p4: 'Los nuevos tipos de eventos se agregan automáticamente.',
        p5: '<b>Importante</b>: Si utiliza su propio archivo, los siguientes títulos de columna deben copiarse exactamente como se muestran en inglés (distingue entre mayúsculas y minúsculas):',
        p6: 'Los ejemplos en cada columna de la plantilla le muestran el formato correcto.',
        p7: 'Los nuevos tipos de eventos se agregan automáticamente.',
        eventTypes: 'IR A TIPOS DE EVENTOS',
        template: 'DESCARGAR PLANTILLA',
        nextStepButton: 'CONTINUAR',
        copy: 'Copiar todo',
      },
      stepTwo: {
        stepTitle: 'CARGAR ARCHIVO',
        stepDescription: 'CARGAR ARCHIVO CSV',
        componentTitle: 'CARGAR ARCHIVO CSV',
        componentDescription:
          'Guarda el archivo de Excel como <b><u>CSV</u></b> y súbelo aquí.',
        p1: 'Comprobaremos el formato y te mostraremos una vista previa.',
        uploadButton: 'Elegir archivo',
        maximumFileSize: 'Tamaño máximo del archivo: 1 MB',
      },
      stepThree: {
        stepTitle: 'Confirmar datos',
        stepDescription: 'Vista previa de tu primer evento.',
        componentTitle: 'Confirmar datos',
        componentDescription:
          'Revisa el ejemplo y ajusta los miembros activados.\nLos miembros seleccionados se aplican a todos los eventos.',
        nextStepButton: 'CONFIRMAR Y SUBIR',
      },
      stepFour: {
        stepTitle: '¡Carga exitosa!',
        stepDescription:
          'La carga de eventos fue exitosa. Consúltalos en el calendario.',
        componentTitle: '¡Carga exitosa!',
        componentDescription: 'Se han importado <b>{{count}}</b> eventos.',
        secondTitle: 'Bueno saber',
        secondSubTitle:
          'Puede deshacer la importación o cargar una nueva lista.',
        goToScheduler: 'IR AL CALENDARIO',
        undoImport: 'DESHACER IMPORTACIÓN',
        uploadNewList: 'SUBIR NUEVA LISTA',
      },
      errors: {
        eventNameErrorTitle: 'Error de nombre del evento',
        eventNameErrorMessage:
          'Falta el nombre del evento en la fila <b><u>{{row}}</u></b>.',
        eventTypeErrorTitle: 'Error de tipo de evento',
        eventTypeErrorMessage:
          'Falta el tipo de evento en la fila <b><u>{{row}}</u></b>.',
        timeErrorTitle: 'Error de hora',
        timeErrorMessage:
          'Falta la hora de inicio o finalización en la fila <b><u>{{row}}</u></b>.',
        dateErrorTitle: 'Error de fecha',
        dateErrorMessage: 'Falta la fecha en la fila <b><u>{{row}}</u></b>.',
        locationErrorTitle: 'Error de ubicación',
        locationErrorMessage:
          'Falta la ubicación del evento en la fila <b><u>{{row}}</u></b>.',
        invalidDateOrTimeTitle: 'Fecha u hora inválida',
        invalidDateOrTimeMessage:
          'Por favor, revise el formato de fecha y hora en la fila <b><u>{{row}}</u></b>. (ej., usa HH:MM para la hora)',
      },
      deletePopupTitle: '¿Eliminar lista de eventos?',
      deletePopupMessage:
        'Esta acción eliminará permanentemente su última lista de eventos cargada. ¿Está seguro?',
      deleteSuccessTitle: '¡Eliminación exitosa!',
      deleteSuccessMessage:
        'Su última lista de eventos cargada se eliminó correctamente.',
      successMessage:
        'Validación exitosa. Continúe al siguiente paso para ver una vista previa.',
      restartProcessTitle: 'Cargar nueva lista de eventos',
      restartProcessMessage:
        'Puedes cargar una nueva lista de eventos en cualquier momento. Ten en cuenta que los eventos cargados previamente seguirán siendo visibles en el calendario. Si deseas reemplazar la lista anterior, elimínala primero o deshaz la última importación.',
      restartProcessOkButton: 'CARGAR NUEVA LISTA',
      exportDataSample: {
        event_name: 'Zurich Street Parade',
        event_type_name: 'Actuación',
        event_location: 'Bahnhofstrasse 5, 8001 Zürich',
        event_description:
          'Festival al aire libre con bandas y DJs internacionales',
      },
    },
    event: {
      event: 'Evento',
      createdAt: 'Fecha de creación',
      editEvent: 'Editar evento',
      createEvent: 'Crear evento',
      updateEvent: 'Actualizar evento',
      eventName: 'Nombre de evento',
      eventType: 'Típo de evento',
      eventDetails: 'Detalles de evento',
      eventTypeDeleted: 'Tipos de eventos eliminados',
      eventOrganizer: 'Organizador de evento',
      eventOrganizerShort: 'Organizador',
      location: 'Ubicación',
      eventTypeEdit: 'Editar el típo de evento',
      eventTypeName: 'Nombre del tipo de evento',
      eventTypeCreate: 'Crear tipo de evento',
      responseRequired: 'Respuesta requerida',
      responseName: 'Nombre de respuesta',
      responsesEdit: 'Editar respuesta',
      responsesCreate: 'Crear respuesta',
      repeatAnEvent: 'Repetir un evento',
      repeatWithInterval: 'Repetir a un intervalo',
      allDay: 'Tdo el día',
      registrationDeadline: 'Deadline de registración',
      commentRequired: 'Commentario requerido',
      editTask: 'Editar tarea',
      createTask: 'Crear tarea',
      eventTaskName: 'Nombre de tarea',
      icon: 'Ícono',
      isMemberComing: '¿Tu respuesta?',
      recurringEvent: 'Recurring event',
      allEvents: 'All events',
      thisEventOnly: 'This event only',
      createEventSuccess: 'Event was successfully created',
      updateEventSuccess: 'Event was successfully updated',
      createEventReplySuccess: 'Respuesta guardada con éxito',
      updateEventReplySuccess: 'Respuesta actualizada con éxito',
      addGuests: '¿Invitados adicionales?',
      eventOperations: {
        eventOnly: 'Solo este evento',
        eventAndFollowUp: 'Este evento y los posteriores',
        all: 'Todos los eventos',
        deleteText: 'Eliminar evento periódico',
        editText: 'Editar evento periódico',
        editReplyText: 'Su respuesta se aplica a',
      },
      dfRes: 'Establecer respuesta predeterminada',
      responseToast:
        'Spanish: ¡Cuidado! Si eliminas la respuesta predeterminada sin guardar una nueva, se borrarán todas las reacciones y comentarios anteriores.',
      setPreferences: 'Establecer preferencias',
      setEventDefaults: 'Establecer valores predeterminados',
      preferencesText:
        'Define las preferencias predeterminadas para los nuevos eventos de este tipo, incluida la respuesta predeterminada para los miembros',
      responseSubTitle: 'Solicitar comentario (opcional)',
      requestComment:
        'Activa esta opción para solicitar al usuario un comentario cuando seleccione esta respuesta (por ejemplo, para "No, no puedo ir" para preguntar el motivo)',
      registeredUsers: 'Respuestas',
      guests: 'Invitados',
      nomination: 'Nominación',
      nominated: 'Estás nominado(a)',
      havePenalty: 'Te han marcado un penalti',
      penalty: 'Penalización',
      invitees: 'Invitados',
      awaiting: 'A la espera de',
      minutes: 'Minutos',
      hours: 'horas',
      days: 'dias',
      weeks: 'semanas',
      weekDay: 'Entre semana',
      duration: 'Duración',
      months: 'meses',
      years: 'años',
      viewAppointment: 'Ver Evento',
      myResponse: 'Mi Respuesta',
      noEvents:
        '¿Todo tranquilo? Tal vez hayas respondido a todas las citas, no estés activado o no hay ninguna programada. Los administradores y líderes de equipo están a cargo.',
      shareEvent:
        'Compartir evento — haga clic en este icono para copiar este evento y enviarlo a cualquier lugar donde pueda pegar textos (correo electrónico, WhatsApp, etc.)',
      shareEventOption: 'Compartir el enlace de la cita',
      exportPDFOption: 'Exportar como PDF',
      deadlineExpired:
        'El plazo de inscripción de este evento parece haber expirado, o el evento ya ha pasado. ',
      totalInvitees: 'Total de invitados',
      responsesReceived: 'Respuestas recibidas',
      additionalGuests: 'Invitados adicionales',
      noPermissionToGiveReply: 'Usted no tiene derecho a cambiar esto',
      responsesPercentage: 'Porcentaje de respuestas',
      acceptedTasks: 'Tareas aceptadas',
      acceptedTasksByUser: 'Tareas por usuario',
      givenComments: 'Comentarios recibidos',
      givenCommentsByUser: 'Comentarios del usuario',
      optionalMessage: 'Haga clic aquí para ver las noticias.',
    },
    task: {
      createTask: 'Create task',
      taskName: 'Task name',
      noTasks: 'No tasks',
    },
    groupSettings: {
      title: 'Configuraciones del grupo',
      tabs: {
        general: 'Preferencias generales',
        eventTypes: 'Típos de evento',
        responses: 'Respuestas',
        membersManagement: 'Administración de miembros',
        other: 'Otro',
      },
      deleteGroupHeader: '¿Está seguro que quiere borrar esto?',
    },
    documentation: {
      title: 'Documentación',
      generalDocumentation: 'Documentación general',
      uploadedFiles: 'Archivos subidos',
      chooseFile: 'Seleccionar archivo',
      tipMessage: 'Tamaño máximo de archivo subido: 50 MB',
      overview: '{{value}} GB de {{capacity}} GB usado(s)',
      uploadSuccess: 'Archivo fué subido exitosamente',
      deleteSuccess: 'Archivo fué borrado exitosamente',
      attachFiles: 'Adjuntar archivos',
      limitExceededHeader:
        'Ha llegado al límite del espacio de almacenamiento disponible para la documentación.',
      sort: {
        label: 'Ordenar por',
        nameAsc: 'Nombre (A-Z)',
        nameDesc: 'Nombre (Z-A)',
        dateOldest: 'Fecha (Más antiguo)',
        dateNewest: 'Fecha (Más reciente)',
      },
    },
    subscriptions: {
      title: 'Suscripciones',
      subscription: 'Suscripcion',
      yearly: 'Al año',
      adBlocker: {
        header: 'Desactiva el Ad-Blocker para Precios Locales',
        body: "Desactiva tu ad blocker para ver precios correctos. Los pasos varían según el navegador y el bloqueador: Normalmente, haz clic en el icono del bloqueador cerca de la barra de URL y elige 'Desactivar'. Recarga la página. Para otros pasos, consulta la ayuda de tu bloqueador o navegador. ¡Gracias.",
      },
      monthly: 'Al mes',
      freeTitle: 'Prueba gratuita de 2 meses',
      freeDescription: 'Inicia tu prueba gratuita de 2 meses ahora',
      freeText1: 'Abrir hasta 2 comunidades',
      freeText2: 'Acceso completo a todas las funciones',
      freeText3:
        'No se requiere tarjeta de crédito: regístrate y comienza de inmediato',
      freeText4: 'Sin renovación automática',
      freeText5: 'Sin anuncios',
      subscribe: 'SUSCRIBA',
      seeDetails: 'VEA DETALLES',
      buildCustom: 'Custom',
      month: 'mes',
      year: 'año',
      community: 'Centro de la Comunidad',
      communities: 'Centros de Comunidad',
      scheduler: 'Planificador Central de Eventos 1',
      schedulers: 'Cada uno con 1 Planificador Central de Eventos',
      group: 'Planificadores de Grupo para Equipos/Departamentos',
      capacity: 'Capacidad de Miembros',
      maxStorage: 'GB almacenamiento',
      classic: 'Clásico',
      classicPlus: 'Clásico+',
      popular: 'POPULAR',
      textInnerTop:
        'Elija la suscripción de Gumb que se ajuste a su comunidad. Con este plan, puede dividir su equipo (o su comunidad) en hasta {{groups}} grupos y administrar hasta {{members}} miembros. Puede actualizar o cancelar la suscripción cuando lo desee. El mes de prueba gratis se activa automáticamente al registrarse exitosamente en la App Gumb sin tener que depositar su información para el pago. Una vez expirada la prueba gratis, puede seleccionar uno de los planes de suscripción para seguir usando Gumb, elijiendo su método de pago preferido.',
      textInnerBottom:
        'Cuando adquieres una suscripción, firmas un contrato indefinido. La ventaja: Su suscripción se renueva automáticamente una vez transcurrido el periodo de validez de un año/mes (se lo recordaremos por correo electrónico). Puede pagar la suscripción con tarjeta de crédito (MasterCard y Visa) o PayPal.',
      chatsExcluded: 'Chats excluidos',
      current: 'ACTUAL',
      pay: 'PAGAR',
      usingBlocker:
        'Desactive el bloqueador de anuncios y vuelva a cargar la página',
      failedToSubscribe: 'No se pudo suscribir',
      usedGroups: 'Grupos usados',
      usedMembers: 'Miembros usados',
      usedCommunity: 'Comunidades usados',
      usedStorage: 'Almacenamiento usado',
      orderSummary: 'Resumen de pedido',
      tax: 'IMPUESTO',
      total: 'Total',
      periodEnds: 'Período pagado se expira',
      cancelSubscription: 'CANCELAR MI SUSCRIPCIÓN',
      paypalLink: 'HISTORIA DE FACTURACIÓN',
      chatEnabled: 'Chat incluido',
      chatExcluded: 'Chat excluido',
      paymentApproved: 'Pago aprobado',
      paymentError: 'Error en el pago',
      limitExceededHeader: 'Ha alcanzado el límite de miembros',
      limitExceededMessage:
        'Por favor contacte el propietario de la comunidad. Los propietario(s) de su comunidad pueden comprobar los límites del plan de suscripción y actualizarlo. ',
      limitExceededMessageOwner:
        'Por favor visite la página del plan de suscripción para comprobar los límites del plan de suscripción o actualizarlo.',
      inactiveSubscriptionHeader: 'Aprobación del pago pendiente',
      inactiveSubscriptionMessage:
        'Lo siento, no puede continuar con esta acción. Es posible que primero deba completar el proceso de pago.',
      subscriptionNotPaid:
        'Su suscripción ha caducado o la suscripción no está pagada.',
      contactAdmin: 'Por favor, contacte con la Administración.',
      free: 'Cuenta gratuita',
      requireSubscription:
        'Gumb es gratis para miembros de comunidades activas. Crea tu propia comunidad y comienza tu prueba gratuita ahora o cambia tu plan en "Cambiar plan".',
      requireSubscriptionMember:
        'No se ha detectado ninguna suscripción válida. Aquí no tiene que hacer nada. El propietario de la comunidad, por otro lado, puede comprobar en su perfil, en "Suscripción", si la licencia para esta comunidad está activada o ampliada.',
      cancelConfirmation: '¿Está seguro de que quiere cancelar su suscripción?',
      unsubscribe: 'Cancelar',
      cancelled:
        'Cancelación confirmada. Se acaba de enviar una confirmación de cancelación a su correo electrónico. Seguirá teniendo acceso a todas las funciones hasta la fecha de liquidación pagada.',
      renewSubscriptionText:
        'Gracias.\n\nSu suscripción anterior se renovará, si la activa ahora',
      activate: 'Activar ahora',
      activated:
        'Su suscripción ha sido activada con éxito. Nos alegramos mucho de que ahora organice y planifique con Gumb. Se acaba de enviar una confirmación de su afiliación a su correo electrónico. Su suscripción es ahora válida y puede acceder a todas las funciones.',
      approved:
        'Se está preparando la suscripción. Un momento, por favor... Esto puede tardar hasta 45 segundos. Hasta entonces, no recargue la página ni cierre la ventana del navegador.',
      trialActivated:
        'Tu período de prueba ha comenzado. Tú y tus administradores pueden comenzar a planificar, invitar miembros y gestionar la configuración.',
      cancelledTitle: 'Cancelado',
      startTrial: 'INICIA TU PRUEBA GRATUITA',
      startTrialHeader: '¡Pruébalo largoooo!',
      startTrialText:
        'Para permitir que tu comunidad pruebe a fondo todas las funciones en una operación real, ¡ofrecemos 2 meses completos de prueba! No se requiere información de pago y no hay costos ocultos: simplemente prueba todas las funciones con todos los miembros sin preocupaciones.',
      subscriptionExpired:
        'Esta comunidad no tiene actualmente una suscripción activa. Para añadir nuevos usuarios, es necesario renovar la suscripción. Para preguntas: support@gumb.app',
      startTrialPopupButton: 'Probar Gratis',
      paymentMethods: 'Tarjeta de crédito o Paypal',
      subscriptionsPopup: {
        title: 'Vuestra suscripción ha caducado',
        subtitle:
          'Vuestra suscripción ha caducado, pero vuestro propietario de la comunidad, <b>{{ownerName}}</b> puede cambiarlo fácilmente. <brPure />Cada comunidad tiene un propietario que es responsable de la suscripción. 👑',
        firstBox: {
          title:
            '<b>{{ownerName}}</b> <brPure />¿Quieres renovar vuestra suscripción y volver a dar vida a la planificación de vuestro equipo?',
          btnText: 'Renovar',
        },
        secondBox: {
          title:
            '<b>Para miembros</b> <brPure />¿Quieres hacerte cargo de la suscripción y convertirte tú mismo en el propietario de la comunidad? (Inicia tu propia suscripción, contáctanos después y te transferiremos la propiedad).',
          btnText: 'Hacerse propietario',
        },
      },
    },
    terms: {
      title: 'Términos & Condiciones de Gumb',
    },
    errors: {
      cantSendMessageToYourself: 'No puedes enviarte un mensaje a ti mismo.',
      downloadFail: 'Error de descarga, inténtalo de nuevo.',
      required: 'Campo requerido',
      email: 'Por favor introducza una dirección de correo electrónico válida',
      invalidEmail:
        '¡Vaya! Por favor, introduce un correo electrónico válido. Revisa si hay errores y vuelve a intentarlo.',
      integer: 'Debe ser un número entero',
      confirmPassword: 'Las contraseñas no coinciden. Inténtelo de nuevo',
      NotAuthorizedException: 'Nombre de usuario o contraseña incorrecta',
      invalidCode: 'Ese código no es válido. Inténtalo de nuevo.',
      errorToast: 'Error: {{message}}',
      invalidRequest: 'Solicitud no válida',
      passwordChanged: 'Contraseña cambiada',
      password:
        'La contraseña debe contener al menos 8 caracteres, letras en mayúsculas y minúsculas, dígitos',
      termsError: 'Por favor acepte los términos y condiciones',
      min: '{{Value}} caracteres como mínimo',
      max: '{{Value}} caracteres como máximo',
      startDate: 'La fecha no puede ser anterior al {{Value}}',
      endDate: 'La fecha no puede ser anterior a la fecha de inicio',
      fileSize: 'El tamaño del archivo es demasiado grande',
      fileSizeCustom:
        'Archivo demasiado grande: El tamaño máximo es de {{size}} MB.',
      customFileSize:
        'Archivo demasiado grande: Max {{size}} GB. Elíminalo e inténtalo de nuevo.',
      unsupportedFormat: 'Formato de archivo no soportado',
      postalCode: '4 dígitos como mínimo',
      sorry: 'Lo siento',
      text404: 'Parece que la página que está buscando no está disponible :(',
      url: 'Debe ser una URL válida.',
      text500: 'Algo salió mal :(',
      onlyForGroupAndAdmin:
        'Esta función solo está disponible para administradores y líderes de grupo',
      onlyForAdmin: 'Solo los administradores pueden usar esta función.',
    },
    eventTypes: {
      createSuccess: 'Típo de evento fue creado exitosaménte',
      editSuccess: 'Los cambios se han actualizado con éxito',
      deleteSuccess: 'Típo de evento fue borrado exitosaménte',
    },
    eventTasks: {
      createSuccess: 'Tarea de evento fue creado exitosaménte',
      editSuccess: 'Los cambios se han actualizado con éxito',
      deleteSuccess: 'Tarea de evento fue borrado exitosaménte',
    },
    eventResponses: {
      createSuccess: 'Respuesta de evento fue creado exitosaménte',
      editSuccess: 'Los cambios se han actualizado con éxito',
      deleteSuccess: 'Respuesta de evento fue borrado exitosaménte',
    },
    tips: {
      eventType:
        'El administrador de la comunidad puede añadir nuevos tipos de eventos en la página de las configuraciones de la comunidad',
    },
    frequencies: {
      doNotRepeat: 'No se repite',
      daily: 'Cada día',
      weekly: 'Cada semana',
      monthly: 'Cada mes',
      yearly: 'Anualmente',
      everyWorkingDay: 'Todos los días laborables (de lunes a viernes)',
      other: 'Personalizar…',
    },
  },
  it: {
    appWidget: 'Installare Gumb App sul proprio dispositivo',
    featurePopups: {
      appointmentSchedule: {
        innerHtml:
          "<h3>New feature: Download your appointment schedule as an Excel or CSV file. </h3><p>We have completely redesigned the statistics page, and you can export your entire schedule now. The new report called 'Appointmentschedule' gives you an overview of facts and figures of your events.</p><p>We are already working on another report called 'Member figures' which will be related to user performance. </p><p>The diary report is now available in the web app for all users.</p>",
        buttonOk: 'LATER',
        buttonRedirect: 'SEE NOW',
      },
      preferecePopup: {
        title: '2 New Features',
        first: {
          title: 'Set All to "Going":',
          subtitle:
            'Set the default response for new or existing events – for all participants at once!',
          link: '',
        },
        second: {
          title: 'Default Event Settings:',
          subtitle: 'Define how new events should look.',
          link: 'Instructions',
        },
      },
      appleCalendar: {
        title: 'COLLEGA IL TUO CALENDARIO (iCal)&nbsp;🍏',
        message:
          'Abbiamo fatto centro! Dopo tanto impegno, Gumb ora consente la connessione diretta del calendario nella versione WEB per una sincronizzazione senza soluzione di continuità. Resta sintonizzato per il rilascio della versione per iOS/Smartphone, disponibile&nbsp;a&nbsp;breve!',
        extraMessage: 'Apri la guida passo dopo passo',
        redirectText: 'qui',
        okButton: 'Collega ora',
        cancelButton: 'Non ora',
      },
      calendar: {
        innerhtml:
          "<h3>Ce l'abbiamo fatta!</h3> <p> È arrivata la connessione a GOOGLE CALENDAR! </p> <br/> <p> Eventi da Gumb - Ogni comunità selezionabile individualmente può ora essere trasferita automaticamente al vostro calendario. </p> <div class='images-container'> <img src='https://gumb-cdn-prod.s3.eu-central-1.amazonaws.com/featurePopups/calendar-1.png' alt='calendar-1' /> <img src='https://gumb-cdn-prod.s3.eu-central-1.amazonaws.com/featurePopups/calendar-2.png' alt='calendar-2' /></div> <p> Tutto ciò che occorre fare è spiegato in questa guida: <br/><a target='_blank' rel='noopener noreferrer' href='https://gumb.app/en/support/connect-gumb-with-google-calender/'>→&nbsp; Leggere le istruzioni complete </a> </p> <p>Guida rapida:</p> <ol> <li>Connessione e importante: selezionare la casella!</li> <li>Selezionare la comunità</li> <li>Attendere qualche minuto e ricaricare la pagina</li> </ol> <p> Collegate il vostro calendario Google per risparmiare tempo e sfruttare al meglio ogni giorno. </p>",
        buttonOk: 'NIENTE PER ME',
        buttonRedirect: 'CONNECT NOW',
      },
      notifications: {
        title: 'Come devono essere notificati i membri??',
        firstRow:
          'Abbiamo aggiunto: Impostazioni dei messaggi e delle notifiche push per cellulari.',
        secondRow:
          "Le impostazioni di notifica che gli amministratori gestiscono possono ora essere personalizzate per l'intera comunità:",
        thirdRow: 'Tutto quello che dovete fare è:',
        listFirstRow: 'Vai a Impostazioni della comunità → Notifiche.',
        listSecondRow:
          'Attivare/disattivare le opzioni di ogni categoria → per personalizzare tutto perfettamente per te. (→ Leggi le istruzioni <0>qui</0>)',
        lastRow:
          'Dì ai tuoi membri di abilitare le impostazioni push o email nel loro profilo Gumb ora.',
        buttonLater: 'Più tardi',
        buttonSettings: 'Vai alle impostazioni ora',
      },
      pushNotifications: {
        title: '📢 Comunicazione di livello superiore: notifiche push',
        message:
          "Gli amministratori e i capigruppo possono ora creare notifiche push per inviare informazioni importanti direttamente sul tuo smartphone.\n\nCosa puoi fare con le notifiche push:\n\n• <b>Condividere informazioni importanti all'istante</b> (ad es. cambiamenti di programma, emergenze)\n• <b>Raggiungere singole persone o interi team</b> (comunicazione personalizzata)\n• <b>Salvare i messaggi come modelli per risparmiare tempo</b> (comunicazione efficiente)",
        extraMessage:
          'Rimani sempre aggiornato e non perderti più nessuna novità!',
        cancelButton: 'Vedi dopo',
        okButton: 'Prova ora',
        newMessageOne:
          'Gli amministratori e i capigruppo possono ora comunicare direttamente con singole persone o interi team per inviare informazioni importanti direttamente sul tuo smartphone.\n\n',
        redirectLink: 'Cree notificaciones push personalizadas',
        newMessageTwo:
          ', seleziona i destinatari e salva anche i tuoi messaggi come modelli per un utilizzo successivo.',
      },
      importEvent: {
        title: 'Upload/Import Events ',
        subText:
          "No more manual entry! Easily import your events with a CSV or Excel file. Whether it's 30 or 300 events - it's quick and can even be undone if there are mistakes.",
        cancelButton: 'View later',
        okButton: 'Try now',
        secondText: 'Find step-by-step instructions',
      },
      mail: {
        title: 'Nuova funzione: MAIL MODE',
        firstRow:
          'La funzione e-mail integrata semplifica e velocizza la comunicazione del team. Avete urgente bisogno di inviare un\'e-mail a tutti? Ma solo ai membri che hanno risposto "Sì" alla nomina? → Ora è possibile con pochi clic!',
        secondRow:
          'La modalità mail è ora disponibile nella web app per gli amministratori e i capigruppo. Sarà ulteriormente ampliato nel prossimo periodo.',
        thirdRow:
          'Per saperne di più: <0>https://gumb.app/support/mailmodus/</0>',
        buttonLater: 'VISUALIZZA PIÙ TARDI',
        buttonMail: 'ORA',
      },
      eventPdf: {
        title: 'Nuova funzione: Scaricare la nomina come PDF',
        firstRow:
          "Risparmia tempo ora e stampa i dettagli dell'evento con un solo clic!",
        secondRow:
          "Poco prima dei prossimi eventi, o anche per gli eventi passati, tutti i dettagli dell'evento, comprese tutte le statistiche di risposta, possono essere esportati in PDF.",
        thirdRow:
          'Prerequisito pianificatore di appuntamenti: hai i diritti di amministratore',
        fourthRow:
          'Prerequisito del pianificatore del gruppo: sei un capogruppo',
        fifthRow: 'La funzione è ora disponibile nella web app.',
      },
    },
    menu: {
      homePage: 'HOME',
      subscriptions: 'Abbonamenti',
      groupScheduler: 'CALENDARIO',
      documentation: 'FILE',
      chats: 'Chat',
    },
    dayOfWeek: {
      day1: 'LUN',
      day2: 'MAR',
      day3: 'MER',
      day4: 'GIO',
      day5: 'VEN',
      day6: 'SAB',
      day0: 'DOM',
    },
    email: {
      sender: 'Mittente',
      recipients: 'Destinatari',
      otherRecipients: 'Altri destinatari (separare con la virgola)',
      subject: 'Oggetto',
      message: 'Messaggio',
      attachCommunityDocs: 'Allegare documenti della comunità',
      plusTooltip: 'Scrivi una nuova email',
      emailSentSuccessfuly:
        'L\'e-mail è stata inviata con successo. Si prega di notare: Questa era una "No Reply Email" - un\'email alla quale il destinatario non può rispondere.',
      sendCopy: 'Inviami una copia',
      maxAttachmentsSize:
        'Se aggiungi diversi allegati, non devono superare i 15 MB in totale.',
      changeEmail: {
        title: 'Email di conferma inviata',
        message:
          "Abbiamo appena inviato un'email di conferma con un link per il tuo nuovo indirizzo email. Per favore, apri la tua casella di posta elettronica e fai clic immediatamente sul link per confermare le tue modifiche. Se non ricevi l'email entro pochi minuti, controlla la tua cartella spam. Se hai domande o problemi, il nostro servizio clienti è a tua disposizione.",
      },
    },
    pushNotification: {
      header: 'Notifiche Push',
      filterByRecipient: 'Seleziona destinatari',
      filterByResponse: 'Seleziona destinatari per risposta',
      pushMessage: 'Crea push',
      saveAsTemplate: 'Salva come modello',
      sendCopy: 'Invia copia anche a me',
      pasteUrl: '🌐 Evento o link esterno?',
      plusTooltip: 'Crea una nuova notifica push',
      toasterText:
        '🆕 Nuova funzione: Ora puoi aggiungere un link esterno, come qualsiasi sito web 🌐 (URL), alle notifiche push!\nInvia i destinatari direttamente al sito web.',
      templates: 'Modelli',
      title: 'Titolo',
      lastUsed: 'Ultimo utilizzo',
      noTemplates: 'I modelli salvati vengono visualizzati qui',
      template: 'Modello',
      sendConfirmHeader: 'Verifica prima di inviare',
      totalRecipients: 'DESTINATARI',
      redirectLink: 'AL LINK',
      successTitle: 'Notifica inviata!',
      successMessage:
        'Il tuo messaggio è stato inviato a <b>{{totalRecipients}}</b> destinatari.',
      selectEventFirst: 'Seleziona prima un evento',
      templateLimitTitle: 'Limite modelli raggiunto',
      templateLimitInfo:
        'Salva fino a 8 dei tuoi messaggi come modelli da riutilizzare in seguito. Abbiamo già aggiunto 3 modelli per ispirarti: sentiti libero di modificarli o eliminarli.',
      templateLimitMessage:
        'Hai raggiunto il numero massimo di modelli. Elimina un modello per crearne uno nuovo.',
      urlInfo:
        "Puoi aggiungere qui link a qualsiasi sito web (tranne Gumb). Potrebbe essere un link a una pagina di prodotto, un articolo di notizie o qualsiasi altra cosa che trovi rilevante. Basta incollare l'URL completo (incluso https://).",
      urlInfoOne:
        "Puoi inserire qui qualsiasi sito web esterno (URL) per indirizzare direttamente i tuoi destinatari. Se selezioni un evento sopra, l'app aggiungerà il link dell'evento e, cliccando sulla notifica push, l'evento si aprirà direttamente in Gumb.",
      urlInfoTwo:
        'Prossimamente: aggiungi qualsiasi link esterno (tranne Gumb) alle tue notifiche push! Link a siti web, notizie e altro ancora. Resta&nbsp;sintonizzato!',
      urlTooltip:
        'Puoi creare una notifica push con o senza collegamento a un evento. Vuoi condividere il link del tuo evento? Seleziona semplicemente un evento sopra per copiarlo automaticamente qui.',
    },
    common: {
      onlyAdmin: 'Solo gli admin possono utilizzare questa funzione.',
      download: 'Scarica',
      attachFile: 'Allega file',
      new: 'Nuovo',
      unseen: 'Non visti',
      more: 'Più',
      less: 'Meno',
      sunday: 'Domenica',
      monday: 'Lunedì',
      tuesday: 'Martedì',
      wednesday: 'Mercoledì',
      thursday: 'Giovedì',
      friday: 'Venerdì',
      saturday: 'Sabato',
      january: 'Gennaio',
      february: 'Febbraio',
      march: 'Marzo',
      april: 'Aprile',
      may: 'Maggio',
      june: 'Giugno',
      july: 'Luglio',
      august: 'Agosto',
      september: 'Settembre',
      october: 'Ottobre',
      november: 'Novembre',
      december: 'Dicembre',
      overallScheduler: 'Pianificatore generale',
      selectEvent: 'Seleziona evento (opzionale)',
      displayEventsFrom: 'Visualizza gli eventi da',
      modalExit: 'Sei sicuro di voler uscire senza salvare i tuoi dati?',
      all: 'Tutti',
      tooltipEdit: 'Modifica membro',
      tooltipDelete: 'Rimuovi membro',
      overview: 'Panoramica',
      cancel: 'Annulla',
      removeLogo: 'Sei sicuro di voler eliminare questo elemento?',
      noTasks: 'Nessun compito trovato',
      noUsers: 'Nessun utente trovato',
      role: 'Ruoli',
      admin: 'Amministratori',
      member: 'Membri',
      group_lead: 'Leader gruppi',
      email: 'Email',
      groups: 'Gruppi',
      logoutHeader: 'Disconnessione?',
      logoutMessage: 'Verrai reindirizzato alla schermata di accesso.',
      deleteHeader: "Sei sicuro di voler cancellare l'utente?",
      logout: 'Disconnetti',
      delete: 'Eliminare',
      tablePlaceholder: 'Nessun membro da mostrare',
      contentPlaceholder: 'Nessun contenuto da mostrare',
      create: 'Creare',
      copy: 'Copiare',
      visible: 'Visibile',
      saveChanges: 'Cambi salvati',
      newField: 'Nuovo campo',
      fieldName: 'Nome del campo',
      addField: 'Aggiungere campo',
      notifications: 'Notifiche',
      participants: 'Partecipanti',
      tasks: 'Compiti',
      documentation: 'Documentazione',
      description: 'Descrizione',
      facebook: 'Facebook',
      twitter: 'Twitter',
      linkedin: 'LinkedIn',
      instagram: 'Instagram',
      youtube: 'Youtube',
      startDate: "Data d'inizio",
      startTime: "Ora d'inizio",
      startDateTime: 'Data/ora di inizio',
      endDateTime: 'Data/ora di fine',
      endDate: 'Data di fine',
      endTime: 'Ora di fine',
      icon: 'Icona',
      sendMessage: 'Invia messaggio',
      aboutMe: 'Su di me',
      selectAll: 'Selezionare tutto',
      approve: 'Approvare',
      reject: 'Rifiutare',
      backToGumb: 'Tornare a Gumb',
      edit: 'Modificare',
      addComment: 'Aggiungere commento',
      answer: 'Salva',
      ok: 'Ok',
      withoutGroup: 'Senza gruppo',
      copyLink: 'Copia link',
      copied: 'Copiato',
      sendInviteLink: 'Invia link di invito',
      apply: 'Applica',
      from: 'Da',
      to: 'A',
      enable: 'Abilita',
      search: 'Cerca',
      color: 'Colore',
      columns: 'Colonne sbiadite',
      hiddenColumns: 'Colonne nascoste',
      noResultsFound: 'Nessun risultato trovato',
      sorryNoResults:
        'Siamo spiacenti che non sia stato possibile trovare ciò che stavi cercando. Si prega di provare in un altro modo.',
      tutorial: 'Istruzioni',
      next: 'Avanti',
      back: 'Indietro',
      step: 'Fase',
      createSuccess: {
        title: 'Creata',
        message: 'La tua opzione è stata creata con successo.',
      },
      editSuccess: {
        title: 'Modificata',
        message: 'Le tue modifiche sono state salvate.',
      },
      deleteSuccess: {
        title: 'Cancellata',
        message: "L'opzione è stata rimossa con successo.",
      },
      toolTipText: {
        faq: 'Aiuto: Guide, Contatto, Altro.',
        communityPlusIcon: 'Creare una nuova comunità',
        logoGumb:
          'Mistero in preparazione: Scoprite più tardi cosa nasconde questo pulsante!',
        memberPlusIcon: 'Aggiungere un nuovo utente',
        memberArrowIcon: 'Vai alla panoramica dei membri',
        eventType: 'Crea tipo di evento',
        responses: 'Crea opzione di risposta',
        task: 'Crea compito',
        schedulerPlusIcon: 'Creare un evento o un gruppo',
      },
      shareEventTitle: "Condividi il link dell'evento tramite:",
      copyEventLink: 'Oppure copia il link:',
    },
    auth: {
      signInWith: 'Accedi con',
      continueAs: 'Continua come',
      create: 'Creare conto gratuito',
      signUp: 'Iscriversi',
      signIn: 'Connettersi',
      welcome: 'Benvenuto!',
      createAccount: 'Creare conto gratuito',
      forgotPassword: 'Password dimenticata?',
      resetPassword: 'Resetta la password',
      resetPasswordTooltip:
        "If you reset the password, the member gets an email with instructions on how to set a new password. However, Gumb can't reset for Facebook/Google/Apple sign-ups; they should use the respective icon.",
      resetPasswordModalTitle: 'Reimpostazione password avviata',
      resetPasswordModalText:
        "Il membro riceverà a breve un link di reimpostazione all'email indicata di seguito. Per Facebook/Google/Apple non è possibile, ma il membro può comunque utilizzare l'icona corrispondente per accedere.",
      resetPasswordErrorModalTitle:
        'Reimpostazione della password non possibile',
      resetPasswordErrorModalText:
        "Nota! Questo membro sembra essersi registrato tramite i Social Media (ad es., Facebook, Google, Apple). Pertanto, Gumb non può reimpostare la password per questo account. Tuttavia, l'utente può ancora accedere utilizzando l'icona del rispettivo Social Media. Si prega di informare il membro di conseguenza.",
      terms: {
        '0': 'Accetto le',
        '1': 'Condizioni',
        '2': ' e la',
        '3': 'Politica sulla Privacy',
      },
      home: 'Inizio',
      confirmed: 'Email confermata con successo',
      verifying: 'Verificando la email...',
      recovery: 'Recupera password',
      send: 'Invia',
      codeSended:
        'Il link di verifica è stato inviato alla tua email. Controlla e conferma. Presta attenzione alla cartella spam.',
      signUpSuccess:
        "Iscritto con successo. Per favore controlla la tua email per confermare l'iscrizione",
      inviteSentTitle: 'Invito e dati di accesso inviati con successo',
      inviteSentText:
        "Ottimo! Hai pre-registrato con successo il membro e inviato l'invito con una password temporanea. Il membro può ora accedere e cambiare la sua password. Se necessario, ricarica questa pagina per vedere subito il membro. Tu e i tuoi amministratori potete ora determinare le assegnazioni di gruppo e assegnare ruoli. Usando l'icona della matita all'estrema destra, puoi anche completare i dettagli personali del membro. Continua così!",
      reset: 'Ripristinare',
      yourEmail: '{{value}} è già il tuo indirizzo email',
      existEmail: '{{value}} esiste già',
      labels: {
        email: 'Email',
        currentEmail: 'Email corrente',
        newEmail: 'Nuova email',
        password: 'Password',
        oldPassword: 'Vecchia password',
        newPassword: 'Nuova password',
        confirmPassword: 'Conferma password',
      },
      accountExists:
        'Questo indirizzo e-mail è già utilizzato per un account Gumb. Se questa e-mail è vostra, fate clic su Password dimenticata e controllate la posta in arrivo e la cartella spam.',
      codeExpired:
        "Si trattava di un link scaduto. Dopo aver inserito il vostro indirizzo e-mail, vi invieremo un'e-mail con un nuovo link per completare la verifica del vostro account.",
      userNotConfirmed:
        "È comunque necessario verificare il proprio account. Vi abbiamo inviato un'e-mail con un link di conferma. Se non avete ricevuto l'e-mail, controllate la cartella spam della vostra casella di posta elettronica. ",
      invalidCredentials: 'Nome utente o password non corretti',
      userNotFound:
        "A quanto pare questa e-mail è già collegata tramite Google/Apple/Facebook o non è registrata. Non è possibile modificare la password di un social media tramite Gumb. Provate invece ad accedere tramite l'icona dei social media.",
    },
    profile: {
      editSuccess: 'Le modifiche sono state aggiornate con successo',
      settings: 'Impostazioni',
      editProfile: 'Modifica profilo',
      selectImage: 'Seleziona immagine',
      myProfile: 'Il mio profilo',
      profileEditBtn: 'Aggiungi le tue informazioni: indirizzo, social e altro',
      basicInformation: 'Informazioni di base',
      contactInformation: 'Informazioni di contatto',
      addressInformation: "Informazioni sull'indirizzo",
      myCommunities: 'Le mie comunità',
      myChat: 'I MIEI CHATS',
      myAppointments: 'I miei appuntamenti',
      subscription: 'Abbonamento',
      userProfile: 'Profilo utente',
      tellUsAboutYourself: 'Parlaci di te',
      firstName: 'Nome',
      lastName: 'Cognome',
      profileName: 'Nome profilo',
      person: 'Persona',
      role: 'Ruolo utente',
      email: 'Email',
      dateOfBirth: 'Data di nascita',
      privatePhoneNumber: 'Numero di telefono privato',
      officePhoneNumber: 'Numero di telefono ufficio',
      mobilePhoneNumber: 'Numero di telefono cellulare',
      street: 'Via',
      region: 'Regione',
      postalCode: 'Codice postale',
      city: 'Città',
      country: 'Paese',
      about: 'A proposito di',
      linkChangeEmail: "(Cambiare l'e-mail)",
    },
    profileCommunities: {
      popupLeave: {
        title: 'Sei sicuro di voler lasciare questa comunità?',
        button: 'Lascia',
      },
      leaveCommunity: {
        title: 'Sei sicuro di voler lasciare questa community?',
        message:
          'Quando lasci questa community, il tuo account utente rimane attivo, e puoi ancora unirti ad altre community.',
        okButton: 'Sì, Lascia',
        cancelButton: 'Annulla',
      },
    },
    profileSubscription: {
      heading: 'Dettagli abbonamento',
      titleMembership: 'Iscrizione',
      titlePayment: 'Pagamento',
      buttonCancel: "Annullare l'iscrizione",
      buttonRestart: "Riavviare l'iscrizione",
      details: {
        linkChangePlan: 'Cambia piano',
        infoCancelled:
          "Il tuo abbonamento è stato cancellato e non verrà rinnovato. Puoi continuare a usare l'applicazione fino alla prossima data di fatturazione. Puoi rinnovare il tuo abbonamento in qualsiasi momento.",
        infoTrial:
          'Il tuo mese di prova è attualmente attivo. Dopo la scadenza, naturalmente non ti verrà addebitato nulla.  Se vuoi continuare a usare Gumb, seleziona un abbonamento adatto sotto "Cambia piano".',
        infoTrialNew:
          "Il tuo periodo di prova è attualmente in corso. Dopo la scadenza il {{expireDate}}, non ti verrà addebitato nulla. Se desideri continuare a utilizzare Gumb, seleziona un abbonamento adatto in 'Cambia piano'.",
        linkShowCommunities: 'Mostra le mie comunità',
        linkHistory: 'Cronologia fatturazione',
        linkChangePayment: 'Modificare il metodo di pagamento',
        currentPlan: 'Piano attuale:',
        communitiesInUse: 'Comunità in uso:',
        groupsInUse: 'Gruppi in uso:',
        membersInUse: 'Membri in uso:',
        storageInUse: 'Archiviazione in uso:',
        paymentCycle: 'Ciclo di pagamento:',
        paymentCycleMonth: 'Mensile',
        paymentCycleYear: 'Annuale',
        nextPayment: 'Pagamento successivo:',
        validUntil: 'Ancora valido fino a:',
        paymentMethod: 'Metodo di pagamento:',
      },
    },
    profileSettings: {
      title: 'Impostazioni profilo',
      notification: 'Notifica',
      notificationsPlaceholder: 'Non hai notifiche',
      changePassword: 'Cambia password',
      changeEmail: 'Email',
      saveNewEmail: 'Salva la nuova email',
      language: 'Lingua',
      timeFormat: 'Formato orario',
      permissions: 'Autorizzazioni',
      allowedToEdit: 'Gli amministratori possono modificare il mio profilo',
      other: 'Altro',
      pushNotification: 'Notifica push',
      receiveByEmail: 'Ricevi le notifiche via email',
      receivePush: 'Ricevere le notifiche push da mobile',
      receiveInApp: 'Ricevi notifiche in-app',
      terms: 'Termini e condizioni',
      protectionRegulation: 'Politica sulla privacy',
      deleteAccount: 'Eliminare conto',
      logout: 'Disconnettere',
      deleteProfileHeader: 'Sei sicuro di voler eliminare il tuo conto?',
      notifications: {
        now: 'Adesso',
        hours: '{{value}} ore fa',
        minutes: '{{value}} minuti fa',
        minute: '1 minuto fa',
      },
    },
    calendarConnection: {
      googleCalendar: 'Google Calendar',
      pageTitle: 'Connessione al calendario',
      connectTitle:
        'Collegate il vostro calendario\nper sincronizzare automaticamente gli eventi',
      connectDescription:
        'Gumb può creare automaticamente eventi che appaiono sul vostro calendario. Tenere traccia degli eventi con questa sincronizzazione.',
      appleCalendar: 'Calendario Apple',
      appleConnectionSteps: {
        headerOne: 'Integra eventi Gumb nel tuo calendario Apple con un URL',
        headerOneSplit: 'in 4 passaggi; demo veloce',
        here: 'qui',
        headerTwo: 'Oppure istruzioni dettagliate',
        stepOne:
          '1. Nel tuo Calendario Apple, seleziona "Calendario" poi "File" > "Nuovo abbonamento al calendario".',
        stepTwo:
          '2. Inserisci il link iCal della Community copiato qui sotto, poi clicca su "Iscriviti".',
        stepThree:
          '3. Inserisci un nome per il calendario e scegli un colore per identificarlo facilmente nel tuo calendario.',
        stepFour:
          '4. Regola le preferenze come la posizione (iCloud o Mac) o l\'aggiornamento automatico, poi clicca su "OK".',
      },
      appleConnectionPopup: {
        title: 'Come collegare?',
        stepOne: 'Copia il link della community',
        stepTwo:
          'Nel tuo Calendario Apple, seleziona "Calendario", poi "File" > "Nuovo abbonamento al calendario".',
        stepThree:
          'Inserisci il link iCal della Community copiato, poi clicca su "Iscriviti".',
        stepFour:
          'Nomina il calendario, scegli un colore, imposta preferenze come posizione (iCloud o Mac) o aggiornamento automatico e clicca "OK".',
      },
      appleDisconnectPopup: {
        title: 'Conferma Disconnessione',
        message:
          'Cliccando su "Sì, disconnetti ora" rimuoverai tutti gli eventi Gumb dal/dai tuo/i calendario/i. Si prega di cancellare manualmente il/i calendario/i in seguito, altrimenti rimarrà/rimarranno visibile/i.\n\n<b>Per disiscriverti completamente:</b>\n\n• <b>Su macOS</b>: Apri Calendario, clicca con il tasto destro sul calendario > "Annulla sottoscrizione".\n• <b>Su iOS</b>: Vai su "Calendari", tocca il pulsante "Info" accanto al calendario > "Elimina Calendario".',
        okButton: 'Sì, disconnetti ora',
      },
      appleUncheckBoxPopup: {
        title: 'Conferma Disconnessione',
        message:
          'Cliccando su "Sì, disconnetti ora" rimuoverai tutti gli eventi Gumb dal tuo calendario. Si prega di cancellare manualmente il calendario in seguito, altrimenti rimarrà visibile.\n\n<b>Per disiscriverti completamente:</b>\n\n• <b>Su macOS</b>: Apri Calendario, clicca con il tasto destro sul calendario > "Annulla sottoscrizione".\n• <b>Su iOS</b>: Vai su "Calendari", tocca il pulsante "Info" accanto al calendario > "Elimina Calendario".',
        okButton: 'Sì, disconnetti ora',
      },
      connectWithApple: 'Collega a Apple (iCal)',
      cantSeeICal: 'Non vedi il link iCal? Basta spuntare la casella.',
      syncTip:
        'Solo gli eventi della comunità selezionata saranno sincronizzati con il vostro calendario.',
      connect: 'Collegare',
      connected: 'Associato',
      disconnect: 'Disconnettersi',
      disconnectSuccess: 'Disconnessione riuscita',
      selectCommunities: 'Selezione delle comunità',
      selectCommunitiesDescription:
        'Solo gli eventi della comunità selezionata saranno sincronizzati con il vostro calendario.',
      authSuccessTitle: 'Connessione riuscita',
      authSuccessMessage:
        'Le tue comunità si sincronizzeranno automaticamente. Attendi un momento e poi aggiorna la pagina di Google Calendar per completare la sincronizzazione iniziale.',
      authSuccessTitleTwo: 'Seleziona la tua community ora',
      authSuccessMessageTwo:
        'Tocca il segno di spunta e premi "Salva modifiche" per confermare.',
      syncSuccess:
        "Aggiornamento riuscito!\nD'ora in poi, tutto sarà sincronizzato automaticamente. È possibile modificare la selezione delle comunità in qualsiasi momento.",
      syncSuccessTitle: 'Quasi pronto!',
      syncSuccessMessage:
        'Stiamo collegando la tua community al tuo Google Calendar. Ci vorranno solo 2-3 minuti. Aggiorna la pagina di Google Calendar per vedere gli aggiornamenti.',
      disconnectConfirmTitle: 'Siete sicuri?',
      disconnectConfirmDescription:
        'Se ci si disconnette, tutti gli eventi verranno rimossi dal calendario.',
      disconnectConfirm: {
        title: 'Disconnettere ora?',
        message:
          'La disconnessione rimuoverà tutti gli eventi dal tuo Google Calendar. Non preoccuparti, puoi ricollegare Gumb per una nuova sincronizzazione in qualsiasi momento.',
        cancelButton: 'Annulla',
        okButton: 'Sì, Disconnetti',
      },
      noCommunities:
        'Non ci sono comunità da selezionare.\nAspettate che un amministratore vi aggiunga.',
      checkboxWarning: 'ATTENZIONE: il segno di spunta deve essere impostato!',
    },
    community: {
      plusIconSubText: 'Pianifica un evento o crea serie di eventi.',
      selectAllCommunityAdmins: 'Tutti gli amministratori',
      selectAllGroupAdmins: 'Tutti i capigruppo',
      createSuccess: 'La comunità è stata creata con successo',
      createFailure: 'Fallito nel creare comunità',
      deleteSuccess: 'La comunità è stata eliminata con successo',
      leaveSuccess: 'Hai lasciato con successo la comunità',
      descriptionTitle: 'A proposito della comunità',
      descriptionPlaceholder: 'Qui puoi descrivere la tua comunità',
      online: 'Online',
      updateCommunity: 'Aggiorna comunità',
      members: 'Membri',
      createCommunity: 'Creare comunità',
      communityName: 'Nome della comunità',
      category: 'Categoria',
      aboutCommunity: 'A proposito della comunità',
      selectLogo: 'Seleziona logo',
      selectLogoTip: 'jpg, jpeg, png (5MB)',
      chooseCover: 'Seleziona cover',
      chooseCoverTip: 'jpg, jpeg, png (20MB)',
      website: 'Sito web',
      partnership: 'Partenariato',
      deleteCommunity: 'Elimina comunità',
      deleteHeader: 'Sei sicuro di voler rimuovere questo utente dal gruppo?',
      addMembers: 'Aggiungi membri',
      invitationLink: 'Link di invito',
      communityRole: 'Ruolo nella comunità',
      joinCommunity: 'Raggiungi comunità',
      namePlaceholder: 'La team di {{value}}',
      requestSent: 'Richiesta di adesione inviata con successo',
      requestSentButton: 'Richiesta di adesione inviata',
      limitExceededHeader:
        'Hai raggiunto il limite di comunità. Devi aggiornare il tuo abbonamento per crearne altre.',
      limitExceededMessage:
        "Per favore contatta il proprietario della comunità. Il proprietario della comunità puo controllare i limiti dell'abbonamento e aggiornare il tuo abbonamento comunitario.",
      limitExceededMessageOwner:
        'Per favore visita la pagina degli abbonamenti per controllare i limiti del tuo abbonamento o aggiornare il tuo abbonamento',
      updateFailure: "Fallimento dell'aggiornamento della comunità",
      buttonAddEvent: 'Aggiungi evento',
      userBlockedTitle: 'Bloccato',
      userBlockedText:
        'Sembra che qualcuno ti abbia bloccato su questa comunità. Gli amministratori possono bloccare o sbloccare i membri.',
      createOverLimitTitle: 'Limite di comunità raggiunto',
      createOverLimitText:
        'Inizia ora il tuo periodo di prova gratuito o scegli un abbonamento in "Modifica Abbonamento" per organizzare la tua propria comunità.',
      notInvitedTitle: 'Nessun permesso',
      notInvitedText:
        "Potresti non avere il permesso di accedere a questo evento. Gli amministratori e i capigruppo possono aggiungerti all'evento.",
      noRequestsTitle: 'Nessuna richiesta di revisione',
      noRequestsText:
        'Quando le persone inviano richieste per la tua comunità, queste saranno visualizzate qui perché tu possa esaminarle.',
      invitationCodeExpired:
        'Questo è un link di invito scaduto. Ma ora è possibile inviare una richiesta di adesione, che gli amministratori possono accettare o rifiutare.',
      communityNotFound: 'Comunità non trovata',
      communityPopup: {
        title: 'Crea Evento o Gruppo',
        subTitleOne:
          'Pianifica eventi nel PIANIFICATORE centrale. Usa i gruppi per dividere i membri in squadre.',
        createEvent: {
          infoText:
            "Programma eventi per tutti o per gruppi/membri specifici nel PIANIFICATORE. Visibile nel pianificatore, sotto 'I Miei Eventi' e nei gruppi.",
          btntitle: 'Crea Evento',
          linkText: 'Tutorial',
        },
        createGroup: {
          infoText:
            'Ogni gruppo ha il proprio pianificatore, con eventi visibili solo ai membri del gruppo. Gestisci i ruoli dei membri efficacemente',
          btntitle: 'Crea Gruppo',
          linkText: 'qui',
        },
        chatTitle: 'Opzioni chat',
        chatSubTitle: 'Avvia una chat 1:1 o crea una stanza chat per gruppi.',
        oneToOne: {
          infoText:
            "Clicca sull'icona della chat accanto al nome di un membro o sulla sua immagine del profilo per inviare un messaggio.",
          btntitle: 'Vai alla lista membri',
          linkText: 'Tutorial',
        },
        createChatGroup: {
          infoText:
            'Crea una stanza chat privata per membri selezionati, team o gruppi. Solo gli amministratori possono creare queste stanze.',
          btntitle: 'Crea una stanza chat',
          linkText: 'qui',
        },
      },
    },
    communitySettings: {
      admin: 'Amministratore',
      member: 'Membro',
      blocked: 'Utente bloccato',
      owner: 'Proprietario',
      group_lead: 'Amministratore del gruppo',
      group_admin: 'Leader del gruppo',
      chat_admin: 'Admin chat',
      community_admin: 'Amministratore della comunità',
      editRoleSuccess: 'Il ruolo è stato cambiato con successo',
      deleteUserSuccess:
        "L'utente è stato eliminato con successo dalla comunità",
      removeUser: {
        title: 'Sei sicuro di voler rimuovere questo utente?',
        message:
          'Quando rimuovi questo utente, verrà rimosso dalla tua community, ma conserverà il suo account utente e potrà comunque partecipare ad altre community.',
        cancelButton: 'Annulla',
        okButton: 'Sì, Rimuovi',
      },
      removeUserSuccess: {
        title: 'Utente rimosso',
        message: "L'utente è stato rimosso con successo dalla tua community.",
      },
      editSuccess: 'Le modifiche sono state aggiornate con successo',
      title: 'Impostazioni comunità',
      backToSubscriptionsDetails: "Torna ai dettagli dell'abbonamento",
      tabs: {
        general: 'Preferenze generali',
        eventTypes: 'Tipi di evento',
        subscriptions: 'Sottoscrizioni',
        responses: 'Opzioni di risposta',
        roles: 'Membri',
        requests: 'Richieste',
        other: 'Altro',
        tasks: 'Compiti',
      },
      addMember: 'Aggiungi membro',
      exportMembers: 'Esportare',
      exportMembersTooltip: "Esportare l'elenco dei membri in un file CSV",
      deleteCommunityHeader: 'Sei sicuro di voler eliminare questo elemento?',
      defaultEventList: [
        'Riunione',
        'Formazione',
        'Gioco',
        'Prova',
        'Evento',
        'Riunione del consiglio di amministrazione',
      ],
      defaultResponsesTypes: [
        'Sì, vengo',
        'No, non vengo',
        'Può essere',
        'Neutro',
      ],
      defaultTasks: ['Chi guida?', 'Chi porta da bere?'],
      selectUsers: {
        title: 'Seleziona Utenti',
        message:
          'Seleziona gli utenti spuntando la casella per approvare o rifiutare le richieste di adesione.',
      },
      memberExists: 'Il membro esiste già',
      mailSent: "L'invito è stato inviato con successo",
      userNotFound:
        'Nessun profilo Gumb è stato trovato. Per creare un nuovo profilo e inviare un invito a questa email, fornisci ulteriori informazioni',
      disableAddMemberTooltip:
        "Il numero di membri ha già raggiunto il limite. Per risolvere questo problema, il proprietario della comunità può verificare un aggiornamento dell'abbonamento per aggiungere altri membri.",
      notifyByFields:
        'Invia una notifica quando i seguenti campi vengono aggiornati',
      notifyByReplies:
        'Invia una notifica quando gli invitati hanno risposto con',
      remindBefore: 'Ricorda prima',
      addReminder: '+ Aggiungi promemoria (2 max.)',
      notificationsTypes: [
        'Evento creato',
        'Evento aggiornato',
        'Evento cancellato',
        "Promemoria dell'evento",
      ],
      notificationsClasses: ['In-App', 'E-mail', 'Push'],
      notificationPopupHeader: 'Vuoi inviare notifiche agli utenti invitati?',
      notificationPopupMessageLabel:
        'Aggiungi un messaggio opzionale per gli utenti invitati',
      notificationPopupNotify: 'Invia',
      notificationPopupDoNotNotify: 'Non inviare',
      notificationPopupBack: 'Torna a modificare',
    },
    communityInvitation: {
      subjectFirst: 'Sei stato invitato a far parte della comunità',
      subjectSecond: '',
      body: 'Si prega di seguire il link qui sotto per far parte della comunità',
      invitationSuccess: "L'utente è invitato nella comunità",
      invitationFailure:
        "C'è stato un errore, potrebbe essere rimasto senza abbonamento",
      membersInvitation: {
        title: 'Invitare i membri',
        description:
          'Aggiungete nuovi utenti alla vostra comunità in modo che diventino membri e abbiano il permesso di rispondere ai vostri eventi. Vedere la guida <0>"Aggiungere un nuovo utente"</0>.',
        showMore: 'Mostra di più',
        showLess: 'Mostra meno',
        codeInvitation: {
          title: 'Il modo più semplice e veloce',
          button: 'Copiare il link di invito',
          updateLink: 'Generare il link ora',
          generateLink: 'Generate link now',
          howItWorks: 'Come funziona',
          goodToKnow: 'Da sapere',
          howItWorksText:
            'I membri fanno clic su "Crea un account gratuito" utilizzando il link selezionato sopra. Quando Gumb invia un\'e-mail, i membri devono solo confermare il loro indirizzo e-mail per ottenere l\'accesso diretto.',
          goodToKnowText:
            "L'operazione è rapidissima, ma si noti che una volta completata la verifica dell'account, le persone in possesso di questo link potranno accedere immediatamente ai vostri eventi. Pertanto, è possibile rinnovare il link di cui sopra ogni volta che lo si desidera.",
        },
        linkInvitation: {
          title:
            'Meglio prevenire che curare: inviare la richiesta di adesione con il link della comunità',
          button: 'Copia il link della comunità',
          howItWorks: 'Come funziona',
          adminFlow: 'Gli amministratori accettano o rifiutano',
          howItWorksText:
            '<0>Gli amministratori copiano e condividono il link della comunità.</0><0>I membri, con un account Gumb, cliccano su "unisciti alla comunità".</0><0>I membri senza account devono prima registrarsi su Gumb e poi cliccare nuovamente sul link per inviare la richiesta.</0>',
          adminFlowText:
            'Nella scheda "Richieste", gli amministratori devono approvare o rifiutare la richiesta di un membro di unirsi alla vostra comunità. Quando ci sono richieste aperte, gli amministratori vedranno una notifica sull\'ingranaggio.',
        },
        addMember: {
          title: 'Altre 2 opzioni: Efficiente o personale',
          button: 'AGGIUNGI UTENTE',
          selfSignUp: 'I membri si registrano da soli',
          preRegister: 'Pre-registra la persona',
          selfSignUpText:
            "Dite a tutti di registrarsi su Gumb (gratuitamente) e di inviarvi l'e-mail di registrazione. Il membro verrà immediatamente inserito nel suo account e vedrà automaticamente tutti i suoi appuntamenti.",
          preRegisterText:
            'Registra nuovi membri direttamente attraverso il loro indirizzo email. Questa azione invia loro automaticamente un\'"Invito via Email" con una password temporanea. Usando questa, possono accedere immediatamente e modificarla come preferiscono in seguito.',
        },
      },
    },
    group: {
      deleteEventSuccess: "L'evento è stato cancellato con successo",
      createSuccess: 'Il gruppo è stato creato con successo',
      deleteSuccess: 'Il gruppo è stato eliminato con successo',
      addMembersSuccess: 'I partecipanti sono stati aggiunti con successo',
      addMembersSuccessWithMessage: {
        title: 'Membro aggiunto!',
        message:
          'La tua lista membri è stata aggiornata con successo. Gestisci ruoli e assegnazioni ai gruppi. Puoi anche aggiungere o modificare i dettagli utente.',
      },
      deleteUserSuccess: "Eliminato con successo l'utente dal gruppo",
      addParticipants: 'Aggiungi membri',
      createGroup: 'Crea gruppo',
      deleteGroup: 'Elimina gruppo',
      groupName: 'Nome del gruppo',
      changeGroupNameSuccess: 'Nome del gruppo modificato con successo.',
      groups: 'Gruppi',
      inviteMembers: 'Invita membri',
      tabs: {
        schedule: 'Pianifica',
        members: 'Membri',
        chat: 'Chat',
        documentation: 'Documentazione',
        statistic: 'Esportazione e statistiche',
        import: 'Importazione',
      },
      limitExceededHeader:
        'Hai raggiunto il limite di gruppi. Devi aggiornare il tuo abbonamento per crearne altri.',
      limitExceededMessage:
        "Per favore contatta il proprietario della comunità. Il proprietario della comunità può controllare i limiti dell'abbonamento e aggiornare il tuo abbonamento comunitario.",
      limitExceededMessageOwner:
        'Per favore visita la pagina degli abbonamenti per controllare i limiti del tuo abbonamento o aggiornare il tuo abbonamento',
      groupRole: 'Aggiungi gruppo',
      addGroup: 'Add Group',
    },
    chat: {
      read: {
        now: '{{name}} letto proprio ora',
        hours: '{{name}} letto {{value}}h fa',
        minutes: '{{name}} letto {{value}} min fa',
        minute: '{{name}} letto 1 min fa',
        day: '{{name}} letto il {{value}}',
        dateTime: '{{name}} letto il {{value}} alle {{time}}',
      },
      noRead: 'Non ancora letto',
      lastAdmin:
        'Una chatroom deve avere almeno un amministratore o un responsabile di gruppo. Assicurati che almeno una persona abbia sempre questo ruolo.',
      update: {
        success: 'Elenco membri aggiornato',
        successUpdate: 'Chatroom aggiornata con successo',
        btn: 'SALVA',
      },
      modal: {
        btn: 'Gestisci',
        about: 'Info',
        editRoom: 'Modifica chatroom',
        delete: 'Elimina chatroom',
        editMember: 'Modifica membri',
      },
      details: {
        title: 'Dettagli chatroom',
        secondTitle: 'INFORMAZIONI',
        name: 'Nome della chatroom',
      },
      delete: { success: 'La chatroom è stata eliminata' },
      create: {
        title: 'Crea una stanza chat',
        name: 'Nome della stanza chat',
        description: 'Descrizione del gruppo (opzionale)',
        btnNext: 'AVANTI',
        success: 'Chatroom creata',
      },
      new: 'Nuova',
      chat: 'chat',
      inputPlaceHolder: 'Digita un messaggio',
      searchHover: 'Cerca o avvia una nuova chat',
      op1: 'File & Video',
      op2: 'Foto',
      files: 'File',
      plusTooltip: 'Crea una nuova chat o un gruppo chat',
      emptyStatus: {
        noChatSelected: {
          firstLine: 'Seleziona una conversazione per visualizzare la chat,',
          secondLine: 'o clicca qui per avviare un',
          LinkText: 'NUOVO CHAT',
        },
        noChatUser:
          'Nessuna chat ancora? Avvia una chat 1:1 o aspetta che il tuo admin ti aggiunga.',
        noUnseenUser: "Don't have any unseen chat",
        sendFirstMessage: 'Scrivi il tuo primo messaggio a {{recipient}}.',
      },
      infoPopup: {
        title: 'Limite superato',
        message: 'Puoi caricare al massimo 20 file alla volta.',
      },
    },
    statistics: {
      tableType: 'Scegliere un rapporto',
      eventReplies: 'Risposte e compiti',
      eventTypes: 'Tipo di evento',
      appointmentSchedule: 'Calendario degli appuntamenti',
      memberFigures: 'Controllo delle presenze',
      charts: {
        eventTypesByMonth: 'Numero di appuntamenti per tipo',
        eventReplies: 'Risposte alle opzioni di risposta',
        memberPresence: 'Numero di reazioni per risposta',
      },
      loadingStatistics: 'Caricamento...',
      memberFiguresExportSuccess: {
        title: 'Il rapporto è in fase di creazione!',
        message:
          'Stiamo attualmente creando il tuo rapporto di presenza personalizzato. A causa della grande quantità di dati, questo potrebbe richiedere alcuni minuti. Per ragioni di sicurezza, il rapporto verrà inviato al tuo indirizzo email. Controlla semplicemente la tua casella di posta. Grazie per la tua pazienza!',
      },
    },
    filters: {
      clickToSelect: 'Fare clic qui per filtrare',
      showFilters: 'Mostra filtri',
      dateRange: "Selezionare l'intervallo di date",
      filterBy: 'Filtrare per',
      dateRanges: {
        yearToDate: "Da un anno all'altro",
        lastDays: 'Ultimi {{number}} giorni',
        lastYear: 'Ultimo anno',
        yesterday: 'Ieri',
        today: 'Oggi',
        tomorrow: 'Domani',
        nextDays: 'I prossimi {{number}} giorni',
        thisWeek: 'Questa settimana',
        thisMonth: 'Questo mese',
        thisYear: 'Questo anno',
        allTime: 'Tutto il tempo',
        specificDateRange: 'Intervallo di date specifico',
      },
      downloadXlsxOrCsv: 'Scarica qui: Excel o CSV',
    },
    bulkUploadEvents: {
      toastText:
        '🆕  La nuova funzione di importazione degli appuntamenti è ora disponibile per ogni gruppo!  🎉',
      moduleTitle: 'Importazione',
      step1: 'Fase 1',
      step2: 'Fase 2',
      step3: 'Fase 3',
      step4: 'Fase 4',
      stepOne: {
        stepTitle: "Prepara l'importazione dei dati",
        stepDescription: 'Scarica il modello Excel',
        componentTitle: 'Scarica il modello Excel',
        componentDescription:
          'Compila il modello con i dati del tuo evento o utilizza il tuo file Excel.',
        p1: 'Compila il modello con i dati del tuo evento.',
        p2: 'Le intestazioni delle colonne devono rimanere in inglese.',
        p3: 'Gli esempi in ogni colonna ti mostrano il formato corretto.',
        p4: 'I nuovi tipi di evento vengono aggiunti automaticamente.',
        p5: '<b>Importante</b>: Se usi il tuo file, i seguenti titoli di colonna devono essere copiati esattamente come mostrato in inglese (rispettando maiuscole e minuscole):',
        p6: 'Gli esempi in ogni colonna del modello ti mostrano il formato corretto.',
        p7: 'I nuovi tipi di evento vengono aggiunti automaticamente.',
        eventTypes: 'VAI AI TIPI DI EVENTO',
        template: 'SCARICA IL MODELLO',
        nextStepButton: 'CONTINUA',
        copy: 'Copia tutte le chiavi',
      },
      stepTwo: {
        stepTitle: 'CARICA FILE',
        stepDescription: 'CARICA FILE CSV',
        componentTitle: 'CARICA FILE CSV',
        componentDescription:
          'Salva il file Excel in formato <b><u>CSV</u></b> e caricalo qui.',
        p1: "Verificheremo il formato e ti mostreremo un'anteprima.",
        uploadButton: 'Scegli file',
        maximumFileSize: 'Dimensione massima del file: 1 MB',
      },
      stepThree: {
        stepTitle: 'Conferma dati',
        stepDescription: 'Anteprima del tuo primo evento.',
        componentTitle: 'Conferma dati',
        componentDescription:
          "Controlla l'esempio e regola i membri attivati.\nI membri selezionati si applicano a tutti gli eventi.",
        nextStepButton: 'CONFERMA E CARICA',
      },
      stepFour: {
        stepTitle: 'Caricamento riuscito!',
        stepDescription:
          'Il caricamento degli eventi è avvenuto con successo. Puoi vederli nel calendario.',
        componentTitle: 'Caricamento riuscito!',
        componentDescription: 'Sono stati importati <b>{{count}}</b> eventi.',
        secondTitle: 'Buono a sapersi',
        secondSubTitle:
          "Puoi annullare l'importazione o caricare un nuovo elenco.",
        goToScheduler: 'VAI AL CALENDARIO',
        undoImport: 'ANNULLA IMPORTAZIONE',
        uploadNewList: 'CARICA NUOVO ELENCO',
      },
      errors: {
        eventNameErrorTitle: 'Errore nome evento',
        eventNameErrorMessage:
          'Nome evento mancante alla riga <b><u>{{row}}</u></b>.',
        eventTypeErrorTitle: 'Errore tipo evento',
        eventTypeErrorMessage:
          'Tipo di evento mancante alla riga <b><u>{{row}}</u></b>.',
        timeErrorTitle: 'Errore orario',
        timeErrorMessage:
          'Orario di inizio o fine mancante alla riga <b><u>{{row}}</u></b>.',
        dateErrorTitle: 'Errore data',
        dateErrorMessage: 'Data mancante alla riga <b><u>{{row}}</u></b>.',
        locationErrorTitle: 'Errore luogo',
        locationErrorMessage:
          "Luogo dell'evento mancante alla riga <b><u>{{row}}</u></b>.",
        invalidDateOrTimeTitle: 'Data o ora non valida',
        invalidDateOrTimeMessage:
          "Verificare il formato di data e ora alla riga <b><u>{{row}}</u></b> (ad es. usare HH:MM per l'ora).",
      },
      deletePopupTitle: 'Eliminare la lista degli eventi?',
      deletePopupMessage:
        'Questa azione eliminerà definitivamente la tua ultima lista di eventi caricata. Sei sicuro?',
      deleteSuccessTitle: 'Eliminazione riuscita!',
      deleteSuccessMessage:
        'La tua ultima lista di eventi caricata è stata eliminata correttamente.',
      successMessage:
        "Convalida avvenuta con successo. Procedi al passaggio successivo per visualizzare l'anteprima.",
      restartProcessTitle: 'Carica nuova lista eventi',
      restartProcessMessage:
        "Puoi caricare una nuova lista di eventi in qualsiasi momento. Tieni presente che gli eventi caricati in precedenza saranno ancora visibili nel calendario. Se vuoi sostituire la vecchia lista, eliminala prima o annulla l'ultima importazione.",
      restartProcessOkButton: 'CARICA NUOVA LISTA',
      exportDataSample: {
        event_name: 'Zurich Street Parade',
        event_type_name: 'Spettacolo',
        event_location: 'Bahnhofstrasse 5, 8001 Zurigo',
        event_description: "Festival all'aperto con gruppi e DJ internazionali",
      },
    },
    event: {
      event: 'Evento',
      createdAt: 'Data di creazione',
      editEvent: 'Modifica evento',
      createEvent: 'Crea evento',
      updateEvent: 'Aggiorna evento',
      eventName: "Nome dell'evento",
      eventType: 'Tipo di evento',
      eventTypeDeleted: 'Tipi di eventi eliminati',
      eventDetails: "Dettagli dell'evento",
      eventOrganizer: "Organizzatore dell'evento",
      eventOrganizerShort: 'Organizzatore',
      location: 'Luogo',
      eventTypeEdit: 'Modifica tipo di evento',
      eventTypeName: 'Nome del tipo di evento',
      responseRequired: 'Risposta necessaria',
      responseName: 'Nome della risposta',
      responsesEdit: 'Modifica risposta',
      responsesCreate: 'Creare risposta',
      repeatAnEvent: 'Ripeti un evento',
      repeatWithInterval: 'Ripeti con un intervallo',
      allDay: 'Giornata completa',
      registrationDeadline: "Scadenza per l'iscrizione",
      commentRequired: 'Commento necessario',
      editTask: 'Modifica compito',
      createTask: 'Crea compito',
      eventTypeCreate: 'Crea tipo di evento',
      eventTaskName: 'Nome del compito',
      icon: 'Icona',
      isMemberComing: 'La tua risposta?',
      recurringEvent: 'Evento ricorrente',
      allEvents: 'Tutti gli eventi',
      thisEventOnly: 'Solo questo evento',
      createEventSuccess: "L'evento è stato creato con successo",
      updateEventSuccess: "L'evento è stato aggiornato con successo",
      createEventReplySuccess: 'Risposta salvata con successo',
      updateEventReplySuccess: 'Risposta aggiornata con successo',
      addGuests: 'Ospiti aggiuntivi ?',
      eventOperations: {
        eventOnly: 'Questo evento',
        eventAndFollowUp: 'Questo evento e quelli successivi',
        all: 'Tutti gli eventi',
        deleteText: 'Elimina evento ricorrente',
        editText: 'Modifica evento periodico',
        editReplyText: 'La tua risposta si applica a',
      },
      dfRes: 'Imposta risposta predefinita',
      responseToast:
        'Attenzione! Se elimini la risposta predefinita senza salvarne una nuova, tutte le reazioni e i commenti precedenti verranno eliminati.',
      setPreferences: 'Imposta preferenze',
      setEventDefaults: 'Imposta predefiniti evento',
      preferencesText:
        'Definisci le impostazioni predefinite per i nuovi eventi di questo tipo, inclusa la risposta predefinita per i membri',
      responseSubTitle: 'Richiedi commento (opzionale)',
      requestComment:
        'Abilita questa opzione per richiedere all\'utente un commento quando viene selezionata questa risposta(ad es.per "No, non posso venire" per chiedere il motivo)',
      registeredUsers: 'Risposte',
      guests: 'Ospiti',
      nomination: 'Nomina',
      nominated: 'Sei nominato/a',
      havePenalty: 'Hai ricevuto una penalità',
      penalty: 'Pena',
      invitees: 'Invitati',
      awaiting: 'In attesa di',
      minutes: 'Minuti',
      hours: 'ore',
      days: 'giorni',
      weeks: 'settimane',
      weekDay: 'Giorno feriale',
      duration: 'Durata',
      months: 'mesi',
      years: 'anni',
      viewAppointment: 'Mostra Evento',
      myResponse: 'La Mia Risposta',
      noEvents:
        'Calma qui? Forse hai risposto a tutti gli appuntamenti, non sei attivato o non ce ne sono di programmati. Gli amministratori e i capigruppo sono responsabili.',
      shareEvent:
        'Condividi evento — clicca su questa icona per copiare questo evento e inviarlo ovunque tu possa incollare dei testi (email, WhatsApp, ecc.)',
      shareEventOption: "Condividi il link dell'appuntamento",
      exportPDFOption: 'Esportazione come PDF',
      deadlineExpired:
        "Il termine di registrazione di questo evento sembra scaduto, o l'evento è già passato.",
      totalInvitees: 'Totale invitati',
      responsesReceived: 'Risposte ricevute',
      additionalGuests: 'Ospiti aggiuntivi',
      noPermissionToGiveReply: "Tu n'as pas le droit de changer cela",
      responsesPercentage: 'Percentuale di risposte',
      acceptedTasks: 'Compiti accettati',
      acceptedTasksByUser: 'Attività per utente',
      givenComments: 'Commenti dati',
      givenCommentsByUser: "Commenti dati dall'utente",
      optionalMessage: 'Clicca qui per vedere le notizie.',
    },
    task: {
      createTask: 'Creare compito',
      taskName: 'Nome del compito',
      noTasks: 'Nessun compito',
    },
    groupSettings: {
      title: 'Impostazioni gruppo',
      tabs: {
        general: 'Preferenze generali',
        eventTypes: 'Tipi di eventi',
        responses: 'Risposte',
        membersManagement: 'Amministrazione dei membri',
        other: 'Altro',
      },
      deleteGroupHeader: 'Sei sicuro di voler eliminare questo elemento?',
    },
    documentation: {
      title: 'Documentazione',
      generalDocumentation: 'Documentazione generale',
      uploadedFiles: 'File caricati',
      chooseFile: 'Scegli file',
      tipMessage: 'Massima dimensione del file da caricare: 50 MB',
      overview: '{{value}} GB usati su {{capacity}} GB',
      uploadSuccess: 'Il documento è stato caricato con successo',
      deleteSuccess: 'Il documento è stato eliminato con successo',
      attachFiles: 'Allega file',
      limitExceededHeader:
        'Avete raggiunto il limite di spazio disponibile per la documentazione.',
      sort: {
        label: 'Ordina per',
        nameAsc: 'Nome (A-Z)',
        nameDesc: 'Nome (Z-A)',
        dateOldest: 'Data (Meno recente)',
        dateNewest: 'Data (Più recente)',
      },
    },
    subscriptions: {
      title: 'Abonnements',
      subscription: 'Abonnement',
      yearly: 'Annuel',
      adBlocker: {
        header: 'Disabilita Ad-Blocker per i prezzi locali',
        body: "Disabilita il tuo ad blocker per vedere i prezzi corretti. I passaggi variano a seconda del browser e del blocker: Clicca sull'icona del blocker vicino alla barra URL e seleziona 'Disattiva'. Ricarica la pagina. Per altri passaggi, consulta l'aiuto del tuo blocker o browser. Grazie.",
      },
      monthly: 'Mensuel',
      freeTitle: 'Essai gratuit de 2 mois',
      freeDescription: 'Commencez votre essai gratuit de 2 mois maintenant',
      freeText1: "Ouvrir jusqu'à 2 communautés",
      freeText2: 'Accès complet à toutes les fonctionnalités',
      freeText3:
        'Aucune carte de crédit requise - inscrivez-vous et commencez immédiatement',
      freeText4: 'Pas de renouvellement automatique',
      freeText5: 'Pas de publicité',
      subscribe: 'SOUSCRIRE',
      seeDetails: 'VOIR DETAILS',
      buildCustom: 'Custom',
      month: 'mese',
      year: 'anno',
      community: 'Comunità',
      communities: 'Comunità',
      maxStorage: 'Stoccaggio GB',
      classic: 'Classico',
      classicPlus: 'Classico+',
      popular: 'POPOLARE',
      textInnerTop:
        "Scegli l'abbonamento Gumb adatto alla tua comunità. Con questo abbonamento puoi dividere la tua team (o la tua comunità) in fino a {{groups}} gruppi e gestire fino a {{members}} membri. Puoi aggiornare o annullare il tuo abbonamento quando vuoi. Il mese di prova gratuito è attivato automaticamente dopo esserti iscritto di successo sull'applicazione Gumb senza dover fornire i tuoi dettagli di pagamento. Dopo che finisce la prova gratuita, puoi selezionare uno degli abbonamenti per continuare ad usare Gumb, fornendoci il tuo metodo di pagamento preferito.",
      textInnerBottom:
        "Quando acquistate un abbonamento, firmate un contratto a tempo indeterminato. Il vantaggio: l'abbonamento si rinnova automaticamente alla scadenza del periodo di validità di un anno/mese (ve lo ricorderemo via e-mail). È possibile pagare l'abbonamento con carta di credito (MasterCard e Visa) o PayPal.",
      chatsExcluded: 'Chat esclusi',
      current: 'ATTUALE',
      pay: 'PAGAMENTO',
      usingBlocker: 'Disabilita il blocco degli annunci e ricarica la pagina',
      failedToSubscribe: 'Iscrizione fallita',
      usedGroups: 'Gruppi utilizzati',
      usedMembers: 'Membri utilizzati',
      usedCommunity: 'Comunità utilizzate',
      usedStorage: 'Stoccaggio utilizzato',
      orderSummary: "Riepilogo dell'ordine",
      tax: 'TASSA',
      total: 'Totale',
      periodEnds: 'Fine periodo di pagamento',
      cancelSubscription: 'ANNULLA IL MIO ABBONAMENTO',
      paypalLink: 'CRONOLOGIA FATTURAZIONE',
      chatEnabled: 'Chat incluse',
      chatExcluded: 'Chat escluse',
      paymentApproved: 'Pagamento accettato',
      paymentError: 'Errore pagamento',
      limitExceededHeader: 'Hai sorpassato il massimo numero di membri',
      limitExceededMessage:
        "Per favore contatta il proprietario della comunità. Il proprietario della comunità puo controllare i limiti dell'abbonamento e aggiornare il tuo abbonamento comunitario.",
      limitExceededMessageOwner:
        'Per favore visita la pagina degli abbonamenti per controllare i limiti del tuo abbonamento o aggiornare il tuo abbonamento',
      inactiveSubscriptionHeader: 'Accettazione pagamento in attesa',
      inactiveSubscriptionMessage:
        'Siamo spiacenti, non può continuare questa azione. Prima dovrebbe completare il processo di pagamento.',
      subscriptionNotPaid:
        "Il tuo abbonamento è scaduto o l'abbonamento non è stato pagato.",
      contactAdmin: "Si prega di contattare l'Admin.",
      free: 'Account gratuito',
      requireSubscription:
        'Gumb è gratuito per i membri delle community attive. Crea la tua community e inizia subito la tua prova gratuita o modifica il tuo abbonamento in "Modifica abbonamento".',
      requireSubscriptionMember:
        'Non è stato rilevato alcun abbonamento valido. Non devi fare nulla qui da solo. Il proprietario della comunità invece può controllare nel suo profilo sotto "Abbonamento" se la licenza per questa comunità è attivata o estesa.',
      cancelConfirmation: 'Sei sicuro di voler cancellare il tuo abbonamento?',
      unsubscribe: 'Cancellare',
      cancelled:
        'Cancellazione confermata. Una conferma di cancellazione è stata appena inviata alla tua email. Continuerai ad avere accesso a tutte le funzioni fino alla data di pagamento.',
      renewSubscriptionText:
        'Grazie!\n\nIl tuo precedente abbonamento sarà rinnovato, se lo attivi ora',
      activate: 'Attiva ora',
      activated:
        'Il tuo abbonamento è stato attivato con successo. Siamo molto contenti che ora organizzi e pianifichi con Gumb. Una conferma della tua iscrizione è stata appena inviata alla tua email. Il tuo abbonamento è ora valido e puoi accedere a tutte le funzioni.',
      approved:
        "L'abbonamento è in preparazione. Un momento per favore... Questo può richiedere fino a 45 secondi. Fino ad allora, non ricaricare la pagina o chiudere la finestra del browser.",
      trialActivated:
        'Il tuo periodo di prova è iniziato. Tu e i tuoi amministratori potete ora iniziare a pianificare, invitare membri e gestire le impostazioni.',
      cancelledTitle: 'Annullato',
      startTrial: 'AVVIA LA PROVA GRATUITA',
      startTrialHeader: 'Test lungooooo!',
      startTrialText:
        "Per permettere alla tua comunità di testare a fondo tutte le funzionalità in un'operatività reale, offriamo 2 mesi completi di test! Nessun dato di pagamento richiesto e nessun costo nascosto: prova tutte le funzioni con tutti i membri in tutta tranquillità.",
      subscriptionExpired:
        "Questa community al momento non ha un abbonamento attivo. Per aggiungere nuovi utenti, è necessario rinnovare l'abbonamento.Per domande: support@gumb.app",
      startTrialPopupButton: 'Prova Gratuitamente',
      paymentMethods: 'Carta di credito o Paypal',
      subscriptionsPopup: {
        title: 'Il tuo abbonamento è scaduto',
        subtitle:
          "Il tuo abbonamento è scaduto, ma il proprietario della tua community, <b>{{ownerName}}</b> può facilmente cambiarlo. <brPure />Ogni community ha un proprietario che è responsabile dell'abbonamento. 👑",
        firstBox: {
          title:
            '<b>{{ownerName}}</b> <brPure />Desideri rinnovare il tuo abbonamento e riportare in vita la pianificazione del team?',
          btnText: 'Rinnova',
        },
        secondBox: {
          title:
            "<b>Per il proprietario</b> <brPure />Vuoi subentrare nell'abbonamento e diventare tu stesso il proprietario della community?(Inizia un tuo abbonamento, contattaci in seguito e trasferiremo la proprietà a te.)",
          btnText: 'Diventa proprietario',
        },
      },
    },
    terms: {
      title: 'Termini e condizioni di Gumb',
    },
    errors: {
      cantSendMessageToYourself: 'Non puoi inviare un messaggio a te stesso.',
      downloadFail: 'Errore di download, riprova.',
      required: 'Campo obbligatorio',
      email: 'Per favore inserisci una email valida',
      invalidEmail:
        "Ops! Inserisci un'email valida. Controlla gli errori e riprova.",
      integer: 'Deve essere un intero',
      confirmPassword: 'Le password non sono identiche. Riprova per favore',
      NotAuthorizedException: 'Il nome utente o la password non sono validi.',
      invalidCode: 'Questo codice non è valido. Riprova per favore.',
      errorToast: 'Errore: {{message}}',
      invalidRequest: 'Richiesta non valida',
      passwordChanged: 'Password cambiata',
      password:
        'La password dovrebbe contenere al meno 8 caratteri, lettere maiuscole e minuscole, numeri',
      termsError: 'Per favore accetta i nostri termini e condizioni',
      min: 'Minimo {{value}} caratteri',
      max: 'Massimo {{value}} caratteri',
      startDate: 'La data non può essere precedente al {{value}}',
      endDate: 'La data non può essere precedente alla data di inizio',
      fileSize: 'Dimensione file troppo grande',
      fileSizeCustom:
        'File troppo grande: La dimensione massima è {{size}} MB.',
      customFileSize:
        'File troppo grande: Max {{size}} GB. Rimuovilo e riprova.',
      unsupportedFormat: 'Formato file non supportato',
      postalCode: 'Minimo 4 numeri',
      sorry: 'Siamo spiacenti',
      text404: 'Sembrerebbe che la pagina che cerchi non è disponibile :(',
      url: 'Deve essere un URL valido.',
      text500: 'Qualche cosa è andata male :(',
      onlyForGroupAndAdmin:
        'Questa funzione è disponibile solo per gli amministratori e i capi gruppo',
      onlyForAdmin: 'Solo gli admin possono utilizzare questa funzione.',
    },
    eventTypes: {
      createSuccess: 'Tipo di evento creato con successo',
      editSuccess: 'Le modifiche sono state aggiornate con successo',
      deleteSuccess: 'Tipo di evento eliminato con successo',
    },
    eventTasks: {
      createSuccess: 'Compito evento creato con successo',
      editSuccess: 'Le modifiche sono state aggiornate con successo',
      deleteSuccess: 'Compito evento eliminato con successo',
    },
    eventResponses: {
      createSuccess: 'Risposta evento creata con successo',
      editSuccess: 'Le modifiche sono state aggiornate con successo',
      deleteSuccess: 'Risposta evento eliminata con successo',
    },
    tips: {
      eventType:
        "L'amministratore delle comunità può aggiungere nuovi tipi di eventi nella pagina di impostazioni della comunità",
    },
    frequencies: {
      doNotRepeat: 'Non si ripete',
      daily: 'Ogni giorno',
      weekly: 'Ogni settimana',
      monthly: 'Ogni mese',
      yearly: 'Ogni anno',
      everyWorkingDay: 'Tutti i giorni della settimana (dal lunedì al venerdì)',
      other: 'Personalizza…',
    },
  },
  fr: {
    appWidget: 'Installer Gumb App sur votre appareil',
    featurePopups: {
      appointmentSchedule: {
        innerHtml:
          "<h3>New feature: Download your appointment schedule as an Excel or CSV file. </h3><p>We have completely redesigned the statistics page, and you can export your entire schedule now. The new report called 'Appointment schedule' gives you an overview of facts and figures of your events.</p><p>We are already working on another report called 'Member figures' which will be related to user performance. </p><p>The diary report is now available in the web app for all users.</p>",
        buttonOk: 'LATER',
        buttonRedirect: 'SEE NOW',
      },
      appleCalendar: {
        title: 'CONNECTEZ VOTRE CALENDRIER (iCal)&nbsp;🍏',
        message:
          "Nous avons réussi! Après beaucoup d'efforts, Gumb permet maintenant une connexion directe du calendrier dans la version WEB pour une synchronisation sans faille. Restez à l'écoute pour la sortie de la version iOS/Smartphone&nbsp;bientôt&nbsp;disponible!",
        extraMessage: 'Ouvrez le guide étape par étape',
        redirectText: 'ici',
        okButton: 'Connecter maintenant',
        cancelButton: 'Pas maintenant',
      },
      preferecePopup: {
        title: '2 New Features',
        first: {
          title: 'Set All to "Going":',
          subtitle:
            'Set the default response for new or existing events – for all participants at once!',
          link: '',
        },
        second: {
          title: 'Default Event Settings:',
          subtitle: 'Define how new events should look.',
          link: 'Instructions',
        },
      },
      calendar: {
        innerhtml:
          "<h3>Nous l'avons fait !</h3> <p> La connexion GOOGLE CALENDRIER est là ! </p> <br/> <p> Les rendez-vous de Gumb - Chaque communauté peut être sélectionnée individuellement, peuvent maintenant être transférés automatiquement dans ton calendrier.  </p> <div class='images-container'> <img src='https://gumb-cdn-prod.s3.eu-central-1.amazonaws.com/featurePopups/calendar-1.png' alt='calendar-1' /> <img src='https://gumb-cdn-prod.s3.eu-central-1.amazonaws.com/featurePopups/calendar-2.png' alt='calendar-2' /></div> <p> Tout ce que tu dois faire t'est expliqué dans ce guide : <br/><a target='_blank' rel='noopener noreferrer' href='https://gumb.app/en/support/connect-gumb-with-google-calender/'>→&nbsp; Lire les instructions complètes </a> </p> <p>Guide de démarrage rapide :</p> <ol> <li>Schritt: Se connecter et important : cocher la case !</li> <li>Sélectionner la communauté</li> <li>Attendre quelques minutes et recharger la page.</li> </ol> <p> Connecte ton agenda Google pour gagner du temps et profiter au maximum de chaque jour. </p>",
        buttonOk: 'RIEN POUR MOI',
        buttonRedirect: 'CONNECTE-TOI MAINTENANT',
      },
      notifications: {
        title: 'Comment les membres doivent-ils être informés ?',
        firstRow:
          'Nous avons ajouté : Messages push mobiles et paramètres de notification.',
        secondRow:
          "Les paramètres de notification gérés par les administrateurs peuvent désormais être ajustés pour l'ensemble de la communauté :",
        thirdRow: 'Tout ce que vous avez à faire est :',
        listFirstRow:
          'Aller dans les paramètres de la communauté → notifications.',
        listSecondRow:
          'Activez/désactivez les options dans chaque catégorie, → pour que tout soit parfaitement adapté à vous. (→ Lire les instructions <0>ici</0>)',
        lastRow:
          "Indiquez à vos membres d'activer maintenant les paramètres de push ou d'e-mail dans leur propre profil Gumb. ",
        buttonLater: 'Plus tard',
        buttonSettings: 'Aller aux paramètres maintenant',
      },
      pushNotifications: {
        title: '📢 Communication de niveau supérieur : Notifications Push',
        message:
          "Les administrateurs et les chefs de groupe peuvent désormais créer des notifications push pour vous envoyer des informations importantes directement sur votre smartphone.\n\nCe que vous pouvez faire avec les notifications push :\n\n• <b>Partager des informations importantes instantanément</b> (ex : changements d'horaires, urgences)\n• <b>Atteindre des personnes individuelles ou des équipes entières</b> (communication personnalisée)\n• <b>Enregistrer des messages en tant que modèles pour gagner du temps</b> (communication efficace)",
        extraMessage: 'Restez informé et ne manquez plus aucune nouvelle !',
        cancelButton: 'Voir plus tard',
        okButton: 'Essayer maintenant',
        newMessageOne:
          'Les administrateurs et les chefs de groupe peuvent désormais communiquer directement avec des personnes individuelles ou des équipes entières pour vous envoyer des informations importantes directement sur votre smartphone.\n\n',
        redirectLink: 'Créez des notifications push personnalisées',
        newMessageTwo:
          ', sélectionnez les destinataires et enregistrez même vos messages en tant que modèles pour une utilisation ultérieure.',
      },
      importEvent: {
        title: 'Upload/Import Events ',
        subText:
          "No more manual entry! Easily import your events with a CSV or Excel file. Whether it's 30 or 300 events - it's quick and can even be undone if there are mistakes.",
        cancelButton: 'View later',
        okButton: 'Try now',
        secondText: 'Find step-by-step instructions',
      },
      mail: {
        title: 'Nouvelle fonction : MODE MESSAGE',
        firstRow:
          "La fonction e-mail intégrée simplifie et accélère la communication au sein de l'équipe.  Envoyer d'urgence un e-mail à tout le monde ? Mais uniquement aux membres qui ont répondu \"oui\" lors du rendez-vous ? → C'est désormais possible en quelques clics !",
        secondRow:
          "Le mode mail est dès à présent disponible dans l'application web pour les administrateurs et les responsables de groupe. Il sera encore développé dans les temps à venir.",
        thirdRow:
          'Pour en savoir plus : <0>https://gumb.app/support/mailmodus/</0>',
        buttonLater: 'PLUS TARD',
        buttonMail: 'VOIR MAINTENANT',
      },
      eventPdf: {
        title: 'Nouvelle fonction : télécharger le rendez-vous au format PDF',
        firstRow:
          "Gagnez du temps et imprimez les détails de l'événement en un seul clic !",
        secondRow:
          'Peu avant les événements à venir ou pour les événements passés, tous les détails des rendez-vous, y compris toutes les statistiques de réponse, peuvent être exportés en PDF.',
        thirdRow:
          "Condition préalable pour l'agenda : tu as des droits d'administrateur.",
        fourthRow:
          "Condition préalable pour l'agenda de groupe : tu es chef de groupe.",
        fifthRow:
          "Cette fonction est disponible dès maintenant dans l'application web.",
      },
    },
    menu: {
      homePage: 'ACCUEIL',
      subscriptions: 'Souscriptions',
      groupScheduler: 'CALENDRIER',
      documentation: 'FICHIERS',
      chats: 'Chat',
    },
    dayOfWeek: {
      day1: 'LUN.',
      day2: 'MAR.',
      day3: 'MER.',
      day4: 'JEU.',
      day5: 'VEN.',
      day6: 'SAM.',
      day0: 'DIM.',
    },
    email: {
      sender: 'Expéditeur',
      recipients: 'Destinataires',
      otherRecipients: 'Autres destinataires (séparer par une virgule)',
      subject: 'Sujet',
      message: 'Message',
      attachCommunityDocs: 'Joindre des documents communautaires',
      emailSentSuccessfuly:
        "L'e-mail a été envoyé avec succès. Veuillez noter que : Il s'agissait d'un \"No Reply E-Mail\" - un e-mail auquel le destinataire ne peut pas répondre.",
      sendCopy: 'Envoie-moi une copie',
      plusTooltip: 'Écrire un nouvel e-mail',
      maxAttachmentsSize:
        'Si vous ajoutez plusieurs pièces jointes, leur taille cumulée ne doit pas dépasser 15 Mo.',
      changeEmail: {
        title: 'Email de confirmation envoyé',
        message:
          "Nous venons de vous envoyer un e-mail de confirmation avec un lien pour votre nouvelle adresse e-mail. Veuillez ouvrir votre boîte de réception et cliquer immédiatement sur le lien pour confirmer vos modifications. Si vous ne recevez pas l'e-mail dans quelques minutes, veuillez vérifier votre dossier de spam. Si vous avez des questions ou des problèmes, notre service clientèle est là pour vous aider.",
      },
    },
    pushNotification: {
      header: 'Notifications Push',
      filterByRecipient: 'Sélectionner les destinataires',
      filterByResponse: 'Sélectionner les destinataires par réponse',
      pushMessage: 'Créer push',
      saveAsTemplate: 'Enregistrer comme modèle',
      sendCopy: "M'envoyer une copie",
      pasteUrl: '🌐 Lien vers un événement ou externe?',
      plusTooltip: 'Créer une nouvelle notification push',
      toasterText:
        "🆕 Nouvelle fonctionnalité : Tu peux maintenant ajouter un lien externe, comme n'importe quel site web 🌐 (URL), dans les notifications push!\nRedirige les destinataires directement vers le site.",
      templates: 'Modèles',
      title: 'Titre',
      lastUsed: 'Dernière utilisation',
      noTemplates: 'Les modèles enregistrés s’affichent ici',
      template: 'Modèle',
      sendConfirmHeader: "Vérifier avant d'envoyer",
      totalRecipients: 'DESTINATAIRES',
      redirectLink: 'VERS LE LIEN',
      successTitle: 'Notification envoyée !',
      successMessage:
        'Votre message a été envoyé à <b>{{totalRecipients}}</b> destinataires.',
      selectEventFirst: "Veuillez d'abord sélectionner un événement",
      templateLimitTitle: 'Limite de modèles atteinte',
      templateLimitInfo:
        "Enregistrez jusqu'à 8 de vos messages en tant que modèles pour les réutiliser ultérieurement.Nous avons déjà ajouté 3 modèles pour vous inspirer - n'hésitez pas à les modifier ou à les supprimer.",
      templateLimitMessage:
        'Vous avez atteint le nombre maximum de modèles. Veuillez en supprimer un pour en créer un nouveau.',
      urlInfo:
        "Vous pouvez ajouter ici des liens vers n'importe quel site Web (sauf Gumb). Il peut s'agir d'un lien vers une page de produit, un article de presse ou tout autre élément que vous jugez pertinent. Collez simplement l'URL complète (y compris https://).",
      urlInfoOne:
        "Tu peux ajouter ici n'importe quel lien vers un site externe (URL) pour diriger tes destinataires directement vers celui-ci. Si tu choisis un événement ci-dessus, l'application insérera le lien de l'événement, et en cliquant sur la notification push, l'événement s'ouvrira directement dans Gumb.",
      urlInfoTwo:
        "Prochainement : Ajoutez n'importe quel lien externe (sauf Gumb) à vos notifications push ! Faites la promotion de sites Web, d'articles de presse et bien plus encore. Restez&nbsp;à&nbsp;l'écoute&nbsp;!",
      urlTooltip:
        'Vous pouvez créer une notification push avec ou sans lien vers un événement. Vous souhaitez partager le lien de votre événement ? Sélectionnez simplement un événement ci-dessus pour le copier automatiquement ici.',
    },
    common: {
      onlyAdmin: 'Seuls les admins peuvent utiliser cette fonction.',
      download: 'Télécharger',
      attachFile: 'Joindre un fichier',
      new: 'Nouveau',
      unseen: 'Non vus',
      more: 'Plus',
      less: 'Moins',
      sunday: 'Dimanche',
      monday: 'Lundi',
      tuesday: 'Mardi',
      wednesday: 'Mercredi',
      thursday: 'Jeudi',
      friday: 'Vendredi',
      saturday: 'Samedi',
      january: 'Janvier',
      february: 'Février',
      march: 'Mars',
      april: 'Avril',
      may: 'Mai',
      june: 'Juin',
      july: 'Juillet',
      august: 'Août',
      september: 'Septembre',
      october: 'Octobre',
      november: 'Novembre',
      december: 'Décembre',
      overallScheduler: 'Planificateur général',
      selectEvent: "Sélectionnez l'événement (facultatif)",
      displayEventsFrom: 'Afficher les événements de',
      modalExit:
        'Êtes-vous sûr de vouloir quitter sans enregistrer vos données ?',
      all: 'Tous',
      tooltipEdit: 'Modifier le membre',
      tooltipDelete: 'Supprimer un membre',
      overview: 'Aperçu',
      cancel: 'Annuler',
      removeLogo: 'Etes-vous sûr de vouloir supprimer ceci?',
      noTasks: 'Aucune tâche trouvée',
      noUsers: 'Aucun utilisateur trouvé',
      role: 'Rôles',
      admin: 'Administrateurs',
      member: 'Membres',
      group_lead: 'Chefs de bande',
      email: 'Email',
      groups: 'Groupes',
      logoutHeader: 'Déconnexion ?',
      logoutMessage: "Vous serez redirigé vers l'écran de connexion.",
      deleteHeader: "Êtes-vous sûr de vouloir supprimer l'utilisateur?",
      logout: 'Déconnexion',
      delete: 'Supprimer',
      tablePlaceholder: 'Aucun membre à montrer',
      contentPlaceholder: 'Aucun contenu à montrer',
      create: 'Créer',
      copy: 'Copier',
      visible: 'Visible',
      saveChanges: 'Sauvegarder les changements',
      newField: 'Nouveau champ',
      fieldName: 'Nom du champ',
      addField: 'Ajouter un champ',
      notifications: 'Notifications',
      participants: 'Participants',
      tasks: 'Tâches',
      documentation: 'Documentation',
      description: 'Description',
      facebook: 'Facebook',
      twitter: 'Twitter',
      linkedin: 'LinkedIn',
      instagram: 'Instagram',
      youtube: 'Youtube',
      startDate: 'Date de début',
      startTime: 'Heure de début',
      startDateTime: 'Date/heure de début',
      endDateTime: 'Date/heure de fin',
      endDate: 'Date de fin',
      endTime: 'Heure de fin',
      icon: 'Icône',
      sendMessage: 'Envoyer message',
      aboutMe: 'A propos de moi',
      selectAll: 'Sélectionner tout',
      approve: 'Approuver',
      reject: 'Rejeter',
      backToGumb: 'Retour à Gumb',
      edit: 'Editer',
      addComment: 'Ajouter commentaire',
      answer: 'Enregistrer',
      ok: 'Ok',
      withoutGroup: 'Sans groupe',
      copyLink: 'Copier le lien',
      copied: 'Copié',
      sendInviteLink: "Envoyer un lien d'invitation",
      apply: 'Appliquer',
      from: 'De',
      to: 'À',
      enable: 'Activer',
      search: 'Recherche',
      color: 'Couleur',
      columns: 'Colonnes affichées',
      hiddenColumns: 'Colonnes masquées',
      noResultsFound: 'Aucun résultat trouvé',
      sorryNoResults:
        "Nous sommes désolés que vous n'ayez pas trouvé ce que vous cherchiez. Veuillez essayer d'une autre manière.",
      tutorial: 'Guide',
      next: 'Suivant',
      back: 'Retour',
      step: 'Étape',
      createSuccess: {
        title: 'Créée',
        message: 'Votre option a été créée avec succès.',
      },
      editSuccess: {
        title: 'Modifiée',
        message: 'Vos modifications ont été enregistrées.',
      },
      deleteSuccess: {
        title: 'Supprimée',
        message: "L'option a été supprimée avec succès.",
      },
      toolTipText: {
        faq: 'Aide: Guides, Contact, Plus.',
        communityPlusIcon: 'Créer une nouvelle communauté',
        logoGumb:
          'Mystère en préparation : Découvrez plus tard ce que cache ce bouton !',
        memberPlusIcon: 'Ajouter un nouvel utilisateur',
        memberArrowIcon: 'Aller à la vue des membres',
        eventType: "Créer un type d'événement",
        responses: 'Créer une option de réponse',
        task: 'Créer une tâche',
        schedulerPlusIcon: 'Créer un événement ou un groupe',
      },
      shareEventTitle: "Partager le lien de l'événement via:",
      copyEventLink: 'Ou copier le lien:',
    },
    auth: {
      signInWith: 'Connectez-vous avec',
      continueAs: 'Continuer en tant que',
      create: 'Créer compte gratuit',
      signUp: 'Créer un compte',
      signIn: 'Se connecter',
      welcome: 'Bienvenue!',
      createAccount: 'Créer compte gratuit',
      forgotPassword: 'Mot de passe oublié?',
      resetPassword: 'Réinitialiser le mot de passe',
      resetPasswordTooltip:
        "Se reimposti la password, il membro riceverà un'email con istruzioni su come impostare una nuova password. Tuttavia, Gumb non può reimpostare per le registrazioni tramite Facebook/Google/Apple; dovrebbero usare l'icona corrispondente.",
      resetPasswordModalTitle: 'Réinitialisation du mot de passe lancée',
      resetPasswordModalText:
        "Le membre recevra sous peu un lien de réinitialisation à l'email indiqué ci-dessous. Pour Facebook/Google/Apple, ce n'est pas possible, mais le membre peut toujours utiliser l'icône correspondante pour se connecter.",
      resetPasswordErrorModalTitle:
        'Réinitialisation du mot de passe impossible',
      resetPasswordErrorModalText:
        "Note! Ce membre semble s'être inscrit via les réseaux sociaux (par exemple, Facebook, Google, Apple). Par conséquent, Gumb ne peut pas réinitialiser le mot de passe pour ce compte. Cependant, l'utilisateur peut toujours se connecter en utilisant l'icône du réseau social correspondant. Veuillez informer le membre en conséquence.",
      terms: {
        '0': "J'accepte les",
        '1': 'Termes',
        '2': 'et la',
        '3': 'Politique de Confidentialité',
      },
      home: 'Accueil',
      confirmed: 'Email confirmé avec succès',
      verifying: "Vérification de l'email...",
      recovery: 'Récupération du mot de passe',
      send: 'Envoyer',
      codeSended:
        'Le lien de vérification a été envoyé à votre adresse e-mail. Veuillez vérifier et confirmer. Soyez attentif au dossier spam.',
      signUpSuccess:
        "Inscription validée. Veuillez confirmer l'inscription dans l'email reçu",
      inviteSentTitle: "Invitation et données d'accès envoyées avec succès",
      inviteSentText:
        "Super! Tu as préenregistré avec succès le membre et envoyé l'invitation avec un mot de passe temporaire. Le membre peut maintenant se connecter et changer son mot de passe. Si nécessaire, recharge cette page pour voir immédiatement le membre. Toi et tes administrateurs pouvez maintenant définir les allocations de groupe et attribuer des rôles. À l'aide de l'icône crayon à l'extrême droite, tu peux également compléter les détails personnels du membre. Continue comme ça!",
      reset: 'Réinitialiser',
      yourEmail: '{{value}} est déjà votre adresse email',
      existEmail: '{{value}} existe déjà',
      labels: {
        email: 'Email',
        currentEmail: 'Courriel actuel',
        newEmail: 'Nouveau email',
        password: 'Mot de passe',
        oldPassword: 'Ancien mot de passe',
        newPassword: 'Nouveau mot de passe',
        confirmPassword: 'Confirmer mot de passe',
      },
      accountExists:
        "Cette adresse e-mail est déjà utilisée pour un compte Gumb. Si cet e-mail t'appartient, clique sur Mot de passe oublié et vérifie ta boîte de réception et ton dossier de courrier indésirable.",
      codeExpired:
        'Ce lien a expiré. Après avoir saisi votre adresse électronique ici, nous vous enverrons un nouveau lien pour terminer la vérification de votre compte.',
      userNotConfirmed:
        "Tu dois encore vérifier ton compte. Nous t'avons envoyé un e-mail avec un lien de confirmation. Si tu n'as pas reçu d'e-mail, vérifie également le dossier spam de ta boîte de réception. ",
      invalidCredentials: "Nom d'utilisateur ou mot de passe incorrect",
      userNotFound:
        "Apparemment, cet e-mail est déjà connecté via Google/Apple/Facebook ou n'est pas enregistré. Tu ne peux pas modifier le mot de passe d'un média social via Gumb. Essayez plutôt de vous connecter via l'icône des médias sociaux.",
    },
    profile: {
      editSuccess: 'Les modifications ont été mises à jour avec succès',
      settings: 'Paramètres',
      editProfile: 'Modifier profil',
      selectImage: 'Sélectionner image',
      myProfile: 'MMon profil',
      profileEditBtn: 'Ajoute tes infos : adresse, réseaux sociaux, et plus',
      basicInformation: 'Informations de base',
      contactInformation: 'Informations de contact',
      addressInformation: "Informations d'adresse",
      myChat: 'MES CHATS',
      myCommunities: 'Mis comunidades',
      myAppointments: 'Mes rendez-vous',
      subscription: 'Abonnement',
      userProfile: 'Profil utilisateur',
      tellUsAboutYourself: 'Parlez-nous de vous',
      firstName: 'Prénom',
      lastName: 'Nom',
      profileName: 'Nom de profil',
      person: 'Personne',
      role: 'Rôle utilisateur',
      email: 'Email',
      dateOfBirth: 'Date de naissance',
      privatePhoneNumber: 'Numéro de téléphone privé',
      officePhoneNumber: 'Numéro de téléphone bureau',
      mobilePhoneNumber: 'Numéro de téléphone portable',
      street: 'Rue',
      region: 'Département',
      postalCode: 'Code postal',
      city: 'Ville',
      country: 'Pays',
      about: 'A propos',
      linkChangeEmail: "(Modifier l'e-mail)",
    },
    profileCommunities: {
      popupLeave: {
        title: 'Êtes-vous sûr de vouloir quitter cette communauté?',
        button: 'Quittez',
      },
      leaveCommunity: {
        title: 'Êtes-vous sûr de vouloir quitter cette communauté ?',
        message:
          "En quittant cette communauté, votre compte utilisateur reste actif, et vous pouvez toujours rejoindre d'autres communautés.",
        okButton: 'Oui, Quitter',
        cancelButton: 'Annuler',
      },
    },
    profileSubscription: {
      heading: 'Détails de l’abonnement',
      titleMembership: 'Adhésion',
      titlePayment: 'Paiement',
      buttonCancel: 'Annuler l’adhésion',
      buttonRestart: "Redémarrer l'adhésion",
      details: {
        linkChangePlan: 'Plan de changement',
        infoCancelled:
          "Votre abonnement a été annulé et ne sera pas renouvelé. Vous pouvez continuer à utiliser l'application jusqu'à la prochaine date de facturation. Vous pouvez renouveler votre abonnement à tout moment.",
        infoTrial:
          'Votre mois d\'essai est actuellement actif. Après l\'expiration, vous ne serez bien sûr pas facturé.  Si vous souhaitez continuer à utiliser Gumb, sélectionnez un abonnement approprié sous "Plan de changement".',
        infoTrialNew:
          "Votre période d'essai est en cours. Après son expiration le {{expireDate}}, vous ne serez pas facturé. Si vous souhaitez continuer à utiliser Gumb, sélectionnez un abonnement approprié sous 'Plan de changement'.",
        linkShowCommunities: 'Montrer à mes communautés',
        linkHistory: 'Histoire de la facturation',
        linkChangePayment: 'Modifier le mode de paiement',
        currentPlan: 'Plan actuel :',
        communitiesInUse: 'Communautés en service :',
        groupsInUse: 'Groupes en cours d’utilisation :',
        membersInUse: 'Membres en service :',
        storageInUse: 'Stockage en cours d’utilisation :',
        paymentCycle: 'Cycle de paiement :',
        paymentCycleMonth: 'Mensuel',
        paymentCycleYear: 'Annuel',
        nextPayment: 'Prochain paiement:',
        validUntil: "Encore valable jusqu'à :",
        paymentMethod: 'Mode de paiement:',
      },
    },
    profileSettings: {
      title: 'Paramètres du profil',
      notification: 'Notification',
      notificationsPlaceholder: "Vous n'avez pas de notifications",
      changePassword: 'Changer mot de passe',
      changeEmail: 'Email',
      saveNewEmail: 'Sauvegarder le nouvel email',
      language: 'Langue',
      timeFormat: 'Format de l’heure',
      permissions: 'Autorisations',
      allowedToEdit: 'Les administrateurs sont autorisés à modifier mon profil',
      other: 'Autre',
      pushNotification: 'Notification push',
      receiveByEmail: 'Recevoir des notifications par e-mail',
      receivePush: 'Recevoir des notifications par push mobile',
      receiveInApp: 'Recevoir des notifications in-app',
      terms: 'Termes et conditions',
      protectionRegulation: 'Politique de confidentialité',
      deleteAccount: 'Supprimer compte',
      logout: 'Se déconnecter',
      deleteProfileHeader: 'Etes-vous sûr de vouloir supprimer votre compte?',
      notifications: {
        now: 'Maintenant',
        hours: 'Il y a {{value}}h',
        minutes: 'Il y a {{value}}min',
        minute: 'Il y a 1 min',
      },
    },
    calendarConnection: {
      googleCalendar: 'Google Calendar',
      pageTitle: 'Connexion au calendrier',
      connectTitle:
        'Connectez votre calendrier\npour synchroniser automatiquement vos événements',
      connectDescription:
        'Gumb peut créer automatiquement des événements qui apparaissent sur votre calendrier. Gardez la trace des choses avec cette synchronisation.',
      appleCalendar: 'Calendrier Apple',
      appleConnectionSteps: {
        headerOne:
          'Intégrez des événements Gumb dans votre calendrier Apple avec une URL',
        headerOneSplit: 'en 4 étapes ; démo rapide',
        here: 'ici',
        headerTwo: 'Ou instructions détaillées',
        stepOne:
          '1. Dans votre Calendrier Apple, sous "Calendrier", choisissez "Fichier" > "Nouvel abonnement au calendrier".',
        stepTwo:
          '2. Entrez le lien iCal de la Communauté copié ci-dessous, puis cliquez sur "S\'abonner".',
        stepThree:
          "3. Entrez un nom pour le calendrier et choisissez une couleur pour vous aider à l'identifier sur votre calendrier.",
        stepFour:
          '4. Ajustez les préférences comme l\'emplacement (iCloud ou Mac) ou le rafraîchissement automatique, puis cliquez sur "OK".',
      },
      appleConnectionPopup: {
        title: 'Comment connecter ?',
        stepOne: 'Copier le lien de la communauté',
        stepTwo:
          'Dans votre Calendrier Apple, sous "Calendrier", choisissez "Fichier" > "Nouvel abonnement au calendrier".',
        stepThree:
          'Entrez le lien iCal de la Communauté copié, puis cliquez sur "S\'abonner".',
        stepFour:
          'Nommez le calendrier, choisissez une couleur, réglez les préférences comme l\'emplacement (iCloud ou Mac) ou la mise à jour automatique et cliquez sur "OK".',
      },
      appleDisconnectPopup: {
        title: 'Confirmer la Déconnexion',
        message:
          'En cliquant sur "Oui, se déconnecter maintenant", tous les événements Gumb seront supprimés de votre/vos calendrier(s). Veuillez ensuite supprimer manuellement le(s) calendrier(s), car il(s) restera/resteront sinon visible(s).\n\n<b>Pour se désabonner complètement :</b>\n\n• <b>Sur macOS</b>: Ouvrez Calendrier, cliquez droit sur le calendrier > "Se désabonner".\n• <b>Sur iOS</b>: Allez dans "Calendriers", touchez le bouton "Info" à côté du calendrier > "Supprimer le Calendrier".',
        okButton: 'Oui, se déconnecter maintenant',
      },
      appleUncheckBoxPopup: {
        title: 'Confirmer la Déconnexion',
        message:
          'En cliquant sur "Oui, se déconnecter maintenant", tous les événements Gumb seront supprimés de votre calendrier. Veuillez ensuite supprimer manuellement le calendrier, car il restera sinon visible.\n\n<b>Pour se désabonner complètement :</b>\n\n• <b>Sur macOS</b> : Ouvrez Calendrier, cliquez droit sur le calendrier > "Se désabonner".\n• <b>Sur iOS</b> : Allez dans "Calendriers", touchez le bouton "Info" à côté du calendrier > "Supprimer le Calendrier".',
        okButton: 'Oui, se déconnecter maintenant',
      },
      connectWithApple: 'Connecter à Apple (iCal)',
      cantSeeICal:
        'Vous ne voyez pas le lien iCal ? Cochez simplement la case.',
      syncTip:
        'Seuls les événements de la communauté sélectionnée seront synchronisés avec votre calendrier.',
      connect: 'Connectez-vous à',
      connected: 'Connecté',
      disconnect: 'Déconnecter',
      disconnectSuccess: 'Déconnexion réussie',
      selectCommunities: 'Sélection des communautés',
      selectCommunitiesDescription:
        'Seuls les événements de la communauté sélectionnée seront synchronisés avec votre calendrier.',
      authSuccessTitle: 'Connexion réussie',
      authSuccessMessage:
        'Vos communautés vont désormais se synchroniser automatiquement. Attendez un instant puis rafraîchissez la page Google Agenda pour achever la synchronisation initiale.',
      authSuccessTitleTwo: 'Sélectionne ta communauté maintenant',
      authSuccessMessageTwo:
        'Coche la case et appuie sur "Enregistrer les modifications" pour confirmer.',
      syncSuccess:
        'Mise à jour réussie ! \nÀ partir de maintenant, tout sera synchronisé automatiquement. Vous pouvez modifier la sélection des communautés à tout moment.',
      syncSuccessTitle: 'Presque fini !',
      syncSuccessMessage:
        'On relie ta communauté à ton Google Agenda. Ça prendra juste 2-3 minutes. Actualise ta page Google Agenda pour voir les mises à jour.',
      disconnectConfirmTitle: 'Vous êtes sûr ?',
      disconnectConfirmDescription:
        'Si vous vous déconnectez, tous les événements seront supprimés de votre calendrier.',
      disconnectConfirm: {
        title: 'Déconnecter maintenant ?',
        message:
          'La déconnexion supprimera tous les événements de votre Google Agenda. Ne vous inquiétez pas, vous pouvez reconnecter Gumb pour une nouvelle synchronisation à tout moment.',
        cancelButton: 'Annuler',
        okButton: 'Oui, Déconnecter',
      },
      noCommunities:
        "Il n'y a aucune communauté à sélectionner.\nAttendez qu'un administrateur vous ajoute.",
      checkboxWarning: 'ATTENTION : La case doit être cochée !',
    },
    community: {
      plusIconSubText: "Planifie un événement ou crée des séries d'événements.",
      selectAllCommunityAdmins: 'Tous les admins',
      selectAllGroupAdmins: 'Tous les chefs de groupe',
      createSuccess: 'Communauté créée avec succès',
      createFailure: 'Echec de création de la communauté',
      deleteSuccess: 'Communauté supprimée avec succès',
      leaveSuccess: 'Vous avez réussi à quitter la communauté',
      descriptionTitle: 'A propos de la communauté',
      descriptionPlaceholder: 'Ici vous pouvez décrire votre communauté',
      online: 'En ligne',
      updateCommunity: 'Actualiser la communauté',
      members: 'Membres',
      createCommunity: 'Créer communauté',
      communityName: 'Nom de la communauté',
      category: 'Catégorie',
      aboutCommunity: 'A propos de la communauté',
      selectLogo: 'Sélectionner logo',
      selectLogoTip: 'jpg, jpeg, png (5MB)',
      chooseCover: 'Choisir photo de couverture',
      chooseCoverTip: 'jpg, jpeg, png (20MB)',
      website: 'Site Internet',
      partnership: 'Partenariat',
      deleteCommunity: 'Supprimer communauté',
      deleteHeader:
        'Êtes-vous sûr de vouloir retirer cet utilisateur du groupe ?',
      addMembers: 'Ajouter des membres',
      invitationLink: "Lien d'invitation",
      communityRole: 'Rôle dans la communauté',
      joinCommunity: 'Rejoindre la communauté',
      namePlaceholder: 'Equipe de {{value}}',
      requestSent: "Demande d'adhésion envoyée avec succès",
      requestSentButton: "Demande d'adhésion envoyée",
      limitExceededHeader:
        "Vous avez atteint la limite de communautés. Vous devez mettre à niveau votre abonnement pour en créer d'autres.",
      limitExceededMessage:
        "Veuillez contacter le propriétaire de la communauté. Le propriétaire de la communauté peut vérifier les limites de l'abonnement et mettre à niveau votre abonnement à la communauté.",
      limitExceededMessageOwner:
        'Veuillez vous rendre sur la page des abonnements pour vérifier les limites de votre abonnement ou mettre à niveau votre abonnement',
      updateFailure: 'Echec de mise à jour de la communauté',
      buttonAddEvent: 'Ajouter un évènement',
      userBlockedTitle: 'Bloqué',
      userBlockedText:
        "Il semble que quelqu'un vous ait bloqué sur cette communauté. Les administrateurs peuvent bloquer ou débloquer des membres.",
      createOverLimitTitle: 'Limite de communautés atteinte',
      createOverLimitText:
        'Démarre ton essai gratuit maintenant ou choisis un abonnement dans "Plan de changement" pour organiser ta propre communauté.',
      notInvitedTitle: "Pas d'autorisation",
      notInvitedText:
        "Vous n'avez peut-être pas l'autorisation d'accéder à cet événement. Les administrateurs et les chefs de groupe peuvent vous ajouter à l'événement.",
      noRequestsTitle: 'Aucune demande à vérifier',
      noRequestsText:
        'Lorsque des personnes soumettent des demandes pour ta communauté, elles sont affichées ici pour vérification.',
      invitationCodeExpired:
        "Ce lien d'invitation a expiré. Mais tu peux maintenant envoyer une demande d'adhésion que les admins peuvent accepter ou refuser.",
      communityNotFound: 'Communauté non trouvée',
      communityPopup: {
        title: 'Créer un Événement ou un Groupe',
        subTitleOne:
          'Planifiez des événements dans le PLANIFICATEUR central. Utilisez des groupes pour diviser vos membres en équipes.',
        createEvent: {
          infoText:
            "Planifiez des événements pour tous ou pour des groupes/membres spécifiques dans le PLANIFICATEUR. Visible dans le planificateur, sous 'Mes Événements', et dans les groupes.",
          btntitle: 'Créer un Événement',
          linkText: 'Tutorial',
        },
        createGroup: {
          infoText:
            'Chaque groupe a son propre planificateur, avec des événements visibles uniquement pour les membres du groupe. Gérez efficacement les rôles des membres',
          btntitle: 'Créer un Groupe',
          linkText: 'ici',
        },
        chatTitle: 'Options de chat',
        chatSubTitle:
          'Lance un chat 1:1 ou crée une salle de chat pour des groupes.',
        oneToOne: {
          infoText:
            "Clique sur l'icône de chat à côté du nom d'un membre ou sur sa photo de profil pour lui envoyer un message.",
          btntitle: 'Voir la liste des membres',
          linkText: 'Tutorial',
        },
        createChatGroup: {
          infoText:
            'Crée une salle de chat privée pour des membres, équipes ou groupes sélectionnés. Seuls les administrateurs peuvent créer ces salles. ',
          btntitle: 'Créer une salle de chat',
          linkText: 'ici',
        },
      },
    },
    communitySettings: {
      admin: 'Administrateur',
      member: 'Membre',
      blocked: 'Utilisateur bloqué',
      owner: 'Propriétaire',
      group_lead: 'Chef de groupe',
      group_admin: 'Administrateur de groupe',
      chat_admin: 'Admin de chat',
      community_admin: 'Administrateur de la communauté',
      editRoleSuccess: 'Le rôle a été modifié avec succès',
      deleteUserSuccess:
        "L'utilisateur a été supprimé avec succès de la communauté",
      removeUser: {
        title: 'Êtes-vous sûr de vouloir supprimer cet utilisateur ?',
        message:
          "En supprimant cet utilisateur, il sera retiré de votre communauté tout en conservant son compte utilisateur et pourra toujours participer à d'autres communautés.",
        cancelButton: 'Annuler',
        okButton: 'Oui, Supprimer',
      },
      removeUserSuccess: {
        title: 'Utilisateur supprimé',
        message:
          "L'utilisateur a été supprimé avec succès de votre communauté.",
      },
      editSuccess: 'Les modifications ont été mises à jour avec succès',
      title: 'Paramètres de la communauté',
      backToSubscriptionsDetails: "Retour aux détails de l'abonnement",
      tabs: {
        general: 'Préférences générales',
        eventTypes: "Types d'événements",
        subscriptions: 'Abonnements',
        responses: 'Options de réponse',
        roles: 'Membres',
        requests: 'Requêtes',
        other: 'Autre',
        tasks: 'Tâches',
      },
      addMember: 'Ajouter un membre',
      exportMembers: 'Exporter',
      exportMembersTooltip: 'Exporter la liste des membres vers un fichier CSV',
      deleteCommunityHeader: 'Etes-vous sûr de vouloir supprimer ceci?',
      defaultEventList: [
        'Réunion',
        'Formation',
        'Jeu',
        'Répétition',
        'Événement',
        "Réunion du conseil d'administration",
      ],
      defaultResponsesTypes: [
        'Oui, je viens',
        'Non, je ne viens pas',
        'Peut être',
        'Neutre',
      ],
      defaultTasks: ['Qui conduit ?', 'Qui apporte les boissons ?'],
      selectUsers: {
        title: 'Sélectionner des Utilisateurs',
        message:
          "Veuillez cocher les utilisateurs pour approuver ou refuser les demandes d'adhésion.",
      },
      memberExists: 'Le membre existe déjà',
      mailSent: "L'invitation a été envoyée avec succès",
      userNotFound:
        "Aucun profil Gumb n'a été trouvé. Pour créer un nouveau profil et envoyer une invitation à cet e-mail, veuillez fournir des informations supplémentaires",
      disableAddMemberTooltip:
        "Le nombre de membres a déjà atteint la limite. Pour résoudre ce problème, le propriétaire de la communauté peut vérifier une mise à niveau de l'abonnement pour ajouter plus de membres.",
      notifyByFields:
        'Envoyer une notification lorsque les champs suivants sont mis à jour',
      notifyByReplies:
        'Envoyer une notification lorsque les personnes invitées ont répondu par',
      remindBefore: 'Rappeler avant',
      addReminder: '+ Ajouter un rappel (2 max.)',
      notificationsTypes: [
        'Événement créé',
        'Événement mis à jour',
        'Événement supprimé',
        "Rappel d'un événement",
      ],
      notificationsClasses: ['In-App', 'E-mail', 'Push'],
      notificationPopupHeader:
        'Souhaitez-vous envoyer des notifications aux utilisateurs invités ?',
      notificationPopupMessageLabel:
        'Ajouter un message facultatif pour les utilisateurs invités',
      notificationPopupNotify: 'Envoyer',
      notificationPopupDoNotNotify: 'Ne pas envoyer',
      notificationPopupBack: "Retour à l'édition",
    },
    communityInvitation: {
      subjectFirst: 'Sei stato invitato a far parte della comunità',
      subjectSecond: '',
      body: 'Si prega di seguire il link qui sotto per far parte della comunità',
      invitationSuccess: "L'utilisateur est invité à la communauté",
      invitationFailure:
        "Il y a eu une erreur, vous avez peut-être manqué d'abonnement",
      membersInvitation: {
        title: 'Inviter des membres',
        description:
          'Ajoute de nouveaux utilisateurs à ta communauté pour qu\'ils deviennent membres et soient autorisés à réagir à vos événements. Voir les instructions <0>"Ajouter un nouvel utilisateur"</0>.',
        showMore: 'Afficher plus',
        showLess: 'Montrer moins',
        codeInvitation: {
          title: 'Le moyen le plus rapide et le plus simple',
          button: "Copier le lien d'invitation",
          updateLink: 'Générer un lien maintenant',
          generateLink: 'Generate link now',
          howItWorks: 'Comment ça marche',
          goodToKnow: 'Bon à savoir',
          howItWorksText:
            'Les membres cliquent sur "Créer un compte gratuit" en utilisant le lien sélectionné ci-dessus. Lorsque Gumb envoie un e-mail, les membres n\'ont qu\'à confirmer leur adresse e-mail pour obtenir un accès direct.',
          goodToKnowText:
            "C'est très rapide, mais veuillez noter qu'une fois la vérification du compte terminée, les personnes disposant de ce lien pourront accéder immédiatement à vos événements. Vous pouvez donc renouveler le lien ci-dessus quand vous le souhaitez.",
        },
        linkInvitation: {
          title:
            "Mieux vaut prévenir que guérir : envoyer une demande d'adhésion avec le lien communautaire",
          button: 'Copier le lien vers la communauté',
          howItWorks: 'Comment ça marche',
          adminFlow: 'Les admins acceptent ou refusent',
          howItWorksText:
            '<0>Les administrateurs copient et partagent ce lien communautaire.</0><0>Les membres, avec un compte Gumb, cliquent sur "rejoindre la communauté".</0><0>Les membres, sans compte, s\'inscrivent d\'abord sur Gumb, puis cliquent à nouveau sur le lien pour vous envoyer la demande.</0>',
          adminFlowText:
            "Dans l'onglet \"Demandes\", les administrateurs doivent approuver ou refuser la demande d'un membre de rejoindre votre communauté. Lorsqu'il y a des demandes ouvertes, les admins verront une notification sur l'engrenage.",
        },
        addMember: {
          title: '2 autres possibilités : Efficace ou Personnel',
          button: 'AJOUTER DES UTILISATEURS',
          selfSignUp: "Les membres s'inscrivent eux-mêmes",
          preRegister: 'Pré-enregistrement de la personne',
          selfSignUpText:
            "Communique à chacun de s'inscrire tout simplement lui-même sur Gumb (gratuitement) et de vous envoyer l'e-mail enregistré. Vous pouvez ensuite saisir celle-ci ici et le membre atterrit immédiatement chez vous et voit automatiquement tous les rendez-vous.",
          preRegisterText:
            'Enregistrez de nouveaux membres directement via leur adresse e-mail. Cette action leur envoie automatiquement une "Invitation par Email" avec un mot de passe temporaire. Avec celui-ci, ils peuvent se connecter immédiatement et le modifier à leur guise plus tard.',
        },
      },
    },
    group: {
      deleteEventSuccess: "L'événement a été supprimé avec succès",
      createSuccess: 'Le groupe a été créé avec succès',
      deleteSuccess: 'Le groupe a été supprimé avec succès',
      addMembersSuccess: 'Les participant ont été ajoutés avec succès',
      addMembersSuccessWithMessage: {
        title: 'Membre ajouté !',
        message:
          "Votre liste de membres a été mise à jour avec succès. Gérez les rôles et les affectations aux groupes. Vous pouvez également ajouter ou modifier les détails de l'utilisateur.",
      },
      deleteUserSuccess: "On a réussi à supprimer l'utilisateur du groupe",
      addParticipants: 'Ajouter des membres',
      createGroup: 'Créer groupe',
      deleteGroup: 'Supprimer groupe',
      groupName: 'Nom du groupe',
      changeGroupNameSuccess: 'Nom du groupe modifié avec succès.',
      groups: 'Groupes',
      inviteMembers: 'Inviter des membres',
      tabs: {
        schedule: 'Planifier',
        members: 'Membres',
        chat: 'Chat',
        documentation: 'Documentation',
        statistic: 'Exportation et statistiques',
        import: 'Importation',
      },
      limitExceededHeader:
        "Vous avez atteint la limite de groupes. Vous devez mettre à niveau votre abonnement pour en créer d'autres.",
      limitExceededMessage:
        "Veuillez contacter le propriétaire de la communauté. Le propriétaire de la communauté peut vérifier les limites de l'abonnement et mettre à niveau votre abonnement à la communauté.",
      limitExceededMessageOwner:
        'Veuillez vous rendre sur la page des abonnements pour vérifier les limites de votre abonnement ou mettre à niveau votre abonnement',
      groupRole: 'Rôle dans le groupe',
      addGroup: 'Ajouter un groupe',
    },
    chat: {
      read: {
        now: "{{name}} lu à l'instant",
        hours: '{{name}} lu il y a {{value}}h',
        minutes: '{{name}} lu il y a {{value}} min',
        minute: '{{name}} lu il y a 1 min',
        day: '{{name}} lu le {{value}}',
        dateTime: '{{name}} lu le {{value}} à {{time}}',
      },
      noRead: 'Pas encore lu',
      lastAdmin:
        "Un salon de discussion doit avoir au moins un administrateur ou un responsable de groupe. Assurez-vous qu'au moins une personne a toujours ce rôle.",
      update: {
        successUpdate: 'Le groupe de discussion a été mis à jour avec succès',
        btn: 'ENREGISTRER',
        success: 'La liste des membres a été mise à jour',
      },
      modal: {
        btn: 'Gérer',
        about: 'Infos',
        editRoom: 'Modifier la salle de discussion',
        delete: 'Supprimer la salle de discussion',
        editMember: 'Modifier les membres',
      },
      details: {
        title: 'Détails du salon',
        secondTitle: 'INFORMATIONS',
        name: 'Nom du groupe de discussion',
      },
      delete: { success: 'Le salon de discussion a été supprimé' },
      create: {
        title: 'Créer une salle de chat',
        name: 'Nom de la salle de chat',
        description: 'Description du groupe (optionnel)',
        btnNext: 'SUIVANT',
        success: 'Salon de discussion créé',
      },
      new: 'Nouveau',
      chat: 'chat',
      inputPlaceHolder: 'Saisir un message',
      searchHover: 'Rechercher ou démarrer un nouveau chat',
      op1: 'Fichiers & Vidéos',
      op2: 'Photos',
      files: 'Fichiers',
      plusTooltip: 'Créer un nouveau chat ou un groupe de discussion',
      emptyStatus: {
        noChatSelected: {
          firstLine: 'Sélectionne une conversation pour voir le chat,',
          secondLine: 'ou clique ici pour démarrer un',
          LinkText: 'NOUVEAU CHAT',
        },
        noChatUser:
          'Pas encore de chats ? Lance un chat 1:1 ou attends que ton admin t’ajoute.',
        noUnseenUser: "Don't have any unseen chat",
        sendFirstMessage: 'Écris ton premier message à {{recipient}}.',
      },
      infoPopup: {
        title: 'Limite dépassée',
        message: "Tu peux envoyer jusqu'à 20 fichiers à la fois.",
      },
    },
    statistics: {
      tableType: 'Choisir un rapport',
      eventReplies: 'Réponses & tâches',
      eventTypes: "Type d'événement",
      appointmentSchedule: 'Calendrier des rendez-vous',
      memberFigures: 'Contrôle de présence',
      charts: {
        eventTypesByMonth: 'Nombre de rendez-vous par type',
        eventReplies: 'Réactions aux possibilités de réponse',
        memberPresence: 'Nombre de réactions par réponse',
      },
      loadingStatistics: 'Chargement...',
      memberFiguresExportSuccess: {
        title: 'Le rapport est en cours de création!',
        message:
          'Nous sommes en train de créer votre rapport de présence personnalisé. En raison de la grande quantité de données, cela peut prendre quelques minutes. Pour des raisons de sécurité, le rapport sera ensuite envoyé à votre adresse e-mail. Vérifiez simplement votre boîte de réception. Merci de votre patience!',
      },
    },
    filters: {
      clickToSelect: 'Cliquez ici pour filtrer',
      showFilters: 'Afficher les filtres',
      dateRange: 'Sélectionner la plage de dates',
      filterBy: 'Filtrer par',
      dateRanges: {
        yearToDate: "Depuis le début de l'année",
        lastDays: '{{number}} derniers jours',
        lastYear: 'Dernière année',
        yesterday: 'Hier',
        today: "Aujourd'hui",
        tomorrow: 'Demain',
        nextDays: '{{number}} prochains jours',
        thisWeek: 'Cette semaine',
        thisMonth: 'Ce mois-ci',
        thisYear: 'Cette année',
        allTime: "Toute l'année",
        specificDateRange: 'Période spécifique',
      },
      downloadXlsxOrCsv: 'Télécharger ici : Excel ou CSV',
    },
    bulkUploadEvents: {
      toastText:
        "🆕  La nouvelle fonction d'importation de rendez-vous est maintenant disponible pour chaque groupe!  🎉",
      moduleTitle: 'Importation',
      step1: 'Étape 1',
      step2: 'Étape 2',
      step3: 'Étape 3',
      step4: 'Étape 4',
      stepOne: {
        stepTitle: "Préparer l'importation de données",
        stepDescription: 'Télécharger le modèle Excel',
        componentTitle: 'Télécharger le modèle Excel',
        componentDescription:
          "Remplissez le modèle avec vos données d'événement ou utilisez votre propre fichier Excel.",
        p1: "Remplissez le modèle avec vos données d'événement.",
        p2: 'Les en-têtes de colonne doivent rester en anglais.',
        p3: 'Des exemples dans chaque colonne vous montrent le format correct.',
        p4: "Les nouveaux types d'événements sont ajoutés automatiquement.",
        p5: "<b>Important</b> : Si vous utilisez votre propre fichier, les titres de colonnes suivants doivent être copiés exactement tels qu'ils apparaissent en anglais (respectez la casse) :",
        p6: 'Des exemples dans chaque colonne du modèle vous montrent le format correct.',
        p7: "Les nouveaux types d'événements sont ajoutés automatiquement.",
        eventTypes: "ALLER AUX TYPES D'ÉVÉNEMENTS",
        template: 'TÉLÉCHARGER LE MODÈLE',
        nextStepButton: 'CONTINUER',
        copy: 'Copier toutes les clés',
      },
      stepTwo: {
        stepTitle: 'TÉLÉCHARGER UN FICHIER',
        stepDescription: 'TÉLÉCHARGER UN FICHIER CSV',
        componentTitle: 'TÉLÉCHARGER UN FICHIER CSV',
        componentDescription:
          'Enregistrer le fichier Excel au format <b><u>CSV</u></b> et le télécharger ici.',
        p1: 'Nous vérifierons le format et vous montrerons un aperçu.',
        uploadButton: 'Choisir un fichier',
        maximumFileSize: 'Taille maximale du fichier : 1 Mo',
      },
      stepThree: {
        stepTitle: 'Confirmer les données',
        stepDescription: 'Aperçu de votre premier événement.',
        componentTitle: 'Confirmer les données',
        componentDescription:
          "Vérifiez l'exemple et ajustez les membres activés.\nLes membres sélectionnés s'appliquent à tous les événements.",
        nextStepButton: 'CONFIRMER ET TÉLÉCHARGER',
      },
      stepFour: {
        stepTitle: 'Téléchargement réussi !',
        stepDescription:
          'Le téléchargement des événements a réussi. Vous pouvez les consulter dans le calendrier.',
        componentTitle: 'Téléchargement réussi !',
        componentDescription: '<b>{{count}}</b> événements ont été importés.',
        secondTitle: 'Bon à savoir',
        secondSubTitle:
          "Vous pouvez annuler l'importation ou télécharger une nouvelle liste.",
        goToScheduler: 'ALLER AU CALENDRIER',
        undoImport: "ANNULER L'IMPORTATION",
        uploadNewList: 'TÉLÉCHARGER UNE NOUVELLE LISTE',
      },
      errors: {
        eventNameErrorTitle: "Erreur de nom d'événement",
        eventNameErrorMessage:
          "Nom de l'événement manquant à la ligne <b><u>{{row}}</u></b>.",
        eventTypeErrorTitle: "Erreur de type d'événement",
        eventTypeErrorMessage:
          "Type d'événement manquant à la ligne <b><u>{{row}}</u></b>.",
        timeErrorTitle: "Erreur d'heure",
        timeErrorMessage:
          'Heure de début ou de fin manquante à la ligne <b><u>{{row}}</u></b>.',
        dateErrorTitle: 'Erreur de date',
        dateErrorMessage: 'Date manquante à la ligne <b><u>{{row}}</u></b>.',
        locationErrorTitle: 'Erreur de lieu',
        locationErrorMessage:
          "Lieu de l'événement manquant à la ligne <b><u>{{row}}</u></b>.",
        invalidDateOrTimeTitle: 'Date ou heure invalide',
        invalidDateOrTimeMessage:
          "Veuillez vérifier le format de la date et de l'heure à la ligne <b><u>{{row}}</u></b> (par exemple, utilisez HH:MM pour l'heure).",
      },
      deletePopupTitle: "Supprimer la liste d'événements ?",
      deletePopupMessage:
        "Cette action supprimera définitivement votre dernière liste d'événements téléchargée. Êtes-vous sûr(e) ?",
      deleteSuccessTitle: 'Suppression réussie!',
      deleteSuccessMessage:
        "Votre dernière liste d'événements a été supprimée avec succès.",
      successMessage:
        "Validation réussie. Passez à l'étape suivante pour voir un aperçu.",
      restartProcessTitle: "Charger une nouvelle liste d'événements",
      restartProcessMessage:
        "Vous pouvez charger une nouvelle liste d'événements à tout moment. Veuillez noter que les événements précédemment chargés resteront visibles dans le calendrier. Si vous souhaitez remplacer l'ancienne liste, supprimez-la d'abord ou annulez la dernière importation.",
      restartProcessOkButton: 'CHARGER UNE NOUVELLE LISTE',
      exportDataSample: {
        event_name: 'Zurich Street Parade',
        event_type_name: 'Performance',
        event_location: 'Bahnhofstrasse 5, 8001 Zurich',
        event_description:
          'Festival en plein air avec groupes et DJs internationaux',
      },
    },
    event: {
      event: 'Evénement',
      createdAt: 'Date de création',
      editEvent: "Modifier l'événement",
      createEvent: 'Créer événement',
      updateEvent: 'Mettre à jour événement',
      eventName: "Nom de l'événement",
      eventType: "Type d'événement",
      eventTypeDeleted: "Types d'événements supprimés",
      eventDetails: "Détails de l'événement",
      eventOrganizer: "Organisateur de l'événement",
      eventOrganizerShort: 'Organisateur',
      location: 'Emplacement',
      eventTypeEdit: "Modifier le type d'événement",
      eventTypeName: "Nom du type d'événement",
      eventTypeCreate: "Créer un type d'événement",
      responseRequired: 'Réponse requise',
      responseName: 'Nom de la réponse',
      responsesEdit: 'Modifier la réponse',
      responsesCreate: 'Créer réponse',
      repeatAnEvent: 'Répéter un événement',
      repeatWithInterval: 'Répéter avec un intervalle',
      allDay: 'Toute la journée',
      registrationDeadline: 'Date limite de souscription',
      commentRequired: 'Commentaire requis',
      editTask: 'Modifier tâche',
      createTask: 'Créer tâche',
      eventTaskName: 'Nom de la tâche',
      icon: 'Icône',
      isMemberComing: 'Ta réponse ?',
      recurringEvent: 'Evenemnt récurrent',
      allEvents: 'Tous les événements',
      thisEventOnly: 'Seulement cet événement',
      createEventSuccess: 'Evénement créé avec succès',
      updateEventSuccess: 'Evénement mis à jour avec succès',
      createEventReplySuccess: 'Réponse enregistrée avec succès',
      updateEventReplySuccess: 'Réponse mise à jour avec succès',
      addGuests: 'Invités supplémentaires ?',
      eventOperations: {
        eventOnly: 'Cet événement',
        eventAndFollowUp: 'Cet événement et tous les suivants',
        all: 'Tous les événements',
        deleteText: "Supprimer l'événement périodique",
        editText: "Modifier l'événement périodique",
        editReplyText: "Votre réponse s'applique à",
      },
      dfRes: 'Définir la réponse par défaut',
      responseToast:
        'Attention ! Si tu supprimes la réponse par défaut sans en enregistrer une nouvelle, toutes les réactions et commentaires précédents seront supprimés.',
      setPreferences: 'Définir les préférences',
      setEventDefaults: 'Définir les paramètres par défaut',
      preferencesText:
        'Définissez les paramètres par défaut pour les nouveaux événements de ce type, y compris la réponse par défaut pour les membres',
      responseSubTitle: 'Demander un commentaire (facultatif)',
      requestComment:
        'Activez cette option pour demander à l\'utilisateur de laisser un commentaire lorsqu\'il sélectionne cette réponse(par ex.pour "Non, je ne peux pas venir" afin de connaître la raison)',
      registeredUsers: 'Réponses',
      guests: 'Invités',
      nomination: 'Nomination',
      nominated: 'Tu es nominé(e)',
      havePenalty: 'Tu as reçu une pénalité',
      penalty: 'Pénalité',
      invitees: 'Invités',
      awaiting: 'En attente de',
      minutes: 'Minutes',
      hours: 'les heures',
      days: 'jours',
      weeks: 'semaines',
      weekDay: 'Jour de la semaine',
      duration: "Durée de l'accord",
      months: 'mois',
      years: 'années',
      viewAppointment: 'Voir Événement',
      myResponse: 'Ma Réponse',
      noEvents:
        "Calme ici? Peut-être que tu as répondu à tous les rendez-vous, tu n'es pas activé ou aucun n'est prévu. Les administrateurs et chefs d'équipe sont responsables.",
      shareEvent:
        "Partager l'événement — cliquez sur cette icône pour copier cet événement et l'envoyer partout où vous pouvez coller des textes (email, WhatsApp, etc.)",
      shareEventOption: 'Partager le lien du rendez-vous',
      exportPDFOption: 'Exporter en PDF',
      deadlineExpired:
        "La date limite d'inscription à cet événement semble avoir expiré, ou l'événement est déjà passé.",
      totalInvitees: 'Total des invités',
      responsesReceived: 'Réponses reçues',
      additionalGuests: 'Invités supplémentaires',
      noPermissionToGiveReply: "Tu n'as pas le droit de changer cela",
      responsesPercentage: 'Pourcentage de réponses',
      acceptedTasks: 'Tâches acceptées',
      acceptedTasksByUser: 'Tâches par utilisateur',
      givenComments: 'Commentaires donnés',
      givenCommentsByUser: "Commentaires donnés par l'utilisateur",
      optionalMessage: 'Cliquez ici pour voir les nouvelles.',
    },
    task: {
      createTask: 'Créer tâche',
      taskName: 'Nom de la tâche',
      noTasks: 'Pas de tâches',
    },
    groupSettings: {
      title: 'Paramètres du groupe',
      tabs: {
        general: 'Préférences générales',
        eventTypes: "Types d'événements",
        responses: 'Réponses',
        membersManagement: 'Administration des membres',
        other: 'Autre',
      },
      deleteGroupHeader: 'Etes-vous sûr de vouloir supprimer ceci?',
    },
    documentation: {
      title: 'Documentation',
      generalDocumentation: 'Documentation générale',
      uploadedFiles: 'Fichiers téléchargés',
      chooseFile: 'Choisir fichier',
      tipMessage: 'Taille maximum du fichier téléchargé: 50 MB',
      overview: '{{value}} GB utilisés sur {{capacity}} GB',
      uploadSuccess: 'Le document a été téléchargé avec succès',
      deleteSuccess: 'Le document a été supprimé avec succès',
      attachFiles: 'Joindres des fichiers',
      limitExceededHeader:
        "Vous avez atteint la limite de l'espace de stockage disponible pour la documentation.",
      sort: {
        label: 'Trier par',
        nameAsc: 'Nom (A-Z)',
        nameDesc: 'Nom (Z-A)',
        dateOldest: 'Date (Plus ancien)',
        dateNewest: 'Date (Plus récent)',
      },
    },
    subscriptions: {
      title: 'Abbonamenti',
      subscription: 'Abbonamento',
      adBlocker: {
        header: 'Désactivez votre bloqueur de publicités pour les prix locaux',
        body: "Désactivez votre bloqueur pour afficher les prix corrects. Les étapes varient selon le navigateur et le bloqueur : Cliquez généralement sur l'icône du bloqueur près de la barre d'URL et sélectionnez 'Désactiver'. Rechargez la page. Pour d'autres étapes, consultez l'aide de votre bloqueur ou navigateur. Merci.",
      },
      yearly: 'Annuale',
      monthly: 'Mensile',
      freeTitle: 'Prova gratuita di 2 mesi',
      freeDescription: 'Inizia la tua prova gratuita di 2 mesi ora',
      freeText1: 'Aprire fino a 2 comunità',
      freeText2: 'Accesso completo a tutte le funzionalità',
      freeText3:
        'Nessuna carta di credito richiesta: iscriviti e inizia immediatamente',
      freeText4: 'Nessun rinnovo automatico',
      freeText5: 'Nessuna pubblicità',
      subscribe: 'ISCRIVITI',
      seeDetails: 'VEDI DETTAGLI',
      buildCustom: 'Custom',
      month: 'mois',
      year: 'année',
      community: 'Communauté',
      communities: 'Communautés',
      maxStorage: 'Capacité de rangement en GB',
      classic: 'Classique',
      classicPlus: 'Classique+',
      popular: 'POPULAIRE',
      textInnerTop:
        "Choisissez l'abonnement Gumb qui correspond le mieux à votre communauté. Avec cet abonnement vous pouvez scinder votre équipe (ou votre communauté) jusqu'à en {{groups}} groupes et gérer jusqu'à {{members}} membres. Vous pouvez mettre à niveau ou annuler votre abonnement quand vous le souhaitez. Le mois d'essai gratuit s'active automatiquement une fois que votre inscription dans l'application Gumb est validée sans que vous ayez à pourvoir vos détails de paiement. Une fois la période d'essai terminée, vous pouvez souscrire à un des abonnements pour pouvoir continuer à utiliser Gumb, vous choisirez à ce moment-là votre préférence en termes de méthode de paiement.",
      textInnerBottom:
        "En achetant un abonnement, tu conclus un contrat à durée indéterminée. L'avantage : ton abonnement est automatiquement prolongé après l'expiration de la durée de validité d'un an/mois (nous te rappelons par e-mail). Tu peux payer l'abonnement par carte de crédit (MasterCard et Visa) ou PayPal.",
      chatsExcluded: 'Chats exclus',
      current: 'COURANT',
      pay: 'PAYER',
      usingBlocker: 'Désactiver le bloqueur de publicités et recharger la page',
      failedToSubscribe: 'Echec de la souscription',
      usedGroups: 'Groupes utilisés',
      usedMembers: 'Membres utilisés',
      usedCommunity: 'Communautés utilisées',
      usedStorage: 'Capacité de rangement utilisée',
      orderSummary: 'Résumé des commandes',
      tax: 'TAXE',
      total: 'Total',
      periodEnds: "La période souscrite s'achève",
      cancelSubscription: 'ANNULER MON ABONNEMENT',
      paypalLink: 'HISTORIQUE DE FACTURATION',
      chatEnabled: 'Chat inclus',
      chatExcluded: 'Chat exclus',
      paymentApproved: 'Paiement accepté',
      paymentError: 'Erreur de paiement',
      limitExceededHeader: 'Vous avez dépassé le nombre maximum de membres',
      limitExceededMessage:
        "Veuillez contacter le propriétaire de la communauté. Le propriétaire de la communauté peut vérifier les limites de l'abonnement et mettre à niveau votre abonnement à la communauté.",
      limitExceededMessageOwner:
        'Veuillez vous rendre sur la page des abonnements pour vérifier les limites de votre abonnement ou mettre à niveau votre abonnement',
      inactiveSubscriptionHeader: 'En attente de confirmation du paiement',
      inactiveSubscriptionMessage:
        "Désolé, vous ne pouvez pas poursuivre cette action. Vous devez peut-être d'abord terminer le processus de paiement.",
      subscriptionNotPaid:
        "Votre abonnement a expiré ou l'abonnement n'est pas payé.",
      contactAdmin: "Veuillez contacter l'Admin.",
      free: 'Compte gratuit',
      requireSubscription:
        'Gumb est gratuit pour les membres des communautés actives. Créez votre propre communauté et commencez votre essai gratuit dès maintenant ou modifiez votre abonnement sous "Modifier l\'abonnement".',
      requireSubscriptionMember:
        'Aucun abonnement valide n\'a été détecté. Vous n\'avez rien à faire ici. Par contre, le propriétaire de la communauté peut vérifier dans son profil sous "Abonnement" si la licence pour cette communauté est activée ou prolongée.',
      cancelConfirmation: 'Êtes-vous sûr de vouloir annuler votre abonnement?',
      unsubscribe: 'Annuler',
      cancelled:
        "Résiliation confirmée. Une confirmation de résiliation vient d'être envoyée à ton e-mail. Jusqu'à la date de facturation payée, tous continuent à avoir accès à toutes les fonctionnalités.",
      renewSubscriptionText:
        "Merci!\n\nVotre abonnement précédent sera renouvelé, si vous l'activez maintenant",
      activate: 'Activer maintenant',
      activated:
        "Ton abonnement a été activé avec succès. Nous sommes très heureux que tu organises et planifies dès maintenant avec Gumb. Une confirmation d'abonnement vient d'être envoyée à ton e-mail. Ton abonnement est valable dès maintenant et tu peux accéder à toutes les fonctions.",
      approved:
        "L'abonnement est en cours de préparation. Un instant s'il vous plaît ... Cela peut prendre jusqu'à 45 secondes. Pendant ce temps, ne rechargez pas la page et ne fermez pas la fenêtre du navigateur.",
      trial: 'Test gratuit',
      trialActivated:
        "Votre période d'essai a commencé. Vous et vos administrateurs pouvez maintenant commencer à planifier, inviter des membres et gérer les paramètres.",
      cancelledTitle: 'Annulé',
      startTrial: "DÉMARREZ L'ESSAI GRATUIT",
      startTrialHeader: 'Test lonnnng !',
      startTrialText:
        'Pour permettre à votre communauté de tester en profondeur toutes les fonctionnalités en opération réelle, nous offrons 2 mois complets de test ! Aucune information de paiement requise et aucun frais caché : testez simplement toutes les fonctionnalités avec tous les membres en toute tranquillité.',
      subscriptionExpired:
        "Cette communauté n'a pas d'abonnement actif pour le moment. Pour ajouter de nouveaux utilisateurs, l'abonnement doit être renouvelé.Pour toute question: support@gumb.app",
      startTrialPopupButton: 'Essai Gratuit',
      paymentMethods: 'Carte de crédit ou Paypal',
      subscriptionsPopup: {
        title: 'Votre abonnement a expiré',
        subtitle:
          "Votre abonnement a expiré, mais votre propriétaire de communauté, <b>{{ownerName}}</b> peut facilement changer cela. <brPure />Chaque communauté a un propriétaire qui est responsable de l'abonnement. 👑",
        firstBox: {
          title:
            '<b>{{ownerName}}</b> <brPure />Souhaitez-vous renouveler votre abonnement et redonner vie à la planification de votre équipe?',
          btnText: 'Renouveler',
        },
        secondBox: {
          title:
            "<b>Pour les membres</b> <brPure />Souhaitez-vous reprendre l'abonnement et devenir vous- même propriétaire de la communauté?(Commencez votre propre abonnement, contactez- nous ensuite et nous vous transférerons la propriété.)",
          btnText: 'Devenir propriétaire',
        },
      },
    },
    terms: {
      title: 'Les Termes et Conditions de Gumb',
    },
    errors: {
      cantSendMessageToYourself: 'Tu ne peux pas t’envoyer un message.',
      downloadFail: 'Erreur de téléchargement, réessaie.',
      required: 'Champ requis',
      email: 'Veillez pourvoir un adresse email valide',
      invalidEmail:
        'Oups! Veuillez entrer une adresse e-mail valide. Vérifiez les fautes de frappe et réessayez.',
      integer: 'Doit être un nombre entier',
      confirmPassword:
        'Les mots de passe ne sont pas identiques. Veuillez réessayer',
      NotAuthorizedException: 'Identifiant ou mot de passe érroné.',
      invalidCode: 'Ce code est invalide. Veuillez réessayer.',
      errorToast: 'Erreur: {{message}}',
      invalidRequest: 'Requête invalide',
      passwordChanged: 'Mot de passe modifié',
      password:
        'Le mot de passe devrait contenir au moins 8 caractères, des majuscules et des minuscules, des chiffres',
      termsError: 'Veuillez accepter les termes et conditions',
      min: 'Minimum {{value}} caractères',
      max: 'Maximum {{value}} caractères',
      startDate: 'La date ne peut être antérieure au {{value}}',
      endDate: 'La date ne peut être antérieure à la date de début',
      fileSize: 'La taille du fichier est trop importante',
      fileSizeCustom:
        'Fichier trop volumineux : Taille maximale de {{size}} Mo.',
      customFileSize:
        'Fichier trop volumineux: Max {{size}} Go. Supprime-le et réessaie.',
      unsupportedFormat: "Le format du fichier n'est pas pris en charge",
      postalCode: 'Minimum 4 chiffres',
      sorry: 'Désolé',
      text404:
        "Il semblrait que la page que vous recherchez n'est pas disponible :(",
      url: 'Doit être une URL valide.',
      text500: "Quelque chose s'est mal passé :(",
      onlyForGroupAndAdmin:
        'Cette fonctionnalité est uniquement accessible aux administrateurs et aux chefs de groupe',
      onlyForAdmin: 'Seuls les admins peuvent utiliser cette fonction.',
    },
    eventTypes: {
      createSuccess: "Le type d'événement a été créé avec succès",
      editSuccess: 'Les modifications ont été mises à jour avec succès',
      deleteSuccess: "Le type d'événement a été supprimé avec succès",
    },
    eventTasks: {
      createSuccess: 'La tâche pour cet événement a été créée avec succès',
      editSuccess: 'Les modifications ont été mises à jour avec succès',
      deleteSuccess: 'La tâche pour cet événement a été supprimée avec succès',
    },
    eventResponses: {
      createSuccess: "La réponse de l'événement a été créée avec succès",
      editSuccess: 'Les modifications ont été mises à jour avec succès',
      deleteSuccess: "La réponse de l'événement a été supprimée avec succès",
    },
    tips: {
      eventType:
        "L'administrateur de la communauté peut ajouter de nouveaux types d'événements dans la page de réglages de la communauté",
    },
    frequencies: {
      doNotRepeat: 'Une seule fois',
      daily: 'Tous les jours',
      weekly: 'Toutes les semaines',
      monthly: 'Tous les mois',
      yearly: 'Tous les ans',
      everyWorkingDay: 'Tous les jours de la semaine (du lundi au vendredi)',
      other: 'Personnaliser…',
    },
  },
  ru: {
    appWidget: 'Установите Gumb на свое устройство',
    featurePopups: {
      appointmentSchedule: {
        innerHtml:
          "<h3>New feature: Download your appointment schedule as an Excel or CSV file. </h3><p>We have completely redesigned the statistics page, and you can export your entire schedule now. The new report called 'Appointment schedule' gives you an overview of facts and figures of your events.</p><p>We are already working on another report called 'Member figures' which will be related to user performance. </p><p>The diary report is now available in the web app for all users.</p>",
        buttonOk: 'LATER',
        buttonRedirect: 'SEE NOW',
      },
      appleCalendar: {
        title: 'ПОДКЛЮЧИТЕ ВАШ КАЛЕНДАРЬ (iCal)&nbsp;🍏',
        message:
          'Мы это сделали! После большого количества работы, Gumb теперь позволяет прямое подключение календаря в WEB-версии для бесперебойной синхронизации. Скоро будет доступна версия&nbsp;для&nbsp;iOS/Смартфонов!',
        extraMessage: 'Откройте пошаговое руководство',
        redirectText: 'здесь',
        okButton: 'Подключить сейчас',
        cancelButton: 'Не сейчас',
      },
      preferecePopup: {
        title: '2 New Features',
        first: {
          title: 'Set All to "Going":',
          subtitle:
            'Set the default response for new or existing events – for all participants at once!',
          link: '',
        },
        second: {
          title: 'Default Event Settings:',
          subtitle: 'Define how new events should look.',
          link: 'Instructions',
        },
      },
      calendar: {
        innerhtml:
          "<h3>Мы сделали это!</h3> <p> Подключение GOOGLE CALENDAR уже здесь! </p> <br/> <p> События из Gumb - каждое сообщество теперь может быть автоматически перенесено в ваш календарь. </p> <div class='images-container'> <img src='https://gumb-cdn-prod.s3.eu-central-1.amazonaws.com/featurePopups/calendar-1.png' alt='calendar-1' /> <img src='https://gumb-cdn-prod.s3.eu-central-1.amazonaws.com/featurePopups/calendar-2.png' alt='calendar-2' /></div> <p> Все, что вам нужно сделать, описано в этом руководстве: <br/><a target='_blank' rel='noopener noreferrer' href='https://gumb.app/en/support/connect-gumb-with-google-calender/'>→&nbsp; Прочитайте полную инструкцию </a> </p> <p>Краткое руководство: </p> <ol> <li>Подключение и важно: установите флажок!</li> <li>Выберите сообщество</li> <li>Подождите несколько минут и перезагрузите страницу</li> </ol> <p> Подключите свой Календарь Google, чтобы сэкономить время и максимально эффективно использовать каждый день. </p>",
        buttonOk: 'Не для меня',
        buttonRedirect: 'ПОДКЛЮЧИТЬСЯ СЕЙЧАС',
      },
      notifications: {
        title: 'Как должны быть уведомлены члены клуба?',
        firstRow: 'Мы добавили настройки уведомлений.',
        secondRow:
          'Настройки уведомлений, которыми управляют администраторы, теперь могут быть кастомизированы для всего сообщества.',
        thirdRow: 'Все, что вам нужно сделать:',
        listFirstRow: 'Перейдите в настройки сообщества → уведомления.',
        listSecondRow:
          'Активируйте/деактивируйте опции в каждой категории, чтобы настроить все идеально для вас. (→ Читайте инструкции <0>здесь</0>)',
        lastRow:
          'Скажите своим пользователям, чтобы они включили настройки push или электронной почты в своем профиле Gumb.',
        buttonLater: 'Позже',
        buttonSettings: 'Перейти к настройкам сейчас',
      },
      pushNotifications: {
        title: '📢 Связь нового уровня: Push-уведомления',
        message:
          'Администраторы и руководители групп теперь могут создавать push-уведомления, чтобы отправлять вам важную информацию прямо на ваш смартфон.\n\nЧто вы можете делать с push-уведомлениями:\n\n• <b>Мгновенно делиться важной информацией</b> (например, об изменениях в расписании, чрезвычайных ситуациях)\n• <b>Связываться с отдельными людьми или целыми командами</b> (персонализированная коммуникация)\n• <b>Сохранять сообщения в качестве шаблонов, чтобы сэкономить время</b> (эффективная коммуникация)',
        extraMessage:
          'Будьте всегда в курсе событий и не пропустите ни одной новости!',
        cancelButton: 'Посмотреть позже',
        okButton: 'Попробовать сейчас',
        newMessageOne:
          'Администраторы и руководители групп теперь могут напрямую общаться с отдельными людьми или целыми командами, чтобы отправлять вам важную информацию прямо на ваш смартфон.\n\n',
        redirectLink: 'Создавайте индивидуальные push-уведомления',
        newMessageTwo:
          ', выбирайте получателей и даже сохраняйте свои сообщения в качестве шаблонов для последующего использования.',
      },
      importEvent: {
        title: 'Upload/Import Events ',
        subText:
          "No more manual entry! Easily import your events with a CSV or Excel file. Whether it's 30 or 300 events - it's quick and can even be undone if there are mistakes.",
        cancelButton: 'View later',
        okButton: 'Try now',
        secondText: 'Find step-by-step instructions',
      },
      mail: {
        title: 'Новая функция: Режим почты',
        firstRow:
          'Встроенная функция электронной почты упрощает и ускоряет общение в команде.  Вам срочно нужно отправить электронное письмо всем? Но только тем членам, которые ответили "Да" на назначение? → Теперь это возможно всего за несколько кликов!',
        secondRow:
          'Режим почты теперь доступен в веб-приложении для администраторов и руководителей групп. В предстоящий период он будет еще более расширен.',
        thirdRow: 'Подробнее: <0>https://gumb.app/support/mailmodus/</0>',
        buttonLater: 'ПОЗЖЕ',
        buttonMail: 'СЕЙЧАС',
      },
      eventPdf: {
        title: 'Новая функция: Загрузить назначение в формате PDF',
        firstRow:
          'Сэкономьте время и распечатайте информацию о мероприятии одним щелчком!',
        secondRow:
          'Незадолго до предстоящих событий или даже для прошедших событий все детали события, включая статистику ответов, можно экспортировать в PDF.',
        thirdRow:
          'Необходимый планировщик встреч: у вас есть права администратора',
        fourthRow:
          'Необходимое условие для планировщика группы: Вы являетесь руководителем группы',
        fifthRow: 'Теперь эта функция доступна в веб-приложении.',
      },
    },
    menu: {
      homePage: 'ГЛАВНАЯ СТРАНИЦА',
      subscriptions: 'Подписки',
      groupScheduler: 'КАЛЕНДАРЬ',
      documentation: 'ФАЙЛЫ',
      chats: 'ЧАТ',
    },
    dayOfWeek: {
      day1: 'ПН',
      day2: 'BT',
      day3: 'CP',
      day4: 'ЧТ',
      day5: 'ПТ',
      day6: 'СБ',
      day0: 'ВС',
    },
    email: {
      sender: 'Отправитель',
      recipients: 'Получатели',
      otherRecipients: 'Дальнейшие адресаты (разделять запятой)',
      subject: 'Тема',
      message: 'Сообщение',
      attachCommunityDocs: 'Прикрепить документы сообщества',
      emailSentSuccessfuly:
        'Электронная почта отправлена успешно. Обратите внимание: Это было "письмо без ответа" - письмо, на которое получатель не может ответить.',
      sendCopy: 'Отправьте копию мне',
      plusTooltip: 'Написать новое письмо',
      maxAttachmentsSize:
        'Если вы добавляете несколько вложений, их общий размер не должен превышать 15 МБ.',
      changeEmail: {
        title: 'Отправлено письмо с подтверждением',
        message:
          'Мы только что отправили вам письмо с подтверждающей ссылкой для вашего нового адреса электронной почты. Пожалуйста, откройте свой почтовый ящик и немедленно нажмите на ссылку, чтобы подтвердить свои изменения. Если вы не получите письмо в течение нескольких минут, проверьте вашу папку спама. Если у вас есть вопросы или проблемы, наша служба поддержки всегда готова вам помочь.',
      },
    },
    pushNotification: {
      header: 'Уведомления Push',
      filterByRecipient: 'Выбрать получателей',
      filterByResponse: 'Выбрать получателя по ответу',
      pushMessage: 'Создать push',
      saveAsTemplate: 'Сохранить как шаблон',
      sendCopy: 'Отправить копию мне',
      pasteUrl: '🌐 Событие или внешний линк?',
      plusTooltip: 'Создать новое push-уведомление',
      toasterText:
        '🆕 Новая функция: Теперь ты можешь добавить внешнюю ссылку, например любой веб-сайт 🌐 (URL), в push-уведомления!\nНаправляй получателей прямо на сайт.',
      templates: 'Шаблоны',
      title: 'Заголовок',
      lastUsed: 'Последнее использование',
      noTemplates: 'Сохраненные шаблоны отображаются здесь',
      template: 'Шаблон',
      sendConfirmHeader: 'Проверить перед отправкой',
      totalRecipients: 'ПОЛУЧАТЕЛИ',
      redirectLink: 'К ССЫЛКЕ',
      successTitle: 'Уведомление отправлено!',
      successMessage:
        'Ваше сообщение отправлено <b>{{totalRecipients}}</b> получателям.',
      selectEventFirst: 'Чтобы продолжить, выберите событие',
      templateLimitTitle: 'Достигнут лимит шаблонов',
      templateLimitInfo:
        'Сохраните до 8 своих сообщений в качестве шаблонов, чтобы использовать их позже. Мы уже добавили 3 шаблона для вдохновения - вы можете редактировать или удалять их по своему усмотрению.',
      templateLimitMessage:
        'Вы достигли максимального количества шаблонов. Пожалуйста, удалите один, чтобы создать новый.',
      urlInfo:
        'Вы можете добавить сюда ссылки на любой сайт (кроме Gumb). Это может быть ссылка на страницу продукта, новостную статью или что-то еще, что вы считаете актуальным. Просто вставьте полный URL-адрес (включая https://).',
      urlInfoOne:
        'Ты можешь вставить любую внешнюю ссылку (URL), чтобы направить получателей прямо на неё. Если ты выберешь событие выше, приложение вставит ссылку на событие, и при нажатии на пуш-уведомление событие откроется прямо в Gumb.',
      urlInfoTwo:
        'Скоро: добавляйте любые внешние ссылки (кроме Gumb) в ваши push-уведомления! Ссылайтесь на сайты, новости и многое другое. Следите&nbsp;за&nbsp;обновлениями!',
      urlTooltip:
        'Вы можете создать push-уведомление со ссылкой на событие или без нее. Хотите поделиться ссылкой на ваше мероприятие? Просто выберите его выше, чтобы автоматически скопировать ссылку здесь.',
    },
    common: {
      onlyAdmin: 'Только администраторы могут использовать эту функцию.',
      download: 'Скачать',
      attachFile: 'Прикрепить файл',
      new: 'Новый',
      unseen: 'Непросмотренные',
      more: 'Больше',
      less: 'Меньше',
      sunday: 'Воскресенье',
      monday: 'Понедельник',
      tuesday: 'Вторник',
      wednesday: 'Среда',
      thursday: 'Четверг',
      friday: 'Пятница',
      saturday: 'Суббота',
      january: 'Январь',
      february: 'Февраль',
      march: 'Март',
      april: 'Апрель',
      may: 'Май',
      june: 'Июнь',
      july: 'Июль',
      august: 'Август',
      september: 'Сентябрь',
      october: 'Октябрь',
      november: 'Ноябрь',
      december: 'Декабрь',
      overallScheduler: 'Общий планировщик',
      selectEvent: 'Выберите событие (необязательно)',
      displayEventsFrom: 'Отображение событий из',
      modalExit: 'Вы уверены, что хотите выйти без сохранения данных?',
      all: 'Все',
      tooltipEdit: 'Редактировать',
      tooltipDelete: 'Удалить',
      overview: 'Список',
      cancel: 'Отмена',
      removeLogo: 'Вы уверены, что хотите удалить логотип?',
      noTasks: 'Задания не найдены',
      noUsers: 'Пользователи не найдены',
      role: 'Роли',
      admin: 'Администраторы',
      member: 'Участники',
      group_lead: 'Лидеры групп',
      email: 'Email',
      groups: 'Группы',
      logoutHeader: 'Выйти?',
      logoutMessage: 'Вы будете перенаправлены на экран входа.',
      deleteHeader: 'Вы уверены, что хотите удалить пользователя?',
      logout: 'Выйти',
      delete: 'Удалить',
      tablePlaceholder: 'Участники не найдены',
      contentPlaceholder: 'Контент не найден',
      create: 'Создать',
      copy: 'Копировать',
      visible: 'Видимый',
      saveChanges: 'Сохранить изменения',
      newField: 'Новое поле',
      fieldName: 'Имя поля',
      addField: 'Добавить поле',
      notifications: 'Оповещения',
      participants: 'Участники',
      tasks: 'Задания',
      documentation: 'Документация',
      description: 'Описание',
      facebook: 'Facebook',
      twitter: 'Twitter',
      linkedin: 'LinkedIn',
      instagram: 'Instagram',
      youtube: 'Youtube',
      startDate: 'Дата старта',
      startTime: 'Время старта',
      startDateTime: 'Дата/время начала',
      endDateTime: 'Дата/время окончания',
      endDate: 'Дата завершения',
      endTime: 'Время завершения',
      icon: 'Значок',
      sendMessage: 'Отправить сообщение',
      aboutMe: 'Обо мне',
      selectAll: 'Выбрать все',
      approve: 'Принять',
      reject: 'Отклонить',
      backToGumb: 'Вернуться к Gumb',
      edit: 'Редактировать',
      addComment: 'Добавить комментарий',
      answer: 'Сохранить',
      ok: 'Ok',
      withoutGroup: 'Без группы',
      copyLink: 'Скопировать ссылку',
      copied: 'Скопирована',
      sendInviteLink: 'Отправить ссылку-приглашение',
      apply: 'Применить',
      from: 'С',
      to: 'По',
      enable: 'Включить',
      search: 'Поиск',
      color: 'Цвет',
      columns: 'Потускневшие колонки',
      hiddenColumns: 'Скрытые колонки',
      noResultsFound: 'Результаты не найдены',
      sorryNoResults:
        'Нам жаль, что вы не смогли найти то, что искали. Пожалуйста, попробуйте другой способ.',
      tutorial: 'Инструкция',
      next: 'Далее',
      back: 'Назад',
      step: 'Шаг',
      createSuccess: {
        title: 'Создано',
        message: 'Ваш вариант был успешно создан.',
      },
      editSuccess: {
        title: 'Изменено',
        message: 'Ваши изменения были сохранены.',
      },
      deleteSuccess: {
        title: 'Удалено',
        message: 'Вариант был успешно удален.',
      },
      toolTipText: {
        faq: 'Помощь: Руководства, Контакт, Другое.',
        communityPlusIcon: 'Создать новое сообщество',
        logoGumb:
          'Загадка в разработке: Узнайте позже, что скрывает эта кнопка!',
        memberArrowIcon: 'Перейти к обзору участников',
        memberPlusIcon: 'Добавить нового пользователя',
        eventType: 'Создать тип события',
        responses: 'Создать вариант ответа',
        task: 'Создать задачу',
        schedulerPlusIcon: 'Создать событие или группу',
      },
      shareEventTitle: 'Поделиться ссылкой на мероприятие через:',
      copyEventLink: 'Или скопировать ссылку:',
    },
    auth: {
      signInWith: 'Войти с помощью',
      continueAs: 'Продолжить как',
      create: 'Создать бесплатный аккаунт',
      signUp: 'Зарегистрироваться',
      signIn: 'Войти',
      welcome: 'Добро пожаловать!',
      createAccount: 'Создать бесплатный аккаунт',
      forgotPassword: 'Забыли пароль?',
      resetPassword: 'Сброс пароля',
      resetPasswordTooltip:
        'Если вы сбросите пароль, участник получит письмо с инструкциями о том, как установить новый пароль. Однако Gumb не может сбросить регистрацию через Facebook/Google/Apple; они должны использовать соответствующую иконку.',
      resetPasswordModalTitle: 'Инициирован сброс пароля',
      resetPasswordModalText:
        'Участник скоро получит ссылку для сброса на указанный ниже email. Для Facebook/Google/Apple это невозможно, но участник все равно может использовать соответствующую иконку для входа.',
      resetPasswordErrorModalTitle: 'Сброс пароля невозможен',
      resetPasswordErrorModalText:
        'Внимание! Похоже, этот участник зарегистрировался через социальные сети (например, Facebook, Google, Apple). Поэтому Gumb не может сбросить пароль для этой учетной записи. Однако пользователь может продолжать входить, используя значок соответствующей социальной сети. Пожалуйста, сообщите участнику соответствующим образом.',
      terms: {
        '0': 'Я соглашаюсь с ',
        '1': 'Условиями',
        '2': ' и ',
        '3': 'Политикой конфиденциальности',
      },
      home: 'Главная страница',
      confirmed: 'Email успешно подтвержден',
      verifying: 'Проверяем email...',
      recovery: 'Восстановление пароля',
      send: 'Отправить',
      codeSended:
        'Ссылка для подтверждения отправлена на вашу почту. Проверьте и подтвердите. Обратите внимание на папку со спамом.',
      inviteSentTitle: 'Приглашение и данные для входа успешно отправлены',
      inviteSentText:
        'Отлично! Вы успешно предварительно зарегистрировали участника и отправили приглашение с временным паролем. Теперь участник может войти в систему и изменить свой пароль. При необходимости перезагрузите эту страницу, чтобы сразу увидеть участника. Вы и ваши администраторы теперь можете определить групповые назначения и назначить роли. Используя значок карандаша справа, вы также можете дополнить личные данные участника. Так держать!',
      reset: 'Сбросить',
      yourEmail: '{{value}} уже является вашим email',
      existEmail: '{{value}} уже существует',
      labels: {
        email: 'Email',
        currentEmail: 'Текущий email',
        newEmail: 'Новый email',
        password: 'Пароль',
        oldPassword: 'Старый пароль',
        newPassword: 'Новый пароль',
        confirmPassword: 'Подтвердить пароль',
      },
      accountExists:
        'Этот адрес электронной почты уже используется для учетной записи Gumb. Если это письмо принадлежит вам, нажмите "Забыли пароль" и проверьте папку "Входящие" и "Спам".',
      codeExpired:
        'Это была ссылка с истекшим сроком действия. После того, как вы введете здесь свой e-mail, мы отправим вам письмо с новой ссылкой для завершения проверки вашей учетной записи.',
      userNotConfirmed:
        'Вам по-прежнему необходимо подтвердить свою учетную запись. Мы отправили вам электронное письмо со ссылкой для подтверждения. Если вы не получили письмо, проверьте папку "Спам" в вашем почтовом ящике.',
      invalidCredentials: 'Неверное имя пользователя или пароль',
      userNotFound:
        'По всей видимости, этот email уже подключен через Google/Apple/Facebook или не зарегистрирован. Вы не можете изменить пароль социальной сети через Gumb. Попробуйте вместо этого войти в систему через значок социальной сети.',
    },
    profile: {
      editSuccess: 'Изменения были успешно обновлены',
      settings: 'Настройки',
      editProfile: 'Редактировать профиль',
      selectImage: 'Выбрать изображение',
      myProfile: 'Мой профиль',
      profileEditBtn: 'Добавь свои данные: адрес, соцсети и другое',
      basicInformation: 'Основная информация',
      contactInformation: 'Контактная информация',
      addressInformation: 'Информация об адресе',
      myCommunities: 'Мои сообщества',
      myAppointments: 'Мои события',
      myChat: 'МОИ ЧАТЫ',
      subscription: 'Подписки',
      userProfile: 'Профиль пользователя',
      tellUsAboutYourself: 'Расскажите о себе',
      firstName: 'Имя',
      lastName: 'Фамилия',
      profileName: 'Имя профиля',
      person: 'Человек',
      role: 'Роль пользователя',
      email: 'Email',
      dateOfBirth: 'Дата рождения',
      privatePhoneNumber: 'Номер Телефона (Личный)',
      officePhoneNumber: 'Номер Телефона (Офис)',
      mobilePhoneNumber: 'Номер Телефона (Мобильный)',
      street: 'Улица',
      region: 'Регион',
      postalCode: 'Почтовый индекс',
      city: 'Город',
      country: 'Страна',
      about: 'Сведения',
      linkChangeEmail: '(Изменить электронную почту)',
    },
    profileCommunities: {
      popupLeave: {
        title: 'Ты уверен, что хочешь покинуть это сообщество?',
        button: 'Оставить',
      },
      leaveCommunity: {
        title: 'Вы уверены, что хотите покинуть это сообщество?',
        message:
          'Покидая это сообщество, ваша учетная запись пользователя остается активной, и вы можете присоединиться к другим сообществам.',
        okButton: 'Да, Покинуть',
        cancelButton: 'Отмена',
      },
    },
    profileSubscription: {
      heading: 'Подробная информация о подписке',
      titleMembership: 'Членство',
      titlePayment: 'Оплаты',
      buttonCancel: 'Возобновить членство',
      buttonRestart: '',
      details: {
        linkChangePlan: 'Изменить план',
        infoCancelled:
          'Ваша подписка была отменена и не будет возобновлена. Вы можете продолжать пользоваться приложением до следующей расчетной даты. Вы можете возобновить подписку в любое время.',
        infoTrial:
          'Ваш пробный месяц в настоящее время активен. По его истечении плата с вас, разумеется, сниматься не будет.  Если вы хотите продолжить пользоваться Gumb, выберите подходящую подписку в разделе "Изменить план".',
        infoTrialNew:
          "Ваш период пробного использования активен в данный момент. После его истечения {{expireDate}} вам ничего не будет начислено. Если вы хотите продолжить использовать Gumb, выберите подходящую подписку в разделе 'Изменить план'.",
        linkShowCommunities: 'Покажите мои сообщества',
        linkHistory: 'История выставления счетов',
        linkChangePayment: 'Изменение способа оплаты',
        currentPlan: 'Текущий план:',
        communitiesInUse: 'Сообщества в использовании:',
        groupsInUse: 'Группы в использовании:',
        membersInUse: 'Участники в использовании:',
        storageInUse: 'Хранение в использовании:',
        paymentCycle: 'Цикл оплаты:',
        paymentCycleMonth: 'Ежемесячно',
        paymentCycleYear: 'Ежегодно',
        nextPayment: 'Следующий платеж:',
        validUntil: 'Ancora valido fino a:',
        paymentMethod: 'Способ оплаты:',
      },
    },
    profileSettings: {
      title: 'Настройки профиля',
      notification: 'Оповещения',
      notificationsPlaceholder: 'Оповещения не найдены',
      changePassword: 'Изменить пароль',
      changeEmail: 'Email',
      saveNewEmail: 'Сохранить новый email',
      language: 'Язык',
      timeFormat: 'Формат времени',
      permissions: 'Разрешения',
      allowedToEdit: 'Администраторам разрешено редактировать мой профиль',
      other: 'Другое',
      pushNotification: 'Push-уведомления',
      receiveByEmail: 'Получать уведомления по электронной почте',
      receivePush: 'Получать мобильные push-уведомления',
      receiveInApp: 'Получать уведомления в приложении',
      terms: 'Условия и положения',
      protectionRegulation: 'Политика конфиденциальности',
      deleteAccount: 'Удалить аккаунт',
      logout: 'Выйти',
      deleteProfileHeader: 'Вы уверены, что хотите удалить аккаунт?',
      notifications: {
        now: 'Сейчас',
        hours: '{{value}}ч назад',
        minutes: '{{value}} мин назад',
        minute: '1 мин назад',
      },
    },
    calendarConnection: {
      googleCalendar: 'Google Calendar',
      pageTitle: 'Синхронизация календаря',
      connectTitle:
        'Подключите свой календарь\nдля автоматической синхронизации событий',
      connectDescription:
        'Gumb будет автоматически синхронизировать события из выбранных сообществ.',
      appleCalendar: 'Календарь Apple',
      appleConnectionSteps: {
        headerOne:
          'Интегрируйте события Gumb в ваш календарь Apple с помощью URL',
        headerOneSplit: 'за 4 шага; быстрая демонстрация',
        here: 'здесь',
        headerTwo: 'Или подробные инструкции',
        stepOne:
          '1. В вашем Календаре Apple, в разделе "Календарь", выберите "Файл" > "Новая подписка на календарь".',
        stepTwo:
          '2. Введите скопированную ниже iCal-ссылку Сообщества, затем нажмите «Подписаться».',
        stepThree:
          '3. Введите название календаря и выберите цвет, чтобы легко идентифицировать его в вашем календаре.',
        stepFour:
          '4. Настройте предпочтения, такие как местоположение (iCloud или Mac) или автообновление, затем нажмите «ОК».',
      },
      appleConnectionPopup: {
        title: 'Как подключиться?',
        stepOne: 'Скопировать ссылку сообщества',
        stepTwo:
          'В вашем Календаре Apple, в разделе "Календарь", выберите "Файл" > "Новая подписка на календарь".',
        stepThree:
          'Введите скопированную iCal-ссылку Сообщества, затем нажмите «Подписаться».',
        stepFour:
          'Назовите календарь, выберите цвет, укажите настройки как местоположение (iCloud или Mac) или автообновление и нажмите «ОК».',
      },
      appleDisconnectPopup: {
        title: 'Подтвердить Отключение',
        message:
          'Нажав "Да, отключить сейчас", вы удалите все события Gumb из вашего/ваших календаря/календарей. Пожалуйста, вручную удалите календарь/календари после этого, иначе он/они останется/останутся видимым/видимыми.\n\n<b>Чтобы полностью отписаться:</b>\n\n• <b>На macOS</b>: Откройте Календарь, кликните правой кнопкой мыши по календарю > "Отписаться".\n• <b>На iOS</b>: Перейдите в "Календари", нажмите кнопку "Информация" рядом с календарем > "Удалить Календарь".',
        okButton: 'Oui, se déconnecter maintenant',
      },
      appleUncheckBoxPopup: {
        title: 'Подтвердить Отключение',
        message:
          'Нажав "Да, отключить сейчас", вы удалите все события Gumb из вашего календаря. Пожалуйста, вручную удалите календарь после этого, иначе он останется видимым.\n\n<b>Чтобы полностью отписаться:</b>\n\n• <b>На macOS</b>: Откройте Календарь, кликните правой кнопкой мыши по календарю > "Отписаться".\n• <b>На iOS</b>: Перейдите в "Календари", нажмите кнопку "Информация" рядом с календарем > "Удалить Календарь".',
        okButton: 'Да, отключить сейчас',
      },
      connectWithApple: 'Подключить Apple (iCal)',
      cantSeeICal: 'Не видите ссылку iCal? Просто отметьте галочку.',
      syncTip:
        'Только события из выбранных сообществ будут синхронизированы с вашим календарем.',
      connect: 'Подключиться',
      connected: 'Подключено',
      disconnect: 'Отключить',
      disconnectSuccess: 'Успешное отключение',
      selectCommunities: 'Выбор сообществ',
      selectCommunitiesDescription:
        'Только события из выбранных сообществ будут синхронизированы с вашим календарем.',
      authSuccessTitle: 'Успешное подключение',
      authSuccessMessage:
        'Ваши сообщества теперь будут автоматически синхронизироваться. Подождите немного и обновите страницу Google Календаря, чтобы завершить начальную синхронизацию.',
      authSuccessTitleTwo: 'Выберите сообщество прямо сейчас',
      authSuccessMessageTwo:
        'Нажмите на галочку и "Сохранить изменени", чтобы подтвердить.',
      syncSuccess:
        'Успешно обновлено! \nС этого момента все будет синхронизироваться автоматически. Вы можете изменить выбор сообществ в любое время.',
      syncSuccessTitle: 'Почти готово!',
      syncSuccessMessage:
        'Мы подключаем твое сообщество к твоему Google Календарю. Это займет всего 2-3 минуты. Обнови страницу Google Календаря, чтобы увидеть обновления.',
      disconnectConfirmTitle: 'Вы уверены?',
      disconnectConfirmDescription:
        'Если вы отключитесь, синхронизированные сообщества будут удалены из вашего календаря.',
      disconnectConfirm: {
        title: 'Отключиться сейчас?',
        message:
          'Отключение удалит все события из вашего календаря Google. Не волнуйтесь, вы можете повторно подключить Gumb для новой синхронизации позже в любое время.',
        cancelButton: 'Отмена',
        okButton: 'Да, Отключить',
      },
      noCommunities:
        'Нет сообществ для выбора.\nПодождите, пока вас добавит администратор.',
      checkboxWarning:
        'ВНИМАНИЕ: Необходимо дать разрешение на использование календаря',
    },
    community: {
      plusIconSubText: 'Запланируй событие или создай серии событий.',
      selectAllCommunityAdmins: 'Все администраторы',
      selectAllGroupAdmins: 'Все руководители групп',
      createSuccess: 'Сообщество успешно создано',
      createFailure: 'Не удалось создать сообщество',
      deleteSuccess: 'Сообщество успешно удалено',
      leaveSuccess: 'Вы успешно покинули сообщество',
      descriptionTitle: 'О сообществе',
      descriptionPlaceholder: 'Здесь вы можете добавить описание сообщества',
      online: 'Online',
      updateCommunity: 'Обновить сообщество',
      members: 'Участники',
      createCommunity: 'Создать сообщество',
      communityName: 'Название сообщества',
      category: 'Категория',
      aboutCommunity: 'О сообществе',
      selectLogo: 'Выбрать логотип',
      selectLogoTip: 'jpg, jpeg, png (5MB)',
      chooseCover: 'Выбрать обложку',
      chooseCoverTip: 'jpg, jpeg, png (20MB)',
      website: 'Веб-сайт',
      partnership: 'Сотрудничество',
      deleteCommunity: 'Удалить сообщество',
      deleteHeader:
        'Вы уверены, что хотите удалить этого пользователя из группы?',
      addMembers: 'Добавить участников',
      invitationLink: 'Ссылка-приглашение',
      communityRole: 'Роль в сообществе',
      joinCommunity: 'Присоединиться к сообществу',
      namePlaceholder: 'Команда {{value}}',
      requestSent: 'Запрос на членство успешно отправлен',
      requestSentButton: 'Отправлен запрос на членство',
      limitExceededHeader:
        'Достигнуто максимальное количество сообществ.Для увеличения лимита необходимо изменить план подписки.',
      limitExceededMessage:
        'Пожалуйста, свяжитесь с владельцем сообщества. Владелец данного сообщества может проверить лимиты плана подписки и изменить его.',
      limitExceededMessageOwner:
        'Пожалуйста, посетите страницу подписок, чтобы проверить лимиты вашего плана подписки или изменить его.',
      updateFailure: 'Не удалось обновить сообщество',
      buttonAddEvent: 'Добавить событие',
      userBlockedTitle: 'Заблокировано',
      userBlockedText:
        'Похоже, что кто-то заблокировал вас в этом сообществе. Администраторы могут блокировать или разблокировать пользователей.',
      createOverLimitTitle: 'Достигнуто максимальное количество сообществ',
      createOverLimitText:
        'Начни свой бесплатный пробный период сейчас или выбери подписку в разделе "Изменить план" чтобы организовать свое собственное сообщество.',
      notInvitedTitle: 'Нет разрешения',
      notInvitedText:
        'Возможно, у вас нет разрешения на доступ к этому событию. Администраторы и руководители групп могут добавить вас на это мероприятие.',
      noRequestsTitle: 'Нет запросов на вступление',
      noRequestsText:
        'Заявки на вступление будут отображаться на этой странице',
      invitationCodeExpired:
        'Это ссылка на приглашение с истекшим сроком действия. Но теперь вы можете отправить запрос на присоединение, который администраторы могут принять или отклонить.',
      communityNotFound: 'Сообщество не найдено',
      communityPopup: {
        title: 'Создать Событие или Группу',
        subTitleOne:
          'Планируйте события в центральном РАСПИСАНИИ. Используйте группы для разделения участников на команды.',
        createEvent: {
          infoText:
            "Планируйте события для всех или определенных групп/участников в РАСПИСАНИИ. Видно в расписании, под 'Мои События' и в группах.",
          btntitle: 'Создать Событие',
          linkText: 'Учебник',
        },
        createGroup: {
          infoText:
            'У каждой группы свой планировщик, события в котором видны только ее участникам. Эффективно управляйте ролями участников',
          btntitle: 'Создать Группу',
          linkText: 'здесь',
        },
        chatTitle: 'Опции чата',
        chatSubTitle: 'Начните 1:1 чат или создайте комнату чата для групп.',
        oneToOne: {
          infoText:
            'Нажми на иконку чата рядом с именем участника или на его фото профиля, чтобы отправить сообщение.',
          btntitle: 'Список участников',
          linkText: 'Учебник',
        },
        createChatGroup: {
          infoText:
            'Создай приватную комнату чата для выбранных участников, команд или групп. Только администраторы могут создавать такие комнаты.',
          btntitle: 'Создать чат-комнату',
          linkText: 'здесь',
        },
      },
    },
    communitySettings: {
      admin: 'Администратор',
      member: 'Участник',
      blocked: 'Заблокированный пользователь',
      owner: 'Владелец',
      group_lead: 'Лидер группы',
      group_admin: 'Администратор группы',
      chat_admin: 'Админ чата',
      community_admin: 'Администратор сообщества',
      editRoleSuccess: 'Роль была успешно изменена',
      deleteUserSuccess: 'Пользователь был успешно удален из сообщества',
      removeUser: {
        title: 'Вы уверены, что хотите удалить этого пользователя?',
        message:
          'При удалении этого пользователя его удалят из вашего сообщества, но сохранится его учетная запись, и он сможет продолжить участие в других сообществах.',
        cancelButton: 'Отмена',
        okButton: 'Да, Удалить',
      },
      removeUserSuccess: {
        title: 'Пользователь удален',
        message: 'Пользователь успешно удален из вашего сообщества.',
      },
      editSuccess: 'Изменения были успешно обновлены',
      title: 'Настройки сообщества',
      backToSubscriptionsDetails: 'Вернуться к деталям подписки',
      tabs: {
        general: 'Общие предпочтения',
        eventTypes: 'Типы событий',
        subscriptions: 'Подписки',
        responses: 'Варианты ответов',
        roles: 'Участники',
        requests: 'Запросы',
        other: 'Другое',
        tasks: 'Задания',
      },
      addMember: 'Добавить пользователя',
      exportMembers: 'Экспорт списка участников в файл CSV',
      exportMembersTooltip: 'Экспорт списка участников в файл CSV',
      deleteCommunityHeader: 'Вы уверены, что хотите это удалить?',
      defaultEventList: [
        'Встреча',
        'Образование',
        'Игра',
        'Репетиция',
        'Представление',
        'Заседание',
      ],
      defaultResponsesTypes: [
        'Да, я иду',
        'Нет, я не иду',
        'Вероятно',
        'Не знаю',
      ],
      defaultTasks: ['Кто за рулем?', 'Кто приносит напитки?'],
      selectUsers: {
        title: 'Выберите Пользователей',
        message:
          'Пожалуйста, отметьте пользователей галочкой, чтобы одобрить или отклонить запросы на вступление.',
      },
      memberExists: 'Пользователь уже вступил в сообщество',
      mailSent: 'Приглашение успешно отправлено на электронную почту',
      userNotFound:
        'Профиль Gumb не найден. Чтобы создать новый профиль и отправить приглашение на этот адрес электронной почты, пожалуйста, предоставьте дополнительную информацию',
      disableAddMemberTooltip:
        'Количество членов сообщества уже достигло предела. Чтобы решить эту проблему, владелец сообщества может проверить обновление подписки, чтобы добавить больше участников.',
      notifyByFields: 'Отправляйте уведомления при обновлении следующих полей',
      notifyByReplies: 'Отправьте уведомление, когда приглашенные ответили',
      remindBefore: 'Напомнить до',
      addReminder: '+ Добавить напоминание (2 макс.)',
      notificationsTypes: [
        'Событие создано',
        'Событие обновлено',
        'Событие удалено',
        'Напоминание о событии',
      ],
      notificationsClasses: ['In-App', 'E-mail', 'Push'],
      notificationPopupHeader:
        'Вы хотите отправлять уведомления приглашенным пользователям?',
      notificationPopupMessageLabel:
        'Добавьте необязательное сообщение для приглашенных пользователей',
      notificationPopupNotify: 'Отправить',
      notificationPopupDoNotNotify: 'Не отправлять',
      notificationPopupBack: 'Вернуться к редактированию',
    },
    communityInvitation: {
      subjectFirst: 'Вас пригласили присоединиться к сообществу',
      subjectSecond: '',
      body: 'Пожалуйста, перейдите по ссылке ниже, чтобы вступить в сообщество',
      invitationSuccess: 'Пользователь приглашен в сообщество',
      invitationFailure:
        'Произошла ошибка. Возможно, у вас закончилась подписка',
      membersInvitation: {
        title: 'Добавление пользователей',
        description:
          'Добавьте новых пользователей в сообщество, чтобы они могли участвовать в ваших событиях. См. руководство <0>"Добавление нового пользователя"</0>.',
        showMore: 'Показать больше',
        showLess: 'Показать меньше',
        codeInvitation: {
          title: 'Самый быстрый и простой способ',
          button: 'Скопируйте ссылку с приглашением',
          updateLink: 'Создать ссылку сейчас',
          generateLink: 'Generate link now',
          howItWorks: 'Как это работает',
          goodToKnow: 'Полезно знать',
          howItWorksText:
            'Пользователи нажимают на "Создать бесплатный аккаунт", используя выбранную выше ссылку. Когда Gumb отправит электронное письмо, участникам нужно будет только подтвердить свой адрес электронной почты, чтобы получить прямой доступ.',
          goodToKnowText:
            'Это быстрый способ, но, пожалуйста, обратите внимание, что после завершения проверки аккаунта люди с этой ссылкой смогут сразу же получить доступ к вашим мероприятиям. Следовательно, вы можете обновить вышеуказанную ссылку, когда захотите.',
        },
        linkInvitation: {
          title:
            'Более безопасный метод: отправьте запрос на членство с ссылкой на сообщество',
          button: 'Скопируйте ссылку на сообщество',
          howItWorks: 'Как это работает',
          adminFlow: 'Администраторы принимают заявки',
          howItWorksText:
            '<0>Администраторы копируют и распространяют эту ссылку на сообщество</0><0>Участники, имеющие аккаунт в Gumb, нажимают "Присоединиться к сообществу"</0><0>Участники, не имеющие аккаунта, сначала регистрируются на Gumb, а затем снова нажимают на ссылку, чтобы отправить запрос</0>',
          adminFlowText:
            'На вкладке "Запросы" администраторы должны одобрить или отклонить запрос участника на вступление в ваше сообщество. Когда есть открытые запросы, администраторы видят уведомление на значке настроек.',
        },
        addMember: {
          title: 'Еще 2 варианта: эффективный или персональный',
          button: 'ДОБАВИТЬ ПОЛЬЗОВАТЕЛЯ',
          selfSignUp: 'Пользователи регистрируются самостоятельно',
          preRegister: 'Предварительная регистрация',
          selfSignUpText:
            'Скажите всем, чтобы они просто зарегистрировались на Gumb (бесплатно) и прислали вам свой зарегистрированный e-mail. Вы можете ввести его здесь, и пользователь сразу же окажется в вашем аккаунте и будет автоматически видеть все ваши встречи.',
          preRegisterText:
            'Зарегистрируйте новых участников напрямую через их адрес электронной почты. Это действие автоматически отправляет им "Приглашение по электронной почте" с временным паролем. С его помощью они могут немедленно войти в систему и изменить пароль по своему усмотрению позже',
        },
      },
    },
    group: {
      deleteEventSuccess: 'Событие успешно удалено',
      createSuccess: 'Группа успешно создана',
      deleteSuccess: 'Группа успешно удалена',
      addMembersSuccess: 'Участники успешно добавлены',
      addMembersSuccessWithMessage: {
        title: 'Участник добавлен!',
        message:
          'Ваш список участников успешно обновлен. Управляйте ролями и назначениями в группах. Вы также можете добавить или изменить данные пользователя.',
      },
      deleteUserSuccess: 'Успешное удаление пользователя из группы',
      addParticipants: 'Добавить участников',
      createGroup: 'Создать группу',
      deleteGroup: 'Удалить группу',
      groupName: 'Названиe группы',
      changeGroupNameSuccess: 'Название группы успешно изменено.',
      groups: 'Группы',
      inviteMembers: 'Пригласить участников',
      tabs: {
        schedule: 'Заланировать',
        members: 'Участники',
        chat: 'Чат',
        documentation: 'Документация',
        statistic: 'Экспорт и статистика',
        import: 'Импорт',
      },
      limitExceededHeader:
        'Достигнуто максимальное количество групп. Для увеличения лимита необходимо изменить план подписки.',
      limitExceededMessage:
        'Пожалуйста, свяжитесь с владельцем сообщества. Владелец данного сообщества может проверить лимиты плана подписки и изменить его.',
      limitExceededMessageOwner:
        'Пожалуйста, посетите страницу подписок, чтобы проверить лимиты вашего плана подписки или изменить его',
      groupRole: 'Роль группы',
      addGroup: 'Добавить группу',
    },
    chat: {
      read: {
        now: '{{name}} прочитано только что',
        hours: '{{name}} прочитано {{value}} ч назад',
        minutes: '{{name}} прочитано {{value}} мин назад',
        minute: '{{name}} прочитано 1 мин назад',
        day: '{{name}} прочитано {{value}}',
        dateTime: '{{name}} прочитано {{value}} в {{time}}',
      },
      noRead: 'Еще не прочитано',
      lastAdmin:
        'В чате должен быть хотя бы один администратор или руководитель группы. Убедитесь, что хотя бы один человек всегда имеет эту роль.',
      update: {
        success: 'Список участников обновлён',
        successUpdate: 'Чат-группа успешно обновлена',
        btn: 'СОХРАНИТЬ',
      },
      modal: {
        btn: 'Управление',
        about: 'Инфо',
        editRoom: 'Редактировать чат',
        delete: 'Удалить чат',
        editMember: 'Редактировать участников',
      },
      details: {
        title: 'Детали чата',
        secondTitle: 'ИНФОРМАЦИЯ',
        name: 'Название чата',
      },
      delete: { success: 'Чат был удален' },
      create: {
        title: 'Создать чат-комнату',
        name: 'Название чат-комнаты',
        description: 'Описание группы (необязательно)',
        btnNext: 'ДАЛЕЕ',
        success: 'Чат создан',
      },
      new: 'Новый',
      chat: 'чат',
      inputPlaceHolder: 'Введите сообщение',
      searchHover: 'Найти или начать новый чат',
      op1: 'Файлы и видео',
      op2: 'Фото',
      files: 'Файлы',
      plusTooltip: 'Создать новый чат или групповой чат',
      emptyStatus: {
        noChatSelected: {
          firstLine: 'Выберите беседу, чтобы просмотреть чат,',
          secondLine: 'или нажмите здесь, чтобы начать',
          LinkText: 'НОВЫЙ ЧАТ',
        },
        noChatUser:
          'Пока нет чатов? Начни 1:1 чат или подожди, пока администратор тебя добавит.',
        noUnseenUser: "Don't have any unseen chat",
        sendFirstMessage: 'Напишите своё первое сообщение {{recipient}}.',
      },
      infoPopup: {
        title: 'Превышен лимит',
        message: 'Можно загрузить не более 20 файлов за раз.',
      },
    },
    statistics: {
      tableType: 'Выберите отчет',
      eventReplies: 'Ответы и задания',
      eventTypes: 'Тип события',
      appointmentSchedule: 'Расписание событий',
      memberFigures: 'Проверка посещаемости',
      charts: {
        eventTypesByMonth: 'Количество назначений по типу',
        eventReplies: 'Ответы на варианты ответов',
        memberPresence: 'Количество реакций на ответ',
      },
      loadingStatistics: 'Загрузка...',
      memberFiguresExportSuccess: {
        title: 'Отчет создается!',
        message:
          'Мы в настоящее время создаем ваш персональный отчет о посещаемости. Из-за большого объема данных это может занять несколько минут. Затем отчет будет отправлен на ваш электронный адрес в целях безопасности. Просто проверьте свой почтовый ящик. Спасибо за ваше терпение!',
      },
    },
    filters: {
      clickToSelect: 'Нажмите здесь, чтобы отфильтровать',
      showFilters: 'Показать фильтры',
      dateRange: 'Выберите диапазон дат',
      filterBy: 'Фильтр по',
      dateRanges: {
        yearToDate: 'За год',
        lastDays: 'Последние {{number}} дней',
        lastYear: 'Последний год',
        yesterday: 'Вчера',
        today: 'Сегодня',
        tomorrow: 'Завтра',
        nextDays: 'Следующие {{number}} дней',
        thisWeek: 'На этой неделе',
        thisMonth: 'Этот месяц',
        thisYear: 'Этот год',
        allTime: 'Все время',
        specificDateRange: 'Определенный диапазон дат',
      },
      downloadXlsxOrCsv: 'Экспорт Excel или CSV',
    },
    bulkUploadEvents: {
      toastText:
        '🆕  Новая функция импорта встреч теперь доступна для каждой группы!  🎉',
      moduleTitle: 'Импорт',
      step1: 'Шаг 1',
      step2: 'Шаг 2',
      step3: 'Шаг 3',
      step4: 'Шаг 4',
      stepOne: {
        stepTitle: 'Подготовить импорт данных',
        stepDescription: 'Скачать шаблон Excel',
        componentTitle: 'Скачать шаблон Excel',
        componentDescription:
          'Заполните шаблон своими данными о событиях или используйте собственный файл Excel.',
        p1: 'Заполните шаблон данными вашего мероприятия.',
        p2: 'Заголовки столбцов должны оставаться на английском языке.',
        p3: 'Примеры в каждом столбце показывают правильный формат.',
        p4: 'Новые типы событий добавляются автоматически.',
        p5: '<b>Важно</b>: Если вы используете свой собственный файл, следующие названия столбцов должны быть скопированы точно так, как показано на английском языке (с учетом регистра):',
        p6: 'Примеры в каждом столбце шаблона показывают правильный формат.',
        p7: 'Новые типы событий добавляются автоматически.',
        eventTypes: 'ПЕРЕЙТИ К ТИПАМ СОБЫТИЙ',
        template: 'СКАЧАТЬ ШАБЛОН',
        nextStepButton: 'ПРОДОЛЖИТЬ',
        copy: 'Скопировать все',
      },
      stepTwo: {
        stepTitle: 'ЗАГРУЗИТЬ ФАЙЛ',
        stepDescription: 'ЗАГРУЗИТЬ CSV-ФАЙЛ',
        componentTitle: 'ЗАГРУЗИТЬ CSV-ФАЙЛ',
        componentDescription:
          'Сохраните файл Excel в формате <b><u>CSV</u></b> и загрузите его сюда.',
        p1: 'Мы проверим формат и покажем вам предварительный просмотр.',
        uploadButton: 'Выбрать файл',
        maximumFileSize: 'Максимальный размер файла: 1 МБ',
      },
      stepThree: {
        stepTitle: 'Подтвердить данные',
        stepDescription: 'Предварительный просмотр вашего первого события.',
        componentTitle: 'Подтвердить данные',
        componentDescription:
          'Проверьте пример и настройте активированных участников.\nВыбранные участники применяются ко всем событиям.',
        nextStepButton: 'ПОДТВЕРДИТЬ И ЗАГРУЗИТЬ',
      },
      stepFour: {
        stepTitle: 'Успешно загружено!',
        stepDescription:
          'Загрузка событий прошла успешно. Посмотрите их в планировщике.',
        componentTitle: 'Успешно загружено!',
        componentDescription: 'Было импортировано <b>{{count}}</b> событий.',
        secondTitle: 'Полезно знать',
        secondSubTitle: 'Вы можете отменить импорт или загрузить новый список.',
        goToScheduler: 'ПЕРЕЙТИ К ПЛАНИРОВЩИКУ',
        undoImport: 'ОТМЕНИТЬ ИМПОРТ',
        uploadNewList: 'ЗАГРУЗИТЬ НОВЫЙ СПИСОК',
      },
      errors: {
        eventNameErrorTitle: 'Ошибка названия события',
        eventNameErrorMessage:
          'В строке <b><u>{{row}}</u></b> отсутствует название события.',
        eventTypeErrorTitle: 'Ошибка типа события',
        eventTypeErrorMessage:
          'В строке <b><u>{{row}}</u></b> отсутствует тип события.',
        timeErrorTitle: 'Ошибка времени',
        timeErrorMessage:
          'В строке <b><u>{{row}}</u></b> отсутствует время начала или окончания.',
        dateErrorTitle: 'Ошибка даты',
        dateErrorMessage: 'В строке <b><u>{{row}}</u></b> отсутствует дата.',
        locationErrorTitle: 'Ошибка местоположения',
        locationErrorMessage:
          'В строке <b><u>{{row}}</u></b> отсутствует место проведения мероприятия.',
        invalidDateOrTimeTitle: 'Неверный формат даты или времени',
        invalidDateOrTimeMessage:
          'В строке <b><u>{{row}}</u></b> отсутствует Пожалуйста, проверьте формат даты и времени (например, используйте HH:MM для времени).',
      },
      deletePopupTitle: 'Удалить список событий?',
      deletePopupMessage:
        'Это действие безвозвратно удалит ваш последний загруженный список событий. Вы уверены?',
      deleteSuccessTitle: 'Удаление успешно!',
      deleteSuccessMessage:
        'Ваш последний загруженный список событий был успешно удален.',
      successMessage:
        'Проверка пройдена успешно. Перейдите к следующему шагу, чтобы увидеть предварительный просмотр.',
      restartProcessTitle: 'Загрузить новый список событий',
      restartProcessMessage:
        'Вы можете загрузить новый список событий в любое время. Обратите внимание, что ранее загруженные события будут по-прежнему видны в планировщике. Если вы хотите заменить старый список, сначала удалите его или отмените последний импорт.',
      restartProcessOkButton: 'ЗАГРУЗИТЬ НОВЫЙ СПИСОК',
      exportDataSample: {
        event_name: 'Zurich Street Parade',
        event_type_name: 'Выступление',
        event_location: 'Bahnhofstrasse 5, 8001 Zurich',
        event_description:
          'Фестиваль под открытым небом с участием международных групп и диджеев',
      },
    },
    event: {
      event: 'События',
      createdAt: 'Дата создания',
      editEvent: 'Редактировать событие',
      createEvent: 'Создать событие',
      updateEvent: 'Обновить событие',
      eventName: 'Название события',
      eventType: 'Тип события',
      eventTypeDeleted: 'Удалённые типы событий',
      eventDetails: 'Детали события',
      eventOrganizer: 'Организатор события',
      eventOrganizerShort: 'Организатор',
      location: 'Местоположение',
      eventTypeEdit: 'Редактировать тип события',
      eventTypeName: 'Название типа события',
      responseRequired: 'Требуется ответ',
      responseName: 'Название ответа',
      responsesEdit: 'Редактировать ответа',
      responsesCreate: 'Создать ответ',
      repeatAnEvent: 'Повторять событие',
      eventTypeCreate: 'Создать тип события',
      repeatWithInterval: 'Повторять с интервалом',
      allDay: 'Весь день',
      registrationDeadline: 'Дедлайн регистрации',
      commentRequired: 'Требуется комментарий',
      editTask: 'Редактировать задание',
      createTask: 'Cоздать задание',
      eventTaskName: 'Название задания',
      icon: 'Значок',
      isMemberComing: 'Твой ответ?',
      recurringEvent: 'Повторяющееся событие',
      allEvents: 'Все события',
      thisEventOnly: 'Только это событие',
      createEventSuccess: 'Задание успешно создано',
      updateEventSuccess: 'Задание успешно обновлено',
      createEventReplySuccess: 'Ответ успешно сохранен',
      updateEventReplySuccess: 'Réponse mise à jour avec succès',
      addGuests: 'Дополнительные гости?',
      eventOperations: {
        eventOnly: 'Только это мероприятие',
        eventAndFollowUp: 'Это и последующие мероприятия',
        all: 'Все мероприятия',
        deleteText: 'Удаление повторяющегося мероприятия',
        editText: 'Редактирование повторяющегося мероприятия',
        editReplyText: 'Ваш ответ относится к',
      },
      dfRes: 'Установить ответ по умолчанию',
      responseToast:
        'Внимание! Если вы удалите стандартный ответ, не сохранив новый, все предыдущие реакции и комментарии будут удалены.',
      setPreferences: 'Установить настройки',
      setEventDefaults: 'Установить настройки событий',
      preferencesText:
        'Определите настройки по умолчанию для новых событий этого типа, включая ответ по умолчанию для участников',
      responseSubTitle: 'Запросить комментарий (необязательно)',
      requestComment:
        'Активируйте эту опцию, чтобы при выборе этого ответа пользователю предлагалось оставить комментарий (например, для ответа «Нет, я не могу», чтобы узнать причину)',
      registeredUsers: 'Ответы',
      guests: 'Гости',
      nomination: 'Требование',
      nominated: 'Вы номинированы',
      penalty: 'Штраф',
      havePenalty: 'Вам был назначен штраф',
      invitees: 'Приглашенные',
      awaiting: 'В ожидании',
      minutes: 'Минута',
      hours: 'Час',
      days: 'День',
      weeks: 'Неделя',
      weekDay: 'Будни',
      duration: 'Продолжительность',
      months: 'Месяц',
      years: 'Год',
      viewAppointment: 'Событие',
      myResponse: 'Мой Ответ',
      noEvents:
        'Тишина? Возможно, вы ответили на все встречи, не активированы или ничего не запланировано. Администраторы и руководители команд отвечают за это.',
      shareEvent:
        'Поделиться событием — нажмите на этот значок, чтобы скопировать это событие и отправить его в любое место, куда можно вставить текст (электронная почта, WhatsApp и т.д.).',
      shareEventOption: 'Поделиться ссылкой',
      exportPDFOption: 'Экспорт в формате PDF',
      deadlineExpired:
        'Срок регистрации на это мероприятие, похоже, истек, или мероприятие уже прошло.',
      totalInvitees: 'Всего приглашенных',
      responsesReceived: 'Полученные ответы',
      additionalGuests: 'Дополнительные гости',
      noPermissionToGiveReply: 'Недостаточно прав на редактирование ответа',
      responsesPercentage: 'Процент ответов',
      acceptedTasks: 'Принятые задания',
      acceptedTasksByUser: 'Задания по пользователям',
      givenComments: 'Комментарии',
      givenCommentsByUser: 'Комментарии пользователей',
      optionalMessage: 'Нажмите здесь, чтобы увидеть новости.',
    },
    task: {
      createTask: 'Создать задание',
      taskName: 'Название задания',
      noTasks: 'Задания не найдены',
    },
    groupSettings: {
      title: 'Настройки групп',
      tabs: {
        general: 'Общие предпочтения',
        eventTypes: 'Типы событий',
        responses: 'Ответы',
        membersManagement: 'Администрация участников',
        other: 'Другое',
      },
      deleteGroupHeader: 'Вы уверены, что хотите это удалить?',
    },
    documentation: {
      title: 'Документация',
      generalDocumentation: 'Общая документация',
      uploadedFiles: 'Загруженные файлы',
      chooseFile: 'Выбрать файл',
      tipMessage: 'Максимальный размер файла: 50 МБ',
      overview: '{{value}} ГБ из {{capacity}} ГБ использовано',
      uploadSuccess: 'Документ успешно загружен',
      deleteSuccess: 'Документ успешно удален',
      attachFiles: 'Прикрепить файл',
      limitExceededHeader:
        'Вы достигли предела доступного пространства для хранения документации.',
      sort: {
        label: 'Сортировка по',
        nameAsc: 'Имя (А-Я)',
        nameDesc: 'Имя (Я-А)',
        dateOldest: 'Дата (Сначала старые)',
        dateNewest: 'Дата (Сначала новые)',
      },
    },
    subscriptions: {
      title: 'Подписки',
      subscription: 'Подписка',
      adBlocker: {
        header: 'Отключите блокировщик рекламы для местных цен',
        body: "Отключите блокировщик, чтобы увидеть правильные цены. Шаги зависят от браузера и блокировщика: Обычно нажмите на значок блокировщика рядом с адресной строкой и выберите 'Отключить'. Перезагрузите страницу. Для других шагов смотрите раздел помощи. Спасибо.",
      },
      yearly: 'По годам',
      monthly: 'По месяцам',
      freeTitle: 'Бесплатное испытание 2 месяца',
      freeDescription:
        'Начните ваше бесплатное испытание на 2 месяца прямо сейчас',
      freeText1: 'Открыть до 2 сообществ',
      freeText2: 'Полный доступ ко всем функциям',
      freeText3:
        'Не требуется кредитная карта - зарегистрируйтесь и начните сразу',
      freeText4: 'Без автоматического продления',
      freeText5: 'Без рекламы',
      subscribe: 'ПОДПИСАТЬСЯ',
      seeDetails: 'ПОСМОТРЕТЬ ДЕТАЛИ',
      buildCustom: 'Кастомный',
      month: 'мес.',
      year: 'г.',
      community: 'Центр сообщества',
      communities: 'Центры сообщества',
      scheduler: '1 Центральный планировщик событий',
      schedulers: 'Каждый с 1 Центральным планировщиком событий',
      group: 'Планировщики групп для команд/отделов',
      capacity: 'Вместимость участников',
      maxStorage: 'ГБ хранилища',
      classic: 'Классический',
      classicPlus: 'Классический+',
      popular: 'ПОПУЛЯРНЫЙ',
      textInnerTop:
        'Выберите подписку Gumb, которая подходит вашему сообществу. Вы сможете разделить свою команду (или сообщество) на группы (до {{groups}}) и управлять участниками до {{members}}. Вы всегда сможете изменить или отменить подписку. Месяц бесплатной пробной версии активируется автоматически после успешной регистрации в приложении Gumb без необходимости указывать свои платежные реквизиты. После окончания бесплатного пробного периода вы можете выбрать один из планов подписки, чтобы продолжать использовать Gumb, указав предпочитаемный способ оплаты.',
      textInnerBottom:
        'Приобретая подписку, вы подписываете бессрочный договор. Преимущество: Ваша подписка автоматически продлевается по истечении срока действия на один год/месяц (мы напомним вам об этом по электронной почте). Вы можете оплатить подписку кредитной картой (MasterCard и Visa) или PayPal.',
      chatsExcluded: 'Чаты исключены',
      current: 'ТЕКУЩИЙ',
      pay: 'ОПЛАТИТЬ',
      usingBlocker: 'Отключите блокировщик рекламы и перезагрузите страницу',
      failedToSubscribe: 'Не удалось оформить подписку',
      usedGroups: 'Израсходовано групп',
      usedMembers: 'Израсходовано участников',
      usedCommunity: 'Израсходовано сообществ',
      usedStorage: 'Израсходовано хранилища',
      orderSummary: 'Детали заказа',
      tax: 'НАЛОГ',
      total: 'Всего',
      periodEnds: 'Оплаченный период завершается',
      cancelSubscription: 'ОТМЕНИТЬ МОЮ ПОДПИСКУ',
      paypalLink: 'ИСТОРИЯ ПЛАТЕЖЕЙ',
      chatEnabled: 'Чат включен',
      chatExcluded: 'Чат исключен',
      paymentApproved: 'Платеж одобрен',
      paymentError: 'Ошибка платежа',
      limitExceededHeader: 'Достигнуто максимальное количество участников',
      limitExceededMessage:
        'Пожалуйста, свяжитесь с владельцем сообщества. Владелец данного сообщества может проверить лимиты плана подписки и изменить его.',
      limitExceededMessageOwner:
        'Пожалуйста, посетите страницу подписок, чтобы проверить лимиты вашего плана подписки или изменить его.',
      inactiveSubscriptionHeader: 'Ожидается подтверждения платежа',
      inactiveSubscriptionMessage:
        'Извините, это действие не может быть продолжено. Возможно, необходимо сначала завершить процесс оплаты.',
      subscriptionNotPaid:
        'Срок действия вашей подписки истек или подписка не оплачена.',
      contactAdmin: 'Пожалуйста, свяжитесь с администратором.',
      free: 'Бесплатный аккаунт',
      requireSubscription:
        'Gumb бесплатен для участников активных сообществ. Создайте свое собственное сообщество и начните бесплатный пробный период или измените свой тарифный план в разделе «Изменить тариф».',
      requireSubscriptionMember:
        'Действующая подписка не обнаружена. Вам ничего не нужно делать самостоятельно. Владелец сообщества может проверить в своем профиле в разделе "Подписка", активирована ли лицензия для этого сообщества и продлить ее при необходимости.',
      cancelConfirmation: 'Ты уверен, что хотешь отменить свою подписку?',
      unsubscribe: 'Отменить',
      cancelled:
        'Отмена подтверждена. На вашу электронную почту только что было отправлено подтверждение об отмене бронирования. Вы будете иметь доступ ко всем функциям до даты оплаченного расчета.',
      renewSubscriptionText:
        'Спасибо!\n\nВаша предыдущая подписка будет продлена, если вы активируете ее сейчас',
      activate: 'Активировать сейчас',
      activated:
        'Ваша подписка была успешно активирована. Мы очень рады, что теперь вы организуете и планируете с Gumb. Подтверждение вашего членства только что было отправлено на вашу электронную почту. Теперь ваша подписка действительна, и вы можете получить доступ ко всем функциям.',
      approved:
        'В настоящее время готовится подписка. Минутку, пожалуйста... Это может занять до 45 секунд. До этого момента не перезагружайте страницу и не закрывайте окно браузера.',
      trial: 'Пробная версия',
      trialActivated:
        'Ваш период пробного использования начался. Вы и ваши администраторы теперь можете начать планирование, приглашение участников и управление настройками.',
      cancelledTitle: 'Отменено',
      startTrial: 'НАЧНИТЕ БЕСПЛАТНЫЙ ТЕСТ',
      startTrialHeader: 'Долго тестировать!',
      startTrialText:
        'Чтобы ваше сообщество могло тщательно протестировать все функции в реальной работе, мы предлагаем полные 2 месяца для тестирования! Не требуется информация о платеже и скрытых расходов – просто тестируйте все функции со всеми участниками без беспокойств.',
      subscriptionExpired:
        'В настоящее время у этого сообщества нет активной подписки. Чтобы добавить новых пользователей, необходимо продлить подписку. По вопросам: support@gumb.app',
      startTrialPopupButton: 'Бесплатное тестирование',
      paymentMethods: 'Кредитная карта или Paypal',
      subscriptionsPopup: {
        title: 'Ваша подписка истекла',
        subtitle:
          'Ваша подписка истекла, но владелец вашего сообщества, <b>{{ownerName}}</b> может легко это изменить. <brPure />У каждого сообщества есть владелец, который отвечает за подписку. 👑',
        firstBox: {
          title:
            '<b>{{ownerName}}</b> <brPure />Bы хотите возобновить подписку и вернуть к жизни планирование команды?',
          btnText: 'Возобновить',
        },
        secondBox: {
          title:
            '<b>Для участников</b> <brPure />Хотите взять на себя подписку и стать владельцем сообщества? (Начните свою собственную подписку, свяжитесь с нами позже, и мы передадим вам право собственности.)',
          btnText: 'Стать владельцем',
        },
      },
    },
    terms: {
      title: 'Правила и Условия Gumb',
    },
    errors: {
      cantSendMessageToYourself: 'Нельзя отправить сообщение самому себе.',
      downloadFail: 'Ошибка загрузки, попробуйте снова.',
      required: 'Требуется поле',
      email: 'Пожалуйста, введите верный email',
      invalidEmail:
        'Ой! Пожалуйста, введите действительный адрес электронной почты. Проверьте на опечатки и попробуйте снова.',
      integer: 'Должно быть целым числом',
      confirmPassword: 'Пароли не совпадают. Пожалуйста, повторите попытку.',
      NotAuthorizedException: 'Неверное имя пользователя или пароль',
      invalidCode: 'Неверный код. Пожалуйста, повторите попытку.',
      errorToast: 'Ошибка: {{message}}',
      invalidRequest: 'Неверный запрос',
      passwordChanged: 'Пароль изменен',
      password:
        'Пароль должен содержать не менее 8 символов, буквы в верхнем и нижнем регистре и цифры .',
      termsError: 'Подтвердите согласие с Условиями и положениями',
      min: 'Минимум {{value}} символов',
      max: 'Максимум {{value}} символов',
      startDate: 'Дата не может быть раньше {{value}}',
      endDate: 'Дата не может быть раньше даты начала',
      fileSize: 'Превышен допустимый размер файла',
      fileSizeCustom:
        'Файл слишком большой: Максимальный размер - {{size}} МБ.',
      customFileSize:
        'Файл слишком большой: Макс {{size}} ГБ. Удалите и попробуйте снова.',
      unsupportedFormat: 'Неподдерживаемый формат файла',
      postalCode: 'Минимум 4 символа',
      sorry: 'Извините',
      text404: 'Похоже, искомая страница недоступна :(',
      url: 'Должен быть действительным URL.',
      text500: 'Что-то пошло не так :(',
      onlyForGroupAndAdmin:
        'Эта функция доступна только администраторам и руководителям групп',
      onlyForAdmin: 'Только администраторы могут использовать эту функцию.',
    },
    eventTypes: {
      createSuccess: 'Тип события успешно создан',
      editSuccess: 'Изменения были успешно обновлены',
      deleteSuccess: 'Тип события успешно удален',
    },
    eventTasks: {
      createSuccess: 'Задание события успешно создано',
      editSuccess: 'Изменения были успешно обновлены',
      deleteSuccess: 'Задание события успешно удалено',
    },
    eventResponses: {
      createSuccess: 'Ответ на событие успешно создан',
      editSuccess: 'Изменения были успешно обновлены',
      deleteSuccess: 'Ответ на событие успешно удален',
    },
    tips: {
      eventType:
        'Администратор сообщества может добавлять новые типы событий на странице настроек сообщества.',
    },
    frequencies: {
      doNotRepeat: 'Не повторять',
      daily: 'Ежедневно',
      weekly: 'Еженедельно',
      monthly: 'Ежемесячно',
      yearly: 'Каждый год',
      everyWorkingDay: 'Каждый будний день (с понедельника по пятницу)',
      other: 'Другое…',
    },
  },
};
