import React, { useMemo } from 'react';
import { isEmpty, map } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';

import { FileItem, FileInput, ContentPlaceholder, Loader } from 'components';
import { hasCommunityAdminPermissions, hasGroupAdminPermissions } from 'utils';
import {
  getGroupDocuments,
  getIsDocumentUploading,
} from 'store/selectors/documents';
import { IRootState } from 'store/reducers';
import {
  getCurrentCommunity,
  getCurrentUserInCommunity,
} from 'store/selectors/currentCommunity';
import { useDocumentation } from 'hooks';

import { FileInputContainer, FilesList, Headline } from './styled';

const Documentation = () => {
  const [searchParams] = useSearchParams();
  const querySearch = searchParams.get('querySearch') || '';
  const sortBy = searchParams.get('sort') || 'name-asc';
  const { t } = useTranslation();
  const { groupId } = useParams();
  const documents = useSelector((state: IRootState) =>
    getGroupDocuments(state, groupId),
  );

  const isUploading = useSelector(getIsDocumentUploading);
  const currentUser = useSelector(getCurrentUserInCommunity);
  const community = useSelector(getCurrentCommunity);

  const { onDelete, onSubmit, register, setValue } = useDocumentation(
    community.id,
    groupId,
  );

  const uploadBlockVisible =
    hasCommunityAdminPermissions(currentUser.id, community) ||
    hasGroupAdminPermissions(currentUser.id, community, groupId);

  const filteredDocuments = useMemo(() => {
    if (!documents) return [];

    let filtered = [...documents];

    // Apply search filter
    if (querySearch) {
      filtered = filtered.filter((doc) =>
        doc.name.toLowerCase().includes(querySearch.toLowerCase()),
      );
    }

    // Apply sorting
    switch (sortBy) {
      case 'name-asc':
        return filtered.sort((a, b) => a.name.localeCompare(b.name));
      case 'name-desc':
        return filtered.sort((a, b) => b.name.localeCompare(a.name));
      case 'date-asc':
        return filtered.sort(
          (a, b) =>
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
        );
      case 'date-desc':
        return filtered.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
        );
      default:
        return filtered;
    }
  }, [documents, querySearch, sortBy]);

  if (!(community || filteredDocuments)) {
    return <Loader />;
  }

  return (
    <>
      {uploadBlockVisible && (
        <FileInputContainer>
          <FileInput
            text={t('documentation.chooseFile')}
            tipText={t('documentation.tipMessage')}
            register={register}
            setValue={setValue}
            handleSubmit={onSubmit}
            name="document"
            width="100%"
            height="100%"
            isLoading={isUploading}
          />
        </FileInputContainer>
      )}
      <Headline>{t('documentation.uploadedFiles')}</Headline>
      <FilesList uploadBlockVisible={uploadBlockVisible}>
        {!isEmpty(filteredDocuments) ? (
          map(
            (document) => (
              <FileItem
                onDelete={onDelete}
                key={document.id}
                file={document}
                uploadBlockVisible={uploadBlockVisible}
              />
            ),
            filteredDocuments,
          )
        ) : (
          <ContentPlaceholder />
        )}
      </FilesList>
    </>
  );
};

export default Documentation;
