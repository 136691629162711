import { defaultButtonStyles } from 'components/Inputs/InvitationLinkInput/styled';
import styled from 'styled-components';
import { breakpoints } from 'theme';
import { IconWrapper } from './Message/styled';

export const Container = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  min-width: 172px;
  border-radius: 8px;
  &:hover ${IconWrapper} {
    opacity: 1;
  }
`;

export const Button = styled.button`
  ${defaultButtonStyles};
  padding: 0px 10px 0px 3px;
  border-radius: 99999px;
  background-color: #16330014;
  min-width: 26px;
  height: 26px;
  transition: all 0.2s ease-in-out;
  svg {
    fill: ${({ theme }) => theme.color.baliHai};
  }
  &:hover {
    background-color: #16330021;
    svg {
      fill: ${({ theme }) => theme.color.pickledBluewood};
    }
    p {
      color: ${({ theme }) => theme.color.pickledBluewood};
    }
  }
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  p {
    font-size: 14px;
    font-weight: 600;
    color: ${({ theme }) => theme.color.baliHai};
    font-family: 'Open Sans', sans-serif;
  }
`;

export const SvgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
`;

export const MenuItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  font-weight: 600;
`;

export const MenuItem = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  font-size: 14px;
  -webkit-text-decoration: none;
  text-decoration: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 20px 8px;
  color: ${({ theme }) => theme.color.baliHai};
  &:hover {
    color: ${({ theme }) => theme.color.pickledBluewood};
    background-color: #ebf2f7;
    svg {
      fill: ${({ theme }) => theme.color.pickledBluewood};
      path:nth-child(1) {
        fill: ${({ theme, onlySecond }) =>
          !onlySecond ? theme.color.pickledBluewood : 'transparent'};
      }
      path:nth-child(2) {
        fill: ${({ theme }) => theme.color.pickledBluewood};
      }
      path {
        fill: ${({ theme }) => theme.color.pickledBluewood};
      }
      circle {
        fill: ${({ theme }) => theme.color.pickledBluewood};
      }
    }
  }
  filter: ${({ disabled }) => (disabled ? 'blur(.6px)' : 'none')};
  cursor: ${({ isLoading }) => (isLoading ? 'not-allowed' : 'pointer')};
  pointer-events: ${({ isLoading, disabled }) =>
    isLoading || disabled ? 'none' : 'auto'};
`;

export const ImageIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Layout = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100svh;
  position: relative;
  transition: width 0.3s ease;
  align-items: center;
  @media ${breakpoints.downSmPlus} {
    position: absolute;
  }
  /* border-left: ${({ isOpen }) => (isOpen ? '1px' : '0px')} solid
    ${({ theme }) => theme.color.baliHai}; */
`;

Layout.Header = styled.div`
  min-height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ isGroup }) =>
    isGroup ? 'space-between' : 'flex-start'};
  padding: ${({ isOpen }) => (isOpen ? '0px 40px 0 40px' : '0 60px 0 80px')};
  background-color: ${({ theme }) => theme.color.aquaSqueeze};
  position: relative;
  @media ${breakpoints.downLgPlus} {
    padding: ${({ isOpen }) => (isOpen ? '0px 20px 0 20px' : '0 60px 0 80px')};
  }
  @media ${breakpoints.downMd} {
    padding: ${({ isOpen }) => (isOpen ? '0px 60px 0 20px' : '0 60px 0 80px')};
  }
`;

Layout.Header.Name = styled.span`
  width: calc(100% - 30px);
  @media ${breakpoints.downMdPlus} {
    width: calc(100% - 12px);
  }
  font-size: 14px;
  color: ${({ theme }) => theme.color.pickledBluewood};
  font-weight: bold;
  white-space: nowrap;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
`;

Layout.Header.Close = styled.span`
  margin-right: ${({ mr = '20px' }) => mr};
  transform: rotate(180deg);
  border-radius: 99999px;
  background-color: #16330014;
  min-width: ${({ width = '26px' }) => width};
  min-height: ${({ height = '26px' }) => height};
  transition: all 0.2s ease-in-out;
  path {
    stroke: ${({ theme, stroke }) => stroke ?? theme.color.baliHai};
  }
  &:hover {
    background-color: #16330021;
    path {
      stroke: ${({ theme, hoverStroke }) =>
        hoverStroke ?? theme.color.pickledBluewood};
    }
  }
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const ChatLayout = styled.div`
  display: flex;
  flex-direction: column;
  width: inherit;
  height: calc(100vh - 50px);
  align-items: center;
  justify-content: space-between;
`;

export const DivChat = styled.div`
  display: flex;
  overflow: auto;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
`;
export const ScrollBottomBtn = styled.div`
  display: flex;
  position: absolute;
  bottom: 1rem;
  right: 48%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  transform: rotate(270deg);
  z-index: 500;
  background-color: aliceblue;
  border-radius: 99999px;
  transition: all 0.2s ease-in-out;
  background-color: #16330014;
  justify-content: center;
  align-items: center;
  path {
    stroke: ${({ theme, stroke }) => stroke ?? theme.color.baliHai};
  }
  &:hover {
    background-color: #16330021;
    path {
      stroke: ${({ theme, hoverStroke }) =>
        hoverStroke ?? theme.color.pickledBluewood};
    }
  }
`;

export const Wrapper = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: ${({ theme }) => theme.color.pickledBluewood};
  font-size: 16px;
  font-weight: 600;
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MessageWrapper = styled.div`
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? 'row' : 'row-reverse')};
  z-index: 10;
  padding: 0px 22px;
  @media ${breakpoints.downMd} {
    padding: 0px 12px;
  }
`;

export const RedirectWrapper = styled.div`
  position: relative;
  width: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PlaceholderContainer = styled.div`
  display: flex;
  justify-content: center;
  width: inherit;
  height: inherit;
  align-items: center;
`;

export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% - 160px);
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
  div:first-child {
    width: auto;
    img {
      cursor: pointer;
    }
    div {
      border: 2px solid ${({ theme }) => theme.color.brightTurquoise};
    }
    span {
      font-size: 16px;
      font-weight: bold;
      color: ${({ theme }) => theme.color.black};
    }
  }
`;

export const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
`;

export const Description = styled.span`
  color: ${({ theme }) => theme.color.baliHai};
  font-size: 10px;
  line-height: 12px;
  text-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
  white-space: nowrap;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  width: calc(100% - 30px);
  @media ${breakpoints.downMdPlus} {
    width: calc(100% - 12px);
  }
`;
